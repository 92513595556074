import Util from './util';

export function asyncFail(handler, message, extra) {
  if (handler) {
    handler(message, extra);
  } else {
    throw new Error(message);
  }
}

/**
 * These are a bit like Twisted/MochiKit Deferred objects, but with
 * somewhat different rules. An Async object represents an
 * asynchronous action. This can either succeed or fail. For this to
 * occur, two things have to happen -- the event-producing code has to
 * call .ok or .fail on the object, and the event-consuming code has
 * to call .wait on it. If one of these doesn't happen, the async
 * never runs.
 *
 * Any amount of extra functions that must run (on succeed, on fail,
 * or both) can be registered using the .protect, .add, and .always
 * methods. The second (fail) argument to .wait is treated specially,
 * and will cause a default handler to be run if it is not given.
 * Override asyncFail to customise failure behaviour.
 *
 *
 * @constructor
 * @template T
 */
export function Async() {
  this.onOk = [];
  this.onFail = [];
  this.finalFail = null;
  this.fired = false; // Set to true if ok or fail method was invoked.
  this.ready = false; // Set to true when wait method is called.
  this.other = false;
  /* Other unmentioned properties:
     this.value: Set by the prototype ok callback
  */
}
Async.prototype.run = function run() {
  var i;
  if (this.error !== undefined) { // True if prototype fail function ever fired.
    // Call all registered onFail functions
    for (i = 0; i < this.onFail.length; ++i) {
      this.onFail[i](this.error, this.extra);
    }
    window.asyncFail(this.finalFail, this.error, this.extra);
  } else {
    // Call all registered onOk functions
    for (i = 0; i < this.onOk.length; ++i) {
      this.onOk[i](this.value);
    }
  }
};
Async.prototype.ok = function ok(value, other) {
  if (this.fired) {
    throw new Error('Async fired more than once.');
  }
  this.fired = true;
  this.value = value;
  this.other = other;
  if (this.ready) {
    this.run();
  }
};
Async.prototype.fail = function fail(message, extra) {
  if (this.fired) {
    throw new Error('Async failed more than once.');
  }
  this.fired = true;
  this.error = message === undefined ? true : message;
  this.extra = extra;
  if (this.ready) {
    this.run();
  }
};

/* Returns 'undefined' */
Async.prototype.wait = function wait(ok, fail) {
  if (this.ready) {
    throw new Error('Async waited on more than once.');
  }
  this.ready = true;
  if (ok) {
    this.onOk.push(ok);
  }
  this.finalFail = fail;
  if (this.fired) {
    this.run();
  }
};

/* Returns a new Async which is like the original Async
   but whose ok-callback-value will be transformed by
   'func' beforehand */
Async.prototype.transform = function transform(func) {
  var result = new Async();
  /* When .ok is called on the original Async, take the value that it was
     called with and transform it using 'func'.  Then call the .ok method
     on the new Async with the transformed value.
  */
  this.wait(// Ok callback:
            function (val) {
              result.ok(func(val));
            },
            // Fail callback:
            result.fail.bind(result));
  return result;
};

/**
 * Returns a transformed promise that invokes given success and failure handlers.
 *
 * The behavior of the result depends on the value X returned by the handler.
 *
 * If X is a promise, the result will adopt its state. That is, the result will
 * be resolved when X is resolved and with the same outcome.
 *
 * If X is not a promise, the result will simply resolve successfully to X.
 *
 * @param {Function} okCallback - Success handler.
 * @param {Function} [failCallback] - Failure handler.
 * @return {Async} - the transformed promise.
 */
Async.prototype.then = function (okCallback, failCallback) {
  var result = new Async();

  okCallback = okCallback || Async.dummy.bind(Async);
  failCallback = failCallback || Async.failure.bind(Async);

  function processMappedValue(mapped) {
    if (mapped instanceof Async) {
      mapped.wait(result.ok.bind(result), result.fail.bind(result));
    } else {
      result.ok(mapped);
    }
  }

  this.wait(function () {
    processMappedValue(okCallback.apply(null, arguments));
  }, function (message, extra) {
    processMappedValue(failCallback(message, extra));
  });

  return result;
};

/**
 * Provide a failure handler for a promise.
 *
 * The result is equivalent to calling then(undefined, callback). If the
 * promise succeeds no change is made, otherwise the handler is invoked.
 * The handler may return either a direct value or another promise.
 *
 * @param {Function} callback - Failure handler.
 * @return {Async} - Promise with installed failure handler.
 */
Async.prototype.catch = function (callback) {
  return this.then(undefined, callback);
};

Async.prototype.always = function always(func) {
  this.onOk.push(func);
  this.onFail.push(func);
  return this;
};
Async.prototype.protect = function protect(onFail) {
  this.onFail.push(onFail);
  return this;
};
Async.prototype.add = function add(onOk) {
  this.onOk.push(onOk);
  return this;
};
/**
 * Adds a timeout to a promise.
 *
 * This will return a fresh promise that will be resolved when
 * either the original promise is resolved or the timeout expires.
 *
 * If the timeout occurs before the original promise is resolved,
 * the promise returned will fail with an error message.
 *
 * @param {number} ms - Timeout, in milliseconds.
 * @return {Async} - Promise with timeout.
 */
Async.prototype.withTimeout = function (ms) {
  var self = this;
  var result = new Async();

  // Create a timeout to call fail if the original
  // promise is still not resolved.
  var timer = window.setTimeout(function () {
    if (!self.fired) {
      result.fail('Timed out', ms);
    }
  }, ms);

  // When the original promise is resolved, clear the timeout.
  result.always(function () {
    window.clearTimeout(timer);
  });

  // Clone the result of the original promise.
  this.wait(result.ok.bind(result), result.fail.bind(result));

  return result;
};

/**
 * Constructs a promise that is immediately resolved with the given value.
 *
 * @param {*} [value] - Value to resolve the promise with.
 * @return {Async} - a resolved promise.
 */
Async.dummy = function (value) {
  var result = new Async();
  result.ok(value);
  return result;
};

/**
 * Constructs a promise that is immediately resolved with a failure.
 *
 * @param {string} [message] - Failure message.
 * @param {*} [extra] - additional error information.
 * @return {Async} - a resolved promise.
 */
Async.failure = function (message, extra) {
  var result = new Async();
  result.fail(message, extra);
  return result;
};

export function MAsync() {
  Async.call(this);
  return this;
}
MAsync.prototype = Util.clone(Async.prototype);
MAsync.prototype.run = function run() {
  if (this.error) {
    Async.prototype.run.call(this);
  } else {
    for (var i = 0; i < this.onOk.length; ++i) {
      this.onOk[i].apply(null, this.value);
    }
  }
};

MAsync.collect = function collect(/* asyncs */) {
  var result = new MAsync();
  var asyncs = arguments;

  function fail(a, b) {
    result.fail(a, b);
  }

  var i = 0;
  var accum = [];
  function cont(value) {
    accum.push(value);
    i++;
    if (i < asyncs.length) {
      asyncs[i].wait(cont, fail);
    } else {
      result.ok(accum);
    }
  }
  if (asyncs.length) {
    asyncs[0].wait(cont, fail);
  } else {
    result.ok([]);
  }
  return result;
};
