import namespaceSectionTemplate from './namespace-section.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
__out.push(namespaceSectionTemplate.template($arg.userOptionCtrl));
__out.push("\n\n");
__out.push(namespaceSectionTemplate.template($arg.repoOptionCtrl));
__out.push("\n\n");
__out.push(namespaceSectionTemplate.template($arg.defaultOptionCtrl));
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;