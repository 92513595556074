import Mold from './lib/mold';

import loading from './templates/instruction/loading.mold';
import reloadTriplerows from './templates/instruction/reload_triplerows.mold';
import loadingmore from './templates/instruction/loadingmore.mold';
import triplerows from './templates/instruction/triplerows.mold';
import nodeinputhelp from './templates/instruction/nodeinputhelp.mold';
import part from './templates/instruction/part.mold';
import importcontexttitle from './templates/instruction/importcontexttitle.mold';
import throb from './templates/instruction/throb.mold';
import jsonoutput from './templates/instruction/jsonoutput.mold';
import multinput from './templates/instruction/multinput.mold';
import more from './templates/instruction/more.mold';
import triples from './templates/instruction/triples.mold';
import canceling from './templates/instruction/canceling.mold';
import help from './templates/instruction/help.mold';
import namespaceTable from './templates/instruction/namespace_table.mold';
import queryOptionTable from './templates/instruction/query_option_table.mold';
import copyable from './templates/instruction/copyable.mold';

export function defineMoldInstructions() {
  Mold.define('loading', loading.template);
  Mold.define('reload_triplerows', reloadTriplerows.template);
  Mold.define('loadingmore', loadingmore.template);
  Mold.define('triplerows', triplerows.template);
  Mold.define('nodeinputhelp', nodeinputhelp.template);
  Mold.define('part', part.template);
  Mold.define('importcontexttitle', importcontexttitle.template);
  Mold.define('throb', throb.template);
  Mold.define('jsonoutput', jsonoutput.template);
  Mold.define('multinput', multinput.template);
  Mold.define('more', more.template);
  Mold.define('triples', triples.template);
  Mold.define('canceling', canceling.template);
  Mold.define('help', help.template);
  Mold.define('namespace_table', namespaceTable.template);
  Mold.define('query_option_table', queryOptionTable.template);
  Mold.define('copyable', copyable.template);
}
