var ProcessInfoGraph = {};

ProcessInfoGraph.getFreshProcessInfoGraph = function () {
  return [
    {
      div: '#PageFaultChart',
      description: 'Minor and major pagefaults',
      lines: [
        { key: 'pid~a.minflt', label: 'Minor page faults', color: 'rgba(240,10,10,0.7)' },
        { key: 'pid~a.majflt', label: 'Major page faults', color: 'rgba(20,220,20,0.7)' },
      ],
      gOptions: {
        series: {
          stack: null,
          lines: {
            show: true,
            fill: null,
            steps: null,
          } },
        yaxis: {
          minTickSize: 1,
          tickDecimals: 0 } },
    },
    {
      div: '#MemoryChart',
      description: 'Memory used by process',
      lines: [
        { key: 'pid~a.vsize', label: 'Virtual memory', color: 'rgba(240,10,10,0.7)' },
        { key: 'pid~a.rss', label: 'RSS', color: 'rgba(20,220,20,0.7)' },
      ],
      gOptions: {
        series: {
          stack: null,
          lines: {
            show: true,
            fill: null,
            steps: null,
          } },
        yaxis: {
          minTickSize: 1,
          tickDecimals: 0 } },
    },
    {
      div: '#CPUChart',
      description: 'CPU used by process',
      lines: [
        { key: 'pid~a.utime', label: 'USER', color: 'rgba(240,10,10,0.7)' },
        { key: 'pid~a.stime', label: 'SYS', color: 'rgba(20,220,20,0.7)' },
      ],
      gOptions: {
        series: {
          stack: true,
          lines: {
            show: true,
            fill: null,
            steps: null,
          } },
        yaxis: {
          minTickSize: 1,
          tickDecimals: 0 } },
    },
    // TODO: this does not take into account internal lisp threads.
    // {
    //     div: '#Threads',
    //     description: 'Number of threads in the process',
    //     lines: [
    //         { key: 'pid~a.threads', label: 'Threads', color: 'rgba(240,10,10,0.7)' },
    //     ],
    //     gOptions: {
    //         series: {
    //             stack: null,
    //             lines: {
    //                 show: true,
    //                 fill: null,
    //                 steps: null,
    //             } },
    //         yaxis: {
    //             minTickSize: 1,
    //             tickDecimals: 0 } },
    // }
  ];
};


ProcessInfoGraph.initializeKeysForPid = function (pid) {
  var graph = ProcessInfoGraph.getFreshProcessInfoGraph();
  graph.forEach(function (field) {
    field.lines.forEach(function (line) {
      line.key = line.key.replace('~a', pid);
    });
  });
  return graph;
};

export default ProcessInfoGraph;
