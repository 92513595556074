import Util from '../util';
import Base from '../base';
import { $$ } from '../util';
import $ from 'jquery';
import Dispatch from '../dispatch';
import setImmediate from '../set-immediate';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function showRunScript() {
  $('#scriptRunForm').toggle($$('doActionValue').value === 'script');
}

__out.push("\n\n<form class=\"pure-form pure-form-aligned\">\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
  
  $arg.ok({whenDay: Util.simpleDate($arg.getWhenDay()),
           whenTime: $node.elements.whenTime.value, 
           repeatEvery: Util.selectValue($node.elements.repeatEvery),
           repeatUntil: Util.simpleDate($arg.getRepeatUntil()),
           doAction: Util.selectValue($node.elements.doActionValue),
           scriptRun: $node.elements.scriptRun.value,
           eventName: $node.elements.eventName.value,
           emailOK:  $node.elements.emailOK.value,
           emailFail: $node.elements.emailFail.value,
           });

});}) + "\"></var>");
__out.push("\n <div id=\"addEventDialog\">\n ");
if (!$arg.vals.smtpConfig ) {
__out.push("\n   <b>Note: There is no SchedulerSMTPConfig in the AllegroGraph configuration file (usually agraph.cfg).  As a result\nnotifications cannot be sent until the the server is restarted with\na SchedulerSMTPConfig specified.</b>\n ");
}
__out.push("\n <fieldset>\n   <div class=\"pure-control-group\">\n     <label for=\"whenDay\">Day:</label>\n     <input name=\"whenDay\" id=\"whenDay\"/>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.getWhenDay = Util.dateInput($node); }) + "\"></var>");
__out.push("\n     at <input name=\"whenTime\" id=\"whenTime\" type=\"time\" required\n            value=\"");
__out.push(Mold.escapeHTML($arg.vals.eventTime ));
__out.push("\"/>\n     hh:mm -- <i>The timezone is that of the browser.</i>\n     </div>\n\n   <div class=\"pure-control-group\">\n     <label for=\"repeatEvery\">Repeat every:</label>\n     <select id=\"repeatEvery\">\n      ");
Mold.forDepth++;
Mold.forEach($arg.vals.repeatChoices, function(repeatOption, $i) {
__out.push("\n        <option value=\"");
__out.push(Mold.escapeHTML(repeatOption ));
__out.push("\">");
__out.push(Mold.escapeHTML(repeatOption ));
__out.push("</option>\n      ");
});
Mold.forDepth--;
__out.push("\n     </select>\n   </div>\n\n   <div class=\"pure-control-group\">\n     <label for=\"repeatUntil\">Repeat until:</label>\n     <input name=\"repeatUntil\" id=\"repeatUntil\"/>\n     ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.getRepeatUntil = Util.dateInput($node); }) + "\"></var>");
__out.push("\n   </div>\n\n   <div class=\"pure-control-group\">\n     <label for=\"doAction\">Do:</label>\n     <select name=\"doActionValue\" id=\"doActionValue\">\n      ");
Mold.forDepth++;
Mold.forEach($arg.vals.actionChoices, function(selopt, $i) {
__out.push("\n        <option value=\"");
__out.push(Mold.escapeHTML(selopt ));
__out.push("\">");
__out.push(Mold.escapeHTML(selopt ));
__out.push("</option>\n      ");
});
Mold.forDepth--;
__out.push("\n     </select>\n     ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
showRunScript();
});}) + "\"></var>");
__out.push("\n    </div>\n\n    <div class=\"pure-control-group\" id=\"scriptRunForm\" style=\"display:none;\">\n      <label for=\"scriptRun\">Script file to run:</label>\n      <input name=\"scriptRun\" id=\"scriptRun\" size=\"40\"/>\n    </div>\n\n    <div class=\"pure-control-group\">\n      <label for \"eventName\">Event Name:</label>\n      <input name=\"eventName\" id=\"eventName\"/>\n    </div>\n\n    <div class=\"pure-control-group\">\n      <label for \"emailOK\">Email on success:</label>\n      <input name=\"emailOK\" id=\"emailOK\"/>  e.g. user1@domain1.com,user2@domain2.com\n    </div>\n\n    <div class=\"pure-control-group\">\n      <label for \"emailFail\">Email on failure:</label>\n      <input name=\"emailFail\" id=\"emailFail\"/>  e.g. user1@domain1.com,user2@domain2.com\n    </div>\n\n   <div class=\"pure-controls\">\n      <button type=\"button\" class=\"pure-button action-button\">Close Dialog</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.closeDialog();
});}) + "\"></var>");
__out.push("\n      <button type=\"submit\" id=\"addEventButton\" class=\"pure-button pure-button-primary\">Add Event</button>\n    </div>\n\n </fieldset>\n</form>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function () {
  showRunScript();
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;