import $ from 'jquery';
import { $$ } from '../../util';
import Util from '../../util';
import setImmediate from '../../set-immediate';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function submit() {
     if(!$$("loginButtonOK").disabled) {
       $arg.ok(
       $$("login-name").value,
       $$("login-password").value,
       $$("login-keep").checked);
     }
   }

__out.push("\n<table width=\"100%\">\n  <tr>\n    <td>\n      <label for=\"login-name\">Username:</label>\n      <input type=\"text\" id=\"login-name\" style=\"width: 18em;\" linkedButton=\"loginButtonOK\"/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "keyup", function($event, $node) {
Util.maybeEnableLinkedButton($node); 
});}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();}) + "\"></var>");
__out.push("\n      <br/>\n      <label for=\"login-password\">Password:</label>\n      <input type=\"password\" id=\"login-password\" style=\"width: 18em;\"/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      <br/>\n      <input class=\"centered-checkbox\" type=\"checkbox\" id=\"login-keep\"/> <label for=\"login-keep\">Stay logged in</label>\n    </td>\n    <td class=\"buttons\">\n      <button id=loginButtonOK>OK</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      <button id=loginButtonCancel>Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;