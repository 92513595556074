import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h3>Result</h3>\n\n");
if ($arg.format === 'text/plain') {
__out.push("\n  <pre>");
__out.push(Mold.escapeHTML($arg.text));
__out.push("</pre>\n");
} else if ($arg.format === 'application/json') {
__out.push("\n  <div class=\"jsontree\">");
Mold.dispatchCustom("jsonoutput", {value: Util.readJSON($arg.text), depth: 0}, __out);
__out.push("</div>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;