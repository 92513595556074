import Layout from '../../layout';
import Util from '../../util';
import helpTooltipTemplate from '../help-tooltip.mold';

import { $$ } from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var extraButtonClass = '';
    if (/^~/.test($arg)) {
      $arg = $arg.substring(1);
      extraButtonClass = 'ag-help-icon--small';
    }
    // Container is stored here in the run block and accessed by the event block
    var container;

__out.push("\n<span class=\"ag-help-icon ");
__out.push(Mold.escapeHTML(extraButtonClass));
__out.push("\" title=\"Click for more info\">\n  <i class=\"fas fa-question-circle\"></i>\n</span>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){var msg = {
      close: toggle,
    };
    // Check if a container div was explicitly specified
    var match = /^\s*container=(\S+)\s*/.exec($arg);
    if (match) {
      // Remove the container spec from the message
      msg.message = $arg.substring(match[0].length);

      // Save the container node
      container = $$(match[1]);
    } else {
      msg.message = $arg;
      // If a container was not specified - create one
      container = document.createElement('div');
      // Find the parent node - escape from any number of span elements
      // to make this work better with pure-css forms
      var parent = $node.parentNode;
      var current = $node;
      while (parent.tagName.toLowerCase() === 'span') {
        current = parent;
        parent = parent.parentNode;
      }
      // This will work even if $node.nextSibling is null
      container = parent.insertBefore(container, current.nextSibling);
    }
    // Add message text to the container
    helpTooltipTemplate.cast(container, msg);
    // Hide the container
    container.style.display = 'none';

    Util.connect($node, 'click', toggle);

    function toggle() {
      if (container.style.display === 'none') {
        container.style.display = 'block';
        $node.classList.add('ag-help-icon-active');
      } else {
        container.style.display = 'none';
        $node.classList.remove('ag-help-icon-active');
      }
    }
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;