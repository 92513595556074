import Dispatch from '../../dispatch';
import User from '../../user';
import Reports from '../../reports';
import App from '../../app';

import reportTemplate from './report.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
__out.push(reportTemplate.template($arg) );
__out.push("\n\n");
if (User.userAccess('W') && !App.currentShard ) {
__out.push("\n  <a id=\"manage-indices-link\"\n     href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl(App.shardPrefix() + 'indices') ));
__out.push("\"\n     class=\"pure-button pure-button-primary\">\n    Manage triple indices\n  </a>\n");
}
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;