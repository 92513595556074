import Base from '../../base';
import User from '../../user';
import Store from '../../store';
import { $$ } from '../../util';

import textIndexTemplate from './text-index.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Free-text indices</h2>\n\n");
if (!$arg.length) {
__out.push("<p>No free-text indices defined.</p>");
}
__out.push("\n\n");
Mold.forDepth++;
Mold.forEach($arg , function(index, $i) {
__out.push("\n  ");
__out.push(textIndexTemplate.template(index, __out));
__out.push("\n");
});
Mold.forDepth--;
__out.push("\n\n");
if (User.userAccess("w")) {
__out.push("\n  <p style=\"margin-top: 1em\">\n    Create an index named\n    <input id=\"ftiname\" value=\"\" type=\"text\" width=\"6em\"/>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
Store.createFTI($$("ftiname").value);
});}) + "\"></var>");
__out.push("\n    <span class=\"linkish\">[create]</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.createFTI($$("ftiname").value);
});}) + "\"></var>");
__out.push("\n  </p>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;