import Base from '../../base';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"small-form\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok(
        Base.multSelectValues($node.elements.withRules),
        $node.elements.useTypeSubproperty.checked,
        $node.elements.commit.value);
  
});}) + "\"></var>");
__out.push("\n  <table width=\"100%\">\n    <tr>\n      <td>\n        <label>Rules:</label>\n          <select class=\"filepicker\" name=\"withRules\" id=\"withRules\" linkedButton=\"matOK\" multiple>\n            <option value=\"same-as\">Same-As</option>\n            <option value=\"restriction\">Restriction</option>\n            <option value=\"values-from\">Values-From</option>\n            <option value=\"class\">Class</option>\n            <option value=\"property\">Property</option>\n          </select>\n          ");
Mold.dispatchCustom("help", " container=materializer-rules-help\n                 Select zero or more materialization rules to be added to the basic rdfs++ rules.", __out);
__out.push("\n      </td>\n      <td>\n        <label>Commit:</label>\n          <input type=\"text\" name=\"commit\" style=\"width: 20em\" value=\"100000\"/>\n          ");
Mold.dispatchCustom("help", " container=materializer-commit-help\n                 \"Commit\" value is used to specify the size of materialization commits.", __out);
__out.push(" (optional)\n        <br>\n        <label>Use Type Subproperty:</label>\n        <input class=\"centered-checkbox\" type=\"checkbox\" name=\"useTypeSubproperty\" value=\"false\"/>\n        ");
Mold.dispatchCustom("help", " container=materializer-uts-help\n               When \"Use Type Subproperty\" is enabled and possible, the materializer prefers using types which are\n               rdfs:subPropertyOf rdf:type rather than rdf:type directly.", __out);
__out.push("\n      </td>\n    </tr>\n    <tr><td colspan=\"2\"><div id=\"materializer-rules-help\"></div></td></tr>\n    <tr><td colspan=\"2\"><div id=\"materializer-commit-help\"></div></td></tr>\n    <tr><td colspan=\"2\"><div id=\"materializer-uts-help\"></div></td></tr>\n    <tr>\n      <td></td>\n      <td class=\"buttons\">\n        <button id=\"matOK\" type=\"submit\">OK</button>\n        <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n  </table>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;