import Base from '../../base';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.loaded !== $arg.total ) {
__out.push("\n  Uploading ");
__out.push(Mold.escapeHTML($arg.name ));
__out.push(": ");
__out.push(Mold.escapeHTML(Base.printBytes($arg.loaded)));
__out.push(" / ");
__out.push(Mold.escapeHTML(Base.printBytes($arg.total)));
__out.push("\n");
} else {
__out.push("\n  Upload complete. Importing triples from ");
__out.push(Mold.escapeHTML($arg.name ));
__out.push("\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;