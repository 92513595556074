import { $$ } from '../../util';
import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if (($$("auditLog")) ) {
__out.push("\n<h2>Auditing Events</h2>\n");
} else {
__out.push("\n");
}
__out.push("\n\n<div class=\"query-result\" id=\"query-result-space\"></div>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(Mold.setLabel("queryResultSpace")) + "\"></var>");
__out.push("\n\n");
if ($arg.canDownload()) {
__out.push("\n   <div id=\"query-download\">\n    <button>Download</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.download(Util.selectValue($$("query-result-format")));
});}) + "\"></var>");
__out.push(" using format\n    <select id=\"query-result-format\">\n      ");
Mold.forDepth++;
Mold.forEach($arg.getDownloadContentTypes(), function(type, $i) {
__out.push("\n        ");
if (type[3]) {
__out.push("\n          <option value=\"");
__out.push(Mold.escapeHTML(type[1]));
__out.push("\">");
__out.push(Mold.escapeHTML(type[0]));
__out.push("</option>\n        ");
}
__out.push("\n      ");
});
Mold.forDepth--;
__out.push("\n    </select>\n  </div>\n");
}
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;