import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("<h2>Manage prepared transactions</h2>\n");
if ($arg.data && $arg.data.length > 0 ) {
__out.push("\n  <h3>Manage prepared transactions</h3>\n  <ul>\n  ");
Mold.forDepth++;
Mold.forEach($arg.data , function(transaction, $i) {
__out.push("\n    <li>\n      ");
__out.push(Mold.escapeHTML(transaction.xid ));
__out.push(" started at ");
__out.push(Mold.escapeHTML(new Date(1000 * transaction.start).toLocaleString() ));
__out.push(".\n      <button class=\"pure-button\">Force commit</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.commit(transaction.xid); 
});}) + "\"></var>");
__out.push("\n      <button class=\"pure-button\">Force rollback</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.rollback(transaction.xid); 
});}) + "\"></var>");
__out.push("\n    </li>\n  ");
});
Mold.forDepth--;
__out.push("\n  </ul>\n");
} else {
__out.push("\n<h3>There are no XA transactions in prepared state.</h3>\n");
}
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;