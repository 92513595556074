import Util from './util';
import $ from 'jquery';
import _ from 'underscore';
import { $$ } from './util';
import alertTemplate from './templates/message/alert.mold';
import confirmTemplate from './templates/message/confirm.mold';
import promptTemplate from './templates/message/prompt.mold';


/**
 * Apart from the header stuff, the page contains two basic areas: The
 * main page area, where the stuff that the current URL fragment
 * refers to is shown, and the message area, which is used for dynamic
 * messages, alert, etcetera.
 *
 * @namespace Layout
 */
var Layout = {};

// How long should simple, success messages reamin visible (in seconds)).
Layout.SHORT_DELAY = 2;

// How long should more complex messages (e.g. about import cancellation
// or failure of a non-essential operation) remain visible, in seconds.
Layout.LONG_DELAY = 5;

var message;
var page;
// Produce initial page layout, save nodes to put dynamic content
// into.
Layout.initLayout = function (msg, pg) {
  message = msg;
  page = pg;
};

// Clear all non-persistent messages, and the main page.
Layout.clearPage = function () {
  var toRemove = [];
  Util.forEach(message.childNodes, function (node) {
    if (node.disappear) {
      toRemove.push(node);
    }
  });
  Util.forEach(toRemove, Util.removeNode.bind(Util));

  $(page).html('');
  window.scrollTo(0, 0);
};

// The main content area.
Layout.getPage = function () {
  return page;
};

var verticalMargin = 15;

function smooth(t) {
  function once(t) {
    return 0.5 - 0.5 * Math.cos(t * Math.PI);
  }
  return once(once(t));
}

// TODO: Rewrite this in a way acceptable in the XXI century.
// Fade and remove a message node.
function fadeOut(node) {
  var FADEOUT_STEP = 0.08; // Change opacity by this much in each step
  var FADEOUT_DELAY = 100; // Step duration in ms
  var t = 1;
  var height = node.offsetHeight + verticalMargin;
  var interval = setInterval(function () {
    t -= FADEOUT_STEP;
    if (t <= 0 || !node.parentNode) {
      if (node.parentNode) {
        Util.removeNode(node);
      }
      clearInterval(interval);
    } else {
      Util.setOpacity(node, t);
      node.style.marginBottom = '-' +
        Math.round((1 - smooth(t)) * height) + 'px';
    }
  }, FADEOUT_DELAY);
}

/**
 * Create a new message node.
 *
 * @param {string|null} id -
 *   The id to assign to the new div.
 * @param {boolean|number} disappear -
 *   if true, the node will stay until the page is cleared;
 *   if false, the node stays until closed explicitly
 *   if a number, the node will fade out after that many seconds.
 * @param {boolean} important - determines the style of the
 *   message, and whether to scroll it into view
 * @return {HTMLDivElement} - the created message node
 */
function messageDIV(id, disappear, important) {
  var container = document.createElement('div');
  container.id = id;
  container.className =
    'message ' +
    (important ? 'important' : 'regular') +
    ((typeof disappear === 'number') ? ' disappear' : '');
  container.disappear = disappear;
  if (typeof disappear === 'number') {
    setTimeout(
      _.partial(fadeOut, container), Math.round(disappear * 1000));
  }

  message.appendChild(container);
  // Dissapearing messages are shown with a fixed position,
  // so it makes no sense to scroll.
  if (important && typeof disappear !== 'number') {
    window.scrollTo(0, 0);
  }
  return container;
}

Layout.removeMessages = function () {
  $('#messages >').remove();
};

// A simple alert-like message. Optional id can be used to search for
// displayed messages.
Layout.showMessage = function (text, disappear, important, ok, id) {
  var div = messageDIV(id, disappear, important);
  alertTemplate.cast(div, {
    text: text,
    close: function () {
      Util.removeNode(div);
      if (ok) {
        ok();
      }
    },
  });
};

/**
 * Yes/no question dialog.
 *
 * @param {string} id - id of the DIV containing the dialog. If this DIV
 *   is not already present in the DOM, it will be created.
 * @param {string} text - the question to show
 * @param {Function} [yes] - action to perform when user confirms the dialog
 * @param {Function} [no] - action to perform when user rejects the dialog
 * @param {boolean|number} [disappear] -
 *   if true, the node will stay until the page is cleared;
 *   if false, the node stays until closed explicitly
 *   if a number, the node will fade out after that many seconds.
 * @param {boolean} [important] - determines the style of the
 *   message, and whether to scroll it into view
 * @param {object} [options] - Additional options.
 * @param {string} [options.yesText] - Label for the 'Yes' button, the default is 'Yes'.
 * @param {string} [options.noText] - Label for the 'No' button, the default is 'No'.
 */
Layout.showDialog = function (id, text, yes, no, disappear, important, options) {
  if (id !== null && Util.scrollToIfPresent(id)) {
    return;
  }

  if (options === undefined) {
    options = {};
  }
  var div = messageDIV(id, disappear, important);
  confirmTemplate.cast(div, {
    text: text,
    yesText: options.yesText === undefined ? 'Yes' : options.yesText,
    noText: options.noText === undefined ? 'No' : options.noText,
    yes: function () {
      if (yes) {
        yes();
      }
      Util.removeNode(div);
    },
    no: function () {
      if (no) {
        no();
      }
      Util.removeNode(div);
    },
  });
  Util.scrollToIfPresent(id);
};

// Text input message.
Layout.showPrompt = function (id, label, ok, cancel, disappear, important) {
  if (id !== null && Util.scrollToIfPresent(id)) {
    return;
  }
  let div = messageDIV(id, disappear, important);
  promptTemplate.cast(div, {
    id: id,
    label: label,
    ok: function (text) {
      if (ok) {
        ok(text);
      }
      Util.removeNode(div);
    },
    cancel: function () {
      if (cancel) {
        cancel();
      }
      Util.removeNode(div);
    },
  });
  Util.scrollToIfPresent(id);
};

// Create a message from a custom template.
// Returns a function that can be used to close the dialog.
Layout.customDialog = function (id, templ, data, disappear, important) {
  if (id !== null && Util.scrollToIfPresent(id)) {
    return null;
  }

  var div = messageDIV(id, disappear, important);
  templ.cast(div, data);
  Util.scrollToIfPresent(id);
  return function () {
    if (div.parentNode) {
      Util.removeNode(div);
      return true;
    } else {
      return false;
    }
  };
};

Layout.openMenu = function (link, menu, moveLeft) {
  moveLeft = moveLeft || 0;
  var header = $$('header-links');
  var left = Util.totalOffset(link).x;
  var top = Util.totalOffset(header).y + header.offsetHeight;
  var base = Util.totalOffset(menu.offsetParent);
  var mover;
  var mout;
  var mup;
  // no idea where this comes from....
  var MAGIC_NUMBER = 5;
  // In ms
  var CLOSE_DELAY = 1500;

  menu.style.left = (left - base.x - MAGIC_NUMBER + moveLeft) + 'px';
  menu.style.top = (top - base.y - 1) + 'px';
  menu.style.display = 'block';
  function close() {
    menu.style.display = 'none';
    mover();
    mout();
    mup();
  }
  var closeTm = null;
  mover = Util.connect(menu, 'mouseover', function (e) {
    var n = e.target;
    while (n) {
      if (n === menu) {
        clearTimeout(closeTm);
        return;
      }
      n = n.parentNode;
    }
  });
  mout = Util.connect(menu, 'mouseout', function (e) {
    if (e.target === menu) {
      var n = e.relatedTarget || e.toElement;
      while (n) {
        if (n === menu) {
          return;
        }
        n = n.parentNode;
      }
      closeTm = setTimeout(close, 500);
    }
  });
  var moutLink = Util.connect(link, 'mouseout', function () {
    closeTm = setTimeout(close, CLOSE_DELAY);
    moutLink();
  });
  mup = Util.connect(document, 'mouseup', close);
};

export default Layout;
