import User from '../user';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div id=\"jobDialog\" class=\"dialog\">\n\n  <h2>Jobs</h2>\n\n  <ul>\n    ");
Mold.forDepth++;
Mold.forEach($arg.jobs, function(job, $i) {
__out.push("\n      <li id=\"job--");
__out.push(Mold.escapeHTML(job.uuid ));
__out.push("\"\n      >");
if (job.catalog ) {
__out.push(Mold.escapeHTML(job.catalog ));
__out.push("/");
}
__out.push(Mold.escapeHTML(job.name ));
__out.push(" (");
__out.push(Mold.escapeHTML(job.kind ));
__out.push(")\n        ");
if (User.userAccess("w")) {
__out.push("\n          - <span class=\"linkish\" title=\"stop job\">stop</span>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.stopJob(job.uuid);
});}) + "\"></var>");
__out.push("\n        ");
}
__out.push("\n        <span id=\"jobDescription\">");
__out.push(Mold.escapeHTML(job.description ));
__out.push("</span>\n      </li>\n    ");
});
Mold.forDepth--;
__out.push("\n    ");
if ($arg.jobs.length === 0) {
__out.push("\n      There are no running jobs.\n    ");
}
__out.push("\n  </ul>\n\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;