import User from '../user';
import Dispatch from '../dispatch';
import Catalog from '../catalog';
import $ from 'jquery';
import { $$ } from '../util';
import App from '../app';
import setImmediate from '../set-immediate';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<!-- Set to float otherwise the height jquery UI sets for the contents\nmay be wrong if a tab's contents float. bug25233 -->\n<div id=\"settingsTabs\" style=\"float: left; width: 100%;\">\n  <ul>\n    ");
if (User.userPerm("super")) {
__out.push("\n      <li><a href=\"#serverSettings\">Server</a></li>\n    ");
}
__out.push("\n    <li><a href=\"#browserSettings\">Browser Session</a></li>\n  </ul>\n\n  ");
if (User.userPerm("super")) {
__out.push("\n    <div id=\"serverSettings\">\n      <div id=\"largeOperationsWarning\">\n        <h3>Miscellaneous</h3>\n        <span>Large operations warning:</span>\n        <textarea type=\"text\" id=\"large-operations-warning\"\n                  style=\"width: 30em\" rows=\"3\" cols=\"40\"\n                  placeholder=\"Enter a message to display before allowing large operations\"\n        >");
__out.push(Mold.escapeHTML(Catalog.getLargeOperationWarning() || ""));
__out.push("</textarea>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "keydown", function($event, $node) {
$("#large-operations-warning-ok").html("");
});}) + "\"></var>");
__out.push("\n        <br/>\n        <button id=\"setLargeOperationWarningButton\" type=\"button\">Set</button>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Catalog.setLargeOperationWarning(
                        $$("large-operations-warning").value,
                        $$("large-operations-warning-ok"));
});}) + "\"></var>");
__out.push("\n        <span id=\"large-operations-warning-ok\" class=\"subtle\"></span>\n      </div>\n    </div>\n  ");
}
__out.push("\n  \n  <div id=\"browserSettings\">\n    <h3>Display options</h3>\n      <span class=\"query-option\">\n        <label for=\"showlongparts\" title=\"Display full URIs when showing parts\">Display full URIs:</label>\n        <input class=\"centered-checkbox\" type=\"checkbox\" id=\"showlongparts\" ");
if (App.showLongParts) {
__out.push("checked=\"checked\"");
}
__out.push("/>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
App.toggleLongParts($node.checked);
});}) + "\"></var>");
__out.push("\n      </span>\n      <span class=\"query-option\">\n        <label for=\"showcontexts\" title=\"Show graph names\">Show graph (i.e., context) names when available:</label>\n        <input class=\"centered-checkbox\" type=\"checkbox\" id=\"showcontexts\" ");
if (App.showContexts) {
__out.push("checked=\"checked\"");
}
__out.push("/>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
App.toggleContexts($node.checked);
});}) + "\"></var>");
__out.push("\n      </span>\n  </div>\n</div>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function () {
  $("#settingsTabs").tabs({
    heightStyle: "auto"
  });
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;