import Util from '../../util';
import App  from '../../app';
import Base from '../../base';
import { $$ } from '../../util'

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function maybeEnableAddInstanceButton() {
  // check that the necessary fields are filled in
  // before enabling the Add Instance button
  function isEmpty(id) {
    var v = $$(id);
    if (v) {
      // node exists on the page
      return v.value.length === 0;
    } else {
      return true;
    }
  }

  var disabled = isEmpty('host') ||  isEmpty('port')
                 || isEmpty('name');
  
  $$('addInstanceButton').disabled = disabled;
}
    


__out.push("\n\n<form class=\"pure-form pure-form-aligned\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "input", function($event, $node) {
maybeEnableAddInstanceButton(); 
});}) + "\"></var>");
__out.push("\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok(Util.selectValue($node.elements.scheme),
              $node.elements.host.value,
              $node.elements.port.value,
              $node.elements.catalog.value,
              $node.elements.name.value,
              $node.elements.group.value,
              $node.elements.user.value,
              $node.elements.password.value,
              $node.elements.instanceName.value);
  
});}) + "\"></var>");
__out.push("\n  <div id=\"addInstanceDialog\">\n    <em>Add Instance will overwrite an existing repository with the specified name</em></div>\n  <fieldset>\n    <div class=\"pure-control-group\">\n      <label for=\"scheme\">Scheme:</label>\n      <select name=\"scheme\" id=\"scheme\">\n        <option value=\"http\" ");
__out.push(Mold.escapeHTML((Base.rootLocation.protocol === "http:") ? "selected" : "" ));
__out.push(">http</option>\n\t<option value=\"https\" ");
__out.push(Mold.escapeHTML((Base.rootLocation.protocol === "https:") ? "selected" : "" ));
__out.push(">https</option>\n      </select>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"host\">Host:</label>\n      <input name=\"host\" id=\"host\"  type=\"text\" size=\"30\" max=\"60\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"port\">Port:</label>\n      <input name=\"port\" id=\"port\" type=\"number\" size=\"8\" \n             min=\"1\" max=\"65535\"\n             value=\"");
__out.push(Mold.escapeHTML(Base.rootLocation.port ));
__out.push("\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"catalog\">Catalog:</label>\n      <input name=\"catalog\" id=\"catalog\" type=\"text\" size=\"10\" max=\"200\"><span class=\"pure-form-message-inline\"> default: root catalog</span>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"name\">Repository Name:</label>\n      <input name=\"name\" id=\"name\" type=\"text\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"group\">Group:</label>\n      <input name=\"group\" id=\"group\" type=\"text\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"user\">Username:</label>\n      <input name=\"user\" id=\"user\" type=\"text\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"password\">Password:</label>\n      <input name=\"password\" id=\"password\" type=\"password\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"instanceName\">Instance Name:</label>\n      <input name=\"instanceName\" id=\"instanceName\" type=\"text\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-controls\">\n      <button type=\"button\" class=\"pure-button action-button\">Close Dialog</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.closeDialog();
});}) + "\"></var>");
__out.push("\n      <button type=\"submit\" disabled=\"true\" id=\"addInstanceButton\" class=\"pure-button pure-button-primary\">Add Instance</button>\n    </div>\n  </fieldset>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;