import Reports from '../../reports';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.field.value !== undefined ) {
__out.push("\n  ");
__out.push(Mold.escapeHTML(Reports.formatValue($arg.field.value, $arg.field.type) ));
__out.push("\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;