import User from '../../user';
import Util from '../../util';
import { $$ } from '../../util';
import Dispatch from '../../dispatch';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function add() {
      User.addUserRole(
        $arg.url, Util.selectValue($$('add-role')), $arg.localRefresh);
    }

__out.push("\n");
if ($arg.roles) {
__out.push("\n  <p>\n    Roles:\n    ");
Mold.forDepth++;
Mold.forEach($arg.roles, function(role, $i) {
__out.push("<span>");
if ($i) {
__out.push(",");
}
__out.push(" ");
__out.push(Mold.escapeHTML(role));
__out.push("\n        <button class=\"small-delete-button\"><i class='fa fa-times-circle' title=\"remove role\"></i></button>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.removeUserRole($arg.url, role, $node.parentNode);
});}) + "\"></var>");
__out.push("</span>");
});
Mold.forDepth--;
__out.push("\n    ");
if (!$arg.roles.length) {
__out.push("None");
}
__out.push("\n    ");
if ($arg.allRoles.length) {
__out.push("\n      | Add\n      <select id=\"add-role\">\n        ");
Mold.forDepth++;
Mold.forEach($arg.allRoles, function(add, $i) {
__out.push("\n          <option value=\"");
__out.push(Mold.escapeHTML(add));
__out.push("\">");
__out.push(Mold.escapeHTML(add));
__out.push("</option>\n        ");
});
Mold.forDepth--;
__out.push("\n      </select>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
add();
});}) + "\"></var>");
__out.push("\n      <span class=\"linkish\">[ok]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
add();
});}) + "\"></var>");
__out.push("\n    ");
}
__out.push("\n  </p>\n");
}
__out.push("\n\n");
if ($arg.type === 'user' ) {
__out.push("\n  ");
if ($arg.suspended) {
__out.push("\n  <span class=\"linkish\">[unsuspend]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.unsuspendUser($arg.name, $arg.localRefresh);
});}) + "\"></var>");
__out.push("\n  ");
} else {
__out.push("\n  <span class=\"linkish\">[suspend]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.suspendUser($arg.name, $arg.localRefresh);
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n\n  ");
if ($arg.enabled) {
__out.push("\n  <span class=\"linkish\">[disable]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.disableUser($arg.name, $arg.localRefresh);
});}) + "\"></var>");
__out.push("\n  ");
} else {
__out.push("\n  <span class=\"linkish\">[enable]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.enableUser($arg.name, $arg.localRefresh);
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n\n  ");
if ($arg.expired) {
__out.push("\n  <span>Password is expired and must be changed.</span>\n  ");
} else {
__out.push("\n  <span class=\"linkish\">[expire password]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.expirePassword($arg.name, $arg.localRefresh);
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n");
}
__out.push("\n\n");
var id = Math.floor(Math.random() * 10000);

__out.push("\n\n<p>\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"super");
__out.push(Mold.escapeHTML(id));
__out.push("\" ");
if (Util.member('super', $arg.perms)) {
__out.push("checked");
}
__out.push(">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.togglePermission($arg.url, "super", $node);
});}) + "\"></var>");
__out.push("\n  <label for=\"super");
__out.push(Mold.escapeHTML(id));
__out.push("\">Superuser</label>\n\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"session");
__out.push(Mold.escapeHTML(id));
__out.push("\" ");
if (Util.member('session', $arg.perms)) {
__out.push("checked");
}
__out.push(">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.togglePermission($arg.url, "session", $node);
});}) + "\"></var>");
__out.push("\n  <label for=\"session");
__out.push(Mold.escapeHTML(id));
__out.push("\">Start sessions</label>\n\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"eval");
__out.push(Mold.escapeHTML(id));
__out.push("\" ");
if (Util.member('eval', $arg.perms)) {
__out.push("checked");
}
__out.push(">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.togglePermission($arg.url, "eval", $node);
});}) + "\"></var>");
__out.push("\n  <label for=\"eval");
__out.push(Mold.escapeHTML(id));
__out.push("\">Evaluate arbitrary code</label>\n\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"replication");
__out.push(Mold.escapeHTML(id));
__out.push("\" ");
if (Util.member('replication', $arg.perms)) {
__out.push("checked");
}
__out.push(">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.togglePermission($arg.url, "replication", $node);
});}) + "\"></var>");
__out.push("\n  <label for=\"replication");
__out.push(Mold.escapeHTML(id));
__out.push("\">Control replication</label>\n\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"2pc");
__out.push(Mold.escapeHTML(id));
__out.push("\" ");
if (Util.member('2pc', $arg.perms)) {
__out.push("checked");
}
__out.push(">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.togglePermission($arg.url, "2pc", $node);
});}) + "\"></var>");
__out.push("\n  <label for=\"2pc");
__out.push(Mold.escapeHTML(id));
__out.push("\">Two-phase commit</label>\n\n  <br>\n\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"user-attributes-header");
__out.push(Mold.escapeHTML(id));
__out.push("\" ");
if (Util.member('user-attributes-header', $arg.perms)) {
__out.push("checked");
}
__out.push(">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.togglePermission($arg.url, "user-attributes-header", $node);
});}) + "\"></var>");
__out.push("\n  <label for=\"user-attributes-header");
__out.push(Mold.escapeHTML(id));
__out.push("\">Allow user attributes via HTTP header <a href=\"doc/triple-attributes.html#x-user-attributes\" target=\"_blank\">x-user-attributes</a></label>\n  <br>\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"user-attributes-prefix");
__out.push(Mold.escapeHTML(id));
__out.push("\" ");
if (Util.member('user-attributes-prefix', $arg.perms)) {
__out.push("checked");
}
__out.push(">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.togglePermission($arg.url, "user-attributes-prefix", $node);
});}) + "\"></var>");
__out.push("\n  <label for=\"user-attributes-prefix");
__out.push(Mold.escapeHTML(id));
__out.push("\">Allow user attributes via SPARQL <a href=\"doc/sparql-reference.html#query-option.userAttributes\" target=\"_blank\">PREFIX franzOption_userAttributes</a></label>\n\n  \n</p>\n\n<ul>\n  ");
Mold.forDepth++;
Mold.forEach($arg.access, function(perm, $i) {
__out.push("\n  <li>\n    <em>\n      ");
if (perm.read && perm.write) {
__out.push("read/write");
} else if (perm.read) {
__out.push("read");
} else {
__out.push("write");
}
__out.push("\n      ");
if (perm['query-results-limit']) {
__out.push(" with query results limit");
}
__out.push("\n    </em> on\n    ");
if (perm.catalog !== '*') {
__out.push("catalog <code>");
__out.push(Mold.escapeHTML(perm.catalog));
__out.push("</code>");
if (perm.repository !== '*') {
__out.push(",\n        repository <code>");
__out.push(Mold.escapeHTML(perm.repository));
__out.push("</code>");
}
__out.push("\n      ");
} else {
__out.push("all");
}
__out.push("\n      <span class=\"linkish\">[remove]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.removeAccess($arg.url, perm, $node.parentNode);
});}) + "\"></var>");
__out.push("\n    </li>\n  ");
});
Mold.forDepth--;
__out.push("\n</ul>\n");
if (!($arg.access.length || Util.member('super', $arg.perms))) {
__out.push("\n  <p>No read/write access.</p>\n");
}
__out.push("\n\n<div>\n  <form>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
User.addAccess(
          $arg.url, Util.selectValue($node.elements.type),
          Util.selectValue($node.elements.catalog),
          Util.selectValue($node.elements.name), $arg.localRefresh);
        $event.stop();
    
});}) + "\"></var>");
__out.push("\n    Grant\n    <select name=\"type\">\n      <option>read/write</option>\n      <option>read</option>\n      <option>write</option>\n      <option>read/write with query results limit</option>\n      <option>read with query results limit</option>\n    </select>\n    on catalog\n    <select name=\"catalog\" style=\"min-width: 5em\">\n      <option selected>*</option>\n      ");
Mold.forDepth++;
Mold.forEach($arg.catalogs, function(cat, $i) {
__out.push("<option>");
__out.push(Mold.escapeHTML(cat));
__out.push("</option>");
});
Mold.forDepth--;
__out.push("\n    </select>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
User.updatePermissionRepoList(Util.selectValue($node), $$("reposelect-" + id));
});}) + "\"></var>");
__out.push("\n    repository\n    <select id=\"reposelect-");
__out.push(Mold.escapeHTML(id));
__out.push("\" name=\"name\" style=\"min-width: 5em\">\n      <option selected>*</option>\n    </select>\n    <button type=\"submit\" class=\"linkish\">[ok]</button>\n  </form>\n</div>\n\n<div>\n  Security Filters:\n ");
if (!$arg.hasFilters ) {
__out.push("\n <span id=\"secfilters-placeholder-");
__out.push(Mold.escapeHTML(id));
__out.push("\" style=\"display: inline;\">\n   <span class=\"subtle\">None</span>\n   <span class=\"linkish\">[add]</span>\n </span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.enableSecurityFilters("secfilters-placeholder-" + id, "secfilters-" + id); 
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n  <form>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
User.addSecurityFilter($arg.url, Util.selectValue($node.elements.type), $node.elements.s.value,
                          $node.elements.p.value, $node.elements.o.value, $node.elements.g.value,
                          $arg.localRefresh);
  $event.stop();
  
});}) + "\"></var>");
__out.push("\n  <table id=\"secfilters-");
__out.push(Mold.escapeHTML(id));
__out.push("\" class=\"node-table\" ");
if ((!$arg.hasFilters) ) {
__out.push(" style=\"display:none;\" ");
}
__out.push(" >\n   <tbody>\n   <tr>\n    <th>Allow/Deny</th>\n    <th>Subject</th>\n    <th>Predicate</th>\n    <th>Object</th>\n    <th>Graph</th>\n    <th></th>\n   </tr>\n   ");
Mold.forDepth++;
Mold.forEach($arg.filtersAllow, function(x, $i) {
__out.push("\n    <tr>\n     <td>allow</td>\n     <td>");
__out.push(Mold.escapeHTML(x.s ));
__out.push("</td>\n     <td>");
__out.push(Mold.escapeHTML(x.p ));
__out.push("</td>\n     <td>");
__out.push(Mold.escapeHTML(x.o ));
__out.push("</td>\n     <td>");
__out.push(Mold.escapeHTML(x.g ));
__out.push("</td>\n     <td><span class=\"linkish\">x</span>\n         ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.removeSecurityFilter($arg, 'allow', x, $arg.localRefresh); 
});}) + "\"></var>");
__out.push("\n     </td>\n    </tr>\n   ");
});
Mold.forDepth--;
__out.push("\n   ");
Mold.forDepth++;
Mold.forEach($arg.filtersDisallow , function(x, $i) {
__out.push("\n    <tr>\n     <td>disallow</td>\n     <td>");
__out.push(Mold.escapeHTML(x.s ));
__out.push("</td>\n     <td>");
__out.push(Mold.escapeHTML(x.p ));
__out.push("</td>\n     <td>");
__out.push(Mold.escapeHTML(x.o ));
__out.push("</td>\n     <td>");
__out.push(Mold.escapeHTML(x.g ));
__out.push("</td>\n     <td><span class=\"linkish\">x</span>\n         ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.removeSecurityFilter($arg, 'disallow', x, $arg.localRefresh); 
});}) + "\"></var>");
__out.push("\n     </td>\n    </tr>\n   ");
});
Mold.forDepth--;
__out.push("\n     <tr>\n       <td><select name=\"type\"><option>allow</option><option>disallow</option></select></td>\n       <td><input name=\"s\"></td>\n       <td><input name=\"p\"></td>\n       <td><input name=\"o\"></td>\n       <td><input name=\"g\"></td>\n       <td><input type=\"submit\" value=\"add\"></td>\n     </tr>\n   </tbody>\n  </table>\n </form>\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;