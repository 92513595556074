import User from '../../user';
import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>\n  Tokens\n  ");
Mold.dispatchCustom("help", " container=tokens-help\n    Tokens can be used instead of passwords to authenticate user when executing\n    HTTP API calls, logging into WebView and running agtool commands. The\n    difference between the tokens and passwords is that tokens can be easily\n    generated and revoked, and they can also be limited by two types of\n    timeouts: expiration timeout (token is valid for a given number of seconds)\n    and idle timeout (token is invalidated if unused for a certain number of\n    seconds).", __out);
__out.push("\n</h2>\n\n<div id=\"tokens-help\"></div>\n\n");
if ($arg.tokens.length) {
__out.push("\n  <table class=\"token-list\">\n    <tr>\n      <th class=\"token-delete-button\"></th>\n      <th>Token ID</th>\n      <th>Label</th>\n      <th>Expires</th>\n      <th>Last Used</th>\n      <th>Idle Timeout</th>\n    </tr>\n    ");
Mold.forDepth++;
Mold.forEach($arg.tokens, function(token, $i) {
__out.push("\n      <tr>\n        <td class=\"token-delete-button\">\n          <button class=\"small-delete-button\"><i class='fa fa-times-circle' title=\"delete token\"></i></button>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.deleteToken(token.token);
});}) + "\"></var>");
__out.push("\n        </td>\n        <td><code>");
__out.push(Mold.escapeHTML(token.token));
__out.push("</code></td>\n        <td>");
__out.push(Mold.escapeHTML(token.description));
__out.push("</td>\n        <td class=\"optional-token-parameter\">");
if (token.expiresAt) {
__out.push(Mold.escapeHTML(token.expiresAt));
} else {
__out.push("-");
}
__out.push("</td>\n        <td>");
__out.push(Mold.escapeHTML(token.touchedAt));
__out.push("</td>\n        <td class=\"optional-token-parameter\">");
if (token.idleTimeout) {
__out.push(Mold.escapeHTML(token.idleTimeout));
} else {
__out.push("-");
}
__out.push("</td>\n      </tr>\n    ");
});
Mold.forDepth--;
__out.push("\n  </table>\n");
} else {
__out.push("\n  You have no tokens yet.\n");
}
__out.push("\n\n<p style=\"margin-top: 1em\">\n  <span class=\"linkish\">[Generate new token]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.newTokenDialog();
});}) + "\"></var>");
__out.push("\n</p>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;