import { $$ } from '../util';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"small-form restore-store\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$arg.ok($$("restore-store-name").value, $$("restore-file-name").value,
                         $$("restore-as-replica").checked);
                 $event.stop();
});}) + "\"></var>");
__out.push("\n  <table width=\"100%\">\n  <tr>\n    <td>\n      <label for=\"restore-store-name\">Repository name:</label>\n      <input id=\"restore-store-name\" type=\"text\" />\n    </td>\n    <td>\n      <label for=\"restore-file-name\">Backup file name:</label>\n      <input id=\"restore-file-name\" type=\"text\" />\n      ");
Mold.dispatchCustom("help", "container=restore-file-name-help This must refer to an AllegroGraph backup file on the server's file system.", __out);
__out.push("\n    </td>\n    <td class=\"buttons\">\n      <button type=\"submit\">OK</button>\n      <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel(); 
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n  <tr><td colspan=\"3\"><div id=\"restore-file-name-help\"></div></td></tr>\n  <tr>\n      <td colspan=\"3\">\n      <input id=\"restore-as-replica\" class=\"centered-checkbox\" type=\"checkbox\" value=\"yes\">\n      <label for=\"restore-as-replica\" style=\"width: auto\">Restore for use as a warm standby replica</label>\n      ");
Mold.dispatchCustom("help", " container=restore-as-replica-help\n          This setting will prepare the repository to be used as a warm\n          standby secondary. If you don't plan to use the repository as a\n          warm standby replica, leave this unchecked.", __out);
__out.push("\n    </td>\n  </tr>\n  <tr><td colspan=\"3\"><div id=\"restore-as-replica-help\"></div></td></tr>\n  </table>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;