import Store from '../../store';
import User from '../../user';
import { $$ } from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Replication</h2>\n\n");
if (!$arg.data || $arg.data.type === 'server' && !$arg.data.clients.length) {
__out.push("\n\n  <p>No warm standby active.</p>\n\n  <h3>Become a warm standby client</h3>\n\n  <form>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      function v(n) {return $node.elements[n].value;}
      Store.startWarmStandby(
        v('jobname'), v('server'), v('port'), v('user'), v('password'));
    
});}) + "\"></var>");
__out.push("\n    Job name: <input type=\"text\" name=\"jobname\" style=\"width: 12em\"/><br/>\n    Remote server: <input type=\"text\" name=\"server\" style=\"width: 10em\"/>\n    on port <input type=\"text\" name=\"port\" value=\"10035\" style=\"width: 4em\"/><br/>\n    Remote user: <input type=\"text\" name=\"user\" style=\"width: 6em\" value=\"");
__out.push(Mold.escapeHTML(User.user ? User.user.name : ""));
__out.push("\"/>,\n    password: <input type=\"password\" name=\"password\" style=\"width: 6em\"/><br/>\n    <button type=\"submit\">Enable</button>\n  </form>\n\n");
} else if ($arg.data.type === 'server') {
__out.push("\n\n  <p>\n    This repository is being replicated by\n    ");
__out.push(Mold.escapeHTML($arg.data.clients.length));
__out.push(" client");
__out.push(Mold.escapeHTML($arg.data.clients.length === 1 ? '' : 's'));
__out.push(".\n  </p>\n\n  ");
Mold.forDepth++;
Mold.forEach($arg.data.clients, function(client, $i) {
__out.push("\n    <p>\n      ");
var jn = client.jobname;
          function switchRoles(sr) {
            Store.switchStandbyRoles(
              jn, $$("user-"+jn).value, $$("password-"+jn).value,
              sr || false, $$("write-"+jn).checked);
          }
      
__out.push("\n      Job '");
__out.push(Mold.escapeHTML(jn));
__out.push("': ");
__out.push(Mold.escapeHTML(client.recordsTransmitted));
__out.push(" records transmitted\n      ");
if (client.switchingRoles) {
__out.push("\n        <strong>[switching roles]</strong>\n      ");
} else {
__out.push("\n        <span class=\"linkish\">[change]</span>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$node.style.display="none"; $$("roles-"+jn).style.display="inline";
});}) + "\"></var>");
__out.push("\n        <span id=\"roles-");
__out.push(Mold.escapeHTML(jn));
__out.push("\" style=\"display: none\"><br/>\n          &nbsp; Remote user: <input type=\"text\" id=\"user-");
__out.push(Mold.escapeHTML(jn));
__out.push("\" style=\"width: 6em\" value=\"");
__out.push(Mold.escapeHTML(User.user ? User.user.name : ""));
__out.push("\"/>,\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
switchRoles();
});}) + "\"></var>");
__out.push("\n          password: <input type=\"password\" id=\"password-");
__out.push(Mold.escapeHTML(jn));
__out.push("\" style=\"width: 6em\"/>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
switchRoles();
});}) + "\"></var>");
__out.push("\n          <input class=\"centered-checkbox\" type=\"checkbox\" id=\"write-");
__out.push(Mold.escapeHTML(jn));
__out.push("\"/> <label for=\"write-");
__out.push(Mold.escapeHTML(jn));
__out.push("\" checked>Enable commits in client</label>\n          <span class=\"linkish\">[stop]</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
switchRoles();
});}) + "\"></var>");
__out.push("\n          <span class=\"linkish\">[switch roles]</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
switchRoles(true);
});}) + "\"></var>");
__out.push("\n        </span>\n      ");
}
__out.push("\n    </p>\n  ");
});
Mold.forDepth--;
__out.push("\n\n");
} else {
__out.push("\n  <p>\n    This repository is replicating repository\n    ");
__out.push(Mold.escapeHTML($arg.data.name));
if ($arg.data.catalog !== '/') {
__out.push(" in catalog ");
__out.push(Mold.escapeHTML($arg.data.catalog));
}
__out.push(",\n    on host ");
__out.push(Mold.escapeHTML($arg.data.host));
__out.push(", port ");
__out.push(Mold.escapeHTML($arg.data.port));
__out.push(".\n  </p>\n\n  <p>The replication job name is '");
__out.push(Mold.escapeHTML($arg.data.jobname));
__out.push("'. ");
__out.push(Mold.escapeHTML($arg.data.recordsReceived));
__out.push(" records have been received.</p>\n\n  ");
if ($arg.data.exiting) {
__out.push("<p><strong>This job is being shut down.</strong></p>");
}
__out.push("\n\n  <p>\n    <span class=\"linkish\">[stop replicating]</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.stopWarmStandby();
});}) + "\"></var>");
__out.push("\n  </p>\n\n");
}
__out.push("\n\n<p>\n  This repository is currently in ");
if ($arg.noComm) {
__out.push("no-");
}
__out.push("commit mode.\n  ");
if (User.userPerm("super")) {
__out.push("\n    <span class=\"linkish\">[switch]</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.switchNoCommit(!$arg.noComm);
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n</p>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;