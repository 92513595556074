import App from './app';
import User from './user';
import { MAsync } from './async';
import Base from './base';
import { Async } from './async';
import Util from './util';
import { $$ } from './util';
import Layout from './layout';
import $ from 'jquery';
import _ from 'underscore';
import Dispatch from './dispatch';
import Store from './store';
import setImmediate from './set-immediate';

import catalogTemplate from './templates/catalog.mold';
import sessionsTemplate from './templates/sessions.mold';
import ndDesignerTemplate from './templates/nd-designer.mold';
import settingsTemplate from './templates/settings.mold';
import serverWarningsTemplate from './templates/serverWarnings.mold';
import restoreTemplate from './templates/restore.mold';

/** @namespace Catalog */
var Catalog = {};
Catalog._currentGoogleKey = null;

Catalog.showCatalog = function () {
  var root = !App.isInCatalog();
  var settings = User.userPerm('super') && root;
  var as = MAsync.collect(
    Base.jsonReq('GET', '/catalogs'),
    Base.jsonReq('GET', 'repositories'),
    settings ? Base.jsonReq('GET', '/users') : Async.dummy(),
    Base.jsonReq('GET', '/largeOperationWarning'),
    User._loadRepoData(User.user));
  Base.load(
    as, 'Loading catalog data',
    Dispatch.protect(
      function (catalogs, repositories, users, largeOperationWarning, _access) {
        Util.setInStorage('largeOperationWarning', largeOperationWarning);
        catalogTemplate.cast(Layout.getPage(), {
          catalogs: Util.filter(function (c) {
            return c.readable;
          }, catalogs),
          repositories: Util.filter(function (r) {
            return r.readable && (r.replication !== 'stopped');
          }, repositories),
          stopped: Util.filter(function (r) {
            return r.replication === 'stopped';
          }, repositories),
          anon: users && Util.member('anonymous', users),
          userCount: users && users.length,
        });
      }));
};

/**
 * @return {string|null} - warning text for large operations.
 */
Catalog.getLargeOperationWarning = function () {
  return Util.getFromStorage('largeOperationWarning');
};

Catalog.setLargeOperationWarning = function (warning, node) {
  $(node).html('');
  Util.setInStorage('largeOperationWarning', warning);
  Base.load(
    Base.req('PUT', '/largeOperationWarning', { body: warning }),
    'Storing Large Operation Warning', function () {
      $(node).html('(saved)');
    });
};

Catalog.makeMaybeVerifyLargeOperationWarning = function (fn) {
  return function () {
    var outerArguments = arguments;
    function doit() {
      fn.apply(null, outerArguments);
    }

    var largeOperationWarning = Catalog.getLargeOperationWarning();
    if (largeOperationWarning) {
      Layout.showDialog('largeOp-confirm',
        '<div>' + largeOperationWarning + '</div>' +
        '<div id="verifyMessage">Are you sure?</div>',
        doit, null, false, true);
    } else {
      doit();
    }
  };
};

Catalog.createRepository = function (name, repl, args) {
  // do a get on repositories and check for the repo in the handler. If
  // it is there, run a yesNoDialog. Otherwise, just do the PUT
  // If repl is true then we are creating the first instance of
  // a cluster.
  // args are query arguments to the call.
  Base.load(
          Base.jsonReq('GET', Base.url('repositories')), 'Checking repository',
          function (data) {
            if (_.find(data, function (repo) {
              return repo.id === name;
            })) {
              Layout.showDialog('overwriteRepo-confirm',
                  'Repository <em>' + name + '</em> already exists. ' +
                  'Do you want to overwrite it?',
                  doCreate,
                  null, false, true,
                  {
                    yesText: 'Overwrite and Create',
                    noText: 'Cancel',
                  });
            } else {
              doCreate();
            }
          });
  function doCreate() {
    App.customRefresh = function () {
      App.goTo(Dispatch.rootUrl(App.currentCatalog, name));
    };

    Base.load(
            Base.req('PUT', Base.url('repositories/', name,
                    repl ? '/repl/createCluster' : '',
                    args ? args : ''
            )),
            'Creating repository', App.refresh.bind(App));
  }
};

Catalog.restoreBackupDialog = function () {
  var close = Layout.customDialog('restore-dialog',
    restoreTemplate, {
      ok: doit,
      cancel: function () {
        close();
      },
    }, false, false);
  function doit(name, path, asReplica) {
    Store.restoreRepository(name, path, asReplica);
    close();
  }
};

Catalog.deleteRepository = function (id) {
  Layout.showDialog('deleteRepo-confirm',
    'Really delete repository "' + id + '"?', ok, null, false, true);
  function ok() {
    Base.load(
      Base.req('DELETE', Base.url('repositories/', id)),
      'Deleting repository', App.refresh.bind(App));
  }
};

Catalog.startRepository = function (id) {
  Base.req('PUT', Base.url('repositories/', id, '/repl/start')).then(App.refresh.bind(App));
};

Catalog.showSessions = function () {
  Base.jsonReq('GET', '/session').wait(function (sessions) {
    sessionsTemplate.cast(Layout.getPage(),
      Util.map(function (session) {
        return {
          uri: Dispatch.url(App.convertSessionUrl(session.uri)),
          port: session.uri.match(/.*:(\d+)\/.*$/)[1],
          description: session.description,
        };
      }, sessions));
  });
};

Catalog.showGeospatialDesigner = function () {
  ndDesignerTemplate.cast(Layout.getPage(), '');
};

Catalog.showSettings = function () {
  var settings = User.userPerm('super');
  var as = MAsync.collect(
    settings ? Base.jsonReq('GET', '/users') : Async.dummy(),
    Base.jsonReq('GET', '/largeOperationWarning'));
  Base.load(
    as, 'Loading settings', function (users, largeOperationWarning) {
      Util.setInStorage('largeOperationWarning', largeOperationWarning);
      settingsTemplate.cast(Layout.getPage(), {
        anon: users && Util.member('anonymous', users),
        userCount: users && users.length,
      });
    });
};

Catalog.startPeriodicallyUpdatingWarnings = function () {
  var UPDATE_FREQUENCY_IN_SECONDS = 60;
  var UPDATE_FREQUENCY = UPDATE_FREQUENCY_IN_SECONDS * 1000; // milliseconds

  function updateAndWait() {
    Catalog.updateWarningsOnce(true).always(function () {
      window.setTimeout(updateAndWait, UPDATE_FREQUENCY);
    }).wait();
  }

  // Start the first update almost immediately:
  setImmediate(updateAndWait);
};

/**
 * Loads server warnings, shows or hides the warnings link as needed.
 *
 * @param {boolean} [noTouch] - if true this request will not update
 *                              the last activity timestamp (which is
 *                              used to logout idle users). That is
 *                              useful for periodic requests.
 * @return {Async} - a promise resolved when the request is complete.
 */
Catalog.updateWarningsOnce = function (noTouch) {
  return Base.jsonReq('GET', '/serverWarnings', { noTouch: noTouch })
      .then(function (warnings) {
        warnings = warnings || [];
        /* Assume admin warnings are only actionable for admin users
         * and do not nag non-admin users about them.*/
        var relevantWarningCount =
            User.userPerm('super')
              ? warnings.length
              : warnings.filter((w) => w.category !== 'admin').length;
        if (relevantWarningCount === 0) {
          $('#server-warnings-indicator').html('');
        } else {
          $('#server-warnings-indicator').html(
            '<span>' +
            '<a href="' + Dispatch.url('serverWarnings') + '" id="warnings" ' +
            'data-toggle="tooltip" title="Server Warnings">' +
            '<i class="fas fa-exclamation-circle server-warnings-icon"></i>' +
            '</a>' +
            '</span>');
        }
      }, function (message, extra) {
        Layout.removeMessages();
        if (extra.status === 0) {
          Layout.showMessage('Server is not running or unreachable.', true, true);
        } else {
          message = message || 'Unknown error occured.';
          Layout.showMessage(message, true, true);
        }
      });
};

Catalog.showServerWarnings = function () {
  Base.load(Base.jsonReq('GET', '/serverWarnings'), '', function (warnings) {
    serverWarningsTemplate.cast(Layout.getPage(), warnings);
  });
};

Catalog.dismissServerWarning = function (tag) {
  Base.load(
    Base.jsonReq('DELETE', Base.url('/serverWarnings?tag=', tag)), '',
    function () {
      Catalog.showServerWarnings();
      Catalog.updateWarningsOnce();
    });
};

/**
 * Implements the Nd Geo Dataype Designer
 */
Catalog.Nd = Util.module(function (exports) {
  /**
   * Set various ordinate row properties. Called whenever
   * the ordinate rows have been updated, such as after
   * the datatype definition has been updated via the
   * dataypeSpecifier editor.
   */
  function ndUpdateUI() {
    let tableRows = $('#tblOrdinates tbody >');
    let names = $('#tblOrdinates tbody .ndInputName input');

    // Returns the number of ordinate rows with non-empty ndInputName elements.
    function namedRowCount() {
      let count = 0;
      names.each(function () {
        if ( $(this).val() !== '') {
          count++;
        }
      });

      return count;
    }

    // Returns the last row with a non-empty ndInputName element,
    // or the last row if they are all empty.
    function lastNamedRow() {
      // defaults to last row.
      let lastNamedIndex = tableRows.length;
      names.each(function (index) {
        if ( $(this).val() !== '') {
          lastNamedIndex = index;
        }
      });

      return $(tableRows[lastNamedIndex]);
    }

    $('.ndBtnDelete').prop('disabled', tableRows.length <= 2);
    $('#btnOrdinatesToDatatype').prop('disabled', tableRows.length < 2);
    tableRows.find('.ndInputWidth input')
        .removeClass('ui-state-disabled')
        .removeProp('title');
    tableRows.find('.btnUp').css('display', 'inline');
    tableRows.find('.btnDown').css('display', 'inline');
    if (namedRowCount() >= 2) {
      let lastRow = lastNamedRow();
      lastRow.find('.ndInputWidth input')
          .addClass('ui-state-disabled');
      lastRow.find('.ndInputWidth')
          .prop('title', 'The strip-width of the last named ordinate is ignored.');
    }
    let lastRow = $(tableRows[tableRows.length - 1]);
    lastRow.find('.btnDown').css('display', 'none');
    let firstRow = $('#tblOrdinates tbody > :first-child');
    firstRow.find('.btnUp').css('display', 'none');
  }

  /**
   * Remove the current (this) ordinate row.
   */
  function ndRowDelete() {
    var row = $(this).parent().parent();
    row.remove();
    ndOrdinatesToDatatype();
  }

  /**
   * Move the current ordinate row up in the list of
   * ordinate rows
   */
  function ndRowUp() {
    var myRow = $(this).parent().parent();
    $(myRow).prev().before($(myRow));
    ndOrdinatesToDatatype();
  }

  /**
   * Move the current ordinate row down in the list of
   * ordinate rows
   */
  function ndRowDown() {
    var myRow = $(this).parent().parent();
    $(myRow).next().after($(myRow));
    ndOrdinatesToDatatype();
  }

  var ndNamespaceConstant = 'http://franz.com/ns/allegrograph/5.0/geo/nd#';
  var ndUpdateTimer = {};

  /**
   * @return {CodeMirror} - the Datatype CM editor.
   */
  function ndEditor() {
    return $('#ndDatatypeSpecifier')[0].editor;
  }

  /**
   * The onChange/keyUp method for the fields in each ordinate
   * row, or the datatype editor. Waits 1s and then re-validate the datatype
   * via the /computeSubtype service.
   *
   * The delay appears to exist for the purpose of allowing fast typists
   * to update multiple fields before triggering a single update for
   * all changes.
   *
   * @param {string} from - Either 'datatype' or 'ordinates', indicating
   *                        which change triggered this update event.
   */
  function ndStartUpdateTimer(from) {
    if (ndUpdateTimer.timer) {
      window.clearTimeout(ndUpdateTimer.timer);
    }
    ndUpdateTimer.timer = window.setTimeout(ndUpdate, 1000);
    ndUpdateTimer.from = from;
  }

  /**
   * the real onChange/keyUp event handler (see above).
   */
  function ndUpdate() {
    window.clearTimeout(ndUpdateTimer.timer);
    if ((ndUpdateTimer.from === 'datatype') && !ndEditor().isClean()) {
      ndDatatypeToOrdinates();
    } else if (ndUpdateTimer.from === 'ordinates') {
      ndOrdinatesToDatatype();
    }
    ndUpdateTimer = {};
    ndEditor().markClean();
  }

  /**
   * Validate a subtype update made via the datatype editor. If it is
   * valid, update the ordinate rows on the page to match it.
   */
  function ndDatatypeToOrdinates() {
    var datatype = ndEditor().getValue();
    Base.load(
      Base.jsonReq(
        'GET',
        '/nd/computeSubtype?datatype=' + encodeURIComponent(datatype)),
      'Parsing', function (data) {
        ndUpdateDatatypeDescription(data, true);
      });
  }

  /**
   * Validate a subtype update made via ordinate row. If it is
   * valid, update the datatype editor and resolution info in
   * each ordinate row.
   *
   * Only ordinate rows with names are considered for the validity
   * check. If no rows meet this criteria, no validation is performed.
   */
  function ndOrdinatesToDatatype() {
    // some modifications to the UI (adding/removing a `Name', e.g.
    // may change which values should be submitted for validation.
    // Update the UI first.
    ndUpdateUI();

    var queryString = ndOrdinatesToQueryString();
    if (queryString && (queryString.length > 0)) {
      Base.load(
        Base.jsonReq('GET', '/nd/computeSubtype?' + queryString), 'Parsing',
        function (data) {
          ndUpdateDatatypeDescription(data, false);
        });
    } else {
      $('#ndDescription').html('');
    }
  }

  exports.ndClearOrdinates = function () {
    ndSetEditorValue(ndNamespaceConstant);
    $('#tblOrdinates tbody').html('');
    ndAddTableRow();
    ndAddTableRow();
    $('#ndDescription').html('');
  };

  function ndAddTableRow(ordinate, quiet) {
    function thisOr(it, instead) {
      return it === undefined ? instead : it;
    }

    var data = thisOr(ordinate, {});
    var actual =
        data.actualResolution === undefined ? '' : data.actualResolution;
    $('#tblOrdinates tbody').append('<tr>' +
      '  <td class="nd-button-item"><span class="small-delete-button ndBtnDelete">' +
      '    <i class="fas fa-times-circle"></i>' +
      '</span></td>' +
      '  <td><div class="ndInputName"><input type="text" value="' +
        thisOr(data.name, '') + '"/></div></td>' +
      '  <td>' +
      '    <div class="ui-widget">' +
      '       <div class="ndInputType"><select id="combobox">' +
      '        <option value="guess"></option>' +
      '        <option value="latitude">Latitude</option>' +
      '        <option value="longitude">Longitude</option>' +
      '        <option value="time">Time</option>' +
      '        <option value="altitude">Altitude</option>' +
      '        <option value="cartesian">Cartesian</option>' +
      '        <option value="default">Other/General</option></select>' +
      '     </div></div>' +
      '  </td>' +
      '  <td><div class="ndInputMin"><input type="text" value="' +
        thisOr(data.minimum, '') + '"/></div></td>' +
      '  <td><div class="ndInputMax"><input type="text" value="' +
        thisOr(data.maximum, '') + '"/></div></td>' +
      '  <td><div class="ndInputResolution"><input type="text" value="' +
        thisOr(data.requestedResolution, '') + '"/></div></td>' +
      '  <td><div class="ndInputActual"><input tabindex="-1" readonly="true"' +
      '           type="text" value="' + actual + '"/></div></td>' +
      '  <td><div class="ndInputWidth"><input type="text" value="' +
        thisOr(data.stripWidth, '') + '"/></div></td>' +
      '  <td class="nd-button-item">' +
      '    <span class="small-icon-button btnUp">' +
      '      <i class="fas fa-arrow-up"></i>' +
      '</span>' +
      '  </td>' +
      '  <td class="nd-button-item">&nbsp' +
      '    <span class="small-icon-button btnDown">' +
      '      <i class="fas fa-arrow-down"></i>' +
      '</span>' +
      '  </td>' +
      '</tr>');

    var newRow = $('#tblOrdinates tbody > :last-child');
    newRow.find('select').change(function () {
      ndStartUpdateTimer('ordinates');
    });
    newRow.find('input').keydown(function () {
      ndStartUpdateTimer('ordinates');
    });
    newRow.find('.ndBtnDelete').bind('click', ndRowDelete);
    newRow.find('.btnUp').bind('click', ndRowUp);
    newRow.find('.btnDown').bind('click', ndRowDown);
    var ndType = $('#tblOrdinates tbody > :last-child .ndInputType >')[0];
    Util.forEach(ndType.options, function (opt) {
      opt.selected = opt.value === thisOr(data.type, '');
    });
    if (!quiet) {
      ndOrdinatesToDatatype();
    }
  }

  /**
   * Construct query parameters from all ordinate rows that
   * have a non-empty name.
   *
   * @return {string} - An http query string, starting with '&'.
   */
  function ndOrdinatesToQueryString() {
    var result = '';
    var names = $('#tblOrdinates tbody .ndInputName input');
    var types = $('#tblOrdinates tbody .ndInputType select');
    var mins = $('#tblOrdinates tbody .ndInputMin input');
    var maxes = $('#tblOrdinates tbody .ndInputMax input');
    var resolutions = $('#tblOrdinates tbody .ndInputResolution input');
    var widths = $('#tblOrdinates tbody .ndInputWidth input');

    // add one ordinate field to the query string being produced.
    function addOne(index, name, data) {
      if (result !== '') {
        result += '&';
      }
      result += '$' + $(names[index]).val() + '-' + name + '=' +
        $(data[index]).val();
    }

    for (var i = 0; i < names.length; i++) {
      if ($(names[i]).val() !== '') {
        if (types[i] !== 'guess') {
          addOne(i, 'type', types);
        }
        addOne(i, 'min', mins);
        addOne(i, 'max', maxes);
        addOne(i, 'resolution', resolutions);
        if (! $(widths[i]).hasClass('ui-state-disabled')) {
          addOne(i, 'width', widths);
        }
      }
    }
    return result;
  }

  // Set the value in the datatype editor.
  function ndSetEditorValue(value) {
    var editor = ndEditor();
    editor.setValue(value);
    editor.markText({ ch: 0, line: 0 }, {
      ch: ndNamespaceConstant.length,
      line: 0,
    }, { readOnly: true });
    editor.setCursor({ ch: ndNamespaceConstant.length, line: 0 });
    editor.markClean();
  }

  exports.ndShowExample = function (value) {
    ndSetEditorValue(ndNamespaceConstant + value);
    ndDatatypeToOrdinates();
  };

  /**
   * Update the datatype description DIV based on the DATA response
   * from the /computeSubtype service.
   *
   * @param {json} data - information about the datatype returned from AG.
   * @param {boolean} reloadOrdinates - should the ordinate rows be erased
   *                                    and readded.
   * @param {boolean} reloadDatatype - should the value in the datatype editor
   *                                   be updated.
   */
  function ndUpdateDatatypeDescription(data,
                                       reloadOrdinates = false,
                                       reloadDatatype = true) {
    if (data.error === undefined) {
      Util.setColoredText($$('datatypeModeline'), '[ valid ]', 'green');
      $('#ndDescription').html(
        '<p>This datatype requested <span id="ndBitsRequested">' +
        Util.toFixed(data.requestedBits, 2) +
        '</span> bits and used <span id="ndBitsUsed">' +
        Util.toFixed(data.actualBits, 2) +
        '</span> bits</p>' +
        '');
      var editorClean = ndEditor().isClean();

      if (reloadOrdinates) {
        if (data.ordinates !== undefined) {
          $('#tblOrdinates tbody').html('');
          Util.forEach(data.ordinates, function (ordinate) {
            ndAddTableRow(ordinate, true);
          });
        }
      }
      if (editorClean && reloadDatatype) {
        if (data.xsd !== undefined) {
          var datatype = data.xsd;
          datatype = datatype.replace(/^</, '');
          datatype = datatype.replace(/>/, '');
          ndSetEditorValue(datatype);
        }
      }
      if (data.ordinates !== undefined) {
        ndUpdateActualResolutions(data.ordinates);
      }
    } else {
      // If the error is a result of modifying the ordinate rows, reset
      // the datatype Editor to just the namespace. Else, if the error
      // is from a bogus datatype entered by the user, leave it be.
      if (!reloadOrdinates) {
        ndSetEditorValue(ndNamespaceConstant);
      }
      Util.setColoredText($$('datatypeModeline'), '[ invalid ]', 'red');
      $('#ndDescription').html('Error computing datatype: <pre>' +
        data.error + '</pre>');
    }
    ndUpdateUI();
  }

  /**
   * Update the resolution fields in the ordinate rows table
   * from the data in ORDINATES
   *
   * @param {list} ordinates - a list of JSON objects describing
   *                           each ordinate row.
   */
  function ndUpdateActualResolutions(ordinates) {
    // what horrid code... sigh
    var rows = $('#tblOrdinates tbody >');
    Util.forEach(rows, function (row) {
      var nameInput = $(row).find('.ndInputName input');
      var nameString = nameInput.val();
      Util.forEachIn(ordinates, function (it) {
        if (nameString === ordinates[it].name) {
          nameInput.closest('tr')
              .find('.ndInputActual input')
              .prop('value', ordinates[it].actualResolution);
        }
      });
    });
  }

  exports.ndStartUpdateTimer = ndStartUpdateTimer;
  exports.ndAddTableRow = ndAddTableRow;
});

export default Catalog;
