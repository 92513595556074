
import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Stacktrace for '");
__out.push(Mold.escapeHTML($arg.name));
__out.push("'</h2>\n\n<pre>");
__out.push(Mold.escapeHTML($arg.trace));
__out.push("</pre>");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;