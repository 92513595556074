import Dispatch from './dispatch';
import Layout from './layout';
import Base from './base';
import App from './app';
import reportTemplate from './templates/reports/report.mold';
import indicesReportTemplate from './templates/reports/indices-report.mold';
import transactionsReportTemplate from './templates/reports/transactions-report.mold';
import genericFieldTemplate from './templates/reports/generic-field.mold';
import pieFieldTemplate from './templates/reports/pie-field.mold';
import tableFieldTemplate from './templates/reports/table-field.mold';
import oscoreFieldTemplate from './templates/reports/oscore-field.mold';
import _ from 'underscore';

var Reports = {};

var templates = {
  'pie': pieFieldTemplate,
  'table': tableFieldTemplate,
  'oscore': oscoreFieldTemplate,
};

var defaultTemplate = genericFieldTemplate;

var reportTemplates = {
  'indices': indicesReportTemplate,
  'transactions': transactionsReportTemplate,
};

var defaultReportTemplate = reportTemplate;

// Default number of decimal places to show when formatting fractions.
var DEFAULT_DECIMAL_PLACES = 3;

/**
 * Gets the template that should be used to render the report with given path.
 *
 * @param {Array<string>} path - report path.
 * @return {*} - A template object.
 */
Reports.getReportTemplate = function (path) {
  return reportTemplates[path || ''] || defaultReportTemplate;
};

Reports.formatValue = function (value, type) {
  if (type === 'bytes') {
    return Base.printBytes(value);
  } else if (type === 'percentage') {
    return value.toString() + '%';
  } else if (type === 'boolean') {
    return value ? '+' : '-';
  } else if (type === 'time') {
    // JS expects timestamps in ms, not seconds
    return new Date(1000 * value).toLocaleString();
  } else if (type === 'oscore') {
    return value.toFixed(DEFAULT_DECIMAL_PLACES);
  } else if (type === 'integer') {
    return value.toLocaleString();
  } else {
    return value.toString();
  }
};

Reports.tooltipForValue = function (value, type) {
  if (type === 'bytes') {
    return value.toLocaleString() + (value === 1 ? ' byte' : ' bytes');
  }
  return Reports.formatValue(value, type);
};

Reports.isDistributedReport = function (report) {
  return report.fields.find(function (element) {
    return element.next && element.next.match('^shardReport:.*$');
  });
};

/**
 * Insert an 'empty' report field with a 'Per-shard reports' label and
 * no 'next' component - it will work as a separator before the
 * per-shard links.
 *
 * @param {Object} report - original report object.
 * @return {Object} - report object with a separator in place.
 */
Reports.addShardReportSeparator = function (report) {
  var index = report.fields.findIndex(function (element) {
    return element.next && element.next.match('^shardReport:.*$');
  });
  if (index !== -1) {
    report.fields.splice(
      index, 0, { label: 'Per-shard reports', type: 'empty' });
  }
  return report;
};

Reports.showReport = function (path) {
  // TODO: Caching
  var template = Reports.getReportTemplate(path);
  var reportParams = path[0] === '' ? '' : '?path=' + path.join('&path=');
  Base.load(Base.jsonReq(
    'GET', Base.serverUrl('reports', null, { useShard: true }) + reportParams),
            'Generating report').then(function (report) {
              if (Reports.isDistributedReport(report)) {
                report = Reports.addShardReportSeparator(report);
              }
              template.cast(Layout.getPage(), report);
            }, function () {
              Layout.showMessage('Unable to retrieve report: '
                                   + path.join('/'),
                                 false, true);
            });
};

// Field types are either strings or objects that have
// a type property (and some other, type specific properties).
// This function extracts the type name in both cases.
Reports.getFieldType = function (field) {
  if (field.type && field.type.hasOwnProperty('type')) {
    return field.type.type;
  } else {
    return field.type;
  }
};

Reports.renderField = function (path, field, embedded) {
  var template = templates[Reports.getFieldType(field)] || defaultTemplate;
  return template.template({
    field: field,
    path: path,
    embedded: embedded,
  });
};

Reports.castField = function (target, path, field, embedded) {
  var template = templates[Reports.getFieldType(field)] || defaultTemplate;
  return template.cast(target, {
    field: field,
    path: path,
    embedded: embedded,
  });
};

// Takes a column object, returns a CSS width (string) or undefined
Reports.getColumnWidth = function (column) {
  if (column.type.type === 'pie') {
    return '7em';
  }
  return undefined;
};

// Takes a variable number of arguments.
// Each argument can be a list, a string (with '/' separators),
// or an object having an id attribute.
// Everything gets concatenated, but elements that start
// with a '/' are interpreted as absolute paths - everything
// to the left gets ignored.
Reports.reportUrl = function () {
  var result = 'reports';
  _.each(arguments, function (arg) {
    var path;
    if (Array.isArray(arg)) {
      var ids = _.map(arg, function (elt) {
        return elt.id !== undefined ? elt.id : elt;
      });
      path = ids.join('/');
    } else {
      path = arg.id !== undefined ? arg.id : arg;
    }
    if (path && path[0] === '/') {
      result = 'reports' + path;
    } else if (path) {
      result = result + '/' + path;
    }
  });
  var match = result.match(/(.*)\/shardReport:(.*)$/);
  if (match) {
    return Dispatch.relativeUrl('shards/' + match[2] + '/' + match[1]);
  } else {
    return Dispatch.relativeUrl(App.shardPrefix() + result);
  }
};

export default Reports;
