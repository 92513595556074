
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
Mold.dispatchCustom("help", "\nTo specify a node or literal in any of the four fields, use syntax resembling\n<a href='http://www.w3.org/TR/rdf-testcases/#ntriples' target='agraph-docs'>N-triples</a>.\n<pre>\n&lt;http://example.com/node20&gt;\n\"a literal with a \\n newline\"\n\"a literal with a type\"^^&lt;http://example.com/type2&gt;\n\"un littéral avec une langue\"@fr\nxsd:integer\n</pre>\nThe last, of course, only works if you have a <a href=\"#namespaces\">namespace</a> prefix 'xsd' defined.\nUnicode characters <em>may</em> be escaped, as in <code>\"litt\\u00e9ral\"</code>.", __out);
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;