import Base from '../../base';
import infoTemplate from './info.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h3>\n  ");
if ($arg.getVisibleValues()[0]) {
__out.push("\n    True\n  ");
} else {
__out.push("\n    False\n  ");
}
__out.push("\n</h3>\n\n");
__out.push(infoTemplate.template($arg.getInformation()) );
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;