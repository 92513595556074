import setImmediate from '../../set-immediate';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<table width=\"100%\">\n  <tr>\n    <td><div class=\"messagebody\">");
__out.push($arg.text);
__out.push("</div></td>\n    <td class=\"buttons\">\n      <button class=\"pure-button pure-button-primary\">");
__out.push(Mold.escapeHTML($arg.yesText ));
__out.push("</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.yes();
});}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){$node.focus();});}) + "\"></var>");
__out.push("\n      <button class=\"pure-button\">");
__out.push(Mold.escapeHTML($arg.noText ));
__out.push("</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.no();
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;