import $ from 'jquery';
import User from '../user';
import Catalog from '../catalog';
import App from '../app';
import Util from '../util';
import Base from '../base';
import setImmediate from '../set-immediate';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n\n<h2>Sessions</h3>\n<div id=\"session\" class=\"section\">\n  <h3>Start new session</h3>\n  <form >\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop(); App.createSession($node.elements.spec.value, $node.elements.autocommit.checked, $node.elements.initfile.checked);
});}) + "\"></var>");
__out.push("\n\n    <div>\n      <label for=\"spec\">Session specification:</label>\n      <input id=\"spec\" type=\"text\" style=\"width: 20em\" linkedButton=\"startSessionButton\"/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "keyup", function($event, $node) {
Util.maybeEnableLinkedButton($node); 
});}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", " container=session-spec-help\n                          In a session specification, local repositories are written like\n                          <code>&lt;catalog:name></code>\n                          (or <code>&lt;name></code> for repositories in the root catalog).\n                          Remote repositories are written by putting their URL between\n                          <code>&lt;</code> and <code>></code>.\n                          Multiple AllegroGraph repositories can be federated by putting <code>+</code>\n                          signs between them, for example\n                          <code>&lt;repo1>+&lt;repo2></code>.\n                          To apply a reasoner to a repository, write <code>[rdfs++]</code>\n                          or <code>[restriction]</code> after it.\n                          To create a filtered repository, which shows only some of the\n                          graphs in a repository, write the graphs to show between braces,\n                          using <code>null</code> to indicate the default graph.\n                          This, for example, federates reasoning versions of repository\n                          \"A\" in the root catalog, and the \"http://example.com/graph1\"\n                          graph of repository \"B\" in the \"public\" catalog:<br/>\n                          &nbsp; &nbsp; <code>&lt;A>[rdfs++]+&lt;public:B>{&lt;http://example.com/graph1>}[rdfs++]</code>", __out);
__out.push("\n      (<input class=\"centered-checkbox\" type=\"checkbox\" checked id=\"autocommit\"/> <label for=\"autocommit\">autocommit</label>,\n      <input class=\"centered-checkbox\" type=\"checkbox\" id=\"initfile\"/> <label for=\"initfile\">load initfile</label>)\n      <button id=\"startSessionButton\" type=\"submit\">Start</button>\n      <div id=\"session-spec-help\"></div>\n    </div>\n  </form>\n\n  <div id=\"session-list\" class=\"section\">\n    ");
if ($arg.length) {
__out.push("\n    <h3>Running sessions</h3>\n    <ul>\n      ");
Mold.forDepth++;
Mold.forEach($arg, function(sess, $i) {
__out.push("\n      <li><a href=\"");
__out.push(Mold.escapeHTML(sess.uri));
__out.push("\">");
__out.push(Mold.escapeHTML(Base.capitalise(sess.description)));
__out.push("\n          <span class=\"subtle\">(port ");
__out.push(Mold.escapeHTML(sess.port));
__out.push(")</span></a></li>\n      ");
});
Mold.forDepth--;
__out.push("\n    </ul>\n    ");
}
__out.push("\n  </div>\n</div>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  var specInput = $('#spec');
  // The regexp specifies characters disallowed in session specifications.
  specInput.filter_input({regex:'[^\\\\~$.*|/&]'});
  Util.maybeEnableLinkedButton(specInput[0]);
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;