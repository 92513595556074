import Util from '../util';
import setImmediate from '../set-immediate';
import $ from 'jquery';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n\n<h3>Scheduled Events</h3>\n<table class=\"pure-table pure-table-horizontal\" id=\"schedTable\"></table>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function () {
    $arg.renderSchedTable($node); }); }) + "\"></var>");
__out.push("\n<div style=\"height:50px\"></div>\n<h3>Scheduler Log</h3>\n<table class=\"pure-table pure-table-horizontal\" id=\"schedLogTable\"></table>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function () {
        $arg.renderLogTable($node); }); }) + "\"></var>");
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;