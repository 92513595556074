import infoTable from './infoTable.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg) {
__out.push("\n    <div id=\"queryInformation\">\n      <h3>Query Information</h3>\n        ");
if ('federation' in $arg) {
__out.push("\n          Information per shard of the distributed repository:\n          ");
var successesArray = $arg['federation']['successes'] 
__out.push("\n          ");
var failuresArray = $arg['federation']['failures'] 
__out.push("\n          ");
if ((successesArray && successesArray.length)) {
__out.push("\n            <p>Successes:\n            <dl>\n              ");
Mold.forDepth++;
Mold.forEachIn(successesArray, function(ix, obj, $i) {
__out.push("\n                <dt><h3>");
__out.push(Mold.escapeHTML(Object.keys(obj)[0]));
__out.push("</h3></dt>\n                <dd>");
__out.push(infoTable.template(Object.values(obj)[0]));
__out.push("</dd>\n              ");
});
Mold.forDepth--;
__out.push("\n            </dl>\n          ");
}
__out.push("\n          ");
if ((failuresArray && failuresArray.length)) {
__out.push("\n            <p>Failures:\n            <dl>\n              ");
Mold.forDepth++;
Mold.forEachIn(failuresArray, function(ix, obj, $i) {
__out.push("\n                <dt><h3>");
__out.push(Mold.escapeHTML(Object.keys(obj)[0]));
__out.push("</h3></dt>\n                <dd>");
__out.push(infoTable.template(Object.values(obj)[0]));
__out.push("</dd>\n              ");
});
Mold.forDepth--;
__out.push("\n            </dl>\n          ");
}
__out.push("\n        </dl>\n        ");
} else if ($arg.constructor === Object ) {
__out.push("\n          ");
__out.push(infoTable.template($arg) );
__out.push("\n        ");
}
__out.push("\n    </div>\n  ");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;