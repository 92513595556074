import { $$ } from '../../util';
import Part from '../../part';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if (($$('auditLog'))) {
__out.push("\n  ");
Mold.forDepth++;
Mold.forEach($arg, function(row, $i) {
__out.push("\n    <tr>\n      ");
Mold.forDepth++;
Mold.forEach(row, function(val, $i) {
__out.push("\n        <td>\n          ");
if (val) {
__out.push("\n            ");
if (typeof val === 'string') {
                  val = Part.part(val);
                }
            
__out.push("\n            ");
if (val === 'null') {
__out.push("\n              <span class=\"subtle\">(null)</span>\n            ");
} else {
__out.push("\n              ");
__out.push(Mold.escapeHTML(val.shortName.replace(/"/g, '')));
__out.push("\n            ");
}
__out.push("\n          ");
} else {
__out.push("&nbsp;");
}
__out.push("\n        </td>\n      ");
});
Mold.forDepth--;
__out.push("\n    </tr>\n  ");
});
Mold.forDepth--;
__out.push("\n");
} else {
__out.push("\n  ");
Mold.forDepth++;
Mold.forEach($arg, function(row, $i) {
__out.push("\n    <tr class=\"queryTableRow\">\n      ");
Mold.forDepth++;
Mold.forEach(row, function(val, $i) {
__out.push("\n        <td class=\"queryTableCell\">");
if (val) {
Mold.dispatchCustom("part", val, __out);
} else {
__out.push("&nbsp;");
}
__out.push("</td>\n      ");
});
Mold.forDepth--;
__out.push("\n    </tr>\n  ");
});
Mold.forDepth--;
__out.push("\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;