
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>");
__out.push(Mold.escapeHTML($arg.config.headerLabel));
__out.push("</h2>\n\n");
Mold.dispatchCustom("query_option_table", $arg, __out);
__out.push("\n\n");
if ($arg.config.canAdd) {
__out.push("\n  <p>\n    <span class=\"subtle linkish\">[");
__out.push(Mold.escapeHTML($arg.config.addLabel));
__out.push("]</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.config.onAdd();
});}) + "\"></var>");
__out.push("\n  </p>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;