import Base from './base';
import Layout from './layout';
import Dispatch from './dispatch';
import _ from 'underscore';
import $ from 'jquery';


/*
 * Display values over time in a graphs on a web page.
 *
 * This is the common code and is currently used by systemstat.js
 * and repstats.js which is where you'll see examples of how
 * to call it.
 */

/** @namespace Anystats */
var Anystats = {};

// How long should the initial help message be visible, in seconds.
var HELP_MESSAGE_TIMEOUT = 30;

// How long to wait with redraw after a resize event, in ms.
// This is needed because some browsers create hundreds of resize
// events when the user is dragging the border, so we 'debounce'
// the event.
var REDRAW_DELAY = 300;

// A padding applied... somewhere, in pixels.
var PLACEHOLDER_PADDING = 20;

/**
 * Used to make sure we show the help message only once.
 *
 * @type {boolean}
 */
Anystats.helpMessageShown = false;

var updateInterval = null; // The currently running auto update setInterval handle.

Anystats.cancelAutoUpdate = function (resetButton) {
  if (updateInterval !== null) {
    window.clearInterval(updateInterval);
  }
  updateInterval = null;
  if (resetButton) {
    document.getElementById('autoNever').checked = 'checked';
  }
};

/**
 * This function displays set of graphs according to the graph description.
 * It uses
 * http://www.flotcharts.org/
 *
 * @param {Object} page -  the chart sizes we  allow, see below.
 * @param {Object} graphs - a description of the graphs to display
 * @param {String} uriSuffix - url (possibly relative) to access for stats
 * @param {String} requestTreePath - the string that will be passed as requestTree
                     parameter to systemstat.json service call.
 * @param {Function} loadPage - if defined, is a function to call to
 *                   load the page  to display
 * @param {Function} dataHook - if defined this is called with
 *                   each new data sample as an argument.
 *
 * The page argument is a object like this:
 * { sizes: { 'big': 200, 'small': 100, }, defaultSize: 'small', }
 * that lists symbolic names for the sizes as well as chosing one
 * to be the default value.  This is used to populate a control
 * in the settings on the left side of the page.
 *
 */
Anystats.showAnystats = function (page, graphs, uriSuffix, requestTreePath,
                                  loadPage, dataHook) {
  if (loadPage) {
    loadPage();
  }

  // This is the value in milliseconds that must be subtracted from a local time
  // to convert to Zulu time, and vice versa.
  var localTzOffset = -((new Date()).getTimezoneOffset()) * 60 * 1000;

  var anydata = []; // This holds the last batch of data from the server.
  var gLen = graphs.length;
  var showLegend = true;
  var yScalepx = page.sizes[page.defaultSize];

  // A counter used to handle server responses arriving out of order.
  // This might happen, since the requests are processed asynchronously.
  // The counter is incremented with each request and echoed back by the
  // server. Responses with counter value smaller than the last processed one
  // are simply ignored.
  var requestId = 0;
  var lastRequestIdReceived = -1;

  // RFE: This could be controlled in the page settings form.
  // A spinner with min, max, and step?
  var desiredSampleCount = 1500;

  var serverTzOffset = 0; // The server time zone in milliseconds from UTC.
  var dataCurrentUT = 0; // The UT contained in the last sample received.

  // Get the data from the process collecting the data.
  // Then populate the individual graph data arrays and redraw.
  function getData() {
    // Do nothing if the user is currently making a selection.
    if (isSelecting()) {
      return;
    }
    Base.load(
            Base.catchNotFound(Base.req('GET', uriSuffix,
                    {
                      query: {
                        // Get only data collected since last retrieval.
                        startUt: dataCurrentUT,
                        // Pass and increment the request counter.
                        id: requestId++,
                        // Access list for information obtained.
                        requestTree: requestTreePath,
                      },
                    })),
            'Loading repl stats',
            function (text) {
              var newStuff = JSON.parse(text);
              // If we already have newer data, ignore this request
              if (newStuff.id < lastRequestIdReceived) {
                return;
              }
              lastRequestIdReceived = newStuff.id;
              var newData = newStuff.data; // The array of samples.
              var newDataLen = newData.length;
              // If data is non-empty and dataHook is provided, call
              // it with the new data.
              if (dataHook && newDataLen > 0) {
                dataHook(newData);
              }
              // Server tz offset from UTC.
              // Convert time zone offset to milliseconds
              serverTzOffset = newStuff.tz * 60 * 60 * 1000;
              if (newDataLen > 0) {
                dataCurrentUT = newData[newDataLen - 1].utms;
              }

              // Append new data to the global data array
              anydata = anydata.concat(newData);
              if (anydata.length > desiredSampleCount) {
                // Remove excess entries from the beginning of the global data array
                anydata.splice(0, anydata.length - desiredSampleCount);
              }

              redraw();
            },
            true // Do not display the spinner with the loading text.
    );
  }

  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
  };

  /**
   * Prepares a single line of time series data for display by Flot.
   *
   * Modifies the time series description (line) by replacing its 'data'
   * property with data extracted from the global anydata variable and
   * adjusted to account for the time zone chosen by the user.
   *
   * @param {Object} line Single time series description.
   * @param {Number} tzAdjust Number of milliseconds to add to all timestamps.
   */
  function createChartArray(line, tzAdjust) {
    var pathArr = line.key.split('.');
    line.data = _.map(anydata, function (col) {
      return [col.utms + tzAdjust, getNestedObject(col, pathArr)];
    });
  }

  /**
   * Returns the adjustment in milliseconds that should be applied to time data.
   *
   * The result depends on the timezone setting chosen in the UI.
   *
   * See https://github.com/flot/flot/blob/master/API.md#time-series-data for a
   * description of time series handling in Flot.
   *
   * @return {number} Number of milliseconds to add to timestamps.
   */
  function computeTzAdjust() {
    var timeZone = document.getElementById('timeZone');
    var targetTzState;
    if (timeZone) {
      targetTzState = timeZone.innerText;
    }
    var adjust = 0;
    if (targetTzState === 'local') {
      adjust = localTzOffset;
    } else if (targetTzState === 'server') {
      adjust = serverTzOffset;
    } // else targetTzState === 'UTC'
    return (adjust);
  }

  /**
   * Adds or replaces 'data' attributes in all time series in all graphs by
   * extracting data from 'anydata' and adjusting to account for time zone settings.
   */
  function createChartArrays() {
    var adjust = computeTzAdjust();
    for (var gi = 0; gi < gLen; gi++) {
      var stuff = graphs[gi].lines;
      for (var lineIndex = 0; lineIndex < stuff.length; lineIndex++) {
        var line = stuff[lineIndex];
        createChartArray(line, adjust);
      }
    }
  }

  /* This function determines whether the right edge of a mouse selection includes the
     right edge of the chart, i.e. its maximum value.  It is used by zoom to decide whether
     to turn off auto update, or to continue updating showing only the most recent samples
     of the zoom extent.  See below.
  */
  function extendsToNow(selectMaxx, graph) {
    var extendsToNow = false;
    var dat = graph.lines[0].data;
    var lastx = dat[dat.length - 1][0];
    if ((lastx - selectMaxx) <= 500) {
      extendsToNow = true;
    } // Within 0.5 sec of right edge?
    return extendsToNow;
  }

  /* This is the zoom machinery.  Swiping a horizontal region of any
     chart selects the zoom region.  Exact behavior depends on
     whether the zoom extends to the extreme right edge of the
     chart, i.e. the current time.  If it does not, auto update is
     cancelled.  If it does, and auto update is running, then auto
     update will continue and the zoom will update for the selected
     time range relative to the right edge of the chart.
  */

  // If zoomed region includes right border, this saves the zoom width
  // in msec, needed if auto update is running.
  var zoomWidth = null;

  // Bind a region select handler for each plot.
  graphs.forEach(function (graph) {
    $(graph.div).on('plotselected',
                    function (_event, ranges) {
                      var min = ranges.xaxis.from;
                      var max = ranges.xaxis.to;
                      if (extendsToNow(max, graph)) {
                        // On autoupdate or update, zoom to this distance.
                        zoomWidth = max - min;
                      } else {
                        // Zoom region does not touch the right border,
                        // so we disable autoupdate, assuming that the user
                        // is interested in examining a particular area.
                        Anystats.cancelAutoUpdate(true);
                      }
                      document.getElementById('unzoom').disabled = false;
                      graphs.forEach(function (graph) {
                        var plotn = graph.plot;
                        $.each(plotn.getXAxes(), function (_event, axis) {
                          var opts = axis.options;
                          opts.min = min;
                          opts.max = max;
                        });
                        plotn.setupGrid();
                        plotn.draw();
                        plotn.clearSelection();
                      });
                    });
  });

  /**
   * Checks if the user is currently making a selection.
   *
   * @return {boolean} - true if selection is in progress.
   */
  function isSelecting() {
    return graphs.some(function (graph) {
      // First check if we have created the plot already.
      return graph.plot && graph.plot.getSelection() !== null;
    });
  }

  // Is there a possibility for this to be called multiple times
  // asynchronously?  Could protect without complex asyncs by
  // incrementing a variable as a semaphore, later callers just return
  // immediately.
  function redraw() {
    $('.flot-placeholder').css({
      'min-height': yScalepx,
      'height': yScalepx,
    });
    $('.flot-placeholder1').css({
      'min-height': yScalepx + PLACEHOLDER_PADDING,
      'height': yScalepx + PLACEHOLDER_PADDING,
    });

    createChartArrays();

    for (var i = 0; i < gLen; i++) {
      var g = graphs[i];
      var opts = $.extend(
        true,
        {
          xaxis: { mode: 'time' },
          yaxis: { labelWidth: 60 },
          legend: {
            show: showLegend,
            backgroundOpacity: 0.3,
            sorted: 'reverse', // make vertical order same as stacking
            position: 'nw' },
          selection: { mode: 'x' },
          series: {
            stack: true,
            lines: {
              show: true,
              fill: true,
              steps: null,
            } } },
        g.gOptions);
      if (yScalepx < page.sizes.short) {
        // In tiny scaling we want to suppress the x-axis time labels
        // in all but the first chart.  But flot by default reduces
        // chart width by the amount the x-axis label may extend beyond
        // the right edge of the chart, so the first chart is less wide
        // and the vertical ticks don't line up.  The following
        // compensates.
        opts.xaxis.labelWidth = 50;
        if (i > 0) {
          opts.xaxis.labelHeight = 1; // 1 instead of 0 because 0 tests as undefined.
          opts.xaxis.timeformat = '';
        }
      }
      if (zoomWidth) {
        // var now = dataCurrentUT;
        var data = g.lines[0].data;
        var now = data[data.length - 1][0]; // The TZ scaled xaxis right edge.
        opts.xaxis.max = now;
        opts.xaxis.min = now - zoomWidth;
      }
      g.plot = $.plot(g.div, g.lines, opts);
    }
  }

  var controls = $('.flotControls');

  $('#refresh').click(getData);

  $('#unzoom').click(function () {
    zoomWidth = null;
    document.getElementById('unzoom').disabled = true;
    redraw();
  });

  document.getElementById('unzoom').disabled = true;

  function doAutoUpdate(seconds) {
    Anystats.cancelAutoUpdate(false);
    if (seconds > 0) {
      var getDataWhileOnPage = Dispatch.protect(getData, Anystats.cancelAutoUpdate);
      updateInterval = window.setInterval(getDataWhileOnPage, 1000 * seconds);
    }
  }

  controls.find('input[name="autoUpdate"]').change(
          function () {
            var autoUpdateSeconds = Number($(this).val()); // seconds, 0 for never
            doAutoUpdate(autoUpdateSeconds);
          });

  // may start out in auto update mode
  doAutoUpdate(Number(controls.find('input[name="autoUpdate"]:checked').val()));

  // Cycle through local, server, UTC on each click.
  $('#timeZone').click(function () {
    var element = document.getElementById('timeZone');
    if (element.innerText === 'local') {
      element.innerText = 'server';
    } else if (element.innerText === 'server') {
      element.innerText = 'UTC';
    } else {
      element.innerText = 'local';
    }
    // zonifyTime(anydata);
    redraw();
  });

  controls.find('input[name="yScale"]').change(
    function () {
      yScalepx = Number($(this).val());
      if (yScalepx < page.sizes.short) {
        $('.chartTitle').hide();
        showLegend = false;
      } else {
        $('.chartTitle').show();
        showLegend = true;
      }
      redraw();
    });

  $(window).resize(_.debounce(redraw, REDRAW_DELAY));

  getData();

  if (!Anystats.helpMessageShown) {
    Anystats.helpMessageShown = true;
    Layout.showMessage('Mouse drag a time region to zoom.' +
        ' If right edge is included, auto refresh stays on.',
        HELP_MESSAGE_TIMEOUT, false);
  }
};

export default Anystats;
