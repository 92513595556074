import CrossTab from './crosstab';
import App from './app';
import Base from './base';
import Util from './util';
import { MAsync } from './async';
import { Async } from './async';
import Layout from './layout';
import Query from './query';
import { $$ } from './util';
import Store from './store';
import Part from './part';
import HttpStatus from './http-status';
import _ from 'underscore';
import listTemplate from './templates/user/list.mold';
import tokenListTemplate from './templates/user/tokens.mold';
import permissionsTemplate from './templates/user/permissions.mold';
import namespacesTemplate from './templates/user/namespaces.mold';
import queryOptionsTemplate from './templates/user/query-options.mold';
import loginTemplate from './templates/user/login.mold';
import changePasswordTemplate from './templates/user/change-password.mold';
import newTemplate from './templates/user/new.mold';
import newTokenTemplate from './templates/user/new-token.mold';
import deniedTemplate from './templates/denied.mold';

/**
 * Managing the logged in user, and various pieces of interface
 * functionality related to user and account management.
 * @namespace User
 */
var User = {};

// Global state: the logged in user. Will contain an object when we
// have a user (even if it's the anonymous user), and null if we are
// not logged in and there is no anonymous user.
User.user = null;

User.sessionId = null;

// LocalStorage key used to store all user data
User.AUTH_KEY = 'auth';

// Storage event used to signal logout to other tabs
User.LOGOUT_EVENT = 'logout-event';

// LocalStorage key used to store the timestamp of the last
// server access (used to detect login timeouts).
User.LAST_ACCESS_KEY = 'last-activity';

// Time in seconds, for which user access token is valid if unused.
User.IDLE_TOKEN_TIMEOUT = 10 * 60; // 10 minutes

// Time in seconds, for which user access token is valid if unused, if
// the user selected a 'Stay logged in' option.
/* eslint-disable no-magic-numbers */
User.LONG_IDLE_TOKEN_TIMEOUT = 30 * 24 * 3600; // 30 days
/* eslint-enable no-magic-numbers */

/**
 * Initializes the User module (called in app.js).
 */
User.init = function () {
  /**
   * Observable used for listening to user changes.
   * @type {CrossTab.Observable}
   * @private
   */
  User._userChangeListeners = new CrossTab.Observable();

  // Setup event handler for auth info from other tabs
  App.crosstabVars.observe(User.AUTH_KEY).addObserver(
      User._onNewAuthInfo, User);

  // Logout is a special case...
  App.events.observe(User.LOGOUT_EVENT).addObserver(
      User._performLogout, User);

  // Setup idle timeout - will be activated on login
  User.onUserChange(User._resetIdleTimer.bind(User), true);
};

/**
 * @return {boolean} - whether a non-anonymous user is currently logged in.
 */
User.isLoggedIn = function () {
  return Boolean(User.user && User.user.name !== 'anonymous');
};

User.getTagUrl = function (name, tag) {
  return Base.url('/users/', name, '/data/wv.', tag);
};

User.getUserData = function (tag, name, isJson) {
  var async = Base.req('GET', User.getTagUrl(name, tag));
  if (isJson) {
    async = async.transform(function (val) {
      return val && Util.readJSON(val);
    });
  }
  return Base.catchNotFound(async);
};

User.setUserData = function (tag, name, value) {
  return Base.req('PUT', User.getTagUrl(name, tag), { body: value });
};

User.delUserData = function (tag, name) {
  return Base.req('DELETE', User.getTagUrl(name, tag));
};

User.getCurrentUserData = function (tag, isJson) {
  return User.getUserData(tag, User.user.name, isJson);
};

User.setCurrentUserData = function (tag, value) {
  return User.setUserData(tag, User.user.name, value);
};

User.delCurrentUserData = function (tag) {
  return User.delUserData(tag, User.user.name);
};

User.getCurrentUserDataList = function (prefix) {
  return Base.jsonReq(
      'GET',
      Base.url('/users/', User.user.name, '/data', { prefix: 'wv.' + prefix }));
};

/**
 * Register a callback that will be invoked each time a value
 * is assigned to the global user variable.
 *
 * This happens after a successful login and logout, including
 * anonymous logins or automated logins using data from local
 * store.
 *
 * The callback function will also be invoked once immediately,
 * unless a truthy value is passed as the second argument
 * to onUserChange
 *
 * @param {function} f - Callback function.
 * @param {boolean} [dontCallImmediately] - if true, skip the initial
 *        invocation of the callback function.
 */
User.onUserChange = function (f, dontCallImmediately) {
  User._userChangeListeners.addObserver(f);
  if (!dontCallImmediately) {
    f();
  }
};

/**
 * Loads user data for user NAME.
 * On success, establishes the current user and then calls the
 * callback 'cb'.
 *
 * @param {string} name - Load data for this user.
 * @param {function} cb - A callback function of zero args. Called
 *   after user data is successfully loaded. Typically used to
 *   refresh the app view based on the newly loaded user data.
 * @param {function} unauth - A callback function that accepts a
 *   single string argument, a message indicating why the user
 *   data could not be loaded. Typically used to reprompt the
 *   client for auth.
**/
User._loadUserData = function (name, cb, unauth) {
  var user = {};

  var as = MAsync.collect(
      Base.jsonReq('GET', Base.url('/users/', name, '/effectivePermissions')));
  Base.load(as, 'Fetching user information').wait(success, fail);

  function success(perms) {
    // Fetch this only if the previous one succeeds to avoid duplicate
    // failedLogins in the audit log.
    var as2 =
        (name !== 'anonymous')
          ? Base.jsonReq('GET', Base.url('/users/', name, '/loginTimeout'))
          : Async.dummy(-1);
    Base.load(as2, 'Fetching user information').wait(success1, fail);
    function success1(loginTimeout) {
      if (loginTimeout === -1) {
        loginTimeout = null;
      } else {
        // Convert seconds to ms
        loginTimeout = 1000 * loginTimeout;
      }
      user = {
        name: name, perms: perms,
        loginTimeout: loginTimeout,
      };
      User._loadRepoData(user).then(function () {
        User.user = user;
        User._userChangeListeners.notifyObservers();
        if (cb) {
          cb();
        }
      }, fail);
    }
  }
  function fail(message, req) {
    if (req && (req.status === HttpStatus.HTTP_FORBIDDEN ||
        req.status === HttpStatus.HTTP_UNAUTHORIZED)) {
      var authData = User.getAuthData();
      if (authData && authData.errorMessage) {
        message = authData.errorMessage;
      }
      User._clearAuth();
      User._userChangeListeners.notifyObservers();
      if (unauth) {
        unauth(message);
      }
    } else {
      window.asyncFail('Fetching user information', message, req);
    }
  }
};

User._loadRepoData = function (user) {
  var nsReq;
  var qoReq;
  var accessReq;

  if (App.isInRepoOrSession()) {
    nsReq = Base.jsonReq('GET', Base.url('namespaces', { type: 'any', effective: false }));
    qoReq = Base.jsonReq('GET', Base.url('query-options', { type: 'any', effective: false }));
    accessReq = Base.jsonReq('GET', 'access');
  } else {
    nsReq = Async.dummy([]);
    qoReq = Async.dummy([]);
    accessReq = Base.jsonReq(
        'GET',
        Base.url('/users/', user.name, '/effectiveAccess'));
  }
  return Base.loadWithDefaultErrorHandler(
    MAsync.collect(nsReq, qoReq, accessReq)
        .then(function (namespaces, queryOptions, access) {
          // Only for the catalog page, when 'access' is a list of permissions.
          if (typeof access !== 'string') {
            access = convertAccessListToString(access, user.perms);
          }
          user.access = access;
          user.namespaces = namespaces;
          user.queryOptions = queryOptions;
          User.defaultNamespaces = User.DEFAULT_NAMESPACES;
        }),
    'Fetching user information');
};

function convertAccessListToString(access, permissions) {
  var r = Util.member('super', permissions);
  var w = r;
  var l = false;
  if (App.currentCatalog ==='root') {
    App.currentCatalog = '/';
  }
  Util.forEach(access, function (acc) {
    var isCurrentCatalog = (acc.catalog === App.currentCatalog);
    if (acc.catalog === '*' || isCurrentCatalog && (acc.repository === '*')) {
      if (acc.read) {
        r = true;
      }
      if (acc.write) {
        w = true;
      }
      if (acc['query-results-limit']) {
        l = true;
      }
    }
  });
  return (l ? 'l' : '') + (r ? 'r' : '') + (w ? 'w' : '');
}

// A function that will be called after every URL change.
// We want to reload user permissions, namespaces etc,
// but only if we're in a different repository or session.

User._lastLabel = '';

User.refreshPermissions = function () {
  var label = App.isInRepoOrSession() ? App.getCurrentRepoOrSessionLabel() : '';
  if (User.user && label !== User._lastLabel) {
    return User._loadRepoData(User.user).then(function () {
      User._lastLabel = label;
      User._userChangeListeners.notifyObservers();
    });
  } else {
    return Async.dummy();
  }
};

/**
 * Attempt an anonymous login.
 *
 * @param {function} cb - A callback function of zero args.
 *   Used to update the app view based on the results of
 *   loading the anonymous user data, whether successful
 *   or unsuccessful.
**/
User._initLogin = function (cb) {
  User._clearAuth();
  User._loadUserData('anonymous', cb, function () {
    User.loginDialog(cb);
  });
};

// Called when receiving new authentication info from ANY tab,
// including this one.
User._onNewAuthInfo = function () {
  User.initUser();
};

/**
 * Initialises the user at start-up time. Looks for authentication
 * data in the local storage and if it satisfies expected conditions,
 * tries to use it for authentication. If no credentials are found,
 * checks if other tabs are open and requests auth info through the
 * storage event mechanism.
 *
 * @param {function()} [cb] - Callback invoked once the initial username
 *                           has been set. See call in app.js:App.initialize.
 */
User.initUser = function (cb) {
  var storedInfo = Util.getFromStorage(User.AUTH_KEY);
  var async;
  if (storedInfo !== null && User.authDataConforms(storedInfo)) {
    // Found credentials in local or session store
    async = Async.dummy(storedInfo);
  } else {
    // Ask other tabs
    async = App.crosstabVars.getItem(User.AUTH_KEY);
    // Fail if null is returned
    async = async.then(function (authInfo) {
      if (authInfo === null || !User.authDataConforms(authInfo)) {
        return Async.failure('Auth credentials not found');
      } else {
        return Async.dummy(authInfo);
      }
    });
  }

  async.wait(function (authInfo) {
    User.sessionId = authInfo.session;
    User._loadUserData(authInfo.name, cb, function () {
      User._initLogin(cb);
    });
  }, function () {
    // No credentials and no other frames, try anonymous login.
    User._initLogin(cb);
  });
};

/**
 * Construct basic authorization header and request token from server
 * using basic authentication method.
 *
 * @param {string} name - Username.
 * @param {string} password - Plaintext password.
 * @param {boolean} keep - if true, generate a token with much longer timeout.
 * @return {async} - result of the token generation request.
 */
User._createAuthToken = function (name, password, keep) {
  return Base.req(
    'GET', Base.url('/token') + Base.encodeArgs({
      type: 'agwv',
      idleTimeout: keep ? User.LONG_IDLE_TOKEN_TIMEOUT : User.IDLE_TOKEN_TIMEOUT,
    }), {}, 'Basic ' + btoa(name + ':' + password)
  );
};

/**
 * Place authentication credentials in a local store.
 *
 * @param {string} name - Username.
 * @param {string} password - Plaintext password.
 * @param {boolean} keep - if true, the data will be preserved
 *        when the browser window is closed.
 * @return {async} - makes sure auth data is in storage (with or withouth the token).
 */
User.setAuth = function (name, password, keep) {
  // Authentication info structure must store AG version to make it
  // easier to migrate between authentication methods in the future
  // releases.
  var authInfo = { name: name, session: User.sessionId, version: App.agraphVersion };
  return Base.load(User._createAuthToken(name, password, keep), 'Generating access token')
      .then(function (token) {
        // On success, store generated token along with auth info.
        authInfo.token = token;
        User.setAuthData(authInfo, keep);
      }, function (message) {
        // On failure, set empty token, which will fail authentication
        // later on.
        authInfo.token = '';
        authInfo.errorMessage = message;

        User.setAuthData(authInfo, keep);
      });
};

User.setAuthData = function (authInfo, keep) {
  // Clear old credentials from all scopes
  Util.removeFromStorage(User.AUTH_KEY);
  Util.setInStorage(User.AUTH_KEY, authInfo, keep ? 'local' : 'session');
  // Inform other tabs - the last argument ensures that our own
  // handler will not be triggered.
  App.crosstabVars.setItem(User.AUTH_KEY, authInfo, {
    includeCurrentTab: false,
  });
};

User.getAuthData = function () {
  return Util.getFromStorage(User.AUTH_KEY);
};

// Check if authInfo structure satisfies expected conditions:
//   - it has 'version' property, and
//   - its value is the same as current agraph version (i.e. the
//     authentication data was created for the AG instance of the same
//     version; see User.setAuth).
User.authDataConforms = function (authInfo) {
  return Boolean(authInfo.version && authInfo.version === App.agraphVersion);
};

// Timer used to 'enforce' login timeouts.
User.idleTimer = null;

// Always wait at least this long (ms) - in case the browser decided
// it is ok to fire a timeout a few ms too early.
User.MIN_IDLE_TIMEOUT = 10000;

// Called when a potential login timeout is detected.
User._timeout = function () {
  function done() {
    Layout.showMessage('Session expired - please log in again', true);
  }
  var lastAccess = Util.getFromStorage(User.LAST_ACCESS_KEY, 'local');
  if (lastAccess && User.user && User.user.loginTimeout) {
    var threshold = lastAccess + User.user.loginTimeout;
    if (Date.now() >= threshold) {
      User._clearAuth();
      User._loadUserData('anonymous', done, done);
    } else {
      var remaining = Math.min(User.MIN_IDLE_TIMEOUT, threshold - Date.now());
      User.idleTimer = window.setTimeout(User._timeout.bind(User), remaining);
    }
  }
};

/**
 * Update the last access time used to detect login timeouts.
 * Also inform other tabs through a storage event.
 */
User.touch = function () {
  // Put last access time in local store, so other tabs can see it.
  Util.setInStorage(User.LAST_ACCESS_KEY, Date.now(), 'local');
};

User._resetIdleTimer = function () {
  // Clear the old timer if it exists
  if (User.idleTimer !== null) {
    window.clearTimeout(User.idleTimer);
    User.idleTimer = null;
  }
  if (User.user && User.user.loginTimeout !== null) {
    User.idleTimer = window.setTimeout(
        User._timeout.bind(User), User.user.loginTimeout);
  }
  // Set the handler called during each HTTP request
  // that does not carry the 'noTouch' flag.
  Base.touch = User.touch.bind(User);
};

/**
 * Constructs an authentication token for the current user.
 *
 * @return {string|null} Token that should be sent in the authentication header
 *                       or null, if the user doesn't have authentication data.
 */
User.getAuthToken = function () {
  var authInfo = User.getAuthData();
  if (authInfo !== null) {
    var result = 'Token ' + authInfo.name + ' ' + authInfo.token;
    if (authInfo.session) {
      result += ' ' + authInfo.session;
    }
    return result;
  }
  return null;
};

/**
 * Checks if user's auth data is present.
 *
 * @param {Object} authInfo - auth info object; optional, used to
 *                            accomodate cases when auth info is
 *                            extracted beforehand.
 * @return {boolean} - Whether user has a token.
 */
User.hasAuthToken = function (authInfo) {
  if (!authInfo) {
    authInfo = User.getAuthData();
  }
  return authInfo !== null && authInfo.token !== '';
};

User._performLogout = function () {
  Query.queryHistory.clear();
  User._clearAuth();
  App.refresh();
};

User.logout = function () {
  var async = Base.load(
      Base.req('POST', Base.url('/users/', User.user.name, '/logout'), {}),
      'Logging out', null);
  async.wait(doneOrNot, doneOrNot);

  function doneOrNot() {
    // Actual logout will be performed by the event handler.
    App.events.triggerEvent(User.LOGOUT_EVENT);
  }
};

// Show a login dialog, try to use the given credential to fetch user
// information. Calls the function 'callback' to update the view of the
// app that is visible to the user when it presents the login dialog.
User.loginDialog = function (callback) {
  var close;
  var data = {
    ok: tryLogin,
    cancel: function () {
      close();
    },
  };
  close = Layout.customDialog('login-dialog', loginTemplate, data, false, false);

  if (callback) {
    callback();
  }

  function tryLogin(name, password, keep) {
    User.sessionId = User._newSessionId();
    User.setAuth(name, password, keep).then(function () {
      User._loadUserData(name, success, fail);
    });

    function success() {
      Base.load(Base.req('POST', Base.url('/users/', name, '/login')),
          'Logging in', function () {
          });
      close();
      Query.queryHistory.clear();
      // Remove any lingering messages. These are almost certainly related
      // to failed logins, but are likely irrelevant in any event since
      // a new user is being logged in.
      Layout.removeMessages();
      Layout.showMessage('Login successful.', Layout.SHORT_DELAY, false);
    }

    function fail(message) {
      if (message !== 'No anonymous access allowed.') {
        // These messages are disappear=false, but they
        // will be removed automatically (w/o user dismissal)
        // upon a successful login.
        Layout.showMessage(message, false, true);
        close();
        if (User.passwordExpired(message)) {
          User.changePasswordDialog(keep);
        }
      }
      User._loadUserData('anonymous');
    }
  }
};

// Show login dialog and replace auth data in storage with input, if
// the username matches current user's name. Show an error
// otherwise. Cancelling the dialog performs logout.
User.reloginDialog = function () {
  var close;
  if (!$$('login-name')) {
    var data = {
      ok: tryRelogin,
      cancel: function () {
        close();
        User._performLogout();
      },
    };
    close = Layout.customDialog('login-dialog', loginTemplate, data, true, false);
  }

  function tryRelogin(name, password, keep) {
    if (User.user.name === name) {
      // Since relogin with incorrect password will set token to an
      // empty string which would break us out of the relogin loop (see
      // User.hasAuthToken() and Base._reqWithAsync() in base.js),
      // we save old auth info to be able to restore it and stay in
      // the relogin loop if that is the case.
      var oldAuthInfo = Util.getFromStorage(User.AUTH_KEY, keep ? 'local' : 'session');
      User.setAuth(name, password, keep).then(function () {
        close();
        if (User.hasAuthToken()) {
          Layout.removeMessages();
          Layout.showMessage('Relogin successful.', Layout.SHORT_DELAY, false);
        } else {
          // Restore old auth info to stay in the relogin loop.
          Util.setInStorage(User.AUTH_KEY, oldAuthInfo, keep ? 'local' : 'session');
          Layout.showMessage('Relogin failed. Bad username/password combination.', false, true);
          User.reloginDialog();
        }
      });
    } else {
      close();
      Layout.showMessage('Relogin failed. Login under the same user or press \'Cancel\' ' +
                         'to be able to login under a different one.', false, true);
      User.reloginDialog();
    }
  }
};

User.passwordExpired = function (message) {
  return message === 'Password expired.';
};

User._newSessionId = function () {
  var mask = 0xffffffff;
  var radix = 16;
  return ((Math.floor(Math.random() * mask).toString(radix)) +
      (Math.floor(Math.random() * mask).toString(radix)) +
      (Math.floor(Math.random() * mask).toString(radix)) +
      (Math.floor(Math.random() * mask).toString(radix)));
};

// Attempt to destroy the authentication token using token deletion
// API. Pass username and token as an explicit authorization header
// (see User._clearAuth for an explanation).
User._destroyAuthToken = function (name, token) {
  return Base.load(
    Base.req('DELETE', Base.url('/token') + Base.encodeArgs({ token: token }),
             {}, 'Basic ' + btoa(name + ':' + token)),
    'Destroying access token');
};


// Clear current user data (User.user and User.sessionId variables),
// remove authentication data from storage and destroy authentication
// token if it exists.
//
// Authentication data is removed from storage before attempting to
// destroy the token in order to make sure that token destruction
// failure (e.g. if the token is already expired) does not trigger
// the relogin loop (see User.hasAuthToken call in Base._reqWithAsync).
User._clearAuth = function () {
  User.sessionId = null;
  User.user = null;

  var authInfo = User.getAuthData();

  Util.removeFromStorage(User.AUTH_KEY);

  if (User.hasAuthToken(authInfo)) {
    User._destroyAuthToken(authInfo.name, authInfo.token)
        .wait(null, function () {}); // Make sure token destruction fails silently.
  }
};

User.changePasswordDialog = function (keepLoggedIn) {
  // changePasswordDialog can be called from different contexts:
  // 1. A request returned status HTTP_UNAUTHORIZED and the body was
  //    "Password expired", so instead of showing "Relogin" dialog and
  //    refreshing the token, we show the "Change password" dialog and
  //    change the password first.
  // 2. User was trying to log-in, but password is expired. Then
  //    changePasswordDialog should inherit loginDialog's "Keep logged
  //    in" setting.
  // 3. User is logged in and just trying to change the password. Then
  //    we want to inherit user's current storage ("local" /
  //    "session"), i.e. keep current setting on with new password.
  // 4. In other cases just keep it safe and use "session".
  var keep;
  if (keepLoggedIn !== undefined) {
    keep = keepLoggedIn;
  } else if (User.isLoggedIn()) {
    // since user is logged in, credentials MUST be held in either local (for keep == true)
    // or session (keep == false) storage.
    var currentlyKept = Util.getFromStorage(User.AUTH_KEY, 'session') === null;
    keep = currentlyKept;
  } else {
    keep = false;
  }
  var close;
  var data = {
    ok: tryChangePassword,
    cancel: function () {
      close();
    },
    login: (User.isLoggedIn() ? User.user.name : ''),
  };
  close = Layout.customDialog('changePassword-dialog',
                              changePasswordTemplate, data, false, false);

  function tryChangePassword(name, oldPassword, newPassword, newPassword2) {
    if (oldPassword === newPassword) {
      Layout.showMessage('New and old passwords must be different.',
                         Layout.SHORT_DELAY, false);
      return;
    }
    if (newPassword !== newPassword2) {
      Layout.showMessage('Passwords do not match.', Layout.SHORT_DELAY, false);
      return;
    }
    // If user is logged in but makes a mistake in the current
    // password we want to just show an error about it, but not log
    // him/her out. In order to achieve this we need to save current
    // auth data and restore it on failure. Note the use of basic
    // authorization with the old password instead of the stored auth
    // data (which is a token).
    var currentAuthData = User.getAuthData();
    Base.load(Base.req('POST',
                       Base.url('/users/', name, '/password'),
                       { body: newPassword },
                       'Basic ' + btoa(name + ':' + oldPassword)),
              'Setting password')
        .wait(onSuccess, onFailure);

    function onSuccess() {
      User.setAuth(name, newPassword, keep).then(function () {
        User._loadUserData(name, function () {}, function () {});
        close();
        Layout.showMessage('Password changed.', Layout.SHORT_DELAY, false);
      });
    }

    function onFailure(message) {
      if (currentAuthData) {
        User.setAuthData(currentAuthData, keep);
        Layout.showMessage(message, true, true);
      } else {
        User._clearAuth();
        Layout.showMessage(message, true, true);
      }
    }
  }
};

// Test whether the current user has a certain role. Used mostly in
// templates to determine which controls to show.
User.userPerm = function (type) {
  if (!User.user || !User.user.perms) {
    return false;
  }
  return Util.member('super', User.user.perms) ||
      Util.member(type, User.user.perms);
};

// Note that 'W' (capital W) means the user has access AND the
// store can be written to.
User.userAccess = function (type) {
  if (type === 'W') {
    if (App.currentSession && !App.currentSession.writable) {
      return false;
    }
    type = 'w';
  }
  return User.user && User.user.access && User.user.access.indexOf(type) > -1;
};

// Show a disappearing message about a query results limit if we know
// that the user has the 'query-results-limit' access flag.
//
// TODO: it would be nice to also show the actual limit (either read
//       it from config or through a dedicated API).
User.maybeShowLimitedResultsWarning = function (message) {
  if (User.userAccess('l')) {
    Layout.showMessage(
      'Query results are limited: ' + message + '.', Layout.LONG_DELAY, false);
  }
};

User.userList = function () {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }
  Base.load(MAsync.collect(Base.jsonReq('GET', '/users'),
      Base.jsonReq('GET', '/roles')),
      'Fetching users',
      function (users, roles) {
        listTemplate.cast(Layout.getPage(), { users: users, roles: roles });
      });
};

User.tokenList = function () {
  Base.load(Base.jsonReq('GET', '/token/list'), 'Fetching tokens',
            function (tokens) {
              tokenListTemplate.cast(Layout.getPage(), { tokens: tokens });
            });
};

User.newTokenDialog = function () {
  var close = Layout.customDialog(
    'newToken-dialog',
    newTokenTemplate, {
      ok: submit, cancel: function () {
        close();
      },
    }, false, false);

  function submit(expiresIn, idleTimeout) {
    Base.load(Base.req('GET', Base.url('/token', {
      expiresIn: expiresIn, idleTimeout: idleTimeout,
    })), 'Generating new token', function (token) {
      close();
      Layout.showMessage(
        'New token: "' + token +
          '". You will not be able to see it again, '+
          'so make sure to save it before closing this message.',
        true, false);
      User.tokenList();
    });
  }
};

User.deleteToken = function (token) {
  Base.load(Base.jsonReq('DELETE', Base.url('/token', { token: token })),
            'Deleting', function () {
              Layout.showMessage('Token "' + token + '" was deleted.', true, false);
              User.tokenList();
            });
};

User._expandNode = function (div, node) {
  if (!div) {
    div = document.createElement('DIV');
    div.className = 'indented';
    node.parentNode.parentNode.appendChild(div);
    Util.removeNode(node);
  }
  return div;
};

User.expandUser = function (name, allRoles, node) {
  var div;
  function local() {
    var as = MAsync.collect(
        Base.jsonReq('GET', Base.url('/users/', name, '/permissions')),
        Base.jsonReq('GET', Base.url('/users/', name, '/access')),
        Base.jsonReq('GET', Base.url('/users/', name, '/roles')),
        Base.jsonReq('GET', Base.url('/users/', name, '/security-filters/allow')),
        Base.jsonReq('GET',
            Base.url('/users/', name, '/security-filters/disallow')),
        Base.jsonReq('GET', '/catalogs'),
        Base.jsonReq('GET', Base.url('/users/', name, '/suspended')),
        Base.jsonReq('GET', Base.url('/users/', name, '/enabled')),
        Base.jsonReq('GET', Base.url('/users/', name, '/password/expired')));
    Base.load(as, 'Fetching permissions',
        function (permissions, access, roles, filtersAllow, filtersDisallow,
                  catalogs, suspended, enabled, passwordExpired) {
          div = User._expandNode(div, node);
          permissionsTemplate.cast(div, {
            type: 'user',
            perms: permissions,
            access: access,
            roles: roles,
            allRoles: allRoles,
            filtersAllow: filtersAllow,
            filtersDisallow: filtersDisallow,
            hasFilters: filtersAllow.length !== 0 || filtersDisallow.length !== 0,
            localRefresh: local,
            url: Base.url('/users/', name),
            catalogs: Util.map(function (c) {
              return c.id;
            }, catalogs),
            name: name,
            suspended: suspended,
            enabled: enabled,
            expired: passwordExpired,
          });
        });
  }
  local();
};

User.suspendUser = function (name, localRefresh) {
  Base.load(Base.req('PUT', Base.url('/users/', name, '/suspended'), {}),
      'Suspending User', localRefresh);
};

User.unsuspendUser = function (name, localRefresh) {
  Base.load(Base.req('DELETE', Base.url('/users/', name, '/suspended'), {}),
      'Suspending User', localRefresh);
};

User.disableUser = function (name, localRefresh) {
  Base.load(Base.req('DELETE', Base.url('/users/', name, '/enabled'), {}),
      'Disabling User', localRefresh);
};

User.enableUser = function (name, localRefresh) {
  Base.load(Base.req('PUT', Base.url('/users/', name, '/enabled'), {}),
      'Enabling User', localRefresh);
};

User.expirePassword = function (name, localRefresh) {
  Base.load(Base.req('PUT', Base.url('/users/', name,
      '/password/expired'), {}), 'Expiring Password', localRefresh);
};

User.updatePermissionRepoList = function (catalog, select) {
  select.firstChild.selected = true;
  while (select.options.length > 1) {
    Util.removeNode(select.options[1]);
  }
  if (catalog === '*') {
    return;
  }

  select.disabled = true;
  var myurl;
  if (catalog === '/') {
    myurl = '';
  } else {
    myurl = Base.url('/catalogs/', catalog);
  }
  myurl += '/repositories';
  Base.load(Base.jsonReq('GET', myurl), 'Fetching repositories',
      function (repos) {
        Util.forEach(repos, function (repo) {
          var opt = select.appendChild(document.createElement('OPTION'));
          opt.appendChild(document.createTextNode(repo.id));
        });
        select.disabled = false;
      });
};

User.expandRole = function (name, node) {
  var div;
  function local() {
    var as = MAsync.collect(
        Base.jsonReq('GET', Base.url('/roles/', name, '/permissions')),
        Base.jsonReq('GET', Base.url('/roles/', name, '/access')),
        Base.jsonReq('GET', Base.url('/roles/', name, '/security-filters/allow')),
        Base.jsonReq('GET',
            Base.url('/roles/', name, '/security-filters/disallow')),
        Base.jsonReq('GET', '/catalogs'));
    Base.load(as, 'Fetching permissions',
        function (permissions, access, filtersAllow, filtersDisallow, catalogs) {
          div = User._expandNode(div, node);
          permissionsTemplate.cast(div, {
            type: 'role',
            perms: permissions,
            access: access,
            filtersAllow: filtersAllow,
            filtersDisallow: filtersDisallow,
            hasFilters: filtersAllow.length !== 0 || filtersDisallow.length !== 0,
            localRefresh: local,
            url: Base.url('/roles/', name),
            catalogs: Util.map(function (c) {
              return c.id;
            }, catalogs),
          });
        });
  }
  local();
};

User.togglePermission = function (url1, type, node) {
  function doIt() {
    url1 = Base.url(url1 + '/permissions/' + type);
    if (node.checked) {
      Base.load(
          Base.req('PUT', url1).protect(function () {
            node.checked = false;
          }),
          'Adding permission', Util.ident.bind(Util));
    } else {
      Base.load(
          Base.req('DELETE', url1).protect(function () {
            node.checked = true;
          }),
          'Removing permission', Util.ident.bind(Util));
    }
  }
  function unDo() {
    node.checked = !node.checked;
  }

  if (!node.checked && type === 'super' &&
      url1 === Base.url('/users/', User.user.name)) {
    Layout.showDialog('revokeSuper-confirm',
        'Do you really want to remove your superuser flag? ' +
        '(There is no way back.)', doIt, unDo, false, true);
  } else {
    doIt();
  }
};

User.removeAccess = function (url, perm, node) {
  var args = {
    catalog: perm.catalog,
    repository: perm.repository,
  };
  if (perm.read) {
    args.read = true;
  }
  if (perm.write) {
    args.write = true;
  }
  if (perm['query-results-limit']) {
    args['query-results-limit'] = true;
  }
  Base.load(
      Base.req('DELETE', url + '/access' + Base.encodeArgs(args)),
      'Removing permission', _.partial(Util.removeNode.bind(Util), node));
};

User.addAccess = function (url, type, catalog, repository, localRefresh) {
  var args = {
    catalog: catalog,
    repository: repository,
  };
  if (/read/.test(type)) {
    args.read = true;
  }
  if (/write/.test(type)) {
    args.write = true;
  }
  if (/query results limit/.test(type)) {
    args['query-results-limit'] = true;
  }

  Base.load(Base.req('PUT', url + '/access' + Base.encodeArgs(args)),
      'Storing permission', localRefresh);
};

User.removeSecurityFilter =
    function (userspec, type, filterspec, localRefresh) {
      Base.load(Base.req('DELETE', userspec.url + '/security-filters/' +
          type + Base.encodeArgs(filterspec)),
          'Deleting security filter', localRefresh);
    };

User.addSecurityFilter = function (url, type, s, p, o, g, localRefresh) {
  var filterspec = { s: s, p: p, o: o, g: g };
  Base.load(Base.req('POST', url + '/security-filters/' +
      type + Base.encodeArgs(filterspec)), 'Adding security filter',
      localRefresh);
};

User.enableSecurityFilters = function (placeholderId, id) {
  var table = document.getElementById(id);
  var placeholder = document.getElementById(placeholderId);
  table.style.display = 'block';
  placeholder.style.display = 'none';
};

User.removeUserRole = function (uri, role, node) {
  Base.load(
      Base.req('DELETE', Base.url(uri + '/roles/', role)), 'Removing',
      _.partial(Util.removeNode.bind(Util), node));
};

User.addUserRole = function (uri, role, localRefresh) {
  Base.load(Base.req('PUT', Base.url(uri + '/roles/', role)),
      'Adding', localRefresh);
};

User.deleteUser = function (name) {
  if (name === User.user.name) {
    User.deleteMyUser();
    return;
  }
  Layout.showDialog('delUser-confirm', 'Really delete ' + name + '?',
                    del, null, false, false);
  function del() {
    Base.load(Base.req('DELETE', Base.url('/users/', name)),
        'Deleting', App.refresh.bind(App));
  }
};

User.deleteRole = function (name) {
  Layout.showDialog('delRole-confirm',
      'Really delete role ' + name + '?', del, null, false, false);
  function del() {
    Base.load(Base.req('DELETE', Base.url('/roles/', name)),
        'Deleting', App.refresh.bind(App));
  }
};

User.newRoleDialog = function () {
  Layout.showPrompt('add-role', 'Name for the new role', ok, null, null, false);
  function ok(name) {
    if (!name) {
      return;
    }
    Base.load(Base.req('PUT', Base.url('/roles/', name)),
        'Creating role', App.refresh.bind(App));
  }
};

User.deleteMyUser = function () {
  Layout.showDialog('delSelfUser-confirm', 'Really delete your account?',
                    del, null, false, false);

  function del() {
    Base.load(
        Base.req('DELETE', Base.url('/users/', User.user.name)),
        'Deleting user', User.logout.bind(User));
  }
};

// Dialog for user creation.
User.newUserDialog = function (asAnon) {
  if ($$('user-name')) {
    return;
  }
  var close = Layout.customDialog('newUser-dialog',
      newTemplate, {
        ok: submit,
        cancel: function () {
          close();
        },
      }, false, false);

  function submit(name, password1, password2) {
    var anon = name === 'anonymous';
    if ((!anon && !password1) || !name) {
      Layout.showMessage('You\'ll have to give a password and a name.',
                         Layout.SHORT_DELAY, true);
    } else if (!anon && password1 !== password2) {
      Layout.showMessage('Passwords do not match.', Layout.SHORT_DELAY, true);
    } else {
      Base.load(
          Base.req('PUT', Base.url(
              '/users/', name, { password: password1 || '' })),
          'Creating user').then(success, failure);
    }

    function success() {
      close();
      if (asAnon) {
        User.setAuth(name, password1, false).then(function () {
          User._loadUserData(name, function () {
            Layout.showMessage('Account created.', Layout.SHORT_DELAY, false);
          });
        });
      } else {
        App.refresh();
      }
    }
    function failure(message) {
      Layout.showMessage('Could not create account: ' + message, true, true);
    }
  }
};

User.changeUserPassword = function (name) {
  Layout.showPrompt('change-password', 'New password for ' + name, function (pass) {
    Base.load(
        Base.req('POST', Base.url('/users/', name, '/password'), { body: pass }),
        'Setting password', function () {
          if (name === User.user.name) {
            User.setAuth(name, pass, false);
          }
        });
  });
};

// Namespaces


/**
 *  These are the default default namespaces -- what you get when no
 * default namespaces have been set for a repository yet.
 */
User.DEFAULT_NAMESPACES = Util.getSortedNamespaces([
  { prefix: 'rdf', namespace: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#' },
  { prefix: 'rdfs', namespace: 'http://www.w3.org/2000/01/rdf-schema#' },
  { prefix: 'owl', namespace: 'http://www.w3.org/2002/07/owl#' },
  { prefix: 'dc', namespace: 'http://purl.org/dc/elements/1.1/' },
  { prefix: 'dcterms', namespace: 'http://purl.org/dc/terms/' },
  { prefix: 'foaf', namespace: 'http://xmlns.com/foaf/0.1/' },
  { prefix: 'fti', namespace: 'http://franz.com/ns/allegrograph/2.2/textindex/' },
  { prefix: 'skos', namespace: 'http://www.w3.org/2004/02/skos/core#' },
]);

/**
 * List of namespaces defined for the currently selected repository.
 * Fetched from <%repo%/data/wv.namespaces>.
 * Defaults to User.DEFAULT_NAMESPACES if the server returns that the value
 * under the 'vw.namespaces' key does not exist.
 * Can be null if we're outside of a repository or session.
 * @type {NamespaceEntry[]|null}
 */
User.defaultNamespaces = null;

// Display function for user options (#namespaces, #query-options)
User.userOptions = function (name, template, options,
                             addFunction, deleteFunction) {
  var userOptions = [];
  var repoOptions = [];
  var defaultOptions = [];
  Util.forEach(options, function (o) {
    if (o.type === 'user') {
      userOptions.push(o);
    } else if (o.type === 'repository') {
      repoOptions.push(o);
    } else {
      defaultOptions.push(o);
    }
  });
  template.cast(
    Layout.getPage(),
    new User.OptionPageController(
      name, userOptions, repoOptions, defaultOptions,
      addFunction, deleteFunction));
};

// Display function for #namespaces
User.userNamespaces = function () {
  User.userOptions(
    'namespace',
    namespacesTemplate,
    Part.NameSpaces.current(),
    function (type) {
      return User.addUserNamespace(type);
    },
    function (ns, type) {
      return User.deleteUserNamespace(ns.prefix, type);
    },
  );
};

// Display function #query-options
User.userQueryOptions = function () {
  User.userOptions(
    'query option',
    queryOptionsTemplate,
    User.user.queryOptions,
    function (type) {
      return User.addUserQueryOption(type);
    },
    function (qo, type) {
      return User.deleteUserQueryOption(qo.name, type);
    },
  );
};


/**
 * A controller for the user option templates (`user/namespaces` and
 * `user/query-options`).
 *
 * @param {string} name - name of the option kind ('namespace' or 'query option').
 * @param {(NamespaceEntry|QueryOptionEntry)[]} userOptions - user-level options.
 * @param {(NamespaceEntry|QueryOptionEntry)[]} repoOptions - repository-level options.
 * @param {(NamespaceEntry|QueryOptionEntry)[]} defaultOptions - default (server-level) options.
 * @param {Function} addFunction - the function to use to add the option definition.
 * @param {Function} deleteFunction - the function to use to delete the option definition.
 * @constructor
 */
User.OptionPageController = function (name, userOptions, repoOptions, defaultOptions,
                                      addFunction, deleteFunction) {
  this.userOptionCtrl = new Store.OptionSectionController(userOptions, {
    headerLabel: 'User ' + name + 's',
    canAdd: true,
    addLabel: 'define a user ' + name,
    onAdd: function () {
      addFunction('user').then(App.refresh.bind(App));
    },
    canSelect: false,
    canDelete: true,
    onDelete: function (o) {
      deleteFunction(o, 'user').then(App.refresh.bind(App));
    },
  });
  this.repoOptionCtrl = new Store.OptionSectionController(repoOptions, {
    headerLabel: 'Repository ' + name + 's',
    canAdd: User.userAccess('w') && App.isInRealRepo(),
    addLabel: 'define a repository ' + name,
    onAdd: function () {
      addFunction('repository').then(App.refresh.bind(App));
    },
    canSelect: false,
    canDelete: User.userAccess('w') && App.isInRealRepo(),
    onDelete: function (o) {
      deleteFunction(o, 'repository').then(App.refresh.bind(App));
    },
  });
  this.defaultOptionCtrl = new Store.OptionSectionController(defaultOptions, {
    headerLabel: 'Default ' + name + 's',
    canAdd: User.userPerm('super') && App.isInRealRepo(),
    addLabel: 'define a default ' + name,
    onAdd: function () {
      addFunction('default').then(App.refresh.bind(App));
    },
    canSelect: false,
    canDelete: User.userPerm('super') && App.isInRealRepo(),
    onDelete: function (o) {
      deleteFunction(o, 'default').then(App.refresh.bind(App));
    },
  });
};

User._deleteUserNamespaceReq = function (prefix, type) {
  return Base.load(
    Base.req('DELETE', Base.url('namespaces/', prefix, { type: type })),
    'Deleting namespace').add(function () {
      prefix = Part.normalizeNamespacePrefix(prefix);
      User.user.namespaces = Part.removeNamespace(Part.NameSpaces.current(), prefix, type);
      for (let i = 0; i < User.user.namespaces.length; i++) {
        if (User.user.namespaces[i].prefix === prefix && User.user.namespaces[i].type < type) {
          User.user.namespaces[i].shadowed = false;
          break;
        }
      }
    });
};

User.deleteUserNamespace = function (prefix, type) {
  var async = new Async();
  Layout.showDialog('deleteUserNS-confirm', 'Delete ' + type + ' namespace "' +
                    prefix + '"?', doDelete);

  function doDelete() {
    Base.load(User._deleteUserNamespaceReq(prefix, type), 'Deleting', function () {
      async.ok();
    });
  }
  return async;
};

User.addUserNamespaceReq = function (ns) {
  return Base.load(
    Base.req('PUT', Base.url('namespaces/', ns.prefix, { type: ns.type }), { body: ns.namespace }),
    'Adding namespace').add(function () {
      ns.prefix = Part.normalizeNamespacePrefix(ns.prefix);
      ns.namespace = Part.normalizeNamespaceURI(ns.namespace);
      User.user.namespaces = Part.removeNamespace(Part.NameSpaces.current(), ns.prefix, ns.type);
      User.user.namespaces.forEach(function (n) {
        if (n.prefix === ns.prefix) {
          if (n.type < ns.type) {
            n.shadowed = true;
          } else if (n.type > ns.type) {
            ns.shadowed = true;
          }
        }
      });
      User.user.namespaces.push(ns);
      User.user.namespaces = Util.getSortedNamespaces(User.user.namespaces);
    });
};

/**
 * Shows a dialog that allows the user to add one or more user namespaces.
 * When the user clicks OK, it sends them to the server, updates
 * `User.user.namespaces` if server-save was successful (separately for
 * each namespace), and then resolves the resulting Async.
 *
 * @param {string} type - type of namespace definition.
 * @return {Async} - resolves to success if all namespaces were successfully
 *   saved on the server. If saving fails, it will NOT be resolved at all
 *   (instead, an error message will be shown to the user by
 *   loadWithDefaultErrorHandler).
 */
User.addUserNamespace = function (type) {
  return Store.showNamespaceDialog(type).then(function (namespaces) {
    return Base.loadWithDefaultErrorHandler(
        MAsync.collect.apply(
          null, Util.map(User.addUserNamespaceReq.bind(User), namespaces)),
        'Saving namespaces');
  });
};


// Query options

User.addUserQueryOptionReq = function (qo) {
  return Base.load(
    Base.req('PUT', Base.url('query-options/', qo.name, { type: qo.type }), { body: qo.value }),
    'Adding query option').add(function () {
      qo.name = Part.normalizeQueryOptionName(qo.name);
      qo.value = Part.normalizeQueryOptionValue(qo.value);
      User.user.queryOptions = Util.filter(
        function (option) {
          return option.name !== qo.name || option.type !== qo.type;
        },
        User.user.queryOptions
      );
      User.user.queryOptions.forEach(function (o) {
        if (o.name === qo.name) {
          if (o.type < qo.type) {
            o.shadowed = true;
          } else if (o.type > qo.type) {
            qo.shadowed = true;
          }
        }
      });
      User.user.queryOptions.push(qo);
      User.user.queryOptions = Util.getSortedQueryOptions(User.user.queryOptions);
    });
};

User.addUserQueryOption = function (type) {
  return Store.showQueryOptionDialog(type).then(function (queryOptions) {
    return Base.loadWithDefaultErrorHandler(
      MAsync.collect.apply(
        null, Util.map(User.addUserQueryOptionReq.bind(User), queryOptions)),
      'Saving query options');
  });
};

User.deleteUserQueryOptionReq = function (name, type) {
  return Base.load(
    Base.req('DELETE', Base.url('query-options/', name)),
    'Deleting query option').add(function () {
      name = Part.normalizeQueryOptionName(name);
      User.user.queryOptions = Util.filter(
        function (qo) {
          return name !== qo.name ||type !== qo.type;
        },
        User.user.queryOptions
      );
      for (let i = 0; i < User.user.queryOptions.length; i++) {
        if (User.user.queryOptions[i].name === name && User.user.queryOptions[i].type < type) {
          User.user.queryOptions[i].shadowed = false;
          break;
        }
      }
    });
};

User.deleteUserQueryOption = function (name, type) {
  var async = new Async();
  Layout.showDialog(
    'deleteUserQO-confirm', 'Delete ' + type + ' query option "' + name + '"?',
    doDelete);

  function doDelete() {
    Base.load(
      User.deleteUserQueryOptionReq(name, type), 'Deleting', function () {
        async.ok();
      });
  }
  return async;
};


// Misc

/**
 * Shows the 'access denied' page, with a login dialog
 * if the user is not logged in.
 */
User.denied = function () {
  deniedTemplate.cast(Layout.getPage(), User.user);
  if (App.initialised && !User.isLoggedIn()) {
    User.loginDialog();
  }
};

export default User;
