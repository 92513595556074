import Dispatch from '../dispatch';
import App from '../app';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<p id=\"loading\"><span id=\"loading-text\"></span> ");
Mold.dispatchCustom("throb", undefined, __out);
__out.push("</p>\n\n<div class=\"head\" id=\"header-info\">\n</div>\n\n<div class=\"nav\" id=\"header-links\">&nbsp;</div>\n\n<div id=\"messages\"></div>\n<div id=\"page\"></div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;