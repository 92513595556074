import Dispatch from '../dispatch';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg) {
__out.push("\n  <h2>Permission denied</h2>\n  <p>Unable to display this page because your user lacks the necessary permissions.</p>\n  <p class=\"subtle\">&nbsp; <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.rootUrl() ));
__out.push("\">« back to front page</a></p>\n");
} else {
__out.push("\n  <h2>User required</h2>\n  <p>Please log in to access this page.</p>\n");
}
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;