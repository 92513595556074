
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\nBulk-load mode is ");
__out.push(Mold.escapeHTML($arg.current ? "on (loads are fast, but have no durability guarantees)" : "off (Durability guarantees are in place)"));
__out.push("—<span class=\"linkish\">turn ");
__out.push(Mold.escapeHTML($arg.current ? "off" : "on"));
__out.push("</span>");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;