import { $$ } from '../../util';
import Layout from '../../layout';
import User from '../../user';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function submit() {
      $arg.ok(
        $$("query-option-name").value,
        $$("query-option-value").value,
        $$("query-option-type").value);
    }

__out.push("\n<table width=\"100%\">\n  <tr>\n    <td>\n      <label for=\"namespace-abbrev\">Name:</label>\n      <input type=\"text\" id=\"query-option-name\" class=\"option-input\"/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", " container=query-options-name-help\n             The <code>optionName</code> part of the query option prefix declaration:\n             <br><pre><code>    PREFIX franzOption_<b>optionName</b>: &lt;franz:optionValue&gt;</code></pre>\n             See\n             <a href=\"https://franz.com/agraph/support/documentation/current/sparql-reference.html#sparql-queryoptions\">\n               SPARQL Query Options\n             </a> documentation section for more details.", __out);
__out.push("\n      <br/>\n      <label for=\"query-option-value\">Value:</label>\n      <input type=\"text\" id=\"query-option-value\" class=\"option-input\"/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", " container=query-options-value-help\n             The <code>optionValue</code> part of the query option prefix declaration:\n             <br><pre><code>    PREFIX franzOption_optionName: &lt;franz:<b>optionValue</b>&gt;</code></pre>\n             See\n             <a href=\"https://franz.com/agraph/support/documentation/current/sparql-reference.html#sparql-queryoptions\">\n               SPARQL Query Options\n             </a> documentation section for more details.", __out);
__out.push("\n      <br>\n      <label for=\"query-option-type\">Type:</label>\n      <select id=\"query-option-type\" class=\"option-input\">\n        <option value=\"user\" ");
if ($arg.type === 'user') {
__out.push("selected");
}
__out.push(">user</option>\n        ");
if (User.userAccess('w')) {
__out.push("\n        <option value=\"repository\" ");
if ($arg.type === 'repository') {
__out.push("selected");
}
__out.push(">repository</option>\n        ");
}
__out.push("\n        ");
if (User.userPerm('super')) {
__out.push("\n        <option value=\"default\" ");
if ($arg.type === 'default') {
__out.push("selected");
}
__out.push(">default</option>\n        ");
}
__out.push("\n      </select>\n      ");
Mold.dispatchCustom("help", " container=query-options-type-help\n              User query options only apply to the current user of the given repository\n              and can be modified by the user at will. Repository query options apply to\n              all users of a given repository and can only be modified by a user that\n              has write access to the repository. Default query options apply to all\n              repositories on this server and can only be modified by an administrator.", __out);
__out.push("\n    </td>\n    <td class=\"buttons\">\n      <button>OK</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      <button>Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n  <tr><td colspan=\"2\"><div id=\"query-options-name-help\"></div></td></tr>\n  <tr><td colspan=\"2\"><div id=\"query-options-value-help\"></div></td></tr>\n  <tr><td colspan=\"2\"><div id=\"query-options-type-help\"></div></td></tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;