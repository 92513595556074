import App from './app';
import Server from './server';
import Dispatch from './dispatch';
import User from './user';
import Base from './base';
import Part from './part';
import Layout from './layout';
import { MAsync } from './async';
import Util from './util';
import { Async } from './async';
import { $$ } from './util';
import _ from 'underscore';
import nodeviewTemplate from './templates/nodeview.mold';
import loadingmoreTemplate from './templates/instruction/loadingmore.mold';
import moreTemplate from './templates/instruction/more.mold';
import reloadTriplerowsTemplate from './templates/instruction/reload_triplerows.mold';
import searchTemplate from './templates/query/search.mold';
import searchresultTemplate from './templates/query/searchresult.mold';


/**
 * Functionality for displaying triple-based results -- viewing a node
 * or doing a text search.
 * @namespace Triples
 */
var Triples = {};

Triples._loadStatements = function (type, uri, offset, limit) {
  var args = {
    infer: App.useReasoning,
    offset: offset,
    limit: limit,
  };
  args[type] = uri;
  return Server.getStatements(args);
};

Triples.showNode = function (partName) {
  if (!User.userAccess('r')) {
    User.denied();
    return;
  }
  var part;
  try {
    part = Part.part(partName);
  } catch (e) {
    Layout.showMessage(
      'Invalid node. Could not parse "' + partName + '" as a resource.',
      true, true);
    return;
  }

  var fetch = 100;
  function statements(type) {
    return Triples._loadStatements(type, part.string, 0, fetch);
  }
  Base.load(
    MAsync.collect(statements('subj'), statements('pred'), statements('obj')),
    'Loading', loaded);

  function mark(arr) {
    if (arr.length === fetch) {
      arr.more = true;
    }
    return arr;
  }

  function loaded(subj, pred, obj) {
    nodeviewTemplate.cast(Layout.getPage(), {
      subj: mark(subj),
      pred: mark(pred),
      obj: mark(obj),
      node: part,
    });
  }
};

// Insert more triples into an existing page. (Triples are loaded in
// chunks to prevent building up ridiculously large pages.)
Triples._loadMoreTriples = function (fetch, transform) {
  return function (cell) {
    loadingmoreTemplate.cast(cell);
    function recover() {
      moreTemplate.cast(cell, Triples._loadMoreTriples(fetch, transform));
    }
    Base.load(
      fetch().protect(recover),
      'Getting more triples',
      function (data) {
        var tbody = cell.parentNode.parentNode;
        data = transform(data);
        Base.addingTBodyBefore(tbody, function (div) {
          reloadTriplerowsTemplate.cast(div, data);
        });
        if (data.more) {
          moreTemplate.cast(cell, data.more);
        } else {
          Util.removeNode(tbody);
        }
      });
  };
};

Triples.loadMoreNeighbours = function (node, type, offset) {
  var chunk = 500;
  function fetch() {
    return Triples._loadStatements(type, node.string, offset, chunk);
  }
  function process(data) {
    return {
      triples: data,
      ignore: type,
      more:
        (data.length === chunk) &&
        Triples.loadMoreNeighbours(node, type, offset + chunk),
    };
  }
  return Triples._loadMoreTriples(fetch, process);
};

/**
 * Handler for #search/<index>/<query> URL (full text search).
 *
 * @param {string} index - index name or '*'
 * @param {string} query - full text query
 */
Triples.showSearch = function (index, query) {
  if (!User.userAccess('r')) {
    User.denied();
    return;
  }
  Base.load(
    App.isInRealRepo()
      ? Base.jsonReq('GET', 'freetext/indices')
      : Async.dummy([]),
    'Loading indices',
    function (indices) {
      searchTemplate.cast(Layout.getPage(), {
        query: query || '',
        indices: indices,
        index: index,
      });
      if (query) {
        search(query, index);
      }
    });
};

Triples.runSearch = function (query, index, updateURI) {
  if (/^\s*$/.test(index)) {
    index = '*';
  }
  if (updateURI) {
    var indexURI = encodeURIComponent(index);
    var queryURI = encodeURIComponent(query);
    // We must encode both components because they might have slashes in them:
    App.goTo(Dispatch.relativeUrl('search/' + indexURI + '/' + queryURI));
  } else {
    search(query, index);
  }
};

function search(query, index) {
  var fetch = 500;
  var args = { pattern: query, infer: App.useReasoning, limit: fetch };
  if (index !== '*') {
    args.index = index;
  }
  var searchResults = $$('search-results');
  Base.load(
    Server.freetextSearch(args), 'Searching',
    function (data) {
      searchresultTemplate.cast(searchResults, {
        query: query,
        triples: data,
        more:
          (data.length === fetch) &&
          Triples._loadMoreSearchResults(query, index, fetch),
      });
    });
}

Triples._loadMoreSearchResults = function (query, index, offset) {
  var chunk = 500;
  function fetch() {
    var args = {
      pattern: query,
      infer: App.useReasoning,
      offset: offset,
      limit: chunk,
    };
    if (index !== '*') {
      args.index = index;
    }
    return Server.freetextSearch(args);
  }
  function process(data) {
    return {
      triples: data,
      more:
        (data.length === chunk) &&
        Triples._loadMoreSearchResults(query, index, offset + chunk),
    };
  }
  return Triples._loadMoreTriples(fetch, process);
};

/**
 * Returns true iff the triples in given data contain enough information
 * (i.e. tripleIds) to allow deleting them.
 *
 * @param {Object[]} triples - array of triples
 * @return {boolean}
 */
Triples.isDeleteAllowed = function (triples) {
  if (triples.length === 0) {
    // No values, makes no sense to allow deletion.
    // OTOH, we might just as well. Doesn't matter.
    return false;
  }
  // Only check the first value. If it has a tripleId, we'll assume all
  // the others do, too.
  return _.isNumber(triples[0].tripleId);
};

export default Triples;
