import { Async } from './async';

/**
 * Service used by tests to wait until all pending requests
 * and timeouts have been processed.
 *
 * The idea is that all operations that should block testing
 * should call startOperation when started and endOperation when
 * done (after processing all callbacks etc).
 *
 * The testing code will call whenStable, which returns a promise
 * that will be resolved when there are no pending operations.
 *
 * This module is used by Base.httpRequest, so all HTTP requests
 * made by WebView will block tests until resolved. The same is
 * true regarding tasks created with our implementation
 * of setImmediate.
 *
 * Calls to setTimeout are not counted as operations by this
 * module, since tests might need to be able to run actions
 * before a timeout.
 *
 * Async callbacks are run synchronously (*not* in microtasks),
 * so there is currently no need to integrate promises with
 * the testability system.
 *
 * @namespace Testability
 */
var Testability = {};

var callbackScheduled = false;
var promise = null;
var pending = 0;

function callback() {
  callbackScheduled = false;
  // We must check `pending` again, because code executed after the
  // callback has been scheduled could have changed it.
  if (promise !== null && pending === 0) {
    // Our promise callbacks are synchronous, so we must make
    // sure that calls to whenStable inside the callback code
    // triggered by ok() create a fresh promise.
    var oldPromise = promise;
    promise = null;
    oldPromise.ok();
  }
}

function maybeScheduleCallback() {
  if (pending === 0 && !callbackScheduled) {
    callbackScheduled = true;
    // Callback must happen in a separate task, since any JS code
    // executed after this line in the current task could still
    // change the value of `pending`.
    setTimeout(callback, 0);
  }
}

Testability.whenStable = function () {
  if (promise === null) {
    promise = new Async();
  }
  maybeScheduleCallback();
  return promise;
};

Testability.startOperation = function () {
  pending++;
};

Testability.endOperation = function () {
  pending--;
  maybeScheduleCallback();
};

export default Testability;
