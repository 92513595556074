import Triples from '../../triples';
import App from '../../app';
import User from '../../user';
import Layout from '../../layout';
import Store from '../../store';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var allowDelete = Triples.isDeleteAllowed($arg.triples); 
__out.push("\n");
Mold.forDepth++;
Mold.forEach($arg.triples, function(triple, $i) {
__out.push("\n  <tr>\n    ");
Mold.forDepth++;
Mold.forEach(["subj", "pred", "obj"], function(p, $i) {
__out.push("\n      ");
if (p !== $arg.ignore) {
__out.push("\n        <td>");
if (triple[p]) {
Mold.dispatchCustom("part", triple[p], __out);
} else {
__out.push("&nbsp;");
}
__out.push("</td>\n      ");
}
__out.push("\n    ");
});
Mold.forDepth--;
__out.push("\n    ");
if (App.showContexts) {
__out.push("\n      <td>\n        ");
if (triple.graph) {
__out.push("\n          ");
Mold.dispatchCustom("part", triple.graph, __out);
__out.push("\n        ");
} else {
__out.push("\n          <span class=\"subtle\">(null)</span>\n        ");
}
__out.push("\n      </td>\n    ");
}
__out.push("\n    ");
if (allowDelete && User.userAccess("W")) {
__out.push("\n      <td>\n        <button class=\"small-delete-button\"><i class='fa fa-times-circle' title=\"delete this statement\"></i></button>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Layout.showDialog(
          'delete-dialog',
          'Delete this statement?',
          Store.deleteStatement.bind(Store, triple));
});}) + "\"></var>");
__out.push("\n      </td>\n    ");
}
__out.push("\n  </tr>\n");
});
Mold.forDepth--;
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;