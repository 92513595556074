import $ from 'jquery';
import Clipboard from 'clipboard';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div class=\"copyable\">\n  <span>");
__out.push(Mold.escapeHTML($arg ));
__out.push("</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "dblclick", function($event, $node) {
// since it it copy-able, user should also be able to select it by double-clicking,
    // so let's just silence the event for any parent's listeners.
    $event.stopPropagation();
  
});}) + "\"></var>");
__out.push("\n  ");
if ((Clipboard.isSupported()) ) {
__out.push("\n    <i class=\"copy-trigger far fa-copy\" title=\"Copy to clipboard\" data-clipboard-text=\"");
__out.push(Mold.escapeHTML($arg ));
__out.push("\"></i>\n  ");
}
__out.push("\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;