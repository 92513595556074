// Other codes will be added as needed.

export default {
  HTTP_OK: 200,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_UNAUTHORIZED: 401,
  HTTP_FORBIDDEN: 403,
  HTTP_NOT_FOUND: 404,

  // Code ranges
  HTTP_ERROR_MIN: 400,
};
