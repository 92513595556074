import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"pure-form pure-form-aligned\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok($node.elements.durability.value,
              $node.elements.dtt.value,
              $node.elements.tlc.value,
              $node.elements.tlt.value);
  
});}) + "\"></var>");
__out.push("\n  <fieldset>\n    <div class=\"pure-control-group\">\n      <label for=\"durability\">Durability:</label>\n      <input name=\"durability\" type=\"text\" size=\"10\" max=\"10\"\n      value=\"");
__out.push(Mold.escapeHTML($arg.now.durability ));
__out.push("\">\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"dtt\">Distributed Transaction Timeout:</label>\n      <input name=\"dtt\" type=\"text\" size=\"10\" max=\"10\"\n      value=\"");
__out.push(Mold.escapeHTML($arg.now.distributedTransactionTimeout ));
__out.push("\">\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"tlc\">Transaction Latency Count:</label>\n      <input name=\"tlc\" type=\"text\" size=\"10\" max=\"10\"\n      value=\"");
__out.push(Mold.escapeHTML($arg.now.transactionLatencyCount ));
__out.push("\">\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"tlt\">Transaction Latency Timeout:</label>\n      <input name=\"tlt\" type=\"text\" size=\"10\" max=\"10\"\n      value=\"");
__out.push(Mold.escapeHTML($arg.now.transactionLatencyTimeout ));
__out.push("\">\n    </div>\n    <div class=\"pure-controls\">\n      <button type=\"button\" class=\"pure-button action-button\">Cancel</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.closeDialog();
});}) + "\"></var>");
__out.push("\n      <button type=\"submit\" class=\"pure-button pure-button-primary\">OK</button>\n    </div>\n  </fieldset>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;