import { $$ } from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function submit() {
      $arg.ok(
        $$("user-name").value,
        $$("user-password1").value,
        $$("user-password2").value);
    }

__out.push("\n<table width=\"100%\">\n  <tr>\n    <td>\n      <p>Provide a name and password for the new user.</p>\n      <p>\n        <label for=\"user-name\" class=\"dialog-input-label\">Username:</label>\n        <input type=\"text\" id=\"user-name\" class=\"dialog-input-textbox\"/>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();}) + "\"></var>");
__out.push("\n        <br/>\n        <label for=\"user-password1\" class=\"dialog-input-label\">Password:</label>\n        <input type=\"password\" id=\"user-password1\" class=\"dialog-input-textbox\"/>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n        <br/>\n        <label for=\"user-password2\" class=\"dialog-input-label\">Repeat:</label>\n        <input type=\"password\" id=\"user-password2\" class=\"dialog-input-textbox\"/>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      </p>\n    </td>\n    <td class=\"buttons\">\n      <button>OK</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      <button>Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;