import Util from '../../util';
import App  from '../../app';
import Base from '../../base';
import Store from '../../store';
import { $$ } from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n\n<form class=\"pure-form pure-form-aligned\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "input", function($event, $node) {
Store.maybeEnableConvertStoreButton(); 
});}) + "\"></var>");
__out.push("\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok(Util.selectValue($node.elements.scheme),
              $node.elements.host.value,
              $node.elements.port.value,
              $arg.catalog,
              $arg.reponame,
	      $node.elements.group.value,
              $node.elements.user.value,
              $node.elements.password.value,
              $node.elements.instanceName.value);
  
});}) + "\"></var>");
__out.push("\n  <div>\n    <p>\n    This operation converts a normal AllegroGraph repository into the first\n    instance of a replication cluster.\n    No data is lost.\n    After creating the first instance you can run replicas of this instance\n    on this or other machines.\n    </p><p>\n    In this dialog you must specify\n    the location of this repository instance in a way that's reachable from all\n    other machines on which you'll run replicas of this instance.\n    Don't use <em>localhost</em> as the host name unless you plan to\n    run all cluster instances on this one machine.\n    </p><p>\n    The instance will start a network listener on a port within the range specified by ReplicationPorts in agraph.cfg. If none were specified then the operating system will choose a random port. This port must be reachable from all other instances on all other machines that run instances of this cluster in the future.\n    </p>\n    <em>Once a repository becomes a replication instance it can not\n    be converted back to a normal repository.</em></div>\n  <fieldset>\n    <div class=\"pure-control-group\">\n      <label for=\"scheme\">Scheme:</label>\n      <select name=\"scheme\" id=\"scheme\" >\n       ");
if ($arg.hostPortInfo.http > 0 ) {
__out.push("\n        <option value=\"http\" ");
__out.push(Mold.escapeHTML((Base.rootLocation.protocol === "http:") ? "selected" : "" ));
__out.push(">http</option>\n       ");
}
__out.push("\n       ");
if ($arg.hostPortInfo.https > 0 ) {
__out.push("\n        <option value=\"https\" ");
__out.push(Mold.escapeHTML((Base.rootLocation.protocol === "https:") ? "selected" : "" ));
__out.push(">https</option>\n       ");
}
__out.push("\n      </select>\n       ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
Store.setPortToMatchScheme($arg.hostPortInfo); 
});}) + "\"></var>");
__out.push("\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"host\">Host:</label>\n      <input name=\"host\" id=\"host\"  type=\"text\" size=\"30\" max=\"60\"\n             value=\"");
__out.push(Mold.escapeHTML($arg.hostPortInfo.hostname ));
__out.push("\"\n      />\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"port\">Port:</label>\n      <input name=\"port\" id=\"port\" type=\"number\" size=\"8\"\n             min=\"1\" max=\"65535\"\n             value=\"");
__out.push(Mold.escapeHTML(Base.rootLocation.port ));
__out.push("\"/>\n    </div>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Store.setPortToMatchScheme($arg.hostPortInfo); }) + "\"></var>");
__out.push("\n    <div class=\"pure-control-group\">\n      <label for=\"catalog\">Catalog:</label><b>");
__out.push(Mold.escapeHTML($arg.catalog ));
__out.push("</b>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"name\">Repository Name:</label><b>");
__out.push(Mold.escapeHTML($arg.reponame ));
__out.push("</b>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"group\">Group:</label>\n      <input name=\"group\" id=\"group\"  type=\"text\" size=\"20\" max=\"60\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"user\">Username:</label>\n      <input name=\"user\" id=\"user\" type=\"text\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"password\">Password:</label>\n      <input name=\"password\" id=\"password\" type=\"password\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"instanceName\">Instance Name:</label>\n      <input name=\"instanceName\" id=\"instanceName\" type=\"text\" size=\"20\" max=\"200\"/>\n    </div>\n    <div class=\"pure-controls\">\n      <button type=\"button\" class=\"pure-button action-button\">Cancel</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      <button type=\"submit\" disabled=\"true\" id=\"convertStoreButton\" class=\"pure-button pure-button-primary\">Convert Store to Instance</button>\n      <span id=\"workingMessage\"></span>\n    </div>\n  </fieldset>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;