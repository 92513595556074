import User from '../../user';
import Store from '../../store';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div>\n  <h3>");
__out.push(Mold.escapeHTML($arg.name));
__out.push("\n    ");
if (User.userAccess("w")) {
__out.push("\n      <span class=\"subtle linkish\">[edit]</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.editFTI($arg);
});}) + "\"></var>");
__out.push("\n      <span class=\"subtle linkish\" title=\"delete\">[delete]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.deleteFTI($arg, $node.parentNode.parentNode);
});}) + "\"></var>");
__out.push("\n    ");
}
__out.push("\n  </h3>\n  <p style=\"margin-left: 1em\">\n    <em>Predicates:</em>\n    ");
Mold.forDepth++;
Mold.forEach($arg.predicates, function(p, $i) {
if ($i) {
__out.push(", ");
}
Mold.dispatchCustom("part", p, __out);
});
Mold.forDepth--;
if (!$arg.predicates.length) {
__out.push("<em>All</em>");
}
__out.push("<br/>\n    <em>Index literals:</em>\n    ");
if ($arg.indexLiterals === true) {
__out.push("true\n    ");
} else if ($arg.indexLiterals === false) {
__out.push("false\n    ");
} else {
Mold.forDepth++;
Mold.forEach($arg.indexLiterals, function(tp, $i) {
if ($i) {
__out.push(", ");
}
Mold.dispatchCustom("part", tp, __out);
});
Mold.forDepth--;
__out.push("\n    ");
}
__out.push("<br/>\n    <em>Index resources:</em> ");
__out.push(Mold.escapeHTML($arg.indexResources + ""));
__out.push("<br/>\n    <em>Parts indexed:</em> ");
Mold.forDepth++;
Mold.forEach($arg.indexFields, function(f, $i) {
if ($i) {
__out.push(", ");
}
__out.push(Mold.escapeHTML(f));
});
Mold.forDepth--;
__out.push("<br/>\n    <em>Tokenizer:</em> ");
__out.push(Mold.escapeHTML($arg.tokenizer + ""));
__out.push("<br/>\n    <em>Minimum word size:</em> ");
__out.push(Mold.escapeHTML($arg.minimumWordSize));
__out.push("<br/>\n    <em>Stop words:</em>\n    ");
Mold.forDepth++;
Mold.forEach($arg.stopWords, function(w, $i) {
if ($i) {
__out.push(", ");
}
__out.push(Mold.escapeHTML(w));
});
Mold.forDepth--;
if (!$arg.stopWords.length) {
__out.push("<em>None</em>");
}
__out.push("<br/>\n    <em>Word filters:</em> ");
Mold.forDepth++;
Mold.forEach($arg.wordFilters, function(f, $i) {
if ($i) {
__out.push(", ");
}
__out.push(Mold.escapeHTML(f));
});
Mold.forDepth--;
__out.push("\n      ");
if (!$arg.wordFilters.length) {
__out.push("<em>None</em>");
}
__out.push("<br/>\n  </p>\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;