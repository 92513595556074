import Base from '../../base';
import { $$ } from '../../util';
import $ from 'jquery';
import setImmediate from '../../set-immediate';
import infoTemplate from './info.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h3>No results</h3>\n\n<h3 id=\"queryWarnings\">SPARQL query plan had warnings</h3>\n\n");
__out.push(infoTemplate.template($arg.information) );
__out.push("\n  \n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  if ($$('queryWarningFlag')) {
    $('#queryWarnings').css('display', 'block');
  } else {
    $('#queryWarnings').css('display', 'none');
  }
});
}) + "\"></var>");
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;