import Base from './base';
import Dispatch from './dispatch';
import _ from 'underscore';
import { MAsync } from './async';
import ReplUtil from './replUtil';
import Util from './util';
import App from './app';
import Replstats from './replstats';

// UI imports

import Layout from './layout';
import $ from 'jquery';

import replTemplate from './templates/repl/repl.mold';
import addInstanceTemplate from './templates/repl/addinstance.mold';
import settingsTemplate from './templates/repl/settings.mold';

/**
 * Functionality related to repl replication management.
 *
 * @namespace Repl
 */
var Repl = {};


/**
 * Retrieves the array of objects, one per instance.
 * An object is a set of key/value pairs.
 *
 * Each column is described below with an object whose "data"
 * field has a name (such as 'instanceId') that specifies the
 * key to use to find the value for this column in the data
 * returned by Repl.cluster.
 *
 * @return {Async}
 *
 */
Repl.cluster = function () {
  return Base.jsonReq('GET',
          Base.serverUrlNoSession('repl/clusterReport'));
};

//
// UI
//

Repl.showAddInstanceDialog = function (callback) {
  // Show and handle the Add Instance dialog.
  // It's likely when adding multiple instances that many of the
  // values will be the same.  Therefore we leave the dialog
  // up after doing an Add Instance so that it may be modified
  // and another Add Instance done.

  var close = Layout.customDialog('addReplInstance-dialog',
          addInstanceTemplate,
          {
            closeDialog: function () {
              close();
            },
            ok: function (scheme, host, port, catalog, name, group,
                  user, password, instanceName) {
              callback(scheme, host, port, catalog, name, group,
                    user, password, instanceName);
              // don't close the dialog
            },
          });
};


Repl.showSettingsDialog = function () {
  // Show the current settings of the four parameters
  // that control replication in the cluster and then
  // allow the user to modify them.
  //
  // the settings are
  // durability
  // distributedTransactionTimeout (dtt)
  // transactionLatencyCount (tlc)
  // transactionLatencyTimeout (tlt)
  //
  Repl.getSettings(function (current) {
    // Current is an object holding the current
    // setting of the replication parameters.
    var close = Layout.customDialog('replSettings-dialog',
            settingsTemplate,
            {
              closeDialog: function () {
                close();
              },

              // the current values for the settings.
              now: current,

              ok: function (durability, dtt, tlc, tlt) {
                Repl.setSettings(durability, dtt, tlc, tlt,
                        function () {
                          close();
                        });
              },
            });
  });
};

Repl.getSettings = function (callback) {
  // get the current settings of the replication
  // parameter for this cluster and pass that info to
  // the callback function.

  clearLog('Get Settings');

  Base.jsonReq('GET', Base.serverUrlNoSession('repl/settings')).then(
          function (val) {
            callback(val);
          },
          function (val) {
            logtext('Failed to get settings from instance. Error: ' + val);
          }
  );
};

Repl.setSettings = function (durability, dtt, tlc, tlt, callback) {
  // Set the replication settings to the values given
  // and then call the callback.
  // If the set failed (because for example a value is out
  // of range) then the settings dialog will stay up so the
  // user can try again

  clearLog('Set Settings');

  var args = {
    durability: durability,
    distributedTransactionTimeout: dtt,
    transactionLatencyCount: tlc,
    transactionLatencyTimeout: tlt,
  };

  Base.req('POST', Base.serverUrlNoSession('repl/settings', args)).then(
          function (_val) {
            logtext('New settings have been set');
            callback();
          },
          function (val) {
            logtext('Failed to set new settings, error: ' + val);
            // no callback so the dialog stays up
          });
};


Repl.showRepl = function (isControlling) {
  // Implements DataTables AJAX interface
  var load = function (_data, callback) {
    Base.load(Repl.cluster(), 'Fetching cluster data', function (cluster) {
      callback({ data: cluster });
    });
  };
  var page = {
    /* used for load graphs */
    sizes: {
      'tall': 300,
      'medium': 170,
      'short': 120,
      'tiny': 80,
    },
    defaultSize: 'short',
  };

  var ctrl = {};

  $.extend(ctrl, page);


  $.extend(ctrl, {
    renderReplTable: function (node) {
      // The refresh button is always displayed below the table
      var buttons = [
        {
          text: 'Refresh',
          action: function (_e, _dt) {
            ctrl.refresh();
          },
        },
      ];

      if (isControlling) {
        // If we are communicating with the controlling instance
        // add extra buttons since we can do more operations
        buttons = buttons.concat([
          {
            text: 'Stop',
            action: ctrl.stopButton,
            extend: 'selected',
          },
          {
            text: 'Start',
            action: ctrl.startButton,
            extend: 'selected',
          },
          {
            text: 'Add Instance',
            action: function (_e, _dt) {
              // Only put up the dialog if it's not already showing.
              if (!$('#addInstanceDialog').length) {
                Repl.showAddInstanceDialog(ctrl.addInstance);
              }
            },
          },
          {
            text: 'Remove Instance',
            action: ctrl.removeButton,
            extend: 'selected',
          },
          {
            text: 'Settings',
            action: function (_e, _dt) {
              Repl.showSettingsDialog();
            },
          },
        ]);
      }

      // always show the set controlling instance button
      buttons = buttons.concat([{
        text: 'Set Controlling Instance',
        action: ctrl.controllingButton,
        extend: 'selectedSingle',
      }]);

      var columns = [
        {
          orderable: false,
          className: 'select-checkbox',
          width: '1em',
          data: function () {
            return '';
          },
          title: '<i class="regular-button-icon select-all fa fa-check"></i>',
        },
        {
          title: 'Instance ID',
          data: 'instanceId',
        },
        {
          title: 'Instance Name',
          data: 'instanceName',
        },
        {
          title: 'Repo Name',
          data: 'repoName',
        },
        {
          title: 'Host',
          data: 'host',
        },
        {
          title: 'Port',
          data: 'port',
        },
        {
          title: 'Group',
          data: 'group',
        },
        {
          title: 'State',
          data: 'state',
        },
        {
          title: 'Controlling',
          data: 'controlling',
        },
        {
          title: 'This Instance',
          data: 'thisInstance',
        },
        {
          title: 'Fully Connected',
          data: 'fullyConnected',
        },
        {
          // for the user Catch Up is a more meaningful term
          title: 'Catch Up',
          data: 'partiallyConnected',
        },
        {
          title: 'Unconnected',
          data: 'unconnected',
        },
        {
          title: 'Ingest Queue Length',
          data: 'ingestQueueLength',
        },
        {
          title: 'Commits Behind',
          data: 'commitsBehind',
        },
      ];

      // eslint-disable-next-line new-cap
      var table = $(node).DataTable({
        buttons: buttons,

        // specify the table parts to be displayed
        dom: 'rptB',

        columns: columns,

        // Data loading callback
        ajax: load,
        // Order by instance id.  The column number
        // will be different depending if we have
        // checkboxes or not
        order: [1, 'asc'],

        // Allow selecting multiple rows
        select: {
          style: 'multiple',
        },
        // No need for pagination, there can't be that many instances
        paginate: false,
        // This is used to persist selections on refresh
        rowId: 'instance-id',
        // Allow resizing to work
        autoWidth: false,
        // Colors
        stripeClasses: ['pure-table-odd', 'pure-table-even'],
      });
      // Save the table reference in the controller
      ctrl.table = table;

      Replstats.showReplstats(page);
    },
    refresh: function () {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        ctrl.table.ajax.reload();
      }
    },

    delayedRefresh: function (msecs) {
      // Do the refresh after msecs milliseconds
      setTimeout(Dispatch.protect(function () {
        ctrl.refresh();
        logtext('Refreshed table');
      }), msecs);
    },

    dobutton: function (dt, doing, doing2, urlsuffix, okThis) {
      // Do the action of the the button on all selected instances
      // Don't do the action on the instance we are communicating
      // with unless okThis is true.
      //
      // doing2 names the action e.g. "stop"
      // doing is the 'ing' form, e.g. "stopping"
      var asyncs = [];
      var controlAsync;

      clearLog(doing + ' Instance');
      dt.rows({ selected: true }).every(
              function (_index) {
                var rowdata = this.data();
                // We will send commands through the controlling instance
                // thus if the command is stop it's important that the
                // stop command be sent to the other instances before we
                // perform the stop command on the controlling instance.
                // Thus we delay making the async which performs the command on
                // the controlling instance until the other asyncs
                // have finished.
                var makeAsync = function () {
                  return ctrl.actionInstance(doing, doing2, urlsuffix,
                          rowdata.instanceName);
                };

                if (rowdata.thisInstance === 'yes') {
                  if (okThis) {
                    // we'll make the Async later
                    controlAsync = makeAsync;
                  } else {
                    logtext('Cannot ' + doing2 + ' ' + rowdata.instanceName
                            + ' since this is the instance whose page you are viewing');
                    return true;
                  }
                } else {
                  // start operation running asynchronously
                  asyncs.push(makeAsync());
                }
                return true;
              });

      var masync = MAsync.collect.apply(MAsync, asyncs);
      masync.then(function () {
        // The Asyncs that make up the MAsync never fail and thus a
        // problem with one will not stop the others from running.
        //
        // commands to the other instances have completed
        // send command to the controlling instance if
        // that was requested
        var REFRESH_DELAY = 2000;
        if (controlAsync) {
          controlAsync().then(function () {
            // The only command that will get us here is the stop
            // command.  Having stopped the instance we can no longer
            // view its replication status thus we take the user
            // to the main page from which he can select a
            // repo to view.
            //
            // Allow the user to see that the command has happened
            // before taking him to the main page.
            setTimeout(Dispatch.protect(function () {
              App.goTo(Dispatch.url(''));
            }), REFRESH_DELAY);
          });
        } else {
          ctrl.delayedRefresh(REFRESH_DELAY);
        }
      });
    },

    actionInstance: function (doing, _doing2, urlsuffix, instanceName) {
      // perform the action on one instance.
      // doing is the 'ing' form of what we doing and doing2 is
      // the name for what we're doing.
      //
      // This returns a promise and that promise will always resolve
      // to a success even if the call failed.
      logtext(doing + ' instance ' + instanceName);
      var args = { instanceName: instanceName };
      return Base.req('PUT', Base.serverUrlNoSession(urlsuffix, args)).then(
              function (_val) {
                logtext(Util.String.capitalize(doing) + ' '
                        + instanceName + ' successful');
              },
              function (val) {
                logtext(Util.String.capitalize(doing) + ' ' + instanceName
                        + ' failed with error: ' + val);
              });
    },
    stopButton: function (_e, dt) {
      ctrl.dobutton(dt, 'Stopping', 'stop', 'repl/stop', true);
    },
    startButton: function (_e, dt) {
      ctrl.dobutton(dt, 'Starting', 'start', 'repl/start');
    },
    removeButton: function (_e, dt) {
      ctrl.dobutton(dt, 'Removing', 'remove', 'repl/remove');
    },
    controllingButton: function (_e, dt) {
      // exactly one row will be selected due to selectedSingle specification
      clearLog('Setting Controlling Instance');

      dt.rows({ selected: true }).every(
              function (_index) {
                var rowdata = this.data();

                if (rowdata.controlling === 'yes') {
                  logtext('Selected instance is already the controlling instance');
                } else {
                  var setCI = function () {
                    logtext('Setting ' + rowdata.instanceName
                            + ' as the controlling instance');
                    Base.req(
                        'PUT',
                        Base.serverUrlNoSession('repl/controllingInstance', {
                          'instanceName': rowdata.instanceName,
                          'force': 'true',
                        })).then(function () {
                          logtext('Controlling instance set');
                          logtext('Refreshing...');

                          var REFRESH_DELAY = 2000;
                          setTimeout(Dispatch.protect(function () {
                            // The table will change based on whether we are
                            // the new controlling instance.
                            logtext('Refreshed');
                            if (rowdata.thisInstance === 'yes') {
                              App.goTo(Dispatch.relativeUrl('replmanagecontrolling'));
                            } else {
                              // We want to refresh the page but if we're already
                              // viewing the replmanage page then the goTo below
                              // is a no-op and we won't see any refresh.  Thus
                              // we bracket the goTo with code to ensure that
                              // should we stay on the replmanage page it will
                              // be refreshed so the new controlling instance
                              // will be shown in the table
                              var refreshSame = App.refreshIfSamePage();
                              App.goTo(Dispatch.relativeUrl('replmanage'));
                              refreshSame();
                            }
                          }), REFRESH_DELAY);
                        });
                  };

                  if (!isControlling) {
                    // warn the user about using this function
                    Layout.showDialog('sendToNonController-confirm',
                            'If at all possible the controlling instance ' +
                            'should only be changed by sending a command ' +
                            ' to the current controlling instance. ' +
                            'Do you really want to issue this command ' +
                            'on the webview page for a non controlling ' +
                            'instance?',
                            setCI, null,
                            true, true);
                  } else {
                    setCI();
                  }
                }
                return true;
              }
      );
    },
    addInstance: function (scheme, host, port, catalog, name, group,
            user, password, instanceName) {
      clearLog('Add Instance');

      var args = ReplUtil.configParams(scheme, host, port, catalog, name,
                                       group, user, password, instanceName);

      logtext('Adding an instance. This can take a while for large repositories.');

      var lastResponseLen = false;
      function noteProgress(e) {
        // Called when some data has been returned by the server.
        // The growCluster command will send back progress reports as it
        // goes through the steps of adding an instance.
        var thisResponse;
        var response = e.currentTarget.response;
        // response is the entire response so far from the web server.
        // We set thisResponse to the part of the response that's new
        // since the last time we were notified because that's what we
        // want to display now.
        if (lastResponseLen === false) {
          thisResponse = response;
        } else {
          thisResponse = response.substring(lastResponseLen);
        }
        lastResponseLen = response.length;
        logtext(thisResponse);
      }

      // This is an atypical call in that we might send back
      // a 200 response and mid way through find an error.
      // We can't assume that when the first argument to
      // the then() is called below that the call was successful
      // The log that's displayed will show whether the call succeeded

      Base.req('PUT', Base.serverUrlNoSession('repl/growCluster', args),
              { handlers: { onProgress: noteProgress } }).then(
              function (_val) {
                ctrl.delayedRefresh(1000);
              },
              function (val) {
                logtext('Adding instance failed' +
                        (val.length > 0 ? ' with error ' + val : ''));
              }
      );
    },
  });

  replTemplate.cast(Layout.getPage(), ctrl);
};


function clearLog(title) {
  // clear log entries and put in the title of the new log.
  var lognode = document.getElementById('repllog');
  lognode.innerHTML = '<h2>' + title + ' Log</h2>';
}

function logtext(text) {
  // log the given text on the screen for the user to view

  var lognode = document.getElementById('repllog');
  if (lognode.innerHTML === '') {
    // hasn't been titled yet so give it a generic title
    lognode.innerHTML = '<h2>Action Log</h2>';
  }

  text.split('\n').forEach(function (line) {
    lognode.innerHTML += '<div>' + _.escape(line) + '</div>';
  });
}

export default Repl;
