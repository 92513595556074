import Query from '../../query';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<label for=\"");
__out.push($arg.prefix);
__out.push("importfile-ct\" class=\"heading\" title=\"Select the format of the files being loaded\">Format:</label>\n<select id=\"");
__out.push($arg.prefix);
__out.push("importfile-ct\" name=\"content-type\" size=\"1\">\n");
if ((!$arg.no_autodetect)) {
__out.push("\n  <option value=\"\">Autodetect</option>\n");
}
__out.push("\n  ");
Mold.forDepth++;
Mold.forEach(Query.TRIPLE_CONTENT_TYPES, function(type, $i) {
__out.push("\n    <option value=\"");
__out.push(Mold.escapeHTML(type[1]));
__out.push("\" id=\"");
__out.push($arg.prefix);
__out.push("ct-");
__out.push(Mold.escapeHTML(type[2]));
__out.push("\">");
__out.push(Mold.escapeHTML(type[0]));
__out.push("</option>\n  ");
});
Mold.forDepth--;
__out.push("            \n</select>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;