import { $$ } from '../../util';
import Store from '../../store';
import importFileFormatTemplate from './import-file-format.mold';
import importAutodetectHelpTemplate from './import-autodetect-help.mold';
import importAgloadOptionsTemplate from './import-agload-options.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"small-form\" id=\"server-side-import-form\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok( Store.gatherUploadDialogParameters( $node ));
  
});}) + "\"></var>");
__out.push("\n  <table width=\"100%\">\n    <tr>\n     <td>\n\t<p>Select one or more server files to import <span id=\"cwd\"></span></p>\n      </td>\n      </tr>\n    <tr>\n      <td>\n        <label title=\"Use the file browser to select one or more server-side files to load. Do not forget to select a file type as well.\"\n               class=\"heading\" for=\"file-picker\">File:</label>\n        <select class=\"filepicker\" name=\"files\" id=\"file-picker\" data-cwd=\"cwd\" multiple></select>\n\t");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$('upload-ok').disabled = $node.value === '';
});}) + "\"></var>");
__out.push("\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Store.initFilePicker($node);
	      $$("upload-ok").disabled = true; }) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n    <tr>\n      <td>\n        ");
__out.push(importFileFormatTemplate.template({prefix: 'server-'}));
__out.push("\n\t<div class=\"subtle\" id=\"autodetect-help\">\n          ");
__out.push(importAutodetectHelpTemplate.template() );
__out.push("\n        </div>\n      </td>\n    </tr>\n    <tr>\n      <td>\n        ");
__out.push(importAgloadOptionsTemplate.template({prefix: 'server-', extendedOptions: true}));
__out.push("\n      </td>\n    </tr>\n    <tr>\n      <td class=\"buttons\">\n        <button type=\"submit\" id=\"upload-ok\">OK</button>\n        <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n  </table>\n</form>\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;