
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div id=\"queryResults\">\n  <br>\n  <h3>Query Results</h3>\n  <pre id=\"graphql-results\"><code>");
__out.push(Mold.escapeHTML(JSON.stringify($arg.values, null, 2) ));
__out.push("</code></pre>\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;