
import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.config_text) {
__out.push("\n<h2>");
__out.push(Mold.escapeHTML($arg.config_type));
__out.push(" config file</h2>\n<pre id=\"configfile-contents\">\n");
__out.push(Mold.escapeHTML($arg.config_text));
__out.push("\n</pre>\n");
} else {
__out.push("\n(Unable to fetch the config file contents from the server)<br>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;