
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\ntitle=\"Enter an IRI to use as the import graph for file formats that do not\nalready include one using N-Triples syntax. E.g. &lt;http://www.example.com/context#1000&gt;.\"\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;