import $ from 'jquery';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"pure-form pure-form-stacked\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok(parseInt($('input[name="style"]:checked').val()));
  
});}) + "\"></var>");
__out.push("\n  <h3>Select index style</h3>\n    <label class=\"pure-radio\">\n      <input type=\"radio\" name=\"style\" value=\"1\" checked>\n      Style 1\n    </label>\n    <span class=\"pure-form-message-inline\">\n      Faster to construct and optimize. Better for queries which end up scanning many triples and which typically produce results with many triples.\n    </span>\n    <br />\n    <label class=\"pure-radio\">\n      <input type=\"radio\" name=\"style\" value=\"2\">\n      Style 2\n    </label>\n    <span class=\"pure-form-message-inline\">\n      Takes longer to construct and optimize. Better for point queries where all of s/p/o or s/p/o/g are fixed and where the result is typically a single triple.\n    </span>\n\n  <div class=\"pure-controls\">\n    <button type=\"button\" class=\"pure-button action-button\">Cancel</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    <button type=\"submit\" class=\"pure-button pure-button-primary\">Change</button>\n  </div>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;