
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<br/>&nbsp; &nbsp;<input type=\"text\" name=\"");
__out.push(Mold.escapeHTML($arg.name));
__out.push("\" value=\"");
__out.push(Mold.escapeHTML($arg.value || ""));
__out.push("\"> ");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;