
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.getVisibleLength()) {
__out.push("\n  ");
Mold.dispatchCustom("triples", {
    triples: $arg.getVisibleValues()
  }, __out);
__out.push("\n");
} else {
__out.push("\n  <p>No results.</p>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;