import User from '../user';
import Catalog from '../catalog';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2 id=\"serverWarnings\">Server Warnings</h2>\n\n\n");
if ($arg && ($arg.length > 0) ) {
__out.push("\n  ");
Mold.forDepth++;
Mold.forEach($arg, function(warning, $i) {
__out.push("\n    <div class=\"server-warning\">\n        <h3>\n          ");
if (User.userPerm("super")) {
__out.push("\n            <button class=\"small-delete-button\"><i class='fa fa-times-circle' title=\"dismiss ");
__out.push(Mold.escapeHTML(warning.tag));
__out.push(" warning\"></i></button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Catalog.dismissServerWarning(warning.tag);
});}) + "\"></var>");
__out.push("\n          ");
}
__out.push("\n          <span>");
__out.push(Mold.escapeHTML(warning.tag ));
__out.push("</span>\n          <span class=\"subtle\"> ");
__out.push(Mold.escapeHTML(warning.category ));
__out.push("</span>\n        </h3>\n        <span class=\"formatted-text\">");
__out.push(Mold.escapeHTML(warning.expansion ));
__out.push("</span>\n    </div>\n  ");
});
Mold.forDepth--;
__out.push("\n");
} else {
__out.push("\n  <span>There are no server warnings to report.</span>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;