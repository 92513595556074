import $ from 'jquery';
import setImmediate from '../../set-immediate';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form id='newAttributeForm' class=\"pure-form pure-form-aligned\" autocomplete='off'>\n  <fieldset>\n    <legend><b>New Attribute</b></legend>\n    <div class=\"pure-control-group\">\n      <label for=\"attributeName\">Name</label>\n      <input id=\"attributeName\" type='text' value=''/>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"attributeMin\">Min. number</label>\n      <input id=\"attributeMin\" type='text' value=''/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$($node).filter_input({regex: '[0-9]'}); }) + "\"></var>");
__out.push("\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"attributeMax\">Max. number</label>\n      <input id=\"attributeMax\" type=text value='' />\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$($node).filter_input({regex: '[0-9]'}); }) + "\"></var>");
__out.push("\n    </div>\n    <div class=\"pure-control-group input-with-help\">\n      <label for=\"attributeAllowed\">Allowed Values</label>\n      <input id=\"attributeAllowed\" type=text placeholder='comma separated values' value=''/>\n      <span class=\"ag-help-icon\" title=\"Click for more info\">\n        <i class=\"fas fa-question-circle\"></i>\n      </span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$('#allowedValuesHelp').slideToggle('fast');
});}) + "\"></var>");
__out.push("\n    </div>\n    <div id=\"allowedValuesHelp\">\n      <span>\n      If specified, <em>Allowed Values</em> must be comma-separated list of\n      tokens. Spaces will be trimmed from the start and end of each\n      token. If a token needs to include commas or spaces at the ends,\n      then it can wrapped in quotation marks. The backslash character\n      (\\) can be used to escape individual characters as needed. <br>\n      Some examples include:\n      </span>\n      <ul>\n        <li>a, b, c</li>\n        <li>a, \" b \", \"\\\"quote\\\"\"</li>\n        <li>several words in one token, \" in quotes \"</li>\n      </ul>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"attributeOrdered\">Ordered</label>\n      <input id=\"attributeOrdered\" class=\"centered-checkbox\" type=\"checkbox\" />\n    </div>\n    <div id=\"buttons\">\n      <button type=\"button\" id=\"buttonSaveNewAttribute\" class=\"pure-button pure-button-primary\">Save</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.save();
});}) + "\"></var>");
__out.push("\n      <button type=\"button\" id=\"buttonCancelNewAttribute\" class=\"pure-button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    </div>\n  </fieldset>\n</form>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function () {
      $('#attributeName').focus();
    }, 0);
}) + "\"></var>");
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;