import Testability from './testability';

/**
 * This simply wraps setTimeout(..., 0) for now.
 *
 * A proper setImmediate implementation might be added later.
 *
 * @param {Function} callback - Code to be invoked 'later' (in a future
 * iteration of the event loop).
 */
export default function setImmediate(callback) {
  Testability.startOperation();
  setTimeout(function () {
    try {
      callback();
    } finally {
      Testability.endOperation();
    }
  }, 0);
}
