import Util from '../../util';
import { $$ } from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"small-form add-triple\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$arg.ok(Util.selectValue($$('suppressDuplicates-handling')));
                 $event.stop(); 
});}) + "\"></var>");
__out.push("\n  <table width=\"100%\">\n    <tr>\n      <td><label for=\"suppressDuplicates-handling\">Duplicate suppression strategy:</label>\n        <select id=\"suppressDuplicates-handling\">\n          <option value=\"false\" ");
if ($arg.suppressDuplicates === 'false') {
__out.push("selected = \"selected\"");
}
__out.push(" >Do not suppress duplicates</option>\n          <option value=\"spog\"  ");
if ($arg.suppressDuplicates === 'spog' ) {
__out.push("selected = \"selected\"");
}
__out.push(" >Subject, Predicate, Object and Graph (spog)</option>\n          <option value=\"spo\"   ");
if ($arg.suppressDuplicates === 'spo'  ) {
__out.push("selected = \"selected\"");
}
__out.push(" >Subject, Predicate, Object only (spo)</option>\n        </select>\n      </td>\n      <td class=\"buttons\">\n        <button type=\"submit\">OK</button>\n        <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n  </table>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;