import $ from 'jquery';
import Base from '../../base';
import Util from '../../util';
import multinputTemplate from '../instruction/multinput.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      var context = $($event.target);
      $('.buttons button', context).prop("disabled", true);
      $('.buttons button[type=submit]', context).text('Creating...');
      $arg.ok($node.elements);
  
});}) + "\"></var>");
__out.push("\n  ");
var types = typeof $arg.cur.indexLiterals !== 'boolean';
      var rnd = Math.floor(Math.random() * 10000);
      function insertInput(name, node) {
        var span = document.createElement('SPAN');
        node.parentNode.insertBefore(span, node);
        multinputTemplate.cast(span, {name: name});
      }
  
__out.push("\n  <table width=\"100%\">\n    <tr>\n      <td>\n        <h3>Predicates to index</h3>\n        <p>\n          <input type=\"radio\" name=\"predicates\" ");
if (!$arg.cur.predicates.length) {
__out.push("checked");
}
__out.push(" value=\"none\"\n            id=\"p1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"p1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">All predicates</label><br/>\n          <input type=\"radio\" name=\"predicates\" ");
if ($arg.cur.predicates.length) {
__out.push("checked");
}
__out.push(" value=\"some\"\n            id=\"p2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"p2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">The following:</label>\n          ");
var preds = $arg.cur.predicates.length || 1;
__out.push("\n          ");
Mold.forDepth++;
Mold.forEach($arg.cur.predicates.length ? $arg.cur.predicates : [""], function(pred, $i) {
__out.push("\n            ");
Mold.dispatchCustom("multinput", {name: "predicate" + (1 + $i), value: pred}, __out);
__out.push("\n            ");
if (!$i) {
Mold.dispatchCustom("nodeinputhelp", undefined, __out);
}
__out.push("\n          ");
});
Mold.forDepth--;
__out.push("\n          <span class=\"linkish\">[more]</span>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
preds += 1;
              insertInput('predicate' + preds, $node);
          
});}) + "\"></var>");
__out.push("\n        <p>\n\n        <h3>Index literals</h3>\n        <p>\n          <input type=\"radio\" name=\"literals\" ");
if ($arg.cur.indexLiterals === true) {
__out.push("checked");
}
__out.push(" value=\"true\"\n            id=\"l1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"l1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Always</label><br/>\n          <input type=\"radio\" name=\"literals\" ");
if ($arg.cur.indexLiterals === false) {
__out.push("checked");
}
__out.push(" value=\"false\"\n            id=\"l2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"l2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Never</label><br/>\n          <input type=\"radio\" name=\"literals\" ");
if (types) {
__out.push("checked");
}
__out.push(" value=\"some\"\n            id=\"l3");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"l3");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Only literals with the following types:</label>\n          ");
Mold.forDepth++;
Mold.forEach(types ? $arg.cur.indexLiterals : [""], function(type, $i) {
__out.push("\n            ");
Mold.dispatchCustom("multinput", {name: "literalType" + (1 + $i), value: type}, __out);
__out.push("\n            ");
if (!$i) {
Mold.dispatchCustom("nodeinputhelp", undefined, __out);
}
__out.push("\n          ");
});
Mold.forDepth--;
__out.push("\n          ");
var literalTypes = types ? $arg.cur.indexLiterals.length : 1;
__out.push("\n          <span class=\"linkish\">[more]</span>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
literalTypes += 1;
              insertInput('literalType' + literalTypes, $node);
});}) + "\"></var>");
__out.push("\n        </p>\n\n        <h3>Index resources</h3>\n        <p>\n          <input type=\"radio\" name=\"resources\" ");
if ($arg.cur.indexResources === true) {
__out.push("checked");
}
__out.push(" value=\"true\"\n            id=\"r1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"r1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Fully</label><br/>\n          <input type=\"radio\" name=\"resources\" ");
if ($arg.cur.indexResources === 'short') {
__out.push("checked");
}
__out.push(" value=\"short\"\n            id=\"r2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"r2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Only the part after the <code>#</code> or <code>/</code></label>\n            ");
Mold.dispatchCustom("help", "For example, <code>&lt;http://example.com/person/jack></code> becomes <code>jack</code>.", __out);
__out.push("<br/>\n          <input type=\"radio\" name=\"resources\" ");
if ($arg.cur.indexResources === false) {
__out.push("checked");
}
__out.push(" value=\"false\"\n            id=\"r3");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"r3");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Don't index resources</label>\n        </p>\n\n        <h3>Fields to index</h3>\n        <p><select name=\"fields\" multiple style=\"vertical-align: top\">\n          <option value=\"subject\" ");
if (Util.member('subject', $arg.cur.indexFields)) {
__out.push("selected");
}
__out.push(">Subjects</option>\n          <option value=\"predicate\" ");
if (Util.member('predicate', $arg.cur.indexFields)) {
__out.push("selected");
}
__out.push(">Predicates</option>\n          <option value=\"object\" ");
if (Util.member('object', $arg.cur.indexFields)) {
__out.push("selected");
}
__out.push(">Objects</option>\n          <option value=\"graph\" ");
if (Util.member('graph', $arg.cur.indexFields)) {
__out.push("selected");
}
__out.push(">Graphs</option>\n        </select> ");
Mold.dispatchCustom("help", "Select one or more fields that this index should extract text from when indexing. Note that whether fields are actually indexed also depends on the other parameters specified for the index.", __out);
__out.push("</p>\n\n        <h3>Tokenizer</h3>\n        <p>\n          <input type=\"radio\" name=\"tokenizer\" ");
if ((! $arg.cur) || (! $arg.cur.tokenizer) || ($arg.cur.tokenizer === 'default')) {
__out.push("checked");
}
__out.push(" value=\"default\"\n            id=\"t1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"t1");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Default</label>\n            ");
Mold.dispatchCustom("help", "The default tokenizer is suitable for European languages.", __out);
__out.push("\n          <br/>\n          <input type=\"radio\" name=\"tokenizer\" ");
if ($arg.cur.tokenizer === 'japanese') {
__out.push("checked");
}
__out.push(" value=\"japanese\"\n            id=\"t2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"t2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Japanese</label>\n            ");
Mold.dispatchCustom("help", "The Japanese tokenizer uses a Japanese morphological analyzer and is suitable with Japanese text and Japanese/European mixed text.  If you use this tokenizer, you may want to decrease the minimum word size to 2 or 1", __out);
__out.push("\n          <br/>\n          <input type=\"radio\" name=\"tokenizer\" ");
if ($arg.cur.tokenizer === 'simple-cjk') {
__out.push("checked");
}
__out.push(" value=\"simple-cjk\"\n            id=\"t3");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/><label for=\"t3");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Simple-CJK</label>\n            ");
Mold.dispatchCustom("help", "The Simple-CJK tokenizer uses bigrams to tokenize consecutive CJK characters.\n                    It can result in false positive hits, but it works for Chinese/Korean/Japanese text,\n                    even if they are mixed.  If you use this tokenizer, set the minimum word size to 2.", __out);
__out.push("\n          <br/>\n        </p>\n\n        <h3>Minimum word length</h3>\n        <p>Do not index words shorter than\n          <input type=\"text\" name=\"wordsize\" value=\"");
__out.push(Mold.escapeHTML($arg.cur.minimumWordSize));
__out.push("\" style=\"width: 2em\"/> characters.\n        </p>\n\n        <h3>Stop words</h3>\n        <p>\n          <label style=\"width: auto\" for=\"s2");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">\n            Use the following list of words (separate with spaces):</label>\n          <input name=\"stopwords\" type=\"text\" style=\"width: 90%\"\n                 value=\"");
__out.push(Mold.escapeHTML($arg.cur.stopWords ? $arg.cur.stopWords.join(" ") : $arg.defaultStopWords.join(" ") ));
__out.push("\"/>\n          <button type=\"button\" style=\"width: auto\">Reset to default stop words</button>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$event.stop();
            $("input[name=stopwords]", $event.target.parentNode).val($arg.defaultStopWords.join(" "));
          
});}) + "\"></var>");
__out.push("\n        </p>\n\n        <h3>Word filters</h3>\n        <p>\n          Use these filters (separate with spaces):\n          <input type=\"text\" name=\"wordfilters\" value=\"");
__out.push(Mold.escapeHTML(($arg.cur.wordFilters || []).join(" ")));
__out.push("\"\n            style=\"width: 90%\"/> ");
Mold.dispatchCustom("help", "Examples of valid filters are <code>stem.english</code> (\n                                         an English-language stemmer) and <code>drop-accents</code>.", __out);
__out.push("\n        </p>\n\n        ");
if (!$arg.create) {
__out.push("\n          <h3>Re-indexing</h3>\n          <p>\n            <input class=\"centered-checkbox\" type=\"checkbox\" checked name=\"reindex\" id=\"ri");
__out.push(Mold.escapeHTML(rnd));
__out.push("\"/>\n            <label for=\"ri");
__out.push(Mold.escapeHTML(rnd));
__out.push("\">Re-index existing triples.</label>\n            ");
Mold.dispatchCustom("help", "Uncheck this to have the new configuration only take effect for triples added in the future.", __out);
__out.push("\n          </p>\n        ");
}
__out.push("\n      </td>\n      <td class=\"buttons\">\n        <button type=\"submit\">OK</button>\n        <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n  </table>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;