import $ from 'jquery';
import { $$ } from '../../util';
import Util from '../../util';
import Triples from '../../triples';
import App from '../../app';
import Dispatch from '../../dispatch';
import setImmediate from '../../set-immediate';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Text search</h2>\n\n<p>\n  ");
function doSearch() {
         var index = $$("textindex");
         Triples.runSearch($$("textsearch").value, index ? index.options[index.selectedIndex].value : "*", true);
       }
__out.push("\n  <label for=\"textsearch\">Search</label>\n  <input type=\"text\" id=\"textsearch\" style=\"width: 30em\" linkedButton=\"FTISearchButton\" value=\"");
__out.push(Mold.escapeHTML($arg.query));
__out.push("\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
doSearch();
});}) + "\"></var>");
__out.push("\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "keyup", function($event, $node) {
Util.maybeEnableLinkedButton($node); 
});}) + "\"></var>");
__out.push("\n  ");
if (App.isInRealRepo()) {
__out.push("\n    in <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('text-index') ));
__out.push("\">index</a>\n    <select id=\"textindex\">\n      <option value=\"*\" ");
if ($arg.index === '*') {
__out.push("selected");
}
__out.push(">(all indices)</option>\n      ");
Mold.forDepth++;
Mold.forEach($arg.indices, function(index, $i) {
__out.push("\n        <option value=\"");
__out.push(Mold.escapeHTML(index));
__out.push("\" ");
if ($arg.index === index) {
__out.push("selected");
}
__out.push(">");
__out.push(Mold.escapeHTML(index));
__out.push("</option>\n      ");
});
Mold.forDepth--;
__out.push("\n    </select>\n  ");
}
__out.push("\n  <button id=\"FTISearchButton\">Search</button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
doSearch();
});}) + "\"></var>");
__out.push("\n</p>\n\n");
if (!App.isInRealRepo() || $arg.indices.length) {
__out.push("\n  <p class=\"subtle\">\n    Multiple words mean 'all of these terms'. Double-quote text to mean\n    'literally this'. The '|' character signifies 'or', and parentheses\n    group terms.\n  </p>\n");
} else {
__out.push("\n  <p><strong>Note:</strong> there are currently no free-text indices\n  defined in this repository, so your query is unlikely to yield results!</p>\n");
}
__out.push("\n\n<div id=\"search-results\"></div>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  let input = $$('textsearch');
  Util.maybeEnableLinkedButton(input);
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;