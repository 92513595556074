import Part from '../../part';
import Dispatch from '../../dispatch';
import Util from '../../util';
import App from '../../app';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if (typeof $arg === 'string') {
      $arg = Part.part($arg);
    }

__out.push("\n");
if ($arg.string === 'null') {
__out.push("\n  <span class=\"subtle\">(null)</span>\n");
} else {
__out.push("\n  <span class=\"part-container\">\n    <a class=\"part\"\n       href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('node/' + Util.encodeFragment($arg.string)) ));
__out.push("\"\n       title=\"");
__out.push(Mold.escapeHTML($arg.string ));
__out.push("\">\n      ");
__out.push(Mold.escapeHTML(App.showLongParts ? $arg.string : $arg.shortName ));
__out.push("\n    </a>\n    <i class=\"copy-part copy-trigger far fa-copy\" title=\"Copy to clipboard\" data-clipboard-text=\"");
__out.push(Mold.escapeHTML($arg.string ));
__out.push("\"></i>\n  </span>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;