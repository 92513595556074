import User from '../../user';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
   $arg.cancel();
   $arg.ok($node.elements.namespaces.value,
           $node.elements.namespacestype.value);
   
});}) + "\"></var>");
__out.push("\n  <table width=\"100%\">\n    <tr>\n      <td>\n        <label for=\"namespaces\" id=\"namespaceslabel\">Namespaces:</label>\n        <textarea name=\"namespaces\" class=\"option-bulk-input\"></textarea>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();}) + "\"></var>");
__out.push("\n        ");
Mold.dispatchCustom("help", " container=namespaces-bulk-box-help\n                Enter one namespace per line, in the format '<code>ex http://example.com/</code>',\n                optionally putting a colon after the namespace's name.", __out);
__out.push("\n        <br><br>\n        <label for=\"namespacestype\" id=\"namespacestypelabel\">Type:</label>\n        <select id=\"namespacestype\" class\"option-bulk-input-type\">\n        <option value=\"user\" ");
if ($arg.type === 'user') {
__out.push("selected");
}
__out.push(">user</option>\n        ");
if (User.userAccess('w')) {
__out.push("\n        <option value=\"repository\" ");
if ($arg.type === 'repository') {
__out.push("selected");
}
__out.push(">repository</option>\n        ");
}
__out.push("\n        ");
if (User.userPerm('super')) {
__out.push("\n        <option value=\"default\" ");
if ($arg.type === 'default') {
__out.push("selected");
}
__out.push(">default</option>\n        ");
}
__out.push("\n        </select>\n        ");
Mold.dispatchCustom("help", " container=namespaces-bulk-type-help\n               Repository namespaces apply to all users of a given repository and can only be modified by administrator.\n               User namespaces only apply to the current user of the given repository and can be modified by the user.", __out);
__out.push("\n      </td>\n      <td class=\"buttons\">\n        <button type=\"submit\">OK</button>\n        <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n    <tr><td colspan=\"2\"><div id=\"namespaces-bulk-box-help\"></div></td></tr>\n    <tr><td colspan=\"2\"><div id=\"namespaces-bulk-type-help\"></div></td></tr>\n  </table>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;