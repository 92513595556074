import { $$ } from '../../util';
import setImmediate from '../../set-immediate';
import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var input = null;

    function submit() {
      $arg.ok(input.value);
    }

__out.push("\n<table width=\"100%\">\n  <tr>\n    <td>\n      <label>");
__out.push(Mold.escapeHTML($arg.label));
__out.push(":</label>\n      <input id=\"prompt-input\" type=\"text\" style=\"width: 18em;\" linkedButton=\"promptOK\" />\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "keyup", function($event, $node) {
Util.maybeEnableLinkedButton(input); 
});}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){input = $node;
      	     setImmediate(function() {
			    Util.maybeEnableLinkedButton(input);
	     		    $node.focus();
			    });}) + "\"></var>");
__out.push("\n    </td>\n    <td class=\"buttons\">\n      <button id=\"promptOK\">OK</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      <button>Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;