import $ from 'jquery';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"pure-form pure-form-stacked\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok(parseInt($('input[name="optimization-level"]:checked').val()));
  
});}) + "\"></var>");
__out.push("\n  <h3>Select optimization level</h3>\n  <label class=\"pure-radio\">\n    <input type=\"radio\" name=\"optimization-level\" value=\"2\" checked>\n    Level 2 - Optimize for query performance.\n  </label>\n  \n  <br />\n  <label class=\"pure-radio\">\n    <input type=\"radio\" name=\"optimization-level\" value=\"3\">\n    Level 3 - Optimize for query performance and perform space reclamation for deleted triples.\n  </label>\n\n  <div class=\"pure-controls\">\n    <button type=\"button\" class=\"pure-button action-button\">Cancel</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    <button type=\"submit\" class=\"pure-button pure-button-primary\">Optimize</button>\n  </div>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;