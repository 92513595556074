import Util from './util';
import { $$ } from './util';
import App from './app';
import { Async } from './async';
import Layout from './layout';
import $ from 'jquery';
import HttpStatus from './http-status';
import User from './user';
import Testability from './testability';
import _ from 'underscore';
import sha1 from 'sha1';

/**
 * Various pieces of miscellaneous base functionality.
 * @namespace Base
 */
var Base = {};

Base.encodeArgs = function (map) {
  var result = Util.queryString(map);
  return result ? '?' + result : '';
};

/**
 * Build a URL from given arguments.
 *
 * If you want to pass a list of query parameters,
 * each of which has the same name, pass an object like so:
 * {valueName: [value1, ... , valueN]}
 *
 * @return {string}
 */
Base.url = function () {
  var accum = [];
  for (var i = 0; i < arguments.length; ++i) {
    var arg = arguments[i];
    if (typeof arg === 'object') {
      accum.push(Base.encodeArgs(arg));
    } else if (i % 2) {
      accum.push(encodeURIComponent(arg));
    } else {
      accum.push(arg);
    }
  }
  return accum.join('');
};

// This is adjusted in App.initialize
// (based on data injected into the HTML index template).
Base.rootUrl = '';

// This will be set in App.initialize, once the actual rootUrl
// has been established.
/**
 * Parsed AG server URL.
 *
 * @type {Object}
 * @property {string} protocol - Either 'http' or 'https'.
 * @property {string} hostname - Host address or IP, without port.
 * @property {string} port - port number (or an empty string).
 * @property {string} pathname - path on the server.
 * @property {string} search - Query string (will be empty in this case).
 * @property {string} hash - fragment identifier  (will be empty in this case).
 * @property {string} host - host with port.
 */
Base.rootLocation = window.location;

/**
 * Gets the full URL to a backend service.
 *
 * The service argument can be:
 *   - an absolute URL, in which case it will be returned as-is.
 *   - a path starting with '/', which will be resolved against
 *     the server's root URL.
 *   - any other string, which will be interpreted as a path relative
 *     to the current repository or session path.
 *
 * @param {string} service - Path on the server.
 * @param {Object} [params] - A dictionary of query parameters.
 * @param {object} [options] - Additional keyword arguments.
 * @param {string} [options.rootUrl] - Overrides the server URL if specified.
 * @param {boolean} [options.ignoreSession] - If true the returned URL will not
 *     contain the session id, even if a session is currently active.
 * @param {boolean} [options.useShard] - If true the returned URL will contain
 *     the shard subpath (/shards/<id>/) if it is part of current location.
 * @return {string} - Full URL.
 */
Base.serverUrl = function (service, params, options) {
  if (!options) {
    options = {};
  }
  var result;
  var rootUrl = (options.rootUrl === undefined) ? Base.rootUrl : options.rootUrl;
  if (service && service.charAt(0) === '/') {
    result = rootUrl + service;
  } else if (Util.isAbsoluteUrl(service)) {
    result = service;
  } else {
    result = rootUrl;
    if (App.isInCatalog()) {
      result += '/catalogs/' + App.currentCatalog;
    }
    if (App.currentRepository) {
      result += '/repositories/' + App.currentRepository;
    }
    if (App.currentSession && !options.ignoreSession) {
      result += '/session/' + App.currentSession.port +
          '/sessions/' + App.currentSession.uuid;
    }
    if (App.currentShard && options.useShard) {
      result += '/shards/' + App.currentShard;
    }
    if (service) {
      result += '/' + service;
    }
  }
  if (params) {
    var qs = Util.queryString(params);
    if (qs) {
      result = result + '?' + qs;
    }
  }
  return result;
};

/**
 * Gets the full URL to a backend service but will not return
 * a session URL.
 * Repl replication HTTP calls of the form repl/* are directed
 * at the replication manager and not a repo and they are reached
 * from the front end web server not a back end session.
 *
 * The service argument can be:
 *   - an absolute URL, in which case it will be returned as-is.
 *   - a path starting with '/', which will be resolved against
 *     the server's root URL.
 *   - any other string, which will be interpreted as a path relative
 *     to the current repository but not the session path.
 *
 * @param {string} service - Path on the server.
 * @param {Object} [params] - A dictionary of query parameters.
 * @return {string} - Full URL.
 */
Base.serverUrlNoSession = function (service, params) {
  return Base.serverUrl(service, params, { ignoreSession: true });
};


/*
Invokes an asynchronous HTTP request using the specified HTTP method
and the specified service (which will ultimately become a URL).  See
the docs for httpRequest to see how 'args' should be constructed.

By default, authentication data is provided by User entity. The value
of the optional 'auth' argument is used to override authentication (in
particular, 'null' means no authentication).

If the request is successful, the "ok" method of the supplied async
will be invoked with the response text and the xhr object.

If the request fails, the "fail" method of the supplied async will be
invoked with the response text (possibly a just a subportion of it
if it matches a particular format), and an object with status, url,
and method properties.

Returns the supplied async object.

*/
Base._reqWithAsync = function (async, method1, service, args, auth) {
  service = Base.serverUrl(service);
  if (!args) {
    args = {};
  }
  if (!args.headers) {
    args.headers = {};
  }
  // The auth token MUST NOT be sent to any external sites
  if (Base._isInternalRequest(service)) {
    if (auth === undefined) {
      args.headers.authorization = User.getAuthToken();
    } else {
      args.headers.authorization = auth;
    }
    // If AGWV-Request header is present, server won't respond with
    // www-authenticate header, which will prevent browsers from showing
    // pop-up authentication dialogs.
    args.headers['AGWV-Request'] = '';
  }
  args.method = method1;
  Base.httpRequest(service, args, async.ok.bind(async),
    function (body, req) {
      var m;
      if (req.status === HttpStatus.HTTP_BAD_REQUEST &&
          (m = body.match(/^[A-Z ]+: (.*)$/))) {
        body = m[1];
        async.fail(body, req);
      } else if (req.status === HttpStatus.HTTP_UNAUTHORIZED &&
                 User.hasAuthToken() && !auth) {
        if (!User.isLoggedIn()) {
          async.fail(body, req);
          User.loginDialog();
        } else {
          if (User.passwordExpired(body)) {
            async.fail(body, req);
            User.changePasswordDialog();
          } else {
            async.fail('Login session token expired. Please relogin.', req);
            User.reloginDialog();
          }
        }
      } else {
        async.fail(body, req);
      }
    });
  return async;
};

/**
 * Return true iff given URL represents an internal AG request.
 * This means that absolute root URL is its prefix.
 *
 * @param {string} url - request URL
 * @return {boolean} - whether this is an internal AG request
 */
Base._isInternalRequest = function (url) {
  return Util.String.startsWith(
      Util.getAbsoluteUrl(url), Base._getAbsoluteRootUrl());
};

/*
Calls reqs with a new async object.  Returns that new async object.
*/
Base.req = function (method, service, args, auth) {
  // Async defined in async.js
  return Base._reqWithAsync(new Async(), method, service, args, auth);
};

/* Returns an Async */
Base.jsonReq = function (method, service, args, auth) {
  if (!args) {
    args = {};
  }
  if (!args.accept) {
    args.accept = 'application/json';
  }
  function maybeJSON(input) {
    if (!input) {
      return null;
    } else {
      return Util.readJSON(input);
    }
  }
  return Base.req(method, service, args, auth).transform(maybeJSON);
};

Base.catchNotFound = function (async) {
  var result = new Async();
  async.wait(
    function ok(val) {
      result.ok(val);
    },
    function fail(message, req) {
      if (req && req.status === HttpStatus.HTTP_NOT_FOUND) {
        result.ok(null);
      } else {
        result.fail(message, req);
      }
    });
  return result;
};

/**
 * @return {string} - (cached) absolute URL of this AG instance.
 */
Base._getAbsoluteRootUrl = (function () {
  var absUrl;

  return function () {
    if (!absUrl) {
      absUrl = Util.getAbsoluteUrl(Base.rootUrl);
    }
    return absUrl;
  };
})();

Base.ignore = function (async) {
  async.wait(Util.ident.bind(Util), Util.ident.bind(Util));
};

/**
 * Shorthand for showing a failure message. If the message with the
 * same SHA1 hash is already present, scroll to it, otherwise render a
 * new one.
 *
 * @param {string} prefix - action that failed
 * @param {string} message - explanation of the failure
 * @param {boolean|number} [disappear] (default true) - when should the message
 *   disappear (see messageDIV in layout.js for explanation of possible values)
 */
Base.failMessage = function (prefix, message, disappear) {
  var id = 'fail-message' + sha1(message || '');
  if (!Util.scrollToIfPresent(id)) {
    Layout.showMessage(
      // If message is not empty, show "<prefix>: <message>" message,
      // otherwise show "<prefix>." message.
      prefix + ' failed' + (!message || message === '' ? '.' : ':\n') + message,
      disappear === undefined ? true : disappear,
      true, null, id);
  }
};

/**
 * Function that overrides async's default failure handler.
 *
 * "Our" handler supports 2 additional scenarios:
 *  - when a session expires (indicated by a particular error message),
 *    a special dialog is shown that redirects to main page on closing;
 *  - if handlerOrStr is a string, it's treated as the name of the operation
 *    that failed, and an error message is constructed from this operation
 *    name and the message.
 *
 * @param {Function|string} handlerOrStr - either a function to handle
 *   the error or a string describing the name of the operation that failed
 * @param {string} message - error message
 * @param {Object} [extra] - additional information
 * @return {*}
 */
window.asyncFail = function (handlerOrStr, message, extra) {
  if (handlerOrStr && handlerOrStr.call) {
    return handlerOrStr(message, extra);
  }

  if (App.currentSession &&
          message.match(/^No session running on port/) &&
          extra.status === HttpStatus.HTTP_BAD_REQUEST) {
    Layout.showMessage(
            'Your session seems to have been closed. ' +
            'Close this dialog to leave it.',
            null, true, function () {
              App.goTo(App.currentSession.base);
            });
    return undefined;
  }

  /* this message appears if you try to open a repo that needs upgrading */
  if (message.match(/could not be opened because/) &&
      extra.status >= HttpStatus.HTTP_ERROR_MIN) {
    Layout.showMessage(message, null, true, function () {
      /* When the message is dismissed
       * direct browser to the main Webview page
       * (for the current catalog if any).
       */
      App.goTo(App.isInCatalog() ? '/catalogs/' + App.currentCatalog :'');
    });
    return undefined;
  }

  var LENGTH_LIMIT = 400;
  var ident = (typeof handlerOrStr === 'string') ? handlerOrStr : 'Operation';
  Base.failMessage(ident, Base.truncate(message, LENGTH_LIMIT));
  return undefined;
};

(function () {
  var loadingCount = 0;
  var loadingText = null;
  /**
   *  Call to inform the app that a transfer is in progress.
   *
   * @param {string} [text] - Message to be displayed next to the spinner.
   * @param {boolean} [quiet] - if true do not show the spinner at all.
   */
  Base._startLoading = function (text, quiet) {
    loadingCount++;
    if (!quiet) {
      if (text && !loadingText) {
        loadingText = _.escape(text) + '...';
        $('#loading-text').html(loadingText);
      }
      $$('loading').style.display = 'block';
    }
  };
  Base._stopLoading = function () {
    loadingCount--;
    if (loadingCount === 0) {
      if (loadingText) {
        loadingText = '';
        $('#loading-text').html(loadingText);
      }
      $$('loading').style.display = 'none';
    }
  };
})();

/**
 * Sets up a loading message in the window with given text
 * (description of the operation taking place).
 * Also prepares a callback to clear the loading message when the async
 * finishes.
 *
 * If wait function is given, it is called on success of the async,
 * and on failure, an error message will be shown that includes the name
 * of the operation (text) -- also see window.asyncFail in this file.
 *
 * If no wait function is given, async is returned instead so that someone
 * else can call "wait" on it.
 *
 * @param {Async} async - operation during which a loading indicator is shown
 * @param {string} text - description of the operation taking place; used
 *   in loading indicator and in failure message
 * @param {Function} [wait] - the function to call on success of the async
 * @param {boolean} [quiet] - if true do not display the spinner.
 * @return {Async|undefined} - nothing if "wait" is passed; async otherwise.
 */
Base.load = function (async, text, wait, quiet) {
  Base._startLoading(text, quiet);
  // Call Base._stopLoading when the async has finished (either w/ ok or fail)
  async.always(Base._stopLoading.bind(Base));
  if (wait) {
    async.wait(wait, text);
    return undefined;
  } else {
    return async;
  }
};

/**
 * Same as Base.load without the `wait` parameter, but instead of propagating
 * the failure in the resulting Async, it handles it in the default way
 * (by displaying an error message).
 *
 * @param {Async} async - operation during which a loading indicator is shown
 * @param {string} text - description of the operation taking place; used
 *   in loading indicator and in failure message
 * @return {Async} - will resolve to a success if Base.load succeeds.
 *   NOTE: Will NOT resolve AT ALL if Base.load fails. Instead, an error
 *   message will be shown to the user.
 */
Base.loadWithDefaultErrorHandler = function (async, text) {
  var newAsync = new Async();
  Base.load(async, text, function (result) {
    newAsync.ok(result);
  });
  return newAsync;
};

// Maps abbreviations to names. Used in several of the templates.
Base.FULL_NAME = {
  'subj': 'subject',
  'obj': 'object',
  'pred': 'predicate',
  'context': 'context',
  's': 'subject',
  'p': 'predicate',
  'o': 'object',
  'g': 'graph',
  'i': 'id',
};

Base.capitalise = function (string) {
  return /^\w/.test(string) ?
    string.charAt(0).toUpperCase() + string.slice(1) :
    string;
};

// Used to insert extra results when clicking the 'more' links in
// result tables.
Base.addingTBodyBefore = function (node, continuation) {
  var div = document.createElement('DIV');
  continuation(div);
  // Extract a TBODY from the rendered template:
  var $tbody = $(div).find('tbody').first();
  // Find a TBODY that is a previous sibling of node:
  var $closestTBodySibling = $(node).prevAll('tbody').first();
  // Append all children of rendered tbody to closestTbodySibling:
  $closestTBodySibling.append($tbody.children());
};

/**
 * Describes a way of scaling and presenting data in a chart.
 * @typedef {object} ChartScale
 * @property {string} suffix - Unit of measure (e.g. MiB).
 * @property {number} divisor - scaling divisor.
 */

/**
 * Determine the human-readable scaling for a byte count.
 * The returned object contains a string scaling
 * suffix, e.g. 'MiB', and a power of 2 scaling divisor to
 * produce those units.
 *
 * @param {number} num - byte count (non-negative integer)
 * @return {ChartScale} - An object containing the scaling divisor and suffix.
 */
Base.scaleChartRange = function (num) {
  var suffixes = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB'];

  // special case...
  if (num === 1) {
    return {
      suffix: 'byte',
      divisor: 1,
    };
  }

  for (var idx = 0; idx < suffixes.length; idx++) {
    var divisor = Math.pow(2, 10 * idx);
    if (num < (1024 * divisor)) {
      return { suffix: suffixes[idx], divisor: divisor };
    }
  }
  return {
    suffix: suffixes[suffixes.length - 1],
    divisor: Math.pow(2, ((suffixes.length - 1) * 10)),
  };
};

/**
 * Convert a number to a string representing human-readable byte count
 * appropriate for a flot axis tick.
 *
 * @param {number} val - byte count (not necessarily an integer)
 * @param {ChartScale} scale - Scaling description  returned by scaleChartRange
 * @param {number} tickDecimals - number of digits after decimal point
 * @return {string} - human-readable representation of num
 */
Base.printChartTick = function (val, scale, tickDecimals) {
  if (tickDecimals === undefined) {
    tickDecimals = 1;
  }
  return ((val / scale.divisor).toFixed(tickDecimals) + ' ' + scale.suffix);
};

/**
 * Convert a number to a string representing human-readable byte count
 * (e.g. of a file).
 *
 * @param {number} num - byte count (non-negative integer)
 * @return {string} - human-readable representation of num
 */
Base.printBytes = function (num) {
  var suffixes = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB'];

  // special case...
  if (num === 1) {
    return '1 byte';
  }

  var idx;
  var divisor;
  for (idx = 0; idx < suffixes.length - 1; idx++) {
    divisor = Math.pow(2, 10 * idx);
    if (num < (10 * 1024 * divisor)) {
      break;
    }
  }
  divisor = Math.pow(2, 10 * idx);
  return Math.floor(num / divisor) + ' ' + suffixes[idx];
};

Base.bigNumber = function (num) {
  var GROUP_SIZE = 3;
  num = String(num);
  var pieces = [];
  while (num.length > GROUP_SIZE) {
    var end = num.length - GROUP_SIZE;
    pieces.unshift(num.slice(end));
    num = num.slice(0, end);
  }
  pieces.unshift(num);
  return pieces.join(',');
};

Base.textToHTML = function (str) {
  return _.escape(str).replace(/\n\r?/g, '<br/>');
};

Base.truncate = function (string, length) {
  if (string.length > length) {
    return string.slice(0, length) + '...';
  } else {
    return string;
  }
};

Base.radioValue = function (nodes) {
  for (var i = 0; i < nodes.length; i++) {
    if (nodes[i].checked) {
      return nodes[i].value;
    }
  }
  return undefined;
};

Base.multSelectValues = function (node) {
  var result = [];
  Util.forEach(node.options, function (opt) {
    if (opt.selected) {
      result.push(opt.value);
    }
  });
  return result;
};

Base.multSelectChangeAll = function (node, selected) {
  Util.forEach(node.options, function (opt) {
    opt.selected = selected;
  });
};

/**
 * Repeatedly updates a given WebView page.
 *
 * @param {Object} parameters - parameters for the updated view
 * @param {Function} parameters.requestFunction - a function with no arguments,
 *   returning the data for the page. Will be called repeatedly.
 * @param {Function} parameters.castFunction - a function that takes one
 *   parameter (the result of requestFunction) that actually renders (for the
 *   first time) or refreshes (all subsequent times) the view
 * @param {string} parameters.testElementSelector - the jQuery selector looking
 *   for an element on the page. The reference to this element is saved at the
 *   start. Then, before each refresh, we're searching for that element again.
 *   If the element is not found, refreshing stops.
 * @param {number} [parameters.refreshRate] - how often the page should be
 *   refreshed, in milliseconds; the default is 5000.
 */
Base.showPaneWithRefreshes = function (parameters) {
  var DEFAULT_REFRESH_RATE = 5000; // ms
  parameters = parameters || {};
  var refreshRate = parameters.refreshRate || DEFAULT_REFRESH_RATE;
  // called to do the update
  var castFunction = parameters.castFunction;
  // the element used to test whether the refreshing should continue
  var testElementSelector = parameters.testElementSelector;
  var testElement;
  // the function used to make the request
  var requestFunction = parameters.requestFunction;

  Base.load(requestFunction(), 'Loading', function (data) {
    castAndStartTimer(data);

    function castAndStartTimer(data) {
      castFunction(data);
      testElement = $(testElementSelector)[0];
      window.setTimeout(refreshPage, refreshRate);
    }

    function continueRefreshing() {
      var currentElement = $(testElementSelector)[0];
      return (currentElement === testElement) && currentElement;
    }

    function refreshPage() {
      if (continueRefreshing()) {
        Base.load(requestFunction(), 'Refresh', function (data) {
          castAndStartTimer(data);
        });
      }
    }
  });
};

/**
 * A function that will be called for each request
 * that does not carry the 'noTouch' flag.
 * This is set in the 'User' module.
 * TODO: make this a proper event.
 */
Base.touch = null;

/**
 *  httpRequest invokes an asynchronous HTTP request which
 *  will call the the success or failure callback when ready.
 *
 * @param {string} url - request target.
 * @param {Object} args - may be null, or an object w/ the following
 *                        (all optional) properties:
 *
 *   query: a map of query names and values
 *
 *   method: an HTTP method string (upcase).  Defaults to "GET"
 *
 *   accept: String to use in HTTP Accept: header
 *
 *   contentType: String to use in the HTTP Content-Type: header.
 *
 *   headers: a map of additional header name/values
 *
 *   body: a map of query name/values to be passed in the HTTP request body.
 *
 *   form: a FormData object to be used as the request body.
 *
 *   noTouch: If truthy, do not update the last access timestamp
 *            (i.e. do not consider this request when checking for login timeouts).
 *            This should be set for requests that are run periodically
 *            and are not caused by user interaction - such as polling
 *            for server warnings, jobs or processes.
 *
 *   handlers: a map of event handlers. Supported keys:
 *            * onUploadProgress - registers provided function as event handler for
 *                                 upload.progress
 *            * onProgress - registers provided function as event handler for
 *                                 (download) progress
 *
 *   abort: an Async. After sending the request, httpRequest will .wait() on this
 *          async if present. If the async is resolved with an .ok() response the
 *          request will be cancelled via xhr.abort().
 *          Once the request completes, The async will be resolved with a .fail()
 *          call.
 *
 *
 * If both body and form are present and truthy, body is used.
 *
 * @param {function} success - must be a function which will be called if the HTTP
 *   request is successful (200 or 204 response code).
 *   The function will be called with the response text and the xhr object.
 *
 * @param {function} failure - must be a function which will be called if the HTTP
 *   request fails.  The function will be called with:
 *    1) an error string
 *     and
 *    2) Either the xdr object
 *        or
 *       An object containing the following properties:
 *         status: The HTTP status code
 *         url: The request URL
 *         method: the request method
 *
 * This function returns nothing of interest.
 */
Base.httpRequest = function (url, args, success, failure) {
  var xhr = new XMLHttpRequest();
  args = args || {};
  if (args.query) {
    var query = Util.queryString(args.query);
    if (query.length) {
      url += '?' + query;
    }
  }

  // Prepare an asynchronous XHR
  xhr.open(args.method || 'GET', url, true);
  if (args.accept) {
    xhr.setRequestHeader('Accept', args.accept);
  }
  if (args.hasOwnProperty('contentType')) {
    xhr.setRequestHeader('Content-Type', args.contentType);
  }
  if (args.headers) {
    Util.forEachIn(args.headers, function (name, val) {
      xhr.setRequestHeader(name, val);
    });
  }
  if (args.handlers) {
    if (args.handlers.onUploadProgress) {
      xhr.upload.addEventListener('progress', args.handlers.onUploadProgress);
    }
    if (args.handlers.onProgress) {
      xhr.addEventListener('progress', args.handlers.onProgress);
    }
  }


  xhr.onreadystatechange = function () {
    /*
     Once the HTTP response content has finished loading (i.e, has
     been received), the readyState property of the XMLHttpRequest
     object should be assigned a value of 4 (DONE).
    */
    if (xhr.readyState === XMLHttpRequest.DONE) {
      var ok = null;
      if (args.abort && xhr.status !== 0) {
        // Request completed and was not due to an abort. Stop waiting
        // for a cancel request to arrive.
        args.abort.fail('Cancelling abort handling. Request has completed');
      }

      try {
        ok = (xhr.status === HttpStatus.HTTP_OK ||
            xhr.status === HttpStatus.HTTP_NO_CONTENT);
      } catch (e) {
        // Call the failure callback.
        failure(
            (e && typeof(e) === 'object' && e.message) || String(e), xhr);
      }

      if (ok === true) {
        // Update the last activity timestamp.
        if (!args.noTouch && Util.touch) {
          Util.touch();
        }
        // Call the success callback
        success(xhr.responseText, xhr);
      } else if (ok === false) {
        var text = 'No response';
        try {
          text = xhr.responseText;
        } catch (e) {
          // Ignore
        }
        if (/<html/i.test(text)) {
          try {
            text = xhr.statusText;
          } catch (e) {
            // Ignore
          }
        }
        failure(text, {
          status: xhr.status,
          url: url,
          method: args.method || 'GET',
        });
      }
      Testability.endOperation();
    }
  };
  if (typeof args.body === 'object') {
    args.body = Util.queryString(args.body);
  }

  Testability.startOperation();

  xhr.send(args.body || args.form || null);

  if (args.abort) {
    // We need to pass an explicit error handler - otherwise
    // the default one will be used, resulting in an error
    // being displayed on the screen.
    args.abort.wait(xhr.abort.bind(xhr), function () {});
  }
};

export default Base;
// export default Base;
