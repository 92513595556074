import $ from 'jquery';
import Dispatch from '../dispatch';
import User from '../user';
import Catalog from '../catalog';
import App from '../app';
import Util from '../util';
import Base from '../base';
import setImmediate from '../set-immediate';
import gruffIcon from '../../img/gruff-logo-50x50.png';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n\n<h2>Catalogs and Repositories</h2>\n");
if ($arg.catalogs.length) {
__out.push("\n  <div class=\"layout-row\">\n    <div class=\"layout-list-column\">\n      <div id=\"catalogList\" class=\"section\">\n        <h3>Catalogs</h3>\n        <div>\n          ");
Mold.forDepth++;
Mold.forEach($arg.catalogs, function(cat, $i) {
__out.push("\n          <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.rootUrl(cat.id) ));
__out.push("\">\n            <div class=\"");
__out.push(Mold.escapeHTML((cat.id === App.currentCatalog) ? 'currentCatalogName' : 'catalogName' ));
__out.push("\">\n              ");
__out.push(Mold.escapeHTML(cat.id));
__out.push("\n            </div>\n          </a>\n          ");
});
Mold.forDepth--;
__out.push("\n        </div>\n        <div class=\"catalogListPad\"></div>\n      </div>\n    </div>\n    <div class=\"layout-body-column currentCatalogContents\">\n      <div id=\"catalogRepositories\" class=\"section\">\n        <h3>Repositories</h3>\n        ");
Mold.forDepth++;
Mold.forEach($arg.repositories, function(repo, $i) {
__out.push("\n          <div class=\"repoName\">\n            ");
if (User.userAccess("w")) {
__out.push("\n              <span class=\"repoControl\">\n                <button class=\"small-delete-button\"><i class='fa fa-times-circle' title=\"delete ");
__out.push(Mold.escapeHTML(repo.id));
__out.push("\"></i></button>\n                ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Catalog.deleteRepository(repo.id);
});}) + "\"></var>");
__out.push("\n               </span>\n            ");
}
__out.push("\n            <span>\n            ");
if (repo.readable) {
__out.push("\n              <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.rootUrl(App.currentCatalog, repo.id) ));
__out.push("\">");
__out.push(Mold.escapeHTML(repo.title ));
__out.push("</a>\n              ");
if (repo.distributed) {
__out.push("\n                <span class=\"subtle\" data-toggle=\"tooltip\" title=\"Distributed Triple Store\">DTS</span>\n              ");
} else if (repo.replication === "controlling" || repo.replication === "yes") {
__out.push("\n                <span class=\"subtle\" data-toggle=\"tooltip\" title=\"Multi-Master Replication\">MMR</span>\n              ");
}
__out.push("\n            ");
} else {
__out.push("\n              ");
__out.push(Mold.escapeHTML(repo.id));
__out.push(" <span class=\"subtle\">(no access)</span>\n            ");
}
__out.push("\n            </span>\n          </div>\n        ");
});
Mold.forDepth--;
__out.push("\n        ");
if (!$arg.repositories.length) {
__out.push("<p>None.</p>");
}
__out.push("\n      </div>\n      ");
if ($arg.stopped.length && User.userPerm("super")) {
__out.push("\n      <div id=\"stoppedRepositories\" class=\"section\">\n      <h3>Stopped Replication Repositories ");
Mold.dispatchCustom("help", " container=stoppedRepositoriesHelp\n            Click on a repository name to restart it.\n            Click on the small x to delete the repository.\n            You should remove a repository from the cluster before deleting it.", __out);
__out.push("</h3>\n         <div id=\"stoppedRepositoriesHelp\"/>\n         ");
Mold.forDepth++;
Mold.forEach($arg.stopped, function(repo, $i) {
__out.push("\n          <div class=\"repoName\">\n              <span class=\"repoControl\">\n                <button class=\"small-delete-button\"><i class='fa fa-times-circle' title=\"delete ");
__out.push(Mold.escapeHTML(repo.id));
__out.push("\"></i></button>\n                ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Catalog.deleteRepository(repo.id);
});}) + "\"></var>");
__out.push("\n               </span>\n              <span class=\"linkish\">");
__out.push(Mold.escapeHTML(repo.title));
__out.push("</span>\n              ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Catalog.startRepository(repo.id);
});}) + "\"></var>");
__out.push("\n          </div>\n         ");
});
Mold.forDepth--;
__out.push("\n      </div>\n      ");
}
__out.push("\n\n      <div id=\"create\" class=\"section repositoriesPad\">\n      ");
if (User.userAccess("w")) {
__out.push("\n        <h3>Create new repository</h3>\n        <form class=\"small-form\">\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
            var name = $node.elements.name;
            Catalog.createRepository(name.value);
            name.value = ""; 
});}) + "\"></var>");
__out.push("\n            <div>\n              <label for=\"name\">Name:</label>\n              <input type=\"text\" name=\"name\" id=\"name\" linkedButton=\"createStoreButton\"/>\n      \t");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "keyup", function($event, $node) {
Util.maybeEnableLinkedButton($node); 
});}) + "\"></var>");
__out.push("\n              <button id=\"createStoreButton\" type=\"submit\">Create</button>\n            </div>\n            ");
if (User.userPerm("super")) {
__out.push("\n              <div>\n                <span class=\"linkish\">Restore from a backup</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Catalog.restoreBackupDialog();
});}) + "\"></var>");
__out.push("\n              </div>\n            ");
}
__out.push("\n        </form>\n      ");
}
__out.push("\n      </div>\n    </div>\n  </div>\n");
} else {
__out.push("\n  No accessible catalogs available.\n");
}
__out.push("\n\n<h2>Visualization</h2>\n<div id=\"gruff\" class=\"section\">\n  <div id=\"graph-visualization-block\">\n    <div id=\"gruff-anchor-div\">\n      <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('gruff') ));
__out.push("\">\n        <img id=\"gruff-button\" src=\"");
__out.push(Mold.escapeHTML(gruffIcon ));
__out.push("\"/>\n      </a>\n    </div>\n    <div id=\"gruff-column\">\n      <div id=\"start-gruff-text\"><b>Start Gruff</b></div>\n      <div id=\"gruff-highlights-list\">\n\t<ul>\n\t  <li>Visualization, Exploration</li>\n\t  <li>Query Builder, and more...</li>\n\t</ul>\n      </div>\n    </div>\n  </div>\n</div>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  var nameInput = $('#name');
  // The regexp specifies characters disallowed in repo names.
  nameInput.filter_input({regex:'[^\\\\ ~$(){}<>.*+|/\\[\\]&]'});
  // If user does not have access to current catalog, input element
  // #name will not present, so we verify that it is there before
  // calling maybeEnableLinkedButton function.
  if (nameInput[0]) {
    Util.maybeEnableLinkedButton(nameInput[0]);
  }
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;