import User from '../../user';
import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Users</h2>\n\n");
Mold.forDepth++;
Mold.forEach($arg.users, function(name, $i) {
__out.push("\n  <div>\n    <h3>");
__out.push(Mold.escapeHTML(name));
__out.push("\n      <span class=\"subtle linkish\">[remove]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.deleteUser(name);
});}) + "\"></var>");
__out.push("\n      ");
if (name !== 'anonymous') {
__out.push("\n        <span class=\"subtle linkish\">[change password]</span>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.changeUserPassword(name);
});}) + "\"></var>");
__out.push("\n      ");
}
__out.push("\n      <span class=\"subtle linkish\">[edit]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.expandUser(name, $arg.roles, $node);
});}) + "\"></var>");
__out.push("\n    </h3>\n  </div>\n");
});
Mold.forDepth--;
__out.push("\n\n");
if (!Util.member('anonymous', $arg.users)) {
__out.push("\n  <p>Define a user named 'anonymous', and give it some permissions, to\n  allow access without logging in.</p>\n");
}
__out.push("\n\n<p style=\"margin-top: 1em\">\n  <span class=\"linkish\">[add a user]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.newUserDialog();
});}) + "\"></var>");
__out.push("\n</p>\n\n<h2>Roles</h2>\n\n");
Mold.forDepth++;
Mold.forEach($arg.roles, function(name, $i) {
__out.push("\n  <div>\n    <h3>");
__out.push(Mold.escapeHTML(name));
__out.push("\n      <span class=\"subtle linkish\">[remove]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.deleteRole(name);
});}) + "\"></var>");
__out.push("\n      <span class=\"subtle linkish\">[edit]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.expandRole(name, $node);
});}) + "\"></var>");
__out.push("\n    </h3>\n  </div>\n");
});
Mold.forDepth--;
__out.push("\n\n");
if (!$arg.roles.length) {
__out.push("\n  <p>No roles defined.</p>\n");
}
__out.push("\n\n<p>\n  <span class=\"linkish\">[add a role]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.newRoleDialog();
});}) + "\"></var>");
__out.push("\n</p>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;