import App from './app';
import Util from './util';


// Code that runs on boot-up of the application.
// It should NOT be a part of unit-tests.

export default function () {
  // Load variables from local storage:
  App.useMJQE = Util.getFromStorage('useMJQE') === 't';
  App.showContexts = Util.getFromStorage('showContexts') === 't';
  App.showLongParts = Util.getFromStorage('showLongParts') === 't';
  App.cancelOnWarnings = Util.getFromStorage('cancelOnWarnings') === 't';

  Util.connect(window, 'load', function () {
    App.initialise();
  });
}
