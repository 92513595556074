import $ from 'jquery';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n\n<h2>Triple Attribute Settings</h2>\n<div>\n<h2>Attribute Definitions</h2>\n<div id=\"attributeDefinitions\" class=\"attributeDefinitions\">\n  <div class='buttonRow'>\n    <button id=\"buttonAddNewAttribute\" class=\"button-small pure-button\">\n      <i class=\"fa fa-plus\"></i> Add New\n    </button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.showNewAttributeForm(); 
});}) + "\"></var>");
__out.push("\n  </div>\n  <table class=\"pure-table pure-table-striped\">\n    <thead>\n      <tr>\n        <th>Name</th>\n        <th>Min. number</th>\n        <th>Max. number</th>\n        <th>Allowed values</th>\n        <th>Ordered</th>\n        <th></th>\n      </tr>\n    </thead>\n    <tbody>\n        ");
Mold.forDepth++;
Mold.forEach($arg.getDefinitions(), function(definition, $i) {
__out.push("\n        <tr>\n          <td class=\"attribute-name\">");
__out.push(Mold.escapeHTML(definition.name));
__out.push("</td>\n          <td>\n            ");
if (definition["minimum-number"] ) {
__out.push("\n              ");
__out.push(Mold.escapeHTML(definition["minimum-number"] ));
__out.push("\n            ");
}
__out.push("\n          </td>\n          <td>\n            ");
if (definition["maximum-number"] ) {
__out.push("\n              ");
if (definition["maximum-number"] > Number.MAX_SAFE_INTEGER ) {
__out.push("\n                &infin;\n              ");
} else {
__out.push("\n                ");
__out.push(Mold.escapeHTML(definition["maximum-number"] ));
__out.push("\n              ");
}
__out.push("\n            ");
}
__out.push("\n          </td>\n          <td>\n            ");
if (definition["allowed-values"] ) {
__out.push("\n              ");
__out.push(Mold.escapeHTML(definition["allowed-values"] ));
__out.push("\n            ");
}
__out.push("\n          </td>\n          <td>\n          ");
if (definition.ordered ) {
__out.push("<i class=\"fa fa-check\"></i>");
}
__out.push("\n          </td>\n          <td style=\"text-align: center\">\n            <button class=\"small-delete-button\">\n              <i class='fa fa-times-circle' title=\"delete ");
__out.push(Mold.escapeHTML(definition.name));
__out.push("\"></i>\n            </button>\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.deleteAttributeDefinition( $i );
});}) + "\"></var>");
__out.push("\n          </td>\n        </tr>\n        ");
});
Mold.forDepth--;
__out.push("\n    </tbody>\n  </table>\n  ");
if ($arg.getDefinitions().length === 0 ) {
__out.push("<div class=\"no-attributes\">No attributes have been defined</div>");
}
__out.push("\n</div>\n<div id=\"new-attribute-dialog\" title=\"New Attribute\" style=\"display: none\"></div>\n\n<div id='staticFilterDialog'>\n  <h2>Static Filter <span class='notes' id='staticFilterModeLine'></span>\n    ");
Mold.dispatchCustom("help", "container=staticFilterHelp For details on valid static filter syntax, see the \
<a target='_blank' class='linkish' href='https://franz.com/agraph/support/documentation/current/triple-attributes.html#static-filters'> \
AllegroGraph static filter documention.</a>", __out);
__out.push("\n    <span id='staticFilterHelp' style='font-size: 80%; font-weight: normal'></span>\n  </h2>\n    <div class='staticFilterEditor' id='staticFilterCurrent'>\n      <textarea rows=\"4\" cols=\"40\"\n                placeholder=\"No filter defined\"></textarea>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.setupEditor($node);}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.maybeEnableEditor();
});}) + "\"></var>");
__out.push("\n    </div>\n\n    <button id=\"sf_buttonCancel\" disabled=\"true\">Cancel Edit</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancelFilterEdit();
});}) + "\"></var>");
__out.push("\n    <button id=\"sf_buttonSave\" disabled=\"true\">Save</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.applyEditFilter();
});}) + "\"></var>");
__out.push("\n    <button id=\"sf_buttonDelete\" disabled=\"true\">Delete Filter</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.applyDeleteFilter();
});}) + "\"></var>");
__out.push("\n</div>\n</div>");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;