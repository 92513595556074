import tablerowsTemplate from './tablerows.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<table class=\"node-table\" style=\"vertical-align:top\">\n  <thead>\n    <tr>\n      ");
Mold.forDepth++;
Mold.forEach($arg.getColumnNames(), function(name, $i) {
__out.push("\n        <th><div>");
__out.push(Mold.escapeHTML(name));
__out.push("</div></th>\n      ");
});
Mold.forDepth--;
__out.push("\n    </tr>\n  </thead>\n  <tbody>\n    ");
__out.push(tablerowsTemplate.template($arg.getVisibleValues()));
__out.push("\n    ");
if (!$arg.getVisibleLength()) {
__out.push("\n      <tr><td colspan=\"0\">No results</td></tr>\n    ");
}
__out.push("\n  </tbody>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;