import Dispatch from './dispatch';
import CodeMirror from 'codemirror';
import Clipboard from 'clipboard';
import Layout from './layout';
import Base from './base';
import { $$ } from './util';
import $ from 'jquery';
import CrossTab from './crosstab';
import Util from './util';
import User from './user';
import Query from './query';
import Catalog from './catalog';
import Part from './part';
import { Async } from './async';
import { defineMoldInstructions } from './instructions';
import bodyTemplate from './templates/body.mold';
import headerLinksTemplate from './templates/headerLinks.mold';
import headerInfoTemplate from './templates/headerInfo.mold';
import _ from 'underscore';


/** @namespace App */
var App = {};

// Initialising the application, keeping some global state.
App.agraphVersion = undefined;

// Used by components that have some state they want to preserve
// across refreshes.
App.customRefresh = null;

App.initialised = false;

App.refresh = function (forceFullRefresh) {
  // Don't refresh before we finish initialising.
  if (App.initialised) {
    if (App.customRefresh && !forceFullRefresh) {
      App.customRefresh();
    } else {
      Dispatch.dispatch(
        window.decodeURIComponent(window.location.hash));
    }
    App.refreshHeader();
  }
};

App.refreshIfSamePage = function () {
  var page = window.location.hash;
  return function () {
    if (window.location.hash === page) {
      App.refresh();
    }
  };
};

App.goTo = function (hash) {
  if (window.location.hash !== hash) {
    // Dispatch.dispatch(hash);
    window.location.hash = hash;
  }
};

// Like goTo, but does not trigger a dispatch.
App.updateHash = function (hash) {
  // this will drop the query string...
  history.replaceState(null, null, document.location.pathname + hash);
};

// Initializes CodeMirror editor from passed textArea.
//
// `options` is a hash of additional settings that is just passed to CodeMirror.
//
// We support one additional custom key in `options`: "resizeHandleId".
// If it's passed it should be an `id` of an html element. That element will
// be set up to act as a handler for vertically resizing editor.
App.codeMirrorFromTextArea = function (textArea, options) {
  var editor;
  var resizeHandleId;
  if (!options) {
    options = {};
  }
  if ((typeof options) === 'string') {
    options = { mode: options };
  }
  if (options.hasOwnProperty('resizeHandleId')) {
    resizeHandleId = options.resizeHandleId;
    delete options.resizeHandleId;
  }
  options.matchBrackets = true;
  options.lineNumbers = true;
  if (options.mode === 'application/sparql-query') {
    options.highlightSelectionMatches = {
      showToken: /[?\w]/,
      annotateScrollbar: true,
    };
  }
  options.extraKeys = {
    'Alt-Left': CodeMirror.Pass,
    'Alt-Right': CodeMirror.Pass,
  };
  editor = CodeMirror.fromTextArea(textArea, options);
  if (resizeHandleId) {
    setupResizeCodeMirror(textArea, editor, resizeHandleId);
  }
  return editor;
};

function setupResizeCodeMirror(textArea, editor, resizeHandleId) {
  var MINIMAL_HEIGHT = 300; // px
  var startY;
  var startH;
  var onDrag = function (e) {
    editor.setSize(null, Math.max(MINIMAL_HEIGHT, startH + e.clientY - startY) + 'px');
  };
  var onRelease = function () {
    startH = startY = 0;
    $(window).off('mousemove', onDrag);
    $(window).off('mouseup', onRelease);
  };
  $('#' + resizeHandleId).on('mousedown', function (e) {
    startY = e.clientY;
    startH = $(textArea).siblings('.CodeMirror').outerHeight();
    $(window).on('mousemove', onDrag);
    $(window).on('mouseup', onRelease);
  });
}

/** Initialise the clipboard.js library. */
App._initClipboard = function () {
  var clipboard = new Clipboard('.copy-trigger');
  clipboard.on('success', function (e) {
    Layout.showMessage('Copied: ' + e.text, Layout.SHORT_DELAY, false);
  });
  clipboard.on('error', function () {
    Layout.showMessage('Unable to access the clipboard',
        Layout.LONG_DELAY, false);
  });
};

/**
 * An observable that can be used to detect when
 * the current location (meaning repository, catalog
 * or session) has changed. Will also be triggerd once
 * during the initialization.
 */
App.repoChange = new CrossTab.Observable();

function adjustRootUrl() {
  // First check if anything has been injected into the HTML template.
  var root = $('#content').data('root-url') || Base.rootUrl;
  root = Util.String.stripTrailingSlash(root);
  if (root === '.') {
    root = '';
  }
  // Now convert to a full URL and parse
  Base.rootLocation = document.createElement('a');
  Base.rootLocation.href = root;
  Base.rootUrl = Util.String.stripTrailingSlash(Base.rootLocation.href.split(/[?]/)[0]);
}

App.initialise = function () {
  // Arcane initialisation-process.
  if (App.initialised) {
    throw new Error('App already initialised!');
  }

  // Adjust the server URL based on data injected by AG
  adjustRootUrl();

  defineMoldInstructions();
  bodyTemplate.cast($$('content'));
  Layout.initLayout($$('messages'), $$('page'));

  // Initialize the crosstab communication system.
  // Note that constructors invoked here have side effects
  // (they start various event listeners).
  App.events = new CrossTab.Events();
  App.crosstabVars = new CrossTab.Vars(
    new CrossTab.Heartbeat(), App.events, Util
  );

  // Ugly hack - we need to know the repository to
  // initialize the user, but we need the user
  // to run Dispatch.dispatch...
  Dispatch._extractRepoAndSession(
    window.decodeURIComponent(window.location.hash));

  // Initialize event handlers and timeouts
  User.init();

  // Make sure version information is loaded before user handling happens,
  // so explicitly use no authorization (null) to ensure it succeeds even
  // in the cases when authentication data is expired/stale.
  Base.req('GET', '/version', {}, null).wait(function (version) {
    App.agraphVersion = version;
    $('#agversion').html(_.escape(version));

    User.initUser(function () {
      User.onUserChange(App.refresh.bind(App, true), true);
      App.repoChange.addObserver(App.refreshHeader.bind(App));
      App.repoChange.addObserver(App._startSessionPing.bind(App));
      Query.queryHistory.init();
      // Parse the URL
      // We must do this after initializing the user
      // to avoid displaying a login dialog unnecessarily.
      // Query history must also be initialized before this.
      Dispatch.dispatch(window.decodeURIComponent(window.location.hash))
          .then(App.refreshHeader.bind(App));

      // Refresh the header after location or user changes.
      App.initialised = true;
    });
  });

  Catalog.startPeriodicallyUpdatingWarnings();
  App._initClipboard();

  window.onhashchange = function () {
    Dispatch.dispatch(window.decodeURIComponent(window.location.hash));
  };
};

App.refreshHeader = function () {
  var bl = null;
  var blText = null;

  if (App.currentSession) {
    bl = App.currentSession.base;
    blText = 'Leave session';
  } else if (App.currentRepository && App.isInCatalog()) {
    bl = Dispatch.rootUrl(App.currentCatalog);
    blText = 'To catalog ' + App.currentCatalog;
  } else if (App.currentRepository || App.isInCatalog()) {
    bl = Dispatch.rootUrl();
    blText = 'Back to root catalog';
  }

  if (User.userPerm('super')) {
    Base.req('GET', '/configfiles').wait(function (data) {
      headerLinksTemplate.cast('header-links', {
        backLink: bl,
        backLinkText: blText,
        version: App.agraphVersion,
        configfiles: JSON.parse(data),
      });
    });
  } else {
    headerLinksTemplate.cast('header-links', {
      backLink: bl,
      backLinkText: blText,
      version: App.agraphVersion,
    });
  }
  headerInfoTemplate.cast('header-info');
};

// Various query options
// These values will be filled properly in boot.js.
App.useMJQE = false;
App.showContexts = false;
App.showLongParts = false;
App.cancelOnWarnings = true;

App.toggleMJQE = function (state) {
  state = Boolean(state);
  if (state !== App.useMJQE) {
    App.useMJQE = state;
    if (App.useMJQE && !Query.queryOptionSet('engine', 'mjqe')) {
      Query.injectQueryOption('engine', 'mjqe');
    } else if (!App.useMJQE && Query.queryOptionSet('engine', 'mjqe')) {
      Query.removeQueryOption('engine', 'mjqe');
    }
    Util.setInStorage('useMJQE', App.useMJQE ? 't' : null);
  }
};

App.toggleContexts = function (state) {
  state = Boolean(state);
  if (state !== App.showContexts) {
    App.showContexts = state;
    Util.setInStorage('showContexts', App.showContexts ? 't' : null);
  }
};

App.toggleLongParts = function (state) {
  state = Boolean(state);
  if (state !== App.showLongParts) {
    App.showLongParts = state;
    Util.setInStorage('showLongParts', App.showLongParts ? 't' : null);
    Part.clearPartCache();
  }
};

App.toggleCancelOnWarnings = function (state) {
  state = Boolean(state);
  if (state !== App.cancelOnWarnings) {
    App.cancelOnWarnings = state;
    Util.setInStorage('cancelOnWarnings', App.cancelOnWarnings ? 't' : null);
  }
};

App.useReasoning = false;

App.toggleReasoning = function (state) {
  if (state !== App.useReasoning) {
    App.useReasoning = state;
    App.refresh();
  }
};

// Get all query options as an object.
App.getQueryOptions = function () {
  let options = {
    useMJQE: App.useMJQE,
    showLongParts: App.showLongParts,
    cancelOnWarnings: App.cancelOnWarnings,
    useReasoning: App.useReasoning,
  };

  return options;
};

// Batch set query options from an object.
App.setQueryOptions = function (options) {
  if (options.hasOwnProperty('useMJQE')) {
    App.toggleMJQE(options.useMJQE);
  }
  if (options.hasOwnProperty('showLongParts')) {
    App.toggleLongParts(options.showLongParts);
  }
  if (options.hasOwnProperty('cancelOnWarnings')) {
    App.toggleCancelOnWarnings(options.cancelOnWarnings);
  }
  if (options.hasOwnProperty('useReasoning')) {
    App.toggleReasoning(options.useReasoning);
  }
};

// Session

// setInterval handle for the session pinger
App._sessionPinger = null;

App._startSessionPing = function () {
  // In seconds (note: recent AG versions don't need a default here)
  var DEFAULT_SESSION_LIFETIME = 120;
  // Cancel existing pinger
  if (App._sessionPinger) {
    clearInterval(App._sessionPinger);
    App._sessionPinger = null;
  }
  // Start pinging if we have a session
  if (App.currentSession) {
    // Idle timeout in seconds.
    // The default value is provided since AG used to not report that value.
    var lifetime = App.currentSession.lifetime || DEFAULT_SESSION_LIFETIME;
    // Pinging interval in ms ( = half of the idle timeout)
    var interval = lifetime * 500;
    App._sessionPinger = setInterval(function () {
      Base.ignore(
          // noTouch = Make sure the request is not counted as user activity
          Base.req('GET', 'session/ping', { noTouch: true }));
    }, interval);
  }
};

App.closeSession = function () {
  Base.load(Base.req('POST', 'session/close'), 'Closing session', function () {
    App.goTo(App.currentSession.base);
  });
};

App.convertSessionUrl = function (url) {
  if (url.search('/session/') === -1) {
    return '/session/' + url.match(/.*:(.+)$/)[1];
  } else {
    return url.match(/.*(\/session\/.+)$/)[1];
  }
};

App.createRepoSession = function () {
  Base.load(
    Base.jsonReq('POST', 'session'), 'Creating session', function (url) {
      App.goTo(Dispatch.relativeUrl(App.convertSessionUrl(url)));
    });
};

App.createSession = function (spec, autocommit, initfile) {
  Base.load(
    Base.jsonReq('POST', Base.url('/session', {
      store: spec,
      autoCommit: autocommit,
      loadInitFile: initfile,
    })),
    'Creating session', function (url) {
      App.goTo(App.convertSessionUrl(url));
    });
};

// Getting session data

App.loadSessionInfo = function () {
  if (App.currentSession) {
    return Base.load(
        Base.jsonReq('GET', 'session/description'),
        'Loading session info').then(function (data) {
          $('#sessionid').html(_.escape(data.name));
          _.extend(App.currentSession, data);
          if (data.wrapping) {
            // The URL might not contain this data, so let's update here.
            App.currentRepository = data.wrapping.name;
            App.currentCatalog = data.wrapping.catalog;
          }
          App.refreshHeader();
        });
  } else {
    return Async.dummy();
  }
};

/**
 * Current catalog  - either '/' or the name of the catalog.
 *
 * Must only be modified through App.setLocation().
 *
 * @type {string}
 */
App.currentCatalog = '/';

/**
 * @return {boolean} - whether we're in a named catalog
 *                      (as opposed to the root one).
 */
App.isInCatalog = function () {
  return App.currentCatalog !== '/';
};

/**
 * Current repository. Can be:
 *  - a string -- the name of the current repository
 *  - null -- we're not in the repository
 *            (meaning we're in the main screen or in a session)
 * Must only be modified through App.setLocation().
 * @type {string|null}
 */
App.currentRepository = null;

/**
 * typedef {Object} WrappedRepoData
 * @property {string} catalog - Catalog name (/ = root catalog).
 * @property {string} name - Repository name.
 */

/**
 * @typedef {Object} SessionData
 *
 * Description of a session. Descriptions are obtained in two phases:
 *    - First the URL is matched to get the port and UUID.
 *      Other fields are given temporary values.
 *    - Session information is retrieved from the server with
 *      an asynchronous request.
 *
 * @property name {string} - server-assigned session name
 * @property description {string} - session description
 * @property writable {boolean} - can data be modified through this session.
 * @property wrapping {WrappedRepoData|boolean} - Wrapped repository info
 *     or false if this session does not wrap a single repository.
 * @property autoCommit {boolean}
 * @property port {string} - port of the session.
 * @property uuid {string} - Session identifier.
 * @property base {string} - Base URL from which the session has been started.
 *     This is either the repo page, the catalog page or the overview page.
 * @property lifetime {int} - Session idle timeout in seconds.
 */

/**
 * Current session. Can be:
 *   - null -- we're not in a session
 *   - an object describing the current session.
 *
 * Must only be modified through App.setLocation().
 *
 * TODO: Considering that this is filled asynchronously
 * TODO: this field should really be a promise.
 *
 * @type {SessionData|null}
 */
App.currentSession = null;

/**
 * @return {boolean} - whether we're currently working either on a session
 *                      or on a repository.
 */
App.isInRepoOrSession = function () {
  return (App.currentRepository !== null) || (App.currentSession !== null);
};

/**
 * Sets the current catalog, repository and session.
 *
 * @param {string} catalog - Catalog name.
 * @param {string|null} repo - Repository name.
 * @param {SessionData|null} session - new session data.
 * @param {string|null} shard - Shard index.
 * @return {Async} - a promise that will be resolved once the location is set
 *                   and all session information has been loaded.
 */
App.setLocation = function (catalog, repo, session, shard) {
  if (catalog !== App.currentCatalog ||
      repo !== App.currentRepository ||
      (session && !App.currentSession) ||
      (!session && App.currentSession) ||
      (session && App.currentSession &&
       session.uuid !== App.currentSession.uuid) ||
      shard !== App.currentShard) {
    App.currentCatalog = catalog;
    App.currentRepository = repo;
    App.currentSession = session;
    App.currentShard = shard;
    return App.loadSessionInfo().then(function () {
      App.repoChange.notifyObservers();
    });
  } else {
    return Async.dummy();
  }
};

/**
 * Returns a shard prefix to be included in the constructed URLs if
 * the current location includes the shard index.
 *
 * @return {string} - shard path (shards/<index>) or an empty string.
 */
App.shardPrefix = function () {
  return App.currentShard ? 'shards/' + App.currentShard + '/' : '';
};

/**
 * Returns the label of the current repository (or session).
 * Depending on the `ignoreError` parameter, this will throw an Error
 * if no repository is currently selected.
 *
 * @param {boolean} ignoreError - If true, the empty string is
 *   returned instead of throwing an error when there is no
 *   current session or repository.
 *
 * @return {string} - label of the current repository or session.
 */
App.getCurrentRepoOrSessionLabel = function (ignoreError) {
  if (App.currentSession) {
    return 'session:' + App.currentSession.name + ':' +
      App.currentSession.port;
  } else if (App.currentRepository) {
    if (App.isInCatalog()) {
      return App.currentCatalog + ':' + App.currentRepository;
    }
    return App.currentRepository;
  } else if (ignoreError) {
    return '';
  } else {
    throw new Error('Not in a repository or session!');
  }
};

/**
 * @return {boolean} - whether we're either in a repo
 *                      or in a wrapping session.
 */
App.isInRealRepo = function () {
  return App.currentRepository ||
         (App.currentSession && App.currentSession.wrapping);
};

export default App;
