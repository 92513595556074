import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("<h3>Replication Cluster Management</h3>\n<div>\n\n<table class=\"pure-table pure-table-horizontal\"></table>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.renderReplTable($node); }) + "\"></var>");
__out.push("\n\n</div>\n\n<div id=\"repllog\"></div>\n\n<div id=\"replstatsDialog\">\n\n  <div class=\"anystatsFlotControls flotControls\">\n    <!-- These controls (buttons, etc.) deserve better presentation. -->\n    <h3>Performance</h3>\n    <div>\n      <button id=\"refresh\" class=\"flotButtonClass\">Refresh</button><br/>\n      <button id=\"unzoom\" class=\"flotButtonClass\">Unzoom</button><br/>\n      <div class=\"flotControlHeader\">Time Zone:</div>\n      <button id=\"timeZone\" class=\"flotButtonClass\">local</button><br/>\n      <div class=\"flotControlHeader\">Auto refresh:</div>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"0\" id=\"autoNever\">never<br/></label>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"30\" checked=\"checked\">30 sec<br/></label>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"60\">60 sec<br/></label>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"120\">120 sec<br/></label>\n      <div class=\"flotControlHeader\">Charts size:</div>\n      ");
Mold.forDepth++;
Mold.forEachIn($arg.sizes, function(key, value, $i) {
__out.push("\n      <label>\n        <input name=\"yScale\" type=\"radio\" class=\"radioClass\"\n               ");
if (key === $arg.defaultSize ) {
__out.push("checked=\"true\"");
}
__out.push("\n               value=\"");
__out.push(Mold.escapeHTML(value));
__out.push("\">");
__out.push(Mold.escapeHTML(key));
__out.push("</label><br/>\n      ");
});
Mold.forDepth--;
__out.push("\n    </div>\n  </div>\n\n  <div class=\"anystatsFlotcontent\">\n    <div class=\"flot-container\">\n      <p class=\"chartTitle\" id=\"commitsHeader\">Commits</p> <div id=\"commitsChart\"  class=\"flot-placeholder flot-placeholder1\"></div>\n    </div>\n    <div class=\"flot-container\">\n      <p class=\"chartTitle\" id=\"transactions\">Transaction Rate</p> <div id=\"transactionChart\"  class=\"flot-placeholder flot-placeholder1\"></div>\n    </div>\n  </div>\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;