import App from '../app';
import Dispatch from '../dispatch';
import User from '../user';
import Query from '../query';
import Layout from '../layout';
import { $$ } from '../util';
import Sched from '../scheduler';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var versionString = $arg.version || "current"; 
__out.push("\n\n<table width=\"100%\">\n  <tr>\n    <td id=\"headerMenuCell\">\n      ");
if (($arg.backLink || !App.isInRepoOrSession()) ) {
__out.push("\n        ");
if ($arg.backLink ) {
__out.push("\n          <a href=\"");
__out.push(Mold.escapeHTML($arg.backLink ));
__out.push("\" title=\"");
__out.push(Mold.escapeHTML($arg.backLinkText ));
__out.push("\">\n        ");
} else {
__out.push("\n          <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.rootUrl() ));
__out.push("\" title=\"Catalogs\">\n        ");
}
__out.push("\n        <i class='fas fa-angle-left'></i></a> |\n      ");
}
__out.push("\n      ");
if (App.isInRepoOrSession()) {
__out.push("\n        ");
if (User.userAccess("r")) {
__out.push("\n          <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('overview') ));
__out.push("\">Repository</a> |\n          <span class=\"linkish\">Queries</span> |\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Layout.openMenu($node, $$('query-menu'));
});}) + "\"></var>");
__out.push("\n          <ul class=\"menu\" id=\"query-menu\">\n             <li><a class=\"new-query\" href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query') ));
__out.push("\">New</a>\n             ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Query.currentEditor.clear();
});}) + "\"></var>");
__out.push("\n             </li>\n             ");
if (User.isLoggedIn() && App.isInRealRepo()) {
__out.push("\n               <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query/s') ));
__out.push("\">Saved</a></li>\n             ");
}
__out.push("\n             <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query/recent') ));
__out.push("\">Recent</a></li>\n             <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('search') ));
__out.push("\">Free text</a></li>\n          </ul>\n        ");
}
__out.push("\n      ");
}
__out.push("\n\n      \n      ");
if (User.isLoggedIn()) {
__out.push("\n        <span class=\"linkish\">Utilities</span> |\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Layout.openMenu($node, $$('utility-menu'));
});}) + "\"></var>");
__out.push("\n        <ul class=\"menu\" id=\"utility-menu\">\n          ");
if (User.userPerm("session")) {
__out.push("\n      \t    <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('sessions') ));
__out.push("\">Sessions</a></li>\n          ");
}
__out.push("\n\t  ");
if ((User.userPerm('super') || User.isLoggedIn())) {
__out.push("\n\t    <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('script') ));
__out.push("\">Scripts</a></li>\n          ");
} else {
__out.push("\n          <li><span class=\"disabled\">Scripts</span></li>\n          ");
}
__out.push("\n          ");
if (App.isInRepoOrSession()) {
__out.push("\n  \t    <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('namespaces') ));
__out.push("\">Namespaces</a></li>\n          ");
} else {
__out.push("\n  \t    <li><span class=\"disabled\">Namespaces</span></li>\n          ");
}
__out.push("\n          ");
if (App.isInRepoOrSession()) {
__out.push("\n  \t    <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query-options') ));
__out.push("\">Query options</a></li>\n          ");
} else {
__out.push("\n  \t    <li><span class=\"disabled\">Query options</span></li>\n          ");
}
__out.push("\n\t  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('nd') ));
__out.push("\">Geospatial Datatype designer</a></li>\n          <li class=\"divider\"></li>\n\t  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('settings') ));
__out.push("\">Settings</a></li>\n        <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('systemstat') ));
__out.push("\">View Server Stats</a></li>\n        </ul>\n      ");
}
__out.push("\n      ");
if (User.userPerm("super")) {
__out.push("\n        <span class=\"linkish\">Admin</span> |\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Layout.openMenu($node, $$('server-menu'));
});}) + "\"></var>");
__out.push("\n        <ul class=\"menu\" id=\"server-menu\">\n          <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('users') ));
__out.push("\">Users</a></li>\n          <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('jobs') ));
__out.push("\">Jobs</a></li>\n          <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('requests') ));
__out.push("\">Requests</a></li>\n\t  <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('audit') ));
__out.push("\">Audit log</a></li>\n          <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('logfile') ));
__out.push("\">View server log</a></li>\n          <li class=\"divider\"></li>\n          <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('initfile') ));
__out.push("\">View/Edit initfile</a></li>\n          <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('processes') ));
__out.push("\">Process list</a></li>\n          ");
if (($arg.configfiles.main || $arg.configfiles.cluster)) {
__out.push("\n            <li class=\"divider\"></li>\n            ");
if ($arg.configfiles.main) {
__out.push("\n              <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('configfile/main') ));
__out.push("\">View server configuration</a></li>\n            ");
}
__out.push("\n            ");
if ($arg.configfiles.cluster) {
__out.push("\n              <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('configfile/cluster') ));
__out.push("\">View cluster configuration</a></li>\n            ");
}
__out.push("\n          ");
}
__out.push("\n\t</ul>\n      ");
}
__out.push("\n      <span class=\"linkish\" id=\"user-menu-link\">User");
if (User.user) {
__out.push(" ");
__out.push(Mold.escapeHTML(User.user.name));
}
__out.push("</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Layout.openMenu($node, $$('user-menu'));
});}) + "\"></var>");
__out.push("\n      <ul class=\"menu\" id=\"user-menu\">\n        ");
if (User.isLoggedIn()) {
__out.push("\n          <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('tokens') ));
__out.push("\">Tokens</a></li>\n          <li><span class=\"linkish\">Change password</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.changePasswordDialog();
});}) + "\"></var>");
__out.push("</li>\n\t  ");
if (User.userPerm('super')) {
__out.push("\n\t     <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('scheduler') ));
__out.push("\">Event Scheduler</a></li>\n\t  ");
}
__out.push("\n          <li><span class=\"linkish\" id=\"menu-item-logout\">Logout</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.logout();
});}) + "\"></var>");
__out.push("</li>\n          <li><span class=\"linkish\">Delete</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.deleteMyUser();
});}) + "\"></var>");
__out.push("</li>\n        ");
} else {
__out.push("\n          <li><span class=\"linkish\">Login</span>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
User.loginDialog();
});}) + "\"></var>");
__out.push("</li>\n        ");
}
__out.push("\n      </ul>\n      &nbsp;\n    </td>\n    <td id=\"documentationMenuCell\">\n      <span class=\"linkish\" title=\"Help and links to documentation\">Documentation</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Layout.openMenu($node, $$('documentation-menu'), -120);
});}) + "\"></var>");
__out.push("\n      <ul style=\"text-align: left\" id=\"documentation-menu\" class=\"menu\">\n\n        <li><a href=\"doc/agwebview.html\" target=\"agraph-docs\">WebView documentation</a></li>\n        <li><a href=\"doc/\" target=\"agraph-docs\">AllegroGraph documentation index</a></li>\n        <li><a href=\"doc/agraph-introduction.html\" target=\"agraph-docs\">AllegroGraph introduction</a></li>\n        <li class=\"divider\"></li>\n        <li><a href=\"doc/sparql-lisp-tutorial.html\" target=\"agraph-docs\">SPARQL tutorial</a></li>\n        <li><a href=\"doc/sparql-reference.html\" target=\"agraph-docs\">SPARQL API reference</a></li>\n        <li><a href=\"doc/geospatial-tutorial.html\" target=\"agraph-docs\">Geospatial tutorial</a></li>\n        <li class=\"divider\"></li>\n        <li><a href=\"http://www.w3.org/TR/sparql11-query/\" target=\"agraph-docs\">SPARQL 1.1 (W3C)</a></li>\n        <li><a href=\"http://www.w3.org/TR/sparql11-update/\" target=\"agraph-docs\">SPARQL 1.1 Update (W3C)</a></li>\n        <li class=\"divider\"></li>\n        <li><a href=\"http://www.franz.com/agraph/allegrograph/\"\n               target=\"agraph-docs\">AllegroGraph (franz.com)</a></li>\n        <li><a href=\"http://www.franz.com/agraph/support/documentation/");
__out.push(Mold.escapeHTML(versionString));
__out.push("/agwebview.html\"\n               target=\"agraph-docs\">WebView documentation (franz.com)</a></li>\n        <li><a href=\"http://www.franz.com/agraph/support/documentation/");
__out.push(Mold.escapeHTML(versionString));
__out.push("/agraph-introduction.html\"\n               target=\"agraph-docs\">AllegroGraph introduction (franz.com)</a></li>\n        <li><a href=\"http://www.franz.com/agraph/support/\"\n               target=\"agraph-docs\">AllegroGraph support (franz.com)</a></li>\n      </ul>\n    </td>\n  </tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;