import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"pure-form pure-form-aligned\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok(Util.selectValue($node.elements.flavor),
              Util.selectValue($node.elements.style));
  
});}) + "\"></var>");
__out.push("\n  <fieldset>\n    <div class=\"pure-control-group\">\n      <label for=\"flavor\">Index to add:</label>\n      <select name=\"flavor\" id=\"flavor\">\n        ");
Mold.forDepth++;
Mold.forEach($arg.options, function(flavor, $i) {
__out.push("\n          <option>");
__out.push(Mold.escapeHTML(flavor));
__out.push("</option>\n        ");
});
Mold.forDepth--;
__out.push("\n      </select>\n      <span class=\"pure-form-message-inline\">\n        ");
Mold.dispatchCustom("help", "Index flavor names are built out of the first letters of the fields they index\n                ('s' for subject, 'g' for graph (also called context), and so on). The order is significant.\n                For example, a <code>spogi</code> index can be used to efficiently search on subject, or on\n                subject and predicate (and so on), but not on just predicate.", __out);
__out.push("\n      </span>\n    </div>\n    <div class=\"pure-control-group\">\n      <label for=\"style\">Index style</label>\n      <select name=\"style\" id=\"style\">\n        <option value=\"0\">Default style</option>\n        <option value=\"1\">Style 1</option>\n        <option value=\"2\">Style 2</option>\n      </select>\n    </div>\n    <div class=\"pure-controls\">\n      <button type=\"button\" class=\"pure-button action-button\">Cancel</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      <button type=\"submit\" class=\"pure-button pure-button-primary\">OK</button>\n    </div>\n  </fieldset>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;