
import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div class=\"help-tooltip\">\n  <i class=\"help-icon fas fa-question-circle\"></i>\n  <div class=\"help-message\">\n    ");
__out.push($arg.message );
__out.push("\n  </div>\n  <span class=\"close-icon-button\"><i class=\"fas fa-times\"></i></span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.close();
});}) + "\"></var>");
__out.push("\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;