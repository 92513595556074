import Base from '../base';
import { $$ } from '../util';
import $ from 'jquery';
import Dispatch from '../dispatch';
import Util from '../util';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var resultsNode = null; 
__out.push("\n<h2 id=\"auditLog\">Audit Log</h2>\n\n<div id=\"auditPage\">\n\n");
if (($arg.eventTypes && ($arg.eventTypes.length > 0)) ) {
__out.push("\n<div id=\"instructions\">\n  Select the users and event types you wish to view and then\n  click the <span id=\"viewButton\">View Audit Log</span> button. You can also\n  filter the events by date range. Leave the dates empty\n  to remove the filter.\n</div>\n\n<div id=\"auditForm\">\n<div id=\"userNames\">\n  \n<h3>User names</h3>\n\n<select id=\"userSelect\" multiple>\n   <option value=\"UNKNOWN\" selected>&lt;unknown&gt;</option>\n");
Mold.forDepth++;
Mold.forEach($arg.users, function(name, $i) {
__out.push("\n  <option value=\"");
__out.push(Mold.escapeHTML(name));
__out.push("\" selected>");
__out.push(Mold.escapeHTML(name));
__out.push("</option>\n");
});
Mold.forDepth--;
__out.push("\n</select>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(Mold.setLabel("users")) + "\"></var>");
__out.push("\n<button class=\"firstButton\">All</button>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Base.multSelectChangeAll($$('userSelect'), 1);
});}) + "\"></var>");
__out.push("\n</div id=\"userNames\">\n\n<div id=\"eventTypes\">\n<h3>Event types</h3>\n\n<select id=\"typeSelect\" multiple>\n");
Mold.forDepth++;
Mold.forEach($arg.eventTypes, function(eventType, $i) {
__out.push("\n  <option value=\"");
__out.push(Mold.escapeHTML(encodeURI(eventType.class)));
__out.push("\" selected>");
__out.push(Mold.escapeHTML(eventType.label.replace(/"/g, '') ));
__out.push("</option>\n");
});
Mold.forDepth--;
__out.push("\n</select>\n<button class=\"firstButton\">All</button>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Base.multSelectChangeAll($$('typeSelect'), 1);
});}) + "\"></var>");
__out.push("\n</div>\n\n<div id=\"eventDates\">\n<div id=\"dateStart\">\n<h3>Show only events that happened after</h3>\n<input name=\"datepickerStart\" id=\"datepickerStart\" />\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.getStartDate = Util.dateInput($node); }) + "\"></var>");
__out.push("\n</div>\n\n<div id=\"dateEnd\">\n<h3>Show only events that happened before</h3>\n<input name=\"datepickerEnd\" id=\"datepickerEnd\" />\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.getEndDate = Util.dateInput($node); }) + "\"></var>");
__out.push("\n</div>\n</div>\n\n\n<button id=\"executeButton\" class=\"firstButton\">View Audit Log</button>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.executeAuditQuery(
  Base.multSelectValues($$("userSelect")),
  Base.multSelectValues($$("typeSelect")),
  $arg.getStartDate(),
  $arg.getEndDate(),
  resultsNode);
});}) + "\"></var>");
__out.push("\n\n</div>\n\n<div id=\"query-results\"></div>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){resultsNode = $node; }) + "\"></var>");
__out.push("\n");
} else {
__out.push("\n<div>\n  <p>Auditing information cannot be examined because the system catalog does not exist.</p>\n  <p>Make sure that the <em>Auditing</em> configuration parameter is turned on in the\n  AllegroGraph configuration file. See\n  the <a href=\"doc/audit.html\">auditing documentation</a> for details.</p>\n</div>\n");
}
__out.push("\n\n</div>\n\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;