import Util from '../../util';
import Base from '../../base';
import jsonoutputTemplate from '../instruction/jsonoutput.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function isObj(x) {
      return x && typeof x === 'object';
    }
    var val = $arg.value;

__out.push("\n");
if (!isObj(val)) {
__out.push("\n  ");
__out.push(Mold.escapeHTML(Util.writeJSON(val)));
__out.push("\n");
} else if (val instanceof Array) {
__out.push("\n  ");
var flat = !Util.some(isObj, val) && val.length <= 12 && Util.writeJSON(val);
__out.push("\n  ");
if (flat && flat.length < 100) {
__out.push("\n    ");
__out.push(Mold.escapeHTML(flat));
__out.push("\n  ");
} else if (!$arg.depth || ($arg.depth < 3 && val.length < 10)) {
__out.push("\n    <table>\n      ");
Mold.forDepth++;
Mold.forEach(val, function(elt, $i) {
__out.push("\n        <tr><td class=\"jsonprop\">");
__out.push(Mold.escapeHTML($i));
__out.push(":</td><td>");
Mold.dispatchCustom("jsonoutput", {value: elt, depth: 1 + $arg.depth}, __out);
__out.push("</tr>\n      ");
});
Mold.forDepth--;
__out.push("\n    </table>\n  ");
} else {
__out.push("\n    <div><span class=\"linkish\">[");
__out.push(Mold.escapeHTML(val.length));
__out.push(" ...]</span></div>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
jsonoutputTemplate.cast($node, {value: val, depth: 0});
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n");
} else {
__out.push("\n  ");
var props = [];
       Util.forEachIn(val, function (key) {
         props.push(key);
       });
  
__out.push("\n  ");
if (!$arg.depth || ($arg.depth < 3 && props < 10)) {
__out.push("\n    <table>\n      ");
Mold.forDepth++;
Mold.forEachIn(val, function(key, elt, $i) {
__out.push("\n        <tr><td class=\"jsonprop\">");
__out.push(Mold.escapeHTML(key));
__out.push(":</td><td>");
Mold.dispatchCustom("jsonoutput", {value: elt, depth: 1 + $arg.depth}, __out);
__out.push("</tr>\n      ");
});
Mold.forDepth--;
__out.push("\n    </table>\n  ");
} else {
__out.push("\n    <div><span class=\"linkish\">{");
Mold.forDepth++;
Mold.forEach(props.slice(0, 5), function(key, $i) {
__out.push(Mold.escapeHTML(($i ? ", " : "") + key));
});
Mold.forDepth--;
__out.push("}</span></div>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
jsonoutputTemplate.cast($node, {value: val, depth: 0});
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;