import Base from './base';
import Util from './util';

// UI imports

import Layout from './layout';
import $ from 'jquery';
import { MAsync } from './async';

import schedTemplate from './templates/scheduler.mold';
import addEventTemplate from './templates/addevent.mold';

/**
 * Event Scheduler
 *
 * On this page we have two tables.   The top table shows the events
 * for this user and the bottom table shows scheduler activity.
 * If the user is a super user then events and log entries
 * from all users are shown.
 *
 * @namespace Scheduler
 */

var Scheduler = {};

/**
 * Retrieves the array of objects, one per instance.
 * An object is a set of key/value pairs.
 *
 * Each column is described below with an object whose "data"
 * field has a name (such as 'instanceId') that specifies the
 * key to use to find the value for this column in the data
 * returned by Scheduler.events
 *
 * @return {Async}
 *
 */
Scheduler.events = function () {
  return Base.jsonReq('GET',
          Base.serverUrlNoSession('/scheduler/events', { tzOffset: Util.tzOffset() }));
};

Scheduler.getlog = function () {
  return Base.jsonReq('GET',
          Base.serverUrlNoSession('/scheduler/log', { tzOffset: Util.tzOffset() }));
};

Scheduler.showSched = function () {
  // called when  User name\Event Scheduler  menu item is clicked
  // in headerLinks.mold
  var load = function (_data, callback) {
    Base.load(Scheduler.events(), 'Fetching event data', function (events) {
      callback({ data: events });
    });
  };

  var logload = function (_data, callback) {
    Base.load(Scheduler.getlog(), 'Fetching log data', function (loginfo) {
      callback({ data: loginfo });
    });
  };

  var ctrl = {};
  $.extend(ctrl, {
    // this table shows events
    renderSchedTable: function (node) {
      var buttons = [
        {
          text: 'Refresh',
          action: ctrl.refresh,
        },
        {
          text: 'Delete',
          action: ctrl.deleteEvent,
        },
        {
          text: 'Add Event',
          action: function (_e, _dt) {
            Scheduler.doAddEventDialog(ctrl.refreshAll);
          },
        },
      ];

      var columns = [
        {
          orderable: false,
          className: 'select-checkbox',
          width: '1em',
          data: function () {
            return '';
          },
          title: '<i class="regular-button-icon select-all fa fa-check"></i>',
        },
        {
          title: 'Id',
          data: 'id',
        },
        {
          title: 'Owner',
          data: 'owner',
        },
        {
          title: 'Name',
          data: 'eventName',
        },
        {
          title: 'Run At',
          data: 'runAt',
        },
        {
          title: 'Repeat',
          data: 'repeat',
        },
        {
          title: 'Repeat Until',
          data: 'repeat-until',
        },
        {
          title: 'Action',
          data: 'action',
        },
        {
          title: 'Execute',
          data: 'script',
        },
        {
          title: 'Mail on Success',
          data: 'emailSuccess',
        },
        {
          title: 'Mail on Failure',
          data: 'emailFailure',
        },
      ];
      // eslint-disable-next-line new-cap
      var table = $(node).DataTable({
        buttons: buttons,
        dom: 'rptB',
        columns: columns,
        ajax: load,
        // Allow selecting multiple rows
        select: {
          style: 'multiple',
        },
        paginate: false,
        rowId: 'id',
        autoWidth: false,
        stripeClasses: ['pure-table-odd', 'pure-table-even'],
      });
      ctrl.table = table;
    },

    // this table shows log entries
    renderLogTable: function (node) {
      var buttons = [
        {
          text: 'Refresh',
          action: ctrl.logrefresh,
        },
      ];

      var columns = [
        {
          title: 'Time',
          data: 'time',
        },
        {
          title: 'Owner',
          data: 'owner',
        },
        {
          title: 'Type',
          data: 'type',
        },
        {
          title: 'Event Name',
          data: 'eventName',
        },
        {
          title: 'Message',
          data: 'message',
          render: $.fn.dataTable.render.text(),
        },

      ];
      // eslint-disable-next-line new-cap
      var table = $(node).DataTable({
        buttons: buttons,
        dom: 'rptB',
        columns: columns,
        ajax: logload,
        paginate: true,
        rowId: 'time',
        autoWidth: false,
        stripeClasses: ['pure-table-odd', 'pure-table-even'],
      });
      ctrl.logtable = table;
    },

    refresh: function (_e, _dt) {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        ctrl.table.ajax.reload();
      }
    },

    logrefresh: function (_e, _dt) {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.logtable) {
        ctrl.logtable.ajax.reload();
      }
    },

    refreshAll: function () {
      ctrl.refresh();
      ctrl.logrefresh();
    },

    deleteEvent: function (_e, dt) {
      var asyncs = [];

      dt.rows({ selected: true }).every(
              function (_index) {
                asyncs.push(Base.req('PUT',
                        Base.serverUrlNoSession('/scheduler/deleteEvents',
                        { id: this.data().id })));
                return true;
              });
      if (asyncs.length > 0) {
        Base.loadWithDefaultErrorHandler(
                MAsync.collect.apply(MAsync, asyncs),
                'Deleting Events').then(ctrl.refreshAll);
      }
    },
  });

  schedTemplate.cast(Layout.getPage(), ctrl);
};

Scheduler.doAddEventDialog = function (refresh) {
  Scheduler.getAddEventDefaults().then(
          function (defaults) {
            Scheduler.showAddEventDialog(
                    defaults,
                    function (results) {
                      Scheduler.performAddEvent(refresh, results);
                    });
          });
};

Scheduler.getAddEventDefaults = function () {
  var url = Base.serverUrlNoSession('/scheduler/addEventDefaults', { tzOffset: Util.tzOffset() });
  return Base.jsonReq('GET', url).protect(function (val) {
    Layout.showMessage('Failed to get add event defaults - ' + val);
  });
};


Scheduler.showAddEventDialog = function (defaults, callback) {
  // show the add events dialog and then call the callback
  // with the results if user elected to add the instance

  var close = Layout.customDialog('addEvent-dialog',
          addEventTemplate,
          {
            closeDialog: function () {
              close();
            },
            ok: function (results) {
              callback(results);
              close();
            },
            vals: defaults,
          });
};

Scheduler.performAddEvent = function (refresh, results) {
  // add the event to the list of events and
  // then refresh the event dialog


  var args = {};

  if (results.repeatEvery !== 'never') {
    args.repeatEvery = results.repeatEvery;

    if (results.repeatUntil) {
      args.repeatUntil = results.repeatUntil + ' ' + results.whenTime;
    }
  }

  args.dateTime = results.whenDay + ' ' + results.whenTime;
  args.action = results.doAction;
  args.eventName = results.eventName;
  args.emailSuccess = results.emailOK;
  args.emailFailure = results.emailFail;
  args.script = results.scriptRun;
  args.tzOffset = Util.tzOffset();

  Base.loadWithDefaultErrorHandler(
          Base.req('POST',
                  Base.serverUrlNoSession('/scheduler/createEvent', args)),
          'Creating event').then(refresh);
};


export default Scheduler;

