import Base from './base';

var ServerInfoGraphs = [
  {
    div: '#CpuChart',
    description: 'CPU',
    lines: [
      // We choose not to chart stolen time, but uncommenting next line would restore it.
      // { key: 'st',  label: 'STOLEN'},
      { key: 'wa', label: 'IO WAIT', color: 'rgba(240,10,10,0.7)' },
      { key: 'sy', label: 'SYS', color: 'rgba(240,240,0,0.7)' },
      { key: 'us', label: 'CPU', color: 'rgba(20,220,20,0.7)' },
    ],
    gOptions: { yaxis: { max: 100, min: 0 } },
  },
  {
    div: '#MemChart',
    description: 'Memory',
    lines: [
      // We choose not to chart swpd, but uncommenting next line would restore it.
      // { key: 'swpd',  label: 'SWPD' },
      { key: 'buff', label: 'Buffer' },
      { key: 'cache', label: 'Cache' },
      { key: 'anon', label: 'Anonymous' },
      { key: 'free', label: 'Free' },
    ],
    gOptions: {
      legend: { position: 'sw' },
      yaxis: {
        tickFormatter:
        function (val, axis) {
          return (Base.printChartTick((1024 * val),
                                      Base.scaleChartRange(1024 * axis.max),
                                      0));
        },
      },
    },
  },
  {
    div: '#BiBoChart',
    description: 'Disk bytes transferred per second',
    // These colors need checking.  It's hard to trigger swapping.
    lines: [
      { key: 'si', label: 'Swapped in', color: 'rgba(20,120,100,0.7)' },
      { key: 'so', label: 'Swapped out', color: 'rgba(130,10,60,0.7)' },
      { key: 'bi', label: 'Read in', color: 'rgba(20,220,20,0.7)' },
      { key: 'bo', label: 'Written out', color: 'rgba(220,10,10,0.7)' },
    ],
    gOptions: {
      series: {
        stack: null,
        lines:
        {
          show: true,
          fill: null,
        } },
      yaxis: {
        tickFormatter:
        function (val, axis) {
          // max and val are already in 1 KiB blocks
          return (Base.printChartTick((1024 * val),
                                      Base.scaleChartRange(1024 * axis.max),
                                      1));
        },
      },
    },
  },
  {
    div: '#RBChart',
    description: 'Processes',
    lines: [
      { key: 'b', label: 'Blocked', color: 'rgba(200,30,30,0.7)' },
      { key: 'r', label: 'Runnable', color: 'rgba(30,200,30,0.7)' }],
    gOptions: {
      series: {
        stack: null,
        lines: {
          show: true,
          fill: null,
          steps: null,
        } },
      yaxis: {
        minTickSize: 1,
        tickDecimals: 0 } },
  },
  {
    div: '#IntrChart',
    description: 'Scheduling',
    lines: [
      { key: 'cs', label: 'context switches' },
      { key: 'in', label: 'interrupts processed' },
    ],
    gOptions: {
      series: {
        stack: null,
        lines: {
          show: true,
          fill: null,
        } } },
  },
];

export default ServerInfoGraphs;
