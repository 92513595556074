import App from './app';
import User from './user';
import Base from './base';
import Layout from './layout';
import Dispatch from './dispatch';
import $ from 'jquery';
import Util from './util';
import { $$ } from './util';
import scriptTemplate from './templates/store/script.mold';
import scriptoutputTemplate from './templates/store/scriptoutput.mold';


/** @namespace Script */
var Script = {};

Script._isJS = function (scriptname) {
  return /\.js$/.test(scriptname);
};

/**
 * Nagivates to the script editor page.  If 'flavor' and 'name' are
 * both provided, the page will load the associated script into the
 * editor.
 *
 * @param {string} [flavor] - 'user', 'site' or 'repo'
 * @param {string} [name] - script name
 */
Script._goToScript = function (flavor, name) {
  var url = '/script';
  if (flavor && name) {
    url += '/' + encodeURIComponent(flavor) + '/' + encodeURIComponent(name);
  }
  App.goTo(Dispatch.relativeUrl(url));
};

/**
 * Handler for #script/<flavor>/<scriptname>
 *
 * @param {string} [flavor] - 'user', 'site' or 'repo'
 * @param {string} [scriptname] - name of the script
 */
Script.showScripting = function (flavor, scriptname) {
  if (!User.user || !(App.isInRepoOrSession() || User.userPerm('super'))) {
    User.denied();
    return;
  }
  if (scriptname) {
    var uri;

    switch (flavor) {
      case 'site':
        uri = '/scripts/' + scriptname;
        break;
      case 'repo':
        uri = Base.serverUrl('scripts/' + scriptname);
        break;
      default:
        uri = Base.url(
          '/users/', User.user.name, '/data/wv.script.', scriptname);
    }
    Base.load(
      Base.catchNotFound(Base.req('GET', uri)),
      'Loading script', function (script) {
        var lang = Script._isJS(scriptname) ? 'JS' : 'Lisp';
        if (script) {
          scriptTemplate.cast(Layout.getPage(), {
            code: script,
            language: lang,
            name: scriptname,
          });
        } else {
          scriptTemplate.cast(Layout.getPage(), {
            code: '',
            language: lang,
          });
          Script._goToScript();
        }
      });
  } else {
    scriptTemplate.cast(Layout.getPage(), {
      code: '',
      language: 'JS',
    });
  }
};

Script._populateSelect = function (select, values) {
  $(select).html('');
  select.disabled = values.length === 0;
  if (values.length) {
    Util.forEach(values, function (value) {
      var opt = select.appendChild(document.createElement('option'));
      opt.appendChild(document.createTextNode(value.name));
      opt.value = value.value;
    });
  } else {
    var opt = select.appendChild(document.createElement('option'));
    $(opt).html('None');
    opt.value = '//';
  }
};

Script._fetchFlavorScripts = function (flavor) {
  switch (flavor) {
    case 'site':
      Script.fetchSiteScripts($$('sitescripts'));
      break;
    case 'repo':
      Script.fetchRepoScripts($$('reposcripts'));
      break;
    default:
      Script.fetchUserScripts($$('userscripts'));
  }
};

Script.fetchUserScripts = function (select) {
  if (!select) {
    return;
  }
  Base.jsonReq(
      'GET', Base.url(
          '/users/', User.user.name,
          '/data', { prefix: 'wv.script.' }))
      .wait(function (scripts) {
        function opt(script) {
          return {
            name: script.id.slice(10),
            value: Util.writeJSON([script.uri, script.id.slice(10), 'user']),
          };
        }
        Script._populateSelect(select, Util.map(opt, scripts));
      });
};

Script.fetchSiteScripts = function (select) {
  if (!select) {
    return;
  }
  Base.jsonReq('GET', Base.url('/scripts')).wait(function (scripts) {
    function opt(script) {
      return {
        name: script,
        value: Util.writeJSON(['/scripts/' + script, script, 'site']),
      };
    }
    Script._populateSelect(select, Util.map(opt, scripts));
  });
};

Script.fetchRepoScripts = function (select) {
  if (!select) {
    return;
  }
  Base.jsonReq('GET', Base.serverUrl('scripts')).wait(
    function (scripts) {
      function opt(script) {
        return {
          name: script,
          value: Util.writeJSON(
            [Base.serverUrl('scripts/' + script), script, 'repo']),
        };
      }
      Script._populateSelect(select, Util.map(opt, scripts));
    });
};

Script.saveScript = function (flavor, script, language, name, statusnode) {
  if (/^\s*$/.test(script) || !name) {
    return;
  }
  if (language === 'JS' && !Script._isJS(name)) {
    Layout.showDialog('renameJSScript-confirm',
      'JavaScript code will only be recognized as such ' +
      'if its name ends in ".js". Add extension?',
      function () {
        name += '.js';
        doIt();
      }, doIt, false, false);
  } else {
    doIt();
  }

  function doIt() {
    $(statusnode).html('Saving...');
    var r;
    if (flavor === 'user') {
      r = User.setCurrentUserData('script.' + name, script);
    } else if (flavor === 'site') {
      r = Base.req(
        'PUT', Base.url('/scripts/', name),
        { body: script, contentType: 'text/javascript' });
    } else {
      r = Base.req(
        'PUT', Base.url(Base.serverUrl('scripts/'), name),
        { body: script, contentType: 'text/javascript' });
    }
    r.always(function () {
      $(statusnode).html('');
    });
    Base.load(r, 'Saving script', function () {
      Script._fetchFlavorScripts(flavor);
      Script._goToScript(flavor, name);
    });
  }
};

Script.deleteScript = function (data, flavor) {
  data = Util.readJSON(data);
  Layout.showDialog('deleteScript-confirm',
    'Really delete script "' + data[1] + '"?', del, null, false, true);
  function del() {
    Base.load(Base.req('DELETE', data[0]), 'Deleting script', function () {
      Script._fetchFlavorScripts(flavor);
    });
  }
};

Script.showScript = function (data, editor) {
  data = Util.readJSON(data);
  var url = data[0];
  var name = data[1];
  var flavor = data[2];
  Base.load(Base.req('GET', url), 'Loading script', function (script) {
    var js = Script._isJS(name);
    var lang = $$('scriptlang');
    if (lang) {
      lang.selectedIndex = js ? 0 : 1;
    }
    editor.setOption('mode', js ? 'text/javascript' : 'text/x-lisp');
    editor.setValue(script);
    Script._goToScript(flavor, name);
    var nameInput = $$('scriptname');
    if (nameInput) {
      nameInput.value = name;
    }
  });
};

Script.runScript = function (script, language, accept) {
  var mime = language === 'JS' ? 'text/javascript' : 'text/common-lisp';
  Base.load(
    Base.req(
      'POST', 'eval', { body: script, contentType: mime, accept: accept }),
    'Running script', function (value) {
      var output = $$('scriptoutput');
      if (output) {
        scriptoutputTemplate.cast(output, { text: value, format: accept });
      }
    });
};

Script.modeFromLanguage = function (language) {
  switch (language) {
    case 'JS': return 'text/javascript';
    case 'Lisp': return 'commonlisp';
    default: return null;
  }
};

Script.initScriptEditor = function (replace, code, language) {
  replace.value = code;
  var cm = App.codeMirrorFromTextArea(
    replace, Script.modeFromLanguage(language));
  cm.focus();
  return cm;
};

export default Script;
