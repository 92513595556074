import { $$ } from '../../util';
import Store from '../../store';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>External MongoDB connection settings</h2>\n\n<p id=\"mongo-form\" style=\"margin-top: 1em\">\n  <label for=\"mongo-server\">Mongo Server Hostname:</label>\n  <input id=\"mongo-server\" value=\"");
__out.push(Mold.escapeHTML($arg.mongo_server ));
__out.push("\" type=\"text\" width=\"30em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("mongo-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~The HOSTNAME of the MongoDB server, to be accessed from the AllegroGraph server.", __out);
__out.push("\n  <br/>\n  <label for=\"mongo-port\">Mongo Server Port:</label>\n  <input id=\"mongo-port\" value=\"");
__out.push(Mold.escapeHTML($arg.mongo_port ));
__out.push("\" type=\"text\" width=\"6em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("mongo-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~The PORT number of the MongoDB server, to be accessed from the AllegroGraph server.", __out);
__out.push("\n  <br/>\n  <label for=\"mongo-database\">Mongo Database name:</label>\n  <input id=\"mongo-database\" value=\"");
__out.push(Mold.escapeHTML($arg.mongo_database ));
__out.push("\" type=\"text\" width=\"30em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("mongo-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~The name of the database to use when querying MongoDB.", __out);
__out.push("\n  <br/>\n  <label for=\"mongo-collection\">Mongo Collection name:</label>\n  <input id=\"mongo-collection\" value=\"");
__out.push(Mold.escapeHTML($arg.mongo_collection ));
__out.push("\" type=\"text\" width=\"30em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("mongo-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~The name of the collection to use when querying MongoDB.", __out);
__out.push("\n  <br/>\n  <label for=\"mongo-user\">Mongo Username:</label>\n  <input id=\"mongo-user\" value=\"");
__out.push(Mold.escapeHTML($arg.mongo_user ));
__out.push("\" type=\"text\" width=\"30em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("mongo-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~Used to authenticate to the Mongo DB server.", __out);
__out.push("\n  <br/>\n  <label for=\"mongo-password\">Mongo Password:</label>\n  <input id=\"mongo-password\" type=\"password\" width=\"30em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("mongo-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~Used to authenticate to the Mongo DB server.", __out);
__out.push("\n  <br/>\n  <button id=\"mongo-parameter-set\" type=\"button\">Set</button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.setMongoParameters({
      server: $$("mongo-server").value,
      port: $$("mongo-port").value,
      database: $$("mongo-database").value,
      collection: $$("mongo-collection").value,
      user: $$("mongo-user").value,
      password: $$("mongo-password").value
    });
  
});}) + "\"></var>");
__out.push("\n</p>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;