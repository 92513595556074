import App from './app';
import Base from './base';
import Layout from './layout';
import transactionsTemplate from './templates/transactions.mold';

import _ from 'underscore';

/**
 * Functions related to two-phase commit.
 *
 * @namespace Transactions
 */
var Transactions = {};

Transactions.rollbackTransaction = function (xid) {
  return Base.jsonReq('POST', Base.serverUrl('rollback', { 'xid': xid }));
};

Transactions.commitTransaction = function (xid) {
  return Base.jsonReq('POST', Base.serverUrl('commit', {
    'xid': xid,
    'phase': 'commit',
  }));
};

/**
 * @typedef {Object} PreparedCommitEntry
 *
 * Description of a single prepared commit.
 *
 * @property xid {string} - Distributed transaction ID.
 * @property start {int} - Transaction start time (UNIX timestamp).
 */

/**
 * Retrieves prepared transaction information from the server and
 * adapts it to the format expected by the manager template.
 *
 * @return {Async<List<PreparedCommitEntry>>} - Data about prepared
 *                                              XA transactions.
 */
Transactions.listPrepared = function () {
  return Base.jsonReq(
    'GET', Base.serverUrl('reports', { path: 'transactions' },
                          { useShard: true })
  ).then(function (report) {
    var reportPayload = report.fields[4].value;
    var data;
    if (reportPayload === 'None') {
      data = [];
    } else {
      data = reportPayload.map(function (v) {
        return { xid: v[0], start: v[1] };
      });
    }
    return data;
  });
};

Transactions.showPage = function () {
  Base.load(
    Transactions.listPrepared(),
    'Fetching prepared transactions',
    function (data) {
      var controller = {
        data: data,
        commit: function (xid) {
          Base.load(
            Transactions.commitTransaction(xid),
            'Committing XA transaction ' + _.escape(xid),
            function () {
              App.refresh();
              Layout.showMessage(
                'XA transaction commit completed.',
                Layout.LONG_DELAY);
            });
        },
        rollback: function (xid) {
          Base.load(
            Transactions.rollbackTransaction(xid),
            'Rolling back XA transaction ' + _.escape(xid),
            function () {
              App.refresh();
              Layout.showMessage(
                'XA transaction rollback completed.',
                Layout.LONG_DELAY);
            });
        },
      };
      transactionsTemplate.cast(Layout.getPage(), controller);
    });
};

export default Transactions;
