import $ from 'jquery';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"pure-form pure-form-aligned\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
      $arg.ok($('input[name="rateLimit"]').val());
  
});}) + "\"></var>");
__out.push("\n  <fieldset>\n    <div class=\"pure-control-group\">\n      <label for=\"rate-limit\">Purge rate limit</label>\n      <input type=\"number\" id=\"rate-limit\" name=\"rateLimit\"\n             min=\"0\" max=\"4294967295\"\n             value=\"");
__out.push(Mold.escapeHTML($arg.rateLimit ));
__out.push("\">\n      <span class=\"pure-form-message-inline\">Target average read I/Os per second</span>\n    </div>\n    <div class=\"pure-controls\">\n      <button type=\"button\" class=\"pure-button action-button\">Cancel</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      <button type=\"submit\" class=\"pure-button pure-button-primary\">OK</button>\n    </div>\n  </fieldset>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;