import { $$ } from '../../util';
import Layout from '../../layout';
import User from '../../user';
import namespaceBulkTemplate from './namespace-bulk.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function submit() {
      $arg.ok(
        $$("namespace-abbrev").value,
        $$("namespace-uri").value,
        $$("namespace-type").value);
    }

__out.push("\n<table width=\"100%\">\n  <tr>\n    <td>\n      <label for=\"namespace-abbrev\">Prefix:</label>\n      <input type=\"text\" id=\"namespace-abbrev\" class=\"option-input\"/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();}) + "\"></var>");
__out.push("\n      <br/>\n      <label for=\"namespace-uri\">URI:</label>\n      <input type=\"text\" id=\"namespace-uri\" class=\"option-input\"/>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      ");
Mold.dispatchCustom("help", " container=namespaces-uri-help\n              The URI you enter here should probably end with a hash (<code>#</code>) or slash (<code>/</code>).\n              It is the part that will be prefixed before resources that use this namespace.", __out);
__out.push("\n      <br>\n      <label for=\"namespace-type\">Type:</label>\n      <select id=\"namespace-type\" class=\"option-input\">\n        <option value=\"user\" ");
if ($arg.type === 'user') {
__out.push("selected");
}
__out.push(">user</option>\n        ");
if (User.userAccess('w')) {
__out.push("\n        <option value=\"repository\" ");
if ($arg.type === 'repository') {
__out.push("selected");
}
__out.push(">repository</option>\n        ");
}
__out.push("\n        ");
if (User.userPerm('super')) {
__out.push("\n        <option value=\"default\" ");
if ($arg.type === 'default') {
__out.push("selected");
}
__out.push(">default</option>\n        ");
}
__out.push("\n      </select>\n      ");
Mold.dispatchCustom("help", " container=namespaces-type-help\n              User namespaces only apply to the current user of the given repository\n              and can be modified by the user at will. Repository namespaces apply to\n              all users of a given repository and can only be modified by a user that\n              has write access to the repository. Default namespaces apply to all\n              repositories on this server and can only be modified by an administrator.", __out);
__out.push("\n    </td>\n    <td class=\"buttons\">\n      <button>OK</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      <button>Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      <button>Bulk Input</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
          var close = Layout.customDialog(
            'namespace-bulk-dialog', namespaceBulkTemplate, {
              ok: $arg.bulk,
              cancel: function () {
                close();
              },
              type: $arg.type,
            });
      
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n  <tr><td colspan=\"2\"><div id=\"namespaces-uri-help\"></div></td></tr>\n  <tr><td colspan=\"2\"><div id=\"namespaces-type-help\"></div></td></tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;