import User from './user';
import Base from './base';
import Layout from './layout';
import App from './app';
import _ from 'underscore';
import Util from './util';
import $ from 'jquery';
import { MAsync } from './async';
import Query from './query';
import Server from './server';
import HttpStatus from './http-status';
import jobsTemplate from './templates/jobs.mold';
import requestsTemplate from './templates/requests.mold';
import requestDetailsTemplate from './templates/request_details.mold';
import auditTemplate from './templates/audit.mold';
import logfileTemplate from './templates/logfile.mold';
import configfileTemplate from './templates/configfile.mold';
import initfileTemplate from './templates/initfile.mold';
import processTemplate from './templates/process.mold';
import processesTemplate from './templates/processes.mold';
import processTelnetPortDialogTemplate from './templates/process-telnet-port-dialog.mold';

import Systemstat from './systemstat';
import ProcessInfoGraph from './process-info-graph';

/** @namespace Admin */
var Admin = {};

/**
 * Handler for #jobs.
 */
Admin.showJobs = function () {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }

  Base.showPaneWithRefreshes({
    'refreshRate': 5000,
    'requestFunction': requestJobs,
    'castFunction': castJobs,
    'testElementSelector': '#jobDialog',
  });

  var jobController = new Admin.JobPageController();

  function requestJobs() {
    return Base.jsonReq('GET', '/jobs', { noTouch: true });
  }

  function castJobs(jobs) {
    jobController.setJobs(jobs);
  }
};

Admin.JobPageController = function () {
  this.jobs = [];

  this.setJobs = function (jobs) {
    this.jobs = jobs;
    jobsTemplate.cast(Layout.getPage(), this);
  };

  this.stopJob = function (uuid) {
    Layout.showDialog('stopJob-confirm', 'Really stop job?', ok, null, false, true);
    function ok() {
      Base.load(
        Base.req('DELETE', Base.url('/jobs?jobId=', uuid)),
        'Killing', App.refresh.bind(App));
    }
  };
};

/**
 * Handler for #requests.
 */
Admin.showRequests = function () {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }

  var controller = new Admin.RequestsPageController();
  requestsTemplate.cast(Layout.getPage(), controller);
};

/**
 * Controller for the 'requests.html' template.
 *
 * Renders a jQuery DataTable with currently running and finished HTTP
 * requests for all the web servers AllegroGraph is running.
 */
Admin.RequestsPageController = function () {
  var self = this;

  /**
   * Data for jQuery DataTable. An array of arrays. Each sub-array describes
   * one row to be shown by jQuery DataTable.
   * @type {Array[]}
   */
  this.rows = [];

  /**
   * Key under which the browser-local table configuration is kept.
   * @type {string}
   */
  var STORAGE_KEY = 'requestsTableConfig';

  /**
   * Column definitions for jQuery DataTable.
   * See https://datatables.net/reference/option/columns for explanation
   * of the options.
   */
  var tableColumns = [
    { key: 'server', title: 'Server', width: '5em' },
    {
      key: 'statusCode',
      title: 'Status',
      width: '2em',
    },
    { key: 'method', title: 'Method', width: '3em' },
    {
      key: 'uri',
      title: 'URL',
      // Use a special class name to enable expanding the row's value on hover:
      className: 'request-uri',
      render: function (uri) {
        // The result of render is not sanitized, so we must do it ourselves.
        // There should be no '<' in URLs, but better safe than sorry:
        return '<span>' + uri.replace(/</, '&lt;') + '</span>';
      },
    },
    { key: 'source', title: 'Source IP:port', width: '10em' },
    {
      key: 'started',
      title: 'Started',
      width: '14em',
      render: renderNanosecondTimestamp,
    },
    {
      key: 'finished',
      title: 'Finished',
      width: '14em',
      render: renderNanosecondTimestamp,

      // use finishedForSorting for sorting:
      orderData: 7,
    },
    {
      // The only purpose of this column is to allow better sorting
      // of column 6 ("finished") that may contain empty values.
      // The column itself is invisible.
      key: 'finishedForSorting',
      getValue: function (row, preprocessedData) {
        // We want unfinished requests (that have null "finished" field)
        // to be sorted last, so we assign a very large timestamp to them:
        var input = row.finished;
        return input ? input : preprocessedData.maxFinishedTimestamp + 1;
      },
      visible: false,
      searchable: false,
      initiallyVisible: false,
    },
    {
      key: 'duration',
      title: 'Duration [s]',
      width: '6em',
      render: function (duration) {
        // Convert nanoseconds to seconds with 6 digits after the dot:
        var precision = 6;
        return (duration / 1000 / 1000 / 1000).toFixed(precision);
      },
    },
  ];

  var finishedColIndex = _.findIndex(tableColumns, { key: 'finished' });
  var statusCodeColIndex = _.findIndex(tableColumns, { key: 'statusCode' });
  var urlColIndex = _.findIndex(tableColumns, { key: 'uri' });

  /**
   * Columns that are initially visible to the user. The 'finishedForSorting'
   * column is hidden from the start.
   *
   * @type {number[]}
   */
  var allInitiallyVisibleColumns = tableColumns.map(function (column, index) {
    if (column.initiallyVisible === false) {
      return null;
    } else {
      return index;
    }
  }).filter(function (index) {
    return index !== null;
  });

  /**
   * Default view configuration.
   * @type {Object}
   */
  var DEFAULT_CONFIG = {
    /**
     * Whether to use the local timezone or UTC when displaying started
     * and finished timestamps.
     * @type {boolean}
     */
    useLocalTimezone: false,

    /**
     * Which columns should be visible to the user. For each column index key,
     * the value is either 'true' (visible) or 'false' invisible.
     *
     * @type {Object}
     */
    columnVisibility: (function () {
      var columnVisibility = {};
      allInitiallyVisibleColumns.forEach(function (columnIndex) {
        columnVisibility[columnIndex] = true;
      });
      return columnVisibility;
    })(),

    /**
     * How many rows per page should be shown.
     * @type {number}
     */
    pageLength: 20,

    /**
     * Column number and direction of the sorting.
     * See https://datatables.net/reference/option/order for explanation.
     */
    order: [[finishedColIndex, 'desc']],

    /**
     * Column reordering. Null means no reordering. Otherwise it's an array
     * of integers, where the value is the original column index,
     * with its new position defined by the location in the array.
     * See https://datatables.net/reference/api/colReorder.order() for more.
     */
    columnReorder: null,
  };

  /**
   * The current table configuration of the view.
   * @type {Object}
   */
  var localConfig = null;
  loadLocalConfig();

  /**
   * Saves view configuration in localStorage.
   */
  function saveLocalConfig() {
    Util.setInStorage(STORAGE_KEY, localConfig, 'local');
  }

  /**
   * Loads view configuration from localStorage, applying defaults where
   * values are missing.
   */
  function loadLocalConfig() {
    var loadedConfig = Util.getFromStorage(STORAGE_KEY, 'local');
    localConfig = _.extend({}, DEFAULT_CONFIG, loadedConfig);
  }

  // Modify tableColumns so that each column has its initial visibility
  // set as defined in localConfig.
  tableColumns.forEach(function (column, index) {
    column.visible = localConfig.columnVisibility[index] || false;
  });

  /**
   * Returns the contents of the table cell that describe a timestamp.
   * Uses ISO 8601 format, with local timezone if useLocalTimezone is set,
   * or "Z" timezone otherwise.
   *
   * @param {number|null} ns - timestamp in nanoseconds, or null if there is
   *                           no timestamp (e.g. for unfinished requests)
   * @return {string} - ISO 8601 representation of the timestamp, or an empty
   *                    string if ns was null.
   */
  function renderNanosecondTimestamp(ns) {
    if (ns) {
      // Date constructor expects milliseconds, not nanoseconds:
      var date = new Date(Math.round(ns / 1000 / 1000));
      if (localConfig.useLocalTimezone) {
        return Util.formatLocalDate(date);
      } else {
        return date.toISOString();
      }
    } else {
      // Unfinished request.
      return '';
    }
  }

  /**
   * Returns an object with preprocessed data which can then be used for
   * getValue calls for row cells.
   *
   * @param {Object} requestsByServer
   * @return {Object}
   */
  function preprocessData(requestsByServer) {
    var maxFinishedTimestamp = 0;
    _.each(requestsByServer, function (serverRequests/* , serverName*/) {
      _.each(serverRequests, function (request) {
        if (request.finished && request.finished > maxFinishedTimestamp) {
          maxFinishedTimestamp = request.finished;
        }
      });
    });
    return {
      maxFinishedTimestamp: maxFinishedTimestamp,
    };
  }

  /**
   * Converts the result of the GET /requests server call to a list of rows
   * that jQuery DataTables can understand.
   *
   * @param {Object} requests
   * @return {Array[]}
   */
  function parseRequests(requests) {
    var requestsByServer = requests.requests;
    var preprocessedData = preprocessData(requestsByServer);
    var rows = [];
    // The results of the request are grouped by server name. We have to
    // flatten this to rows of one table.
    _.each(requestsByServer, function (serverRequests, serverName) {
      _.each(serverRequests, function (request) {
        var row = [];
        request = $.extend({ server: serverName }, request);
        tableColumns.forEach(function (column) {
          var value;
          if (column.getValue) {
            // A special case for the 'finishedForSorting' column.
            value = column.getValue(request, preprocessedData);
          } else {
            value = request[column.key];
          }
          row.push(value);
        });
        rows.push(row);
      });
    });
    return rows;
  }

  /**
   * The jQuery DataTable API object, representing the requests table.
   * @type {DataTable}
   */
  self.dataTable = null;

  /**
   * Called after a row has been rendered. Allows to add styling to the row.
   *
   * @param {HTMLTableRowElement} row - the created row
   * @param {Array} rowData - data for the row
   */
  function styleRow(row, rowData) {
    if (isRequestActive(rowData)) {
      $(row).addClass('request-active');
    } else if (isRequestFailed(rowData)) {
      $(row).addClass('request-failed');
    }
  }

  /**
   * @param {Array} rowData - data for the whole row
   * @return {boolean} - True iff the request represented by rowData is active
   *                     (i.e. not finished yet)
   */
  function isRequestActive(rowData) {
    return !rowData[finishedColIndex];
  }

  /**
   * @param {Array} rowData - data for the whole row
   * @return {boolean} - True iff the request represented by rowData has failed
   *                     (i.e. has status code 400 or greater).
   */
  function isRequestFailed(rowData) {
    var status = rowData[statusCodeColIndex];
    return status && (status >= HttpStatus.HTTP_ERROR_MIN);
  }
  /**
   * Renders the initial view of the data table, using self.rows as the
   * source of data and tableColumns as definitions of columns.
   */
  function renderDataTable() {
    var $requestTable = $('#requestsPage').find('.fixed-width-table');

    // Skip setup if already initialized
    if ($.fn.dataTable.isDataTable($requestTable)) {
      return;
    }

    // See https://datatables.net/reference/option/ for explanation of options.
    self.dataTable = $requestTable.DataTable({ // eslint-disable-line new-cap
      dom: 'Bfrtip',
      data: self.rows,
      columns: tableColumns,
      order: localConfig.order,
      colReorder: {
        order: localConfig.columnReorder,
        realtime: false,
      },
      language: {
        buttons: {
          pageLength: 'Set page size...',
          colvis: 'Set column visibility...',
        },
      },
      createdRow: styleRow,
      buttons: [
        {
          text: 'Reset view',

          /**
           * "Forgets" view settings and reloads the whole page.
           */
          action: function () {
            Util.removeFromStorage(STORAGE_KEY, 'local');
            window.location.reload(true);
          },
        },
        'pageLength',
        {
          extend: 'colvis',
          columns: allInitiallyVisibleColumns.slice(),
        },
        {
          text: 'Use local timezone',

          /**
           * Toggles between using local timezone and UTC.
           *
           * @param {*} _evt - Button click event that caused the switch.
           * @param {*} dataTable - DataTables object handle.
           */
          action: function (_evt, dataTable) {
            localConfig.useLocalTimezone = !localConfig.useLocalTimezone;
            this.active(localConfig.useLocalTimezone);
            // Make all rows invalid to force DataTables to re-render
            // timestamp columns:
            dataTable.rows().invalidate();
            saveLocalConfig();
          },
          init: function () {
            // Make the button depressed or not, depending on the value
            // of useLocalTimezone:
            this.active(localConfig.useLocalTimezone);
          },
        },

        // "Save to CSV" button will only appear in browsers that support
        // local saving of files.
        {
          extend: 'csv',
          text: 'Save to CSV',
        },
        {
          text: 'Refresh',
          action: refreshTableData,
        },
      ],
      pageLength: localConfig.pageLength,
      /* eslint-disable no-magic-numbers */
      lengthMenu: [
        [10, 20, 50, 100, -1],
        [10, 20, 50, 100, 'All'],
      ],
      /* eslint-enable no-magic-numbers */
    });

    // Listen to various table events that change the look of the table,
    // and save the changes to local config so that they persist after
    // reloading or coming back to this page.

    $requestTable.on(
      'column-visibility.dt', function (_evt, _settings, column, state) {
        // The data of the column-visibility event does not take into account
        // that the columns might have been reordered, so we have to map it:
        var reorder = self.dataTable.colReorder.order();
        var realColumn = reorder[column];
        localConfig.columnVisibility[realColumn] = state;
        saveLocalConfig();
      });
    $requestTable.on(
      'length.dt', function (_evt, _settings, len) {
        localConfig.pageLength = len;
        saveLocalConfig();
      });
    $requestTable.on(
      'order.dt', function () {
        localConfig.order = self.dataTable.order();
        saveLocalConfig();
      });
    $requestTable.on(
      'column-reorder.dt', function () {
        localConfig.columnReorder = self.dataTable.colReorder.order();
        saveLocalConfig();
      });

    $requestTable.on('click', 'td.request-uri', function () {
      var tr = $(this).closest('tr');
      var row = self.dataTable.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it.
        row.child.hide();
      } else {
        // Open the details of this row.
        row.child(renderDetailsRow(row.data())).show();
      }
    });
  }

  /**
   * Returns HTML to render as details of the row.
   *
   * @param {Array} rowData - table data for the whole row
   * @return {HTMLElement} HTML to render
   */
  function renderDetailsRow(rowData) {
    var url = rowData[urlColIndex];

    // Extract the query part (between ? and #):
    var query = /\?([^#]+)(#|$)/.exec(url);
    query = query ? query[1] : '';

    var paramStrings = query.split('&');
    var params = {};
    paramStrings.forEach(function (paramStr) {
      if (paramStr) {
        var keyVal = paramStr.split('=');
        params[decodeURIComponent(keyVal[0])] = decodeURIComponent(keyVal[1]);
      }
    });

    var detailsRow = $('<div class="request-details"></div>')[0];
    requestDetailsTemplate.cast(detailsRow, {
      url: url,
      params: params,
    });

    return detailsRow;
  }

  /**
   * Reloads the data from the server and replaces all table rows with new
   * data. View parameters of the table don't change, and the table object
   * is still the same. Only the data changes.
   */
  function refreshTableData() {
    setRowsFromServer().then(function () {
      var table = self.dataTable;
      table.clear();
      table.rows.add(self.rows);
      table.draw();
    });
  }

  /**
   * Fetches the request list from the server, converts the data into jQuery
   * DataTable-compatible rows, and saves the value in self.rows.
   *
   * @return {Async}
   */
  function setRowsFromServer() {
    return Base.loadWithDefaultErrorHandler(
        Base.jsonReq('GET', '/requests'),
        'Loading requests').then(parseRequests).then(function (rows) {
          self.rows = rows;
        });
  }

  // Load the initial data and render the table:
  setRowsFromServer().then(renderDataTable);
};

/**
 * Handler for #audit.
 */
Admin.showAuditLog = function () {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }
  Base.load(
    MAsync.collect(
      Base.jsonReq('GET', '/users'),
      Base.jsonReq('GET', '/auditLog/eventTypes')),
    'Fetching audit information',
    function (users, types) {
      var controller = new Admin.AuditLogPageController(users, types);
      auditTemplate.cast(Layout.getPage(), controller);
    });
};

Admin.AuditLogPageController = function (users, eventTypes) {
  this.users = users;
  this.eventTypes = eventTypes;

  /**
   * Performs an audit query and displays results in the #query-results DIV.
   *
   * Called by pressing "View Audit Log" on the Audit Log page.
   *
   * @param {string[]} users - usernames
   * @param {string[]} types - event URIs
   * @param {Date|null} start - date range start
   * @param {Date|null} end - date range end
   * @param {HTMLDivElement} resultsNode - where query results should be
   *                                       rendered
   */
  this.executeAuditQuery = function (users, types, start, end, resultsNode) {
    var query = {};
    if (users.length > 0) {
      query.users = users.join();
    }
    if (types.length > 0) {
      query.events = types.map(decodeURI).join();
    }
    if (start) {
      query.startDate =
        $.datepicker.formatDate('yy-mm-dd', start) + 'T00:00:00Z';
    }
    if (end) {
      query.endDate =
        $.datepicker.formatDate('yy-mm-dd', end) + 'T23:59:59.99999Z';
    }
    Query.loadQueryResults(
      { type: 'AUDIT', query: query, chunkSize: 100 }, resultsNode);
  };
};

/**
 * Handler for #logfile.
 */
Admin.showLogFile = function () {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }

  var logController = new Admin.LogFilePageController();

  Base.showPaneWithRefreshes({
    'refreshRate': 10000,
    'castFunction': logController.setData.bind(logController),
    'requestFunction': logController.fetchData.bind(logController),
    'testElementSelector': '#serverLogDialog',
  });
};

Admin.LogFilePageController = function () {
  this.data = {
    contents: '',
  };

  var lastLogfilePosition = -1; // -1 == first time
  var divName = '#logfile-contents';

  this.fetchData = function () {
    if (lastLogfilePosition === -1) {
      return Base.jsonReq('GET', '/logfile');
    } else {
      return Base.jsonReq(
        'GET', Base.url('/logfile', { 'startAt': lastLogfilePosition }));
    }
  };

  this.setData = function (data) {
    data = data || {};
    data.lastPosition = data.lastPosition || 0;
    data.contents = data.contents || '';
    this.data = data;

    var scrollHeight = 0;
    var $div = $(divName);

    if ($div[0]) {
      scrollHeight = $div[0].scrollHeight;
    }
    var shouldScroll = ($div.scrollTop() + $div.height()) > scrollHeight;
    var scrollAnimationDuration = 800;

    if (lastLogfilePosition === -1) {
      // first time
      logfileTemplate.cast(Layout.getPage(), this);
    } else if (lastLogfilePosition > data.lastPosition) {
      // log truncated
      $div.text(
        $div.text() +
        '\n\n***\n***\n' +
        '    Log truncated, continuing with new information.\n' +
        '\n***\n***\n\n');
    } else {
      // update
      $div.text($div.text() + data.contents);
    }
    maybeScroll(shouldScroll, scrollAnimationDuration);
    lastLogfilePosition = data.lastPosition;

    function maybeScroll(shouldScroll, duration) {
      if (shouldScroll) {
        $div.stop().animate({ scrollTop: $div[0].scrollHeight }, duration || 0);
      }
    }
  };

  this.downloadLogFile = function () {
    if (!User.userPerm('super')) {
      User.denied();
      return;
    }
    Server.download({
      accept: 'text/plain',
      path: '/logfile?all=true',
      file: 'agraph.log',
    });
  };
};

/**
 * Handler for #configfile.
 *
 * @param {string} type - config type (main or cluster)
 */
Admin.showConfigFile = function (type) {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }
  if (!type) {
    type = 'main';
  }
  Base.load(
    Base.catchNotFound(Base.req('GET', '/configfile?type=' + type)),
    'Fetching ' + type + 'config file',
    function (data) {
      configfileTemplate.cast(Layout.getPage(), {
        'config_type': type,
        'config_text': data,
      });
    });
};

/**
 * Handler for #initfile.
 */
Admin.editInitFile = function () {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }

  Base.load(
    Base.catchNotFound(Base.req('GET', '/initfile')),
    'Fetching initfile', function (file) {
      var controller = new Admin.InitFilePageController(file || '');
      initfileTemplate.cast(Layout.getPage(), controller);
    });
};

Admin.InitFilePageController = function (initialContents) {
  var editor = null;

  /**
   * @param {HTMLTextAreaElement} node
   */
  this.initfileInput = function (node) {
    node.value = initialContents;
    editor = App.codeMirrorFromTextArea(node, 'text/x-lisp');
    editor.focus();
  };

  this.saveInitfile = function (messageNode, warnErrorNode) {
    var contents = editor.getValue();
    $(messageNode).html('(saving...)');
    Base.req('PUT',
      Base.serverUrlNoSession('/initfile',
        { 'warnings-are-errors': warnErrorNode.checked }),
      { body: contents }).then(
        // success
        function (_val) {
          $(messageNode).html('(saved)');
          window.setTimeout(function () {
            $(messageNode).html('');
          }, 500);
        },
        // failure
        function (val) {
          $(messageNode).html('<br>Save failed with message:<br>' + _.escape(val));
        });
  };
};

/**
 * Handler for #processes and #processes/<process name>.
 *
 * @param {string} [proc] - Process ID (to show only a single process).
 */
Admin.showProcesses = function (proc) {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }

  if (proc !== undefined) {
    // show information on a single process
    Base.load(
      Base.jsonReq('GET', Base.url('/processes/', proc, '/stacktrace'), { noTouch: true }),
      'Loading', function (trace) {
        processTemplate.cast(Layout.getPage(), { name: proc, trace: trace });
      });
  } else {
    // show all processes
    var allProcCtrl = new Admin.AllProcessesPageController();
    Base.showPaneWithRefreshes({
      'refreshRate': 5000,
      'requestFunction': allProcCtrl.fetchProcesses.bind(allProcCtrl),
      'castFunction': allProcCtrl.setProcesses.bind(allProcCtrl),
      'testElementSelector': '#processesDialog',
    });
  }
};

Admin.showProcessGraphs = function (pid) {
  var graphsWithPid = ProcessInfoGraph.initializeKeysForPid(pid);
  Base.load(
    Base.jsonReq('GET', Base.url('/processes/', pid, '/pidName'), { noTouch: true }),
    'Loading', function (name) {
      Systemstat.showSystemstat(Layout.getPage(), graphsWithPid, 'sys.processes.pid' + pid, name);
    });
};


Admin.AllProcessesPageController = function () {
  this.processes = [];

  this.fetchProcesses = function () {
    return Base.jsonReq('GET', '/processes');
  };

  this.setProcesses = function (processes) {
    this.processes = processes;
    processesTemplate.cast(Layout.getPage(), this);
  };

  this.killProcess = function (proc) {
    Layout.showDialog('killProc-confirm',
      'Really kill process "' + proc + '"?', ok, null, false, true);
    function ok() {
      Base.load(
        Base.req('DELETE', Base.url('/processes/', proc)),
        'Killing', App.refresh.bind(App));
    }
  };

  this.telnetInProcess = function (proc) {
    Base.load(
      Base.req('POST', Base.url('/processes/', proc, '/telnet')),
      'Starting telnet', function (port) {
        var closeDialog;
        var args = {
          port: port,
          close: function () {
            closeDialog();
          },
        };
        closeDialog = Layout.customDialog('telnet-dialog',
                                          processTelnetPortDialogTemplate,
                                          args, false, false);
      });
  };
};

export default Admin;
