import User from '../../user';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.options.length) {
__out.push("\n  <table class=\"namespace-list\">\n    ");
Mold.forDepth++;
Mold.forEach($arg.options, function(qo, $i) {
__out.push("\n      <tr ");
if (qo.shadowed) {
__out.push("class=\"inactive\"");
}
__out.push(">\n        ");
if ($arg.config.canSelect) {
__out.push("\n          <td>\n            <input class=\"centered-checkbox\" type=\"checkbox\"\n                   ");
if ($arg.config.isSelected(qo)) {
__out.push("checked=\"checked\"");
}
__out.push(">\n            ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.config.onSelect(qo, $node);
});}) + "\"></var>");
__out.push("\n          </td>\n        ");
}
__out.push("\n        <th>\n          <span class=\"part\">franzOption_");
__out.push(Mold.escapeHTML(qo.name));
__out.push(":</span>\n        </th>\n        <td>\n          <span class=\"part\">&lt;franz:");
__out.push(Mold.escapeHTML(qo.value));
__out.push("&gt;</span>\n        </td>\n        ");
if ($arg.config.canDelete
             && (qo.type === 'user'
                 || (User.userAccess('w') && qo.type === 'repository')
                 || (User.userPerm('super') && qo.type === 'default'))) {
__out.push("\n        <td>\n          <button class=\"small-delete-button\" title=\"delete query option\">\n            <i class=\"fa fa-times-circle\"></i>\n          </button>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.config.onDelete(qo);
});}) + "\"></var>");
__out.push("\n        </td>\n        ");
} else {
__out.push("\n        <td>\n          <button class=\"small-icon-button-placeholder\"></button>\n        </td>\n        ");
}
__out.push("\n      </tr>\n    ");
});
Mold.forDepth--;
__out.push("\n  </table>\n  ");
if ($arg.config.helpText) {
__out.push("\n    <p>");
__out.push(Mold.escapeHTML($arg.config.helpText));
__out.push("</p>\n  ");
}
__out.push("\n");
} else {
__out.push("\n  No query options are set.\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;