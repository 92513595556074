import Reports from '../../reports';
import setImmediate from '../../set-immediate';
import _ from 'underscore';
import $ from 'jquery';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<table class=\"pure-table pure-table-horizontal report-table\"></table>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){var node = $($node);

  // Creates a DataTables column object from
  // a report column.
  function createTableColumn(column, index) {
    return {
      title: column.label,
      render: function (data, type) {
        if (type === 'display') {
           // Do not render anything at this point,
           // we'll render the template in cellCreated.
           // Otherwise Mold will not be able to bind
           // event handlers and run tags.
           return "";
        } else {
          // Use raw value for filtering, sorting etc.
          // TODO: This should depend on the column type
          return data;
        }
      },
      width: Reports.getColumnWidth(column),
      createdCell: cellCreated(column.type),
    };
  }

  // Display pagination, search and other fancy stuff
  // only for larger tables.
  var isLarge = $arg.field.value.length > 20;

  var dom = isLarge ? 'lfrtip' : 't';

  function rowCreated (row) {
    // Find all descendants with data-row-class attributes
    // and apply specified classes to the row.
    row = $(row);
    row.find('[data-row-class]').each(function (idx, node) {
      row.addClass(node.getAttribute('data-row-class'));
    });
  }

  function cellCreated (type) {
    return function (cell, data) {
      // Render the field
      var field = {
        label: '',
        type: type,
        value: data
      };
      // We need to wait until the cell is visible.
      // Otherwise it has a height of 0 and charts
      // refuse to render.
      setImmediate(function () {
        Reports.castField(cell, $arg.path, field, true);
      });
      
      // Find all descendants with data-cell-class attributes
      // and apply specified classes to the row.
      var $cell = $(cell);
      $cell.find('[data-cell-class]').each(function (idx, node) {
        $cell.addClass(node.getAttribute('data-cell-class'));
      });
    };
  }

  node.DataTable({
    columns: _.map($arg.field.type.columns, createTableColumn),
    data: $arg.field.value,
    paginate: isLarge,
    dom: dom,
    createdRow: rowCreated,
    stripeClasses: ['pure-table-odd', 'pure-table-even'],
    autoWidth: false,
  });
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;