
import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div>\n  Telnet server started on port ");
Mold.dispatchCustom("copyable", $arg.port, __out);
__out.push("\n  <span class=\"close-icon-button\"><i class=\"fas fa-times\"></i></span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.close();
});}) + "\"></var>");
__out.push("\n</div>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "dblclick", function($event, $node) {
$arg.close();
});}) + "\"></var>");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;