import Base from '../../base';
import App from '../../app';
import User from '../../user';
import Triples from '../../triples';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<table class=\"node-table\">\n  <thead>\n    <tr>\n      ");
Mold.forDepth++;
Mold.forEach(["subj", "pred", "obj"], function(p, $i) {
__out.push("\n        ");
if (p !== $arg.ignore) {
__out.push("<th><div>");
__out.push(Mold.escapeHTML(Base.capitalise(Base.FULL_NAME[p])));
__out.push("</div></th>");
}
__out.push("\n      ");
});
Mold.forDepth--;
__out.push("\n      ");
if (App.showContexts) {
__out.push("<th><div>Graph</div></th>");
}
__out.push("\n      ");
if (User.userAccess("W") && Triples.isDeleteAllowed($arg.triples)) {
__out.push("<th></th>");
}
__out.push("\n    </tr>\n  </thead>\n  <tbody>\n    ");
Mold.dispatchCustom("triplerows", $arg, __out);
__out.push("\n  </tbody>\n  ");
if ($arg.more) {
__out.push("\n    <tfoot><tr><td colspan=\"0\" class=\"more\">\n      ");
Mold.dispatchCustom("more", $arg.more, __out);
__out.push("\n    </td></tr></tfoot>\n  ");
}
__out.push("\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;