
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\nUsing the <em>Auto-detect</em> format will guess the format using each file's extension. Note that gzipped files can be imported if they have the additional file extension <code>gz</code> and that bzipped files can be imported if they have the additional file extension <code>bz2</code>.\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;