import boot from './boot';
import Mold from './lib/mold';
import Util from './util';

import './lib/input-validation';

import 'datatables.net';
import 'datatables.net-colreorder';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-select/js/dataTables.select';

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-colreorder-dt/css/colReorder.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-select-dt/css/select.dataTables.css';

import 'Flot';
import 'Flot/jquery.flot.pie';
import 'Flot/jquery.flot.selection';
import 'Flot/jquery.flot.stack';
import 'Flot/jquery.flot.time';

import 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/addon/hint/show-hint.css';

import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/anyword-hint';
import 'codemirror/addon/display/placeholder';

import 'codemirror/mode/sparql/sparql';
import 'codemirror/mode/commonlisp/commonlisp';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/ntriples/ntriples';
import 'codemirror/mode/turtle/turtle';
import 'codemirror/mode/xml/xml';

// TODO: Get rid of this abomination. ASAP.
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/base.css';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/tabs.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/tooltip.css';

import 'purecss/build/base.css';
import 'purecss/build/buttons.css';
import 'purecss/build/forms.css';
import 'purecss/build/grids.css';
import 'purecss/build/menus.css';
import 'purecss/build/tables.css';

import '../css/codemirror-wv.css';
import '../css/flot.css';
import '../css/global.css';
import '../css/graph.css';
import '../css/head.css';
import '../css/reports.css';
import '../css/message.css';
import '../css/icons.css';
import '../css/view.css';

import '@fortawesome/fontawesome-free/js/all';

// Register the util.js event connect function with Mold.
Mold.attachEvent = Util.connect.bind(Util);

import User from '../src/user';

// This will allow us to refer to 'User' from scripts run in the browser.
window.User = User;

boot();
