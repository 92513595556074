import Reports from '../../reports';
import Indices from '../../indices';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.field.value !== undefined ) {
__out.push("\n  ");
var cls = $arg.field.value.class;
    var index = $arg.field.value.index;
  
__out.push("\n  <span class=\"oscore-");
__out.push(Mold.escapeHTML(cls ));
__out.push("\" data-cell-class=\"oscore-");
__out.push(Mold.escapeHTML(cls ));
__out.push("\">\n    ");
__out.push(Mold.escapeHTML(Reports.formatValue($arg.field.value.score, $arg.field.type) ));
__out.push("\n  </span>\n  <div class=\"oscore-indicator oscore-");
__out.push(Mold.escapeHTML(cls ));
__out.push("\"></div>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;