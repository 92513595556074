import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("<table class=\"node-table\">\n  ");
Mold.forDepth++;
Mold.forEachIn($arg , function(key, value, $i) {
__out.push("\n    ");
if (key.match(/^warn/) ) {
__out.push("<span class=\"queryWarningFlag\"></span>");
}
__out.push("\n    <tr>\n      <td>");
__out.push(Mold.escapeHTML(key));
__out.push("</td>\n      <td></td>\n      <td></td>\n    </tr>\n    ");
Mold.forDepth++;
Mold.forEachIn(value, function(info, datum, $i) {
__out.push("\n      <tr>\n        <td></td>\n        <td>");
__out.push(Mold.escapeHTML(info));
__out.push("</td>\n        <td>\n        ");
__out.push(Mold.escapeHTML(key === 'time' ? datum.toFixed(6) : decodeURIComponent(datum) ));
__out.push("\n        </td>\n      </tr>\n    ");
});
Mold.forDepth--;
__out.push("\n  ");
});
Mold.forDepth--;
__out.push("\n</table>");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;