import Util from '../util';
import setImmediate from '../set-immediate';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var resizableNode;

__out.push("\n<div id=\"serverLogDialog\" class=\"dialog\">\n\n  ");
if ($arg.data.contents.length >= 0) {
__out.push("\n    <h2>agraph.log <span class=\"linkish\">[download]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.downloadLogFile(); 
});}) + "\"></var>");
__out.push("\n    </h2>\n\n    <pre id=\"logfile-contents\">\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){resizableNode = new Util.ResizableNode($node, {
          minHeightInPixels: 300,
          additionalBottomMargin: 40
        });
      }) + "\"></var>");
__out.push("\n      ");
__out.push(Mold.escapeHTML($arg.data.contents));
__out.push("\n    </pre>\n  ");
} else {
__out.push("\n    (Can't fetch log contents from the server)\n  ");
}
__out.push("\n\n</div>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function () {
      resizableNode.recalculateNodeHeight();
    });
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;