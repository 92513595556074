
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
Mold.dispatchCustom("throb", undefined, __out);
__out.push(" ");
__out.push(Mold.escapeHTML(($arg && $arg.message) || 'Loading...'));
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;