import Anystats from './anystats';
import Layout from './layout';
import systemstatTemplate from './templates/systemstat.mold';
import $ from 'jquery';
import ServerInfoGraphs from './server-info-graph';

/** @namespace Systemstat */
var Systemstat = {};

/**
 * This function implements the "Server load" from the WebView Utilities menu or similar page
 * of charts.
 * Charts that will be displayed are set in `graphs' parameter, which contains all charts
 * infromation.
 * The html skeleton is webview.html and the chart display is done by the Flot JQuery extension
 * library:
 * http://www.flotcharts.org/
 *
 * @param {Object} resultsPage - an object that represents a page to render graphs to.
 * @param {Object} graphs - an object that defines graph lines, their color and data source.
 * @param {string} requestTreePath - a string that represents a query parameter for systemstat
 *                                   service. Defines which subpart of the information that is
 *                                   being collected on the server to plot.
 * @param {string} name - a string that represents a chart name. Can be undefined.
 */
Systemstat.showSystemstat = function (resultsPage = Layout.getPage(), graphs = ServerInfoGraphs,
                                      requestTreePath = 'sys.vmstat', name) {
  var page = {
    // Available scaling factors (Graph heights in px)
    sizes: {
      'tall': 300,
      'medium': 170,
      'short': 120,
      'tiny': 80,
    },
    defaultSize: 'short',
    graphs: graphs,
    name: name,
  };

  Anystats.showAnystats(page,
      graphs,
      '/systemstat.json',
      requestTreePath,
      function () {
        // load the page on which the graphs will appear
        systemstatTemplate.cast(resultsPage, page);
      },
      function (data) {
        // the number of cpus is returned with each data sample
        $('#CpuChartHeader').text('CPU load (% of ' + data[data.length - 1].ncpus + ' CPUs)');
      }
  );
};

export default Systemstat;

