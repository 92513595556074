import $ from 'jquery';
import { $$ } from '../../util';
import Util from '../../util';
import setImmediate from '../../set-immediate';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<textarea style=\"width: 100%; height: 5em;\" id=\"info-text\"\n\t  linkedButton=\"editInfoSaveButton\">");
__out.push(Mold.escapeHTML($arg.text));
__out.push("</textarea>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "keyup", function($event, $node) {
Util.maybeEnableLinkedButton($node, $arg.text);
});}) + "\"></var>");
__out.push("\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();}) + "\"></var>");
__out.push("\n<button id=\"editInfoSaveButton\">Save</button>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.ok($$("info-text").value);
});}) + "\"></var>");
__out.push("\n<button>Cancel</button>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  var editArea = $$('info-text');
  Util.maybeEnableLinkedButton(editArea, $arg.text);
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;