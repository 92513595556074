import $ from 'jquery';
import App from './app';
import Layout from './layout';
import User from './user';
import Admin from './admin';
import Catalog from './catalog';
import Systemstat from './systemstat';
import Script from './script';
import Store from './store';
import Triples from './triples';
import Query from './query';
import Reports from './reports';
import Indices from './indices';
import Repl from './repl';
import Transactions from './transactions';
import Scheduler from './scheduler';
import Gruff from './gruff';

/** @namespace Dispatch */
var Dispatch = {};

// Dispatch a URL fragment -- matches it against a bunch of patterns to
// decide which function to call to display the corresponding page.
Dispatch.dispatch = function (fragment/* , historyData*/) {
  Dispatch._revision++;
  App.customRefresh = null;
  Layout.clearPage();
  return Dispatch._matchFragment(fragment);
};

// URL fragment pattern
Dispatch._prefixPattern = new RegExp(
  '^(?:/catalogs/([^/]+))?' + // Catalog name
  '(?:/repositories/([^/]+))?' + // repo name
  '(?:/session/(\\d+)/sessions/([^/]*))?' + // session port + UUID
  '(?:/shards/(\\d+))?' + // shard index
  '(?:/?([\\s\\S]*))'); // rest of the URL.

/**
 * Fill current catalog, session, repo and path from the URL fragment.
 *
 * @param {string} fragment - current URL fragment.
 * @return {Async<string>} - rest of the fragment, without the leading '/'.
 */
Dispatch._extractRepoAndSession = function (fragment) {
  var match = Dispatch._prefixPattern.exec(fragment);
  var catalog = match[1] ? decodeURIComponent(match[1]) : '/';
  var repository = match[2] ? decodeURIComponent(match[2]) : null;
  var shard = match[5] ? decodeURIComponent(match[5]) : null;
  var service = match[6];
  var session;
  var updateHash = false;

  // Ensure <store>/shards/<id> path is only accessed for reports.
  if (shard &&
      !service.match(/^reports(?:\/.*)?$/) &&
      !service.match(/^indices\/?$/) &&
      !service.match(/^transactions\/?$/)) {
    Layout.showMessage('Per-shard view is only available for reports ' +
                       'and index/transaction management. ' +
                       'Going back to the repository view.', true, true);
    shard = null;
    updateHash = true;
  }

  // Fields will be added/updated later (in App.loadSessionInfo).
  if (match[3]) {
    var port = match[3];
    var uuid = match[4];
    session = {
      port: port,
      uuid: uuid,
      base: Dispatch.rootUrl(App.currentCatalog, App.currentRepository),
      // These parameters will be updated later
      name: 'Session ' + port,
      description: 'Uninitialized session at port ' + port,
      writable: false,
      wrapping: false,
      autocommit: true,
    };
  } else {
    session = null;
  }

  return App.setLocation(catalog, repository, session, shard).then(function () {
    // At this point the location data has been set, so we can update
    // the hash in case shard index parts must be removed.
    if (updateHash) {
      App.updateHash(Dispatch.relativeUrl(service));
    }
    return service;
  });
};

/**
 * Gets the URL of the overview page of a repo, catalog or the whole server.
 *
 * @param {String|null} [catalog] - Catalog name.
 * @param {String|null} [repository] - Repository name.
 * @param {SessionData} [session] - Session description.
 * @return {string} - the URL.
 */
Dispatch.rootUrl = function (catalog, repository, session) {
  var result = '#';
  if (catalog && catalog !== '/') {
    result += '/catalogs/' + catalog;
  }
  if (repository) {
    result += '/repositories/' + repository;
  }
  if (session) {
    result += '/session/' + session.port + '/sessions/' + session.uuid;
  }
  return result;
};

/**
 * Gets the URL of a given PATH in current repository or session.
 *
 * @param {string} path - Target path.
 * @return {string} - the URL.
 */
Dispatch.relativeUrl = function (path) {
  if (path.substr(0, 1) !== '/') {
    path = '/' + path;
  }
  return Dispatch.rootUrl(
      App.currentCatalog, App.currentRepository, App.currentSession) + path;
};

/**
 * Gets the URL of a given PATH, ignoring current repository and session.
 *
 * @param {string} path - Target path.
 * @return {string} - the URL.
 */
Dispatch.url = function (path) {
  if (path.substr(0, 1) !== '/') {
    path = '/' + path;
  }
  return '#' + path;
};

// Counter used to detect if the URL has changed
// in async callbacks.
Dispatch._revision = 0;

/**
 * Wraps a callback, making sure that it will only
 * work if no URL changes happened since the wrapper
 * has been created.
 *
 * @param {Function} callback - a callback function.
 * @param {Function} onFailure - a callback to be called if URL changes
 *                               did happen since the wrapper has been created.
 * @return {Function} - A wrapped function that will pass all
 *                      its arguments to {@code callback}, but
 *                      only if the URL hasn't changed.
 */
Dispatch.protect = function (callback, onFailure) {
  var oldRevision = Dispatch._revision;
  return function () {
    if (Dispatch._revision === oldRevision) {
      return callback.apply(null, arguments);
    } else if ($.isFunction(onFailure)) {
      return onFailure.apply(null, arguments);
    }
    return undefined;
  };
};

Dispatch._matchFragment = function (fragment) {
  if (fragment && fragment.charAt(0) === '#') {
    fragment = fragment.substr(1);
  }

  function num(str) {
    return (str === '' || str === undefined || str === null)
      ? null
      : Number(str);
  }
  function notFound() {
    Layout.showMessage('Resource "' + fragment + '" not found.', true, true);
  }

  // Update current repository/catalog/session
  return Dispatch._extractRepoAndSession(fragment).then(function (fragment) {
    // Update permissions - we might have entered a different repo.
    // And our controllers are not kind enough to listen to change
    // events, so we must make sure permissions are updated before
    // we dispatch.
    return App.loadSessionInfo()
        .then(User.refreshPermissions.bind(User))
        // Yes, this function has a lot of ifs, but it is pretty simple...
        // eslint-disable-next-line complexity
        .then(function () {
          var match;

          // first we match fragments that don't need an open repository
          if (!User.user) {
            User.denied();
          } else if (fragment === 'users') {
            User.userList();
          } else if (fragment === 'tokens') {
            User.tokenList();
          } else if (fragment === 'initfile') {
            Admin.editInitFile();
          } else if (fragment === 'logfile') {
            Admin.showLogFile();
          } else if ((match = fragment.match(/^configfile\/(.+)$/))) {
            Admin.showConfigFile(match[1]);
          } else if (fragment === 'jobs') {
            Admin.showJobs();
          } else if (fragment === 'requests') {
            Admin.showRequests();
          } else if (fragment === 'audit') {
            Admin.showAuditLog();
          } else if (fragment === 'serverWarnings') {
            Catalog.showServerWarnings();
          } else if (fragment === 'nd') {
            Catalog.showGeospatialDesigner();
          } else if (fragment === 'sessions') {
            Catalog.showSessions();
          } else if (fragment === 'scheduler') {
            Scheduler.showSched();
          } else if (fragment === 'settings') {
            Catalog.showSettings();
          } else if ((match = fragment.match(/^processes(?:\/(.+)\/stacktrace)?$/))) {
            Admin.showProcesses(match[1]);
          } else if ((match = fragment.match(/^processes(?:\/(.+)\/chart)?$/))) {
            Admin.showProcessGraphs(match[1]);
          } else if (fragment === 'systemstat') {
            Systemstat.showSystemstat();
          } else if (fragment === 'gruff') {
            Gruff.startGruff();
          } else if ((match = fragment.match(/^script(?:\/([^/]*)\/(.*))?$/))) {
            Script.showScripting(
                match[1] && decodeURIComponent(match[1]), // flavor ("user", "site", or "repo")
                match[2] && decodeURIComponent(match[2])); // script name
          } else if (!App.isInRepoOrSession()) {
            if (!fragment) {
              Catalog.showCatalog();
            } else {
              notFound();
            }
            // then we match fragments that _do_ need an open repository
          } else if (!fragment || fragment === 'overview') {
            Store.showOverview();
          } else if (fragment === 'indices') {
            Indices.showIndices();
          } else if (fragment === 'namespaces') {
            User.userNamespaces();
          } else if (fragment === 'query-options') {
            User.userQueryOptions();
          } else if ((match = fragment.match(/^node\/(.+)$/))) {
            Triples.showNode(match[1]);
          } else if ((match = fragment.match(/^query(?:\/(\d*)?)?$/))) {
            Query.showQuery(num(match[1]));
          } else if ((match = fragment.match(/^query\/s(?:\/(.+))?$/))) {
            Query.showSavedQuery(match[1]);
          } else if ((match = fragment.match(/^query\/r\/(.+)$/))) {
            Store.showRepoQuery(match[1]);
          } else if ((match = fragment.match(
            // FIXME this is so gross..
            /^query\/d\/(?:(prolog|graphql)\/)?((?:.|\r|\n)+)$/m))) {
            Query.showDirectQuery(match[1] || 'SPARQL', match[2]);
          } else if (fragment === 'query/recent') {
            Query.showRecent();
          } else if ((match = fragment.match(/^search(?:\/([^/]*)\/(.*))?$/))) {
            Triples.showSearch(
                match[1] && decodeURIComponent(match[1]),
                match[2] && decodeURIComponent(match[2]));
          } else if (fragment === 'text-index') {
            Store.showFTI();
          } else if (fragment === 'replication') {
            Store.showReplication();
          } else if (fragment === 'solr') {
            Store.showSolr();
          } else if (fragment === 'mongo') {
            Store.showMongo();
          } else if (fragment === 'attribute-definitions') {
            Store.showAttributeDefinitionsPage();
          } else if ((match = fragment.match(/^reports(?:\/(.*))?$/))) {
            Reports.showReport((match[1] || '').split('/'));
          } else if (fragment === 'replmanage') {
            Repl.showRepl(false);
          } else if (fragment === 'replmanagecontrolling') {
            Repl.showRepl(true);
          } else if (fragment === 'transactions') {
            Transactions.showPage();
          } else {
            notFound();
          }
        }, function (err) {
          Layout.showMessage(err, true, true);
        });
  });
};

export default Dispatch;
