import User from './user';
import Util from './util';


/**
 * Dissecting part/node strings, expanding and collapsing namespaces.
 * @namespace Part
 */
var Part = {};

// Currently active namespaces for the user are kept as global state.
// This is changed when a user logs in or out, or modifies his/her
// namespaces.

Part.NameSpaces = {
  current: function () {
    return (User.user && User.user.namespaces) || User.defaultNamespaces;
  },
  // Finds a matching namespace for a url, or false if none found.
  match: function (url) {
    var longest = -1;
    var found = false;
    Util.forEach(Part.NameSpaces.current(), function (ns) {
      if (ns.namespace.length > longest && url.indexOf(ns.namespace) === 0) {
        longest = ns.namespace.length;
        found = ns;
      }
    });
    return found;
  },
  /**
   * Abbreviate a URL, returns null if no namespace found.
   *
   * @param {string} url - url to be abbreviated
   * @return {string|null}
   */
  abbreviate: function (url) {
    var ns = this.match(url);
    return ns ? ns.prefix + ':' + url.slice(ns.namespace.length) : null;
  },

  /**
   * Take a part string that might contain an abbreviated URI or type,
   * and restore the full URI. Return null if the string could not be
   * parsed, or the string itself if it contains no abbreviations.
   *
   * @param {string} string - string to be expanded to full URI
   * @return {string|null} - expanded string or null if expansion failed
   */
  restore: function (string) {
    var match;
    var ns;

    if (string.charAt(0) === '<') {
      return string;
    } else if (string.charAt(0) === '"') {
      match = string.match(
        /^("(?:.|\n|\r)*[^\\]"|"")(?:\^\^([\d\w_-]*):(.*)|@.+|\^\^<.+>)?$/);
      if (!match) {
        return null;
      }
      if (!match[2] || match[2] === '_') {
        return string;
      }
      ns = Util.findIf(function (ns) {
        return ns.prefix === match[2];
      }, Part.NameSpaces.current());
      return ns ? match[1] + '^^<' + ns.namespace + match[3] + '>' : null;
    } else {
      match = string.match(/^(.*):(.+)$/);
      if (!match) {
        return null;
      }
      if (match[1] === '_') {
        return string;
      }
      ns = Util.findIf(function (ns) {
        return ns.prefix === match[1];
      }, Part.NameSpaces.current());
      return ns ? '<' + ns.namespace + match[2] + '>' : null;
    }
  },
};

Part.removeNamespace = function (set, prefix, type) {
  return Util.filter(function (ns) {
    return ns.prefix !== prefix || ns.type !== type;
  }, set);
};

/**
 * @param {string} url
 * @return {string}
 */
Part._abbrevURL = function (url) {
  var lastSep = Math.max(url.lastIndexOf('/'), url.lastIndexOf('#'));
  if (lastSep === url.length - 1) {
    lastSep = Math.max(
      url.lastIndexOf('/', lastSep - 1), url.lastIndexOf('#', lastSep - 1));
  }
  return url.slice(lastSep + 1);
};

Part._ntUnescape = function (str) {
  return str.replace(/\\u[\da-fA-F]{4}|\\U[\da-fA-F]{8}/g, function (m) {
    return String.fromCharCode(parseInt(m.slice(2), 16));
  });
};

// Objects used to represent parts. Decomposes the part string (url,
// lit, type, lang properties) and produces a shortName property.
Part._Part = Util.Prototype.extend({
  construct: function (string) {
    // always escape
    this.string = Part._ntUnescape(string);
    var match;
    if (string === 'null') {
      this.shortName = string;
    } else if ((match = this.string.match(/^<(.+)>$/))) {
      this.url = match[1];
      var abbrev = Part.NameSpaces.abbreviate(this.url);
      if (abbrev === null) {
        this.shortName = Part._abbrevURL(this.url);
      } else {
        this.shortName = abbrev;
      }
    } else if ((match = this.string.match(
        /^("(?:[^\\]|\\.)*")(?:\^\^<(.+)>|@(.+))?$/))) {
      this.lit = match[1];
      this.shortName = match[1];
      this.type = match[2];
      this.lang = match[3];
    } else if ((match = this.string.match(/^_:(.+)$/))) {
      this.anon = match[1];
      this.shortName = this.string;
    } else {
      throw new Error('Unrecognised part: "' + this.string + '"');
    }
  },

  // Check whether this part is an AllegroGraph-style geospatial
  // coordinate pair, and return a {latitude, longitude} object if it
  // is.
  geo: function () {
    if (this.geoData) {
      return this.geoData;
    }
    if (!this.lit || !this.type ||
        !Util.String.startsWith(
          this.type,
          'http://franz.com/ns/allegrograph/3.0/geospatial/spherical/')) {
      return null;
    }

    function parse(str, isLongitude) {
      // Longitude gets an extra digit, but regexps aren't flexible enough
      // to express that sanely in one regexp.
      var match = str.match(
        isLongitude
          ? /^(\+|-)(\d\d\d)(\d\d)?(\d\d)?(\.\d+)?$/
          : /^(\+|-)(\d\d)(\d\d)?(\d\d)?(\.\d+)?$/);
      if (!match) {
        return null;
      }
      if (match[5]) {
        if (match[4]) {
          match[4] += match[5];
        } else if (match[3]) {
          match[3] += match[5];
        } else {
          match[2] += match[5];
        }
      }
      var degrees = Number(match[2]);
      if (match[3]) {
        degrees += Number(match[3]) / 60;
      }
      if (match[4]) {
        degrees += Number(match[4]) / 60 / 60;
      }
      return match[1] === '+' ? degrees : -degrees;
    }

    var match = this.lit.match(/^'((?:\+|-)[\d.]+)((?:\+|-)[\d.]+)'$/);
    var latitude;
    var longitude;

    if (match &&
        (latitude = parse(match[1])) !== null &&
        (longitude = parse(match[2], true)) !== null) {
      this.geoData = { longitude: longitude, latitude: latitude };
      return this.geoData;
    } else {
      return null;
    }
  },

  toString: function () {
    return this.string;
  },
});

// A cache of string -> part object mappings is kept to prevent
// parsing the same thing a hundred times in a row. Use part() instead
// of new _Part() to when creating part objects.
Part.part = (function () {
  var cache = Util.Dictionary.create();
  var count = 0;
  var genSize = 2000;
  var halfGen = Math.round(genSize / 2);

  Part.clearPartCache = function () {
    cache.clear();
  };

  return function (string) {
    var found;
    if (cache.contains(string)) {
      found = cache.lookup(string);
      if (found.cacheNr < count - halfGen) {
        found.cacheNr = count++;
      }
    } else {
      found = Part._Part.create(string);
      cache.store(string, found);
      found.cacheNr = count++;
    }

    if (count % halfGen === 0) {
      var oldCache = cache;
      cache = Util.Dictionary.create();
      oldCache.each(function (string, part) {
        if (part.cacheNr > count - genSize) {
          cache.store(string, part);
        }
      });
    }

    return found;
  };
})();

// Try to get a part object from a string that might contain
// abbreviated uris and might be invalid. Return null on invalid
// input.
Part.partFromUntrusted = function (string) {
  string = Part.NameSpaces.restore(string);
  if (string === null) {
    return null;
  }
  try {
    return Part.part(string);
  } catch (e) {
    return null;
  }
};

// Normalize namespace/query-option declaration components. No need to
// check anything, same check is performed on the server, and if it
// fails, the request fails, so this is just to extract the normalized
// values.
Part.normalizeNamespacePrefix = function (prefix) {
  return prefix.match(/^(?:\s*)([^:]*?)(?:\s*)(?::?)(?:\s*)$/)[1];
};

Part.normalizeNamespaceURI = function (uri) {
  return uri.match(/^(?:\s*)(?:<?)([^<>]*?)(?:>?)(?:\s*)$/)[1];
};

Part.normalizeQueryOptionName = function (name) {
  return name.match(/^(?:\s*)(?:franzOption_)?([^:]*?)(?:\s*)(?::?)(?:\s*)$/)[1];
};

Part.normalizeQueryOptionValue = function (value) {
  return value.match(/^(?:\s*)(?:<?)(?:franz:)?([^<>]*?)(?:>?)(?:\s*)$/)[1];
};

export default Part;
