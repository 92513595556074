import Base from '../../base';
import Util from '../../util';
import setImmediate from '../../set-immediate';
import $ from 'jquery';
import infoTemplate from './info.mold';
import resultTabHeaderTemplate from './result-tab-header.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var queryData = $arg.queryData,
      resizableNode;

__out.push("\n\n<div id=\"queryResults\">\n  <ul class=\"simple\">\n    ");
if (!queryData.getInformation() ) {
__out.push("\n      <li><a href=\"#queryData\">Results</a></li>\n    ");
}
__out.push("\n    ");
if (queryData.getInformation() ) {
__out.push("\n      <li><a id=\"result-tab-header\" href=\"#queryData\">");
__out.push(resultTabHeaderTemplate.template(queryData));
__out.push("</a></li>\n      <li><a href=\"#queryInformation\"><span id=\"informationTabTitle\">Information</span></a></li>\n    ");
}
__out.push("\n  </ul>\n  <div id=\"queryData\">\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){resizableNode = new Util.ResizableNode($node, {
          minHeightInPixels: 300,
          afterNodes: ['#query-download'],
          additionalBottomMargin: 40
        });
    }) + "\"></var>");
__out.push("\n    ");
__out.push($arg.resultsTemplate.template(queryData) );
__out.push("\n  </div>\n  ");
__out.push(infoTemplate.template(queryData.getInformation()) );
__out.push("\n</div>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  $('#queryResults').tabs();
  if ($('.queryWarningFlag').length) {
    $('#informationTabTitle').
      text('Warnings').
      css('color', 'red');
  } else {
    $('#informationTabTitle').
      text('Information').
      css('color', 'black');
  }
  resizableNode.recalculateNodeHeight();
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;