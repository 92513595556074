import Dispatch from '../dispatch';
import App from '../app';
import Catalog from '../catalog';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h1>\n  <a class=\"plain\" href=\"");
__out.push(Mold.escapeHTML(Dispatch.url('/') ));
__out.push("\">AllegroGraph WebView</a>\n  <span id=\"agversion\">");
__out.push(Mold.escapeHTML(App.agraphVersion || '' ));
__out.push("</span>\n  <span id=\"server-warnings-indicator\"></span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Catalog.updateWarningsOnce() }) + "\"></var>");
__out.push("\n</h1>\n");
if (App.currentSession) {
__out.push("\n  <p class=\"location-element\">\n    <span class=\"subtle location-tag\">session</span>\n    <span id=\"sessionid\"></span>\n  </p>\n");
} else if (App.currentRepository) {
__out.push("\n  <p class=\"location-element\">\n    <span class=\"subtle location-tag\">repository</span>\n    <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('') ));
__out.push("\" class=\"plain\">");
__out.push(Mold.escapeHTML(App.currentRepository));
__out.push("</a>\n  </p>\n  ");
if (App.currentShard) {
__out.push("\n    <p class=\"location-element\">\n      <span class=\"subtle location-tag\">shard</span>\n      <span id=\"shard-id\">");
__out.push(Mold.escapeHTML(App.currentShard));
__out.push("</span>\n    </p>\n  ");
}
__out.push("\n");
} else if (App.isInCatalog()) {
__out.push("\n  <p class=\"location-element\">\n    <span class=\"subtle location-tag\">catalog</span>\n    <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('') ));
__out.push("\" class=\"plain\">");
__out.push(Mold.escapeHTML(App.currentCatalog));
__out.push("</a>\n  </p>\n");
}
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;