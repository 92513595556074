import Part from '../../part';
import $ from 'jquery';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<tr>\n <td>\n  <label class=\"heading\" for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("upload-context\">Context:</label>\n  <input ");
Mold.dispatchCustom("importcontexttitle", undefined, __out);
__out.push(" type=\"text\" name=\"context\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("upload-context\" style=\"width: 20em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
if ($node.value) {
         var full = Part.NameSpaces.restore($node.value);
         if (full) {
           $node.value = full;
         }
       }
});}) + "\"></var>");
__out.push("\n    (leave blank to use the default graph)\n </td>\n</tr>\n\n<tr>\n <td>\n  <label class=\"heading\">Base URI:</label>\n  <input title=\"Specify the URI to be used as an RDF/XML document's base URI or JSON-LD global @base property.\" type=\"text\" name=\"baseURI\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("baseURI\" style=\"width: 20em\"/>\n </td>\n</tr>\n\n\n<tr>\n  <td>\n    <label class=\"heading\" for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("attributes\">Attributes:</label>\n    <input title=\"Specify attributes to be applied to triples that do not specify their own.\" type=\"text\" name=\"attributes\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("attributes\" style=\"width: 40em\"/>\n  </td>\n</tr>\n\n<tr>\n <td>\n  <label class=\"heading\">Relax syntax:</label>\n  <input title=\"Relax syntax reduces the amount of input validation the loader performs depending on the format.\" class=\"centered-checkbox\" type=\"checkbox\" name=\"relaxSyntax\" value=\"true\"/>\n </td>\n</tr>\n\n<tr>\n  <td>\n    <label class=\"heading\" for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("externalReferences\">External references:</label>\n    <input title=\"Specify that references to external files for such formats as RDF/XML and JSON-LD should be followed and not result in an error.\"\n           class=\"centered-checkbox\" type=\"checkbox\"\n           name=\"externalReferences\"\n           id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("externalReferences\"\n           value=\"true\">\n    <label for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("externalReferenceTimeout\" style=\"width: auto; margin-left: 1em; margin-bottom: 0.5em;\">External reference timeout:</label>\n    <input title=\"Specify the timeout (in seconds) to download externally referenced files.\" type=\"number\" value=\"\" min=\"0\"\n           name=\"externalReferenceTimeout\"\n           id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("externalReferenceTimeout\"/>\n  </td>\n</tr>\n\n<tr>\n <td>\n  <label class=\"heading\">Store source:</label>\n  <input title=\"Store each toplevel JSON-LD object in the repository.\" class=\"centered-checkbox\" type=\"checkbox\" name=\"storeSource\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("storeSource\" value=\"true\"/>\n  <label class=\"heading\" style=\"width: auto; margin-left: 1em;\">Suppress duplicate embedded triples (for Turtle*/TriG*):</label>\n  <input title=\"\" class=\"centered-checkbox\" type=\"checkbox\" name=\"suppressDuplicateEmbeddedTriples\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("suppressDuplicateEmbeddedTriples\" value=\"false\"/>\n </td>\n</tr>\n\n");
if ($arg.extendedOptions ) {
__out.push("\n<tr>\n <td>\n  <label class=\"heading\">File loading mode:</label>\n  <input title=\"Executes in a single transaction, ensuring that no triples are added if the import fails. Not recommended for larger files.\" type=\"radio\" name=\"useAgload\" value=\"false\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("regular-load\" checked />\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$('#' + $arg.prefix + 'bulk-loaders').prop("disabled", true); 
});}) + "\"></var>");
__out.push("\n  <label for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("regular-load\">Transactional</label>\n\n  <input title=\"Uses agload to distribute the work over multiple processors. This is usually the fastest method for large imports.\" type=\"radio\" name=\"useAgload\" value=\"true\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("bulk-load\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$('#' + $arg.prefix + 'bulk-loaders').prop("disabled", false); 
});}) + "\"></var>");
__out.push("\n  <label for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("bulk-load\">Using <a href=\"http://franz.com/agraph/support/documentation/current/agload.html\" target=\"_blank\">agload</a></label>\n\n  <label class=\"heading\">Agload threads:</label>\n  <input type=\"number\" value=\"\" min=\"0\"\n         name=\"bulkLoaders\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("bulk-loaders\" title=\"Number of threads to use\" disabled />\n  <span class=\"subtle\">(blank or 0 = default)</span>\n </td>\n</tr>\n");
}
__out.push("\n\n");
if ($arg.extendedOptions ) {
__out.push("\n<tr>\n <td>\n  <label class=\"heading\">Error handling:</label>\n  <input title=\"Cancel the load on the first error\" type=\"radio\" name=\"continueOnError\" value=\"false\" checked id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("error-strategy-cancel\"/>\n  <label for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("error-strategy-cancel\">Cancel load</label>\n  <input title=\"When possible, continue loading even if errors are found\" type=\"radio\" name=\"continueOnError\" value=\"true\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("error-strategy-ignore\"/>\n  <label for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("error-strategy-ignore\">Ignore errors</label>\n </td>\n</tr>\n\n<tr>\n <td>\n  <label for=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("bulkLoad\" class=\"heading\">Use bulk load mode:</label>\n  <input title=\"Bulk mode removes durability guarantees for all running transactions, but will also speed up large loads. Bulk load mode will be turned off when the load is finished, restoring durability guarantees.\"\n         class=\"centered-checkbox\" type=\"checkbox\" name=\"bulkLoad\" id=\"");
__out.push(Mold.escapeHTML($arg.prefix));
__out.push("bulkLoad\" value=\"true\"/>\n </td>\n</tr>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;