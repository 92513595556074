import $ from 'jquery';
import _ from 'underscore';

import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';

/**
 * Various utility functions.
 * @namespace Util
 */
var Util = {

  // Events
  // Standardize a few unportable event properties.
  _normalizeEvent: function (event) {
    if (!event.stopPropagation) {
      event.stopPropagation = function () {
        this.cancelBubble = true;
      };
      event.preventDefault = function () {
        this.returnValue = false;
      };
    }
    if (!event.stop) {
      event.stop = function () {
        this.stopPropagation();
        this.preventDefault();
      };
    }
    if (event.pageX === undefined && event.clientX) {
      event.pageX = event.clientX +
        (document.scrollLeft || document.body.scrollLeft);
      event.pageY = event.clientY +
        (document.scrollTop || document.body.scrollTop);
    }

    if (!event.target && event.srcElement) {
      event.target = event.srcElement;
    }

    if (event.type === 'keypress') {
      if (event.charCode === 0 ||
          event.charCode === null ||
          event.charCode === undefined) {
        event.code = event.keyCode;
      } else {
        event.code = event.charCode;
      }
      event.character = String.fromCharCode(event.code);
    }
    return event;
  },

  // Portably register event handlers.
  connect: function (node, type, handler) {
    function wrapHandler(event) {
      handler(Util._normalizeEvent(event || window.event));
    }
    if (typeof node.addEventListener === 'function') {
      node.addEventListener(type, wrapHandler, false);
      return function () {
        node.removeEventListener(type, wrapHandler, false);
      };
    } else {
      node.attachEvent('on' + type, wrapHandler);
      return function () {
        node.detachEvent('on' + type, wrapHandler);
      };
    }
  },

  disconnect: function (handler) {
    handler();
  },

  // Collections
  forEach: function (collection, action) {
    if (collection && collection.length) {
      var l = collection.length;
      for (var i = 0; i < l; ++i) {
        action(collection[i], i);
      }
    }
  },

  map: function (func, collection) {
    var l = collection.length;
    var result = [];
    for (var i = 0; i < l; ++i) {
      result.push(func(collection[i]));
    }
    return result;
  },

  filter: function (pred, collection) {
    var result = [];
    var l = collection.length;
    for (var i = 0; i < l; ++i) {
      var cur = collection[i];
      if (pred(cur)) {
        result.push(cur);
      }
    }
    return result;
  },

  findIf: function (pred, collection) {
    var l = collection.length;
    for (var i = 0; i < l; ++i) {
      var cur = collection[i];
      if (pred(cur)) {
        return cur;
      }
    }
    return undefined;
  },

  some: function (pred, collection) {
    var l = collection.length;
    for (var i = 0; i < l; ++i) {
      if (pred(collection[i])) {
        return true;
      }
    }
    return false;
  },

  every: function (pred, collection) {
    var l = collection.length;
    for (var i = 0; i < l; ++i) {
      if (!pred(collection[i])) {
        return false;
      }
    }
    return true;
  },

  member: function (value, collection) {
    return Util.some(function (x) {
      return x === value;
    }, collection);
  },

  remove: function (value, collection) {
    var result = [];
    var l = collection.length;
    for (var i = 0; i < l; ++i) {
      if (collection[i] !== value) {
        result.push(collection[i]);
      }
    }
    return result;
  },

  forEachIn: function (object, action) {
    for (var property in object) {
      if (Object.prototype.hasOwnProperty.call(object, property)) {
        action(property, object[property]);
      }
    }
  },

  isBlank: function (string) {
    return (!string || /^\s*$/.test(string));
  },

  ident: function (x) {
    return x;
  },

  // Objects
  clone: function (object) {
    function Dummy() {}
    Dummy.prototype = object;
    return new Dummy();
  },

  fill: function (dest, source) {
    Util.forEachIn(source, function (name, val) {
      dest[name] = val;
    });
  },

  defaultTo: function (dest, source) {
    Util.forEachIn(source, function (name, val) {
      if (!dest.hasOwnProperty(name)) {
        dest[name] = val;
      }
    });
  },

  getter: function (prop) {
    return function (obj) {
      return obj[prop];
    };
  },

  simpleDate: function (date) {
    if (date) {
      return (1 + date.getMonth()) + '/' +
              date.getDate() + '/' +
              date.getFullYear();
    } else {
      return null;
    }
  },

  /**
   *
   * @return {Number} the number of minutes west of GMT for the
   * timezone of the browser.  Can also be negative to express minutes
   * east of GMT.  This is not a constant - it can vary as daylight saving
   * time begins or ends while the application runs.
   */
  tzOffset: function () {
    return new Date().getTimezoneOffset();
  },

  Prototype: {
    extend: function extend(properties) {
      var object = Util.clone(this);
      if (properties) {
        Util.fill(object, properties);
      }
      return object;
    },
    create: function create() {
      var instance = Util.clone(this);
      if (typeof instance.construct === 'function') {
        instance.construct.apply(instance, arguments);
      }
      return instance;
    },
  },

  // DOM

  /**
   * Converts a HTML input to a date input, using native widgets if possible.
   *
   * @param {Object} node - DOM input node to be converted.
   * @param {Date} [value] - Initial value, represented as a Date object.
   * @return {function} - A function that will return the entered date.
   *     If the user has not entered a date null will be returned.
   */
  dateInput: function (node, value) {
    if (Modernizr.inputtypes.date) {
      node.type = 'date';
      if (value) {
        node.value = Util.formatDateWithoutTime(value);
      }
      return function () {
        if (node.value) {
          // Value will be formatted as yyyy-mm-dd
          // We parse that ourselves, since browsers are inconsistent
          // in their behavior wrt. time zones.
          var ymd = node.value.split('-');
          var year = parseInt(ymd[0], 10);
          var month = parseInt(ymd[1], 10) - 1;
          var day = parseInt(ymd[2], 10);
          return new Date(year, month, day);
        } else {
          return null;
        }
      };
    } else {
      node.placeholder = 'mm/dd/yyyy';
      var picker = $(node).datepicker({
        inline: true,
        showOn: 'both',
        buttonText: '<i class="far fa-calendar-times" title="Open calendar"></i>',
      });
      if (value) {
        picker.datepicker('setDate', value);
      }
      return function () {
        return picker.datepicker('getDate');
      };
    }
  },

  removeNode: function (node) {
    // Make sure to clean up any jQuery bindings in the node:
    $(node).remove();
  },

  isInDocument: function (node, doc) {
    doc = doc || document;
    return doc.body.contains(node);
  },

  selectValue: function (node) {
    var opt = node.options[node.selectedIndex];
    // IE7 needs value and text set for option.value to work
    return opt && opt.value;
  },

  totalOffset: function (node) {
    var x = 0;
    var y = 0;
    while (node) {
      x += node.offsetLeft; y += node.offsetTop;
      node = node.offsetParent;
    }
    return { x: x, y: y };
  },

  // Three different ways to set an element's opacity.
  setOpacity: function (node, opacity) {
    node.style.filter = 'alpha(opacity=' + opacity + ')';
    node.style.opacity = String(opacity);
    node.style.MozOpacity = String(opacity);
  },

  /*
   ** change() method for input fields of class 'buttonLinked'.
   ** If NODE is empty, the button associated with the id
   ** in NODEs 'linkedButton' attribute will be disabled.
   ** Else, if said button is not already enabled, it will be
   ** enabled.
   **
   ** If there's an initialValue, the linked button will only
   ** be enabled if the current value differs from it.
   **
   ** see setupEditorForNode in store.js for doing something
   ** similar with CodeMirror editors.
   */
  maybeEnableLinkedButton: function (node, initialValue=null) {
    function nodeValueChanged(node, initialValue) {
      let currentValue = node.value;
      return ((currentValue.length !== initialValue.length) ||
              (initialValue.localeCompare(currentValue) !== 0));
    }

    if (node.hasAttribute('linkedButton')) {
      let buttonID;
      let button;

      buttonID = node.getAttribute('linkedButton');
      button = $$(buttonID);

      // if there's no initialValue, then we enable if the input area
      // has contents.
      if (initialValue === null) {
        button.disabled = node.value === undefined || node.value.length === 0;
      } else {
        button.disabled = !nodeValueChanged(node, initialValue);
      }
    }
  },

  // Time
  time: function () {
    return new Date().getTime();
  },

  /**
   * Given a number, return its absolute, rounded representation, and pad
   * it with a zero if it's less than 10.
   * A useful utility function for formatting timestamps.
   *
   * @param {number} num
   * @return {string}
   */
  _padOneZero: function (num) {
    var norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  },

  /**
   * Converts a Date instance to its ISO 8601 representation, using
   * the local timezone.
   * See <http://stackoverflow.com/a/17415677>
   * and <http://stackoverflow.com/a/28149561>.
   *
   * @param {Date} date - date to format
   * @return {string} - date in ISO 8601 with local timezone
   */
  formatLocalDate: function (date) {
    var pad = this._padOneZero.bind(this);
    var timezoneOffset = -date.getTimezoneOffset();
    var timezoneOffsetMillis = timezoneOffset * 60 * 1000;
    var dif = timezoneOffset >= 0 ? '+' : '-';
    var localIsoTime = (new Date(date.getTime() + timezoneOffsetMillis))
        .toISOString().slice(0, -1);
    return localIsoTime +
      dif + pad(timezoneOffset / 60) +
      ':' + pad(timezoneOffset % 60);
  },

  /**
   * Converts a Date instance to yyyy-mm-dd (in current time zone).
   *
   * @param {Date} date - date to format
   * @return {string} - formatted date.
   */
  formatDateWithoutTime: function (date) {
    return date.getFullYear() + '-'
        + Util._padOneZero(date.getMonth() + 1) + '-'
        + Util._padOneZero(date.getDate());
  },

  /**
   * Like number.toFixed, but uses thousand separators.
   *
   * @param {number} number - The number to be formatted.
   * @param {number} [precision] - Number of decimal places to show.
   *                               The default is 0.
   * @return {string} - printed representation of the number.
   */
  toFixed: function (number, precision) {
    // Could just use number.toLocaleString(), but that is
    // not flexible enough under IE10.
    // Note: ECMA-262 20.1.3.3 states that the decimal separator
    // returned by number.toFixed() will always be a ".".
    var parts = number.toFixed(precision).split('.');
    var groups = [];
    var n = parts[0].length;
    // Use 1000 separators (and not e.g. 100).
    var GROUP_SIZE = 3;
    for (var i = 0; i < n; i += GROUP_SIZE) {
      groups.push(parts[0].substring(n - i - GROUP_SIZE, n - i));
    }
    parts[0] = groups.reverse().join(',');
    return parts.join('.');
  },

  // arrays of values should be encoded as repeated args
  queryString: function (map) {
    var acc = [];
    function add(name, val) {
      acc.push(name + '=' + val);
    }
    function doEncode(name, val) {
      var type = typeof val;
      if (val === null || val === undefined) {
        // Don't encode this key.
      } else if (type === 'boolean') {
        add(name, val ? 'true' : 'false');
      } else if (type === 'string') {
        add(name, encodeURIComponent(val));
      } else if (type === 'number') {
        add(name, val);
      } else if (val.length !== undefined) {
        for (var i = 0; i < val.length; ++i) {
          doEncode(name, val[i]);
        }
      } else {
        throw new Error('Can not encode ' + val);
      }
    }
    Util.forEachIn(map, doEncode);
    return acc.join('&');
  },

  encodeFragment: function (string) {
    return encodeURI(string).replace(/#/, '%23');
  },

  // JSON
  readJSON: function (string) {
    if (string) {
      return JSON.parse(string);
    } else {
      return null;
    }
  },
  writeJSON: JSON.stringify.bind(JSON),

  // storage
  /**
   * Gets the Storage object for given scope.
   *
   * @param {string|undefined} [scope] - Either 'session' or 'local'. If another
   *   value is passed, local storage will be returned.
   * @return {Storage} - a Storage object.
   * @private
   */
  _getStorageObject: function (scope) {
    return scope === 'session' ? sessionStorage : localStorage;
  },

  /**
   * Wraps a scope in an array or returns an array of all scopes.
   *
   * @param {string|undefined} [scope] - Scope to be returned in
   *   the array, if undefined all scopes will be returned.
   * @return {Array.<string>} - an array of scope names.
   * @private
   */
  _getScopes: function (scope) {
    if (scope === undefined) {
      return ['session', 'local'];
    } else {
      return [scope];
    }
  },

  /**
   * Retrieve a value stored with setInStorage.
   *
   * @param {String} key - Storage key.
   * @param {String} [scope] - Either 'local' or 'session',
   *   if not given both scopes are checked (session first).
   * @return {*} - stored value or null (if not found).
   */
  getFromStorage: function (key, scope) {
    var scopes = Util._getScopes(scope);

    var value = null;
    for (var i = 0; i < scopes.length && value === null; i++) {
      value = Util._getStorageObject(scopes[i]).getItem(key);
    }
    return value === null ? value : Util.readJSON(value);
  },

  /**
   * Delete a value stored with setInStorage.
   *
   * @param {String} key - Storage key.
   * @param {String} [scope] - Either 'local' or 'session',
   *   when undefined the key will be removed from all scopes.
   */
  removeFromStorage: function (key, scope) {
    var scopes = Util._getScopes(scope);
    for (var i = 0; i < scopes.length; i++) {
      Util._getStorageObject(scopes[i]).removeItem(key);
    }
  },
  /**
   * Store or change a value in browser's local store.
   *
   * @param {String} key - Storage key.
   * @param {*} value - Value to be stored, must be JSON-encodable.
   * @param {String} [scope] - Either 'local' or 'session', 'local' is the default.
   */
  setInStorage: function (key, value, scope) {
    Util._getStorageObject(scope).setItem(key, Util.writeJSON(value));
  },

  // URL
  relativeUrl: function (url) {
    return url.match('http.*://[^/]*/(.*)')[1];
  },

  /**
   * @param {string} url - URL to check
   * @return {boolean} - whether given URL is an absolute HTTP or HTTPS URL.
   */
  isAbsoluteUrl: function (url) {
    return /^https?:\/\//.test(url);
  },

  /**
   * Given a URL, returns it in absolute version.
   *
   * @param {string} url - URL to convert
   * @return {string} - absolute version of given URL.
   */ // jscs:ignore jsDoc
  getAbsoluteUrl: (function () {
    // A nice utility that allows us to construct absolute URLs from relative
    // ones with ease (by setting URLs as HREFs of an anchor).
    var urlParser = document.createElement('a');

    return function (url) {
      if (Util.isAbsoluteUrl(url)) {
        return url;
      }
      urlParser.href = url;
      // Now urlParser.href contains an absolute URL.
      return urlParser.href;
    };
  })(),

  module: function (init) {
    var ns = {};
    init(ns);
    return ns;
  },

  idIsEmpty: function (id) {
    // test if html element with a given id has no value.
    var v = $$(id);

    if (v && v.value && v.value.length > 0) {
      // have a value for this field
      return false;
    } else {
      return true;
    }
  },

  /**
   * Return a new array of namespaces, sorted alphabetically by prefix,
   * ignoring case.
   *
   * @param {NamespaceEntry[]} namespaces - namespaces to sort
   * @return {NamespaceEntry[]} - sorted namespaces
   */
  getSortedNamespaces: function (namespaces) {
    return namespaces.sort(function (ns1, ns2) {
      if (ns1.prefix.toLowerCase() < ns2.prefix.toLowerCase()) {
        return -1;
      } if (ns1.prefix.toLowerCase() > ns2.prefix.toLowerCase()) {
        return 1;
      } else {
        if (ns1.type > ns2.type) {
          return -1;
        } else if (ns1.type < ns2.type) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  },

  /**
   * Return a new array of query optiosn sorted alphabetically by name,
   * ingnoring case.
   *
   * @param {QueryOptionEntry[]} queryOptions - query options to sort
   * @return {QueryOptionEntry[]} - sorted query options
   */
  getSortedQueryOptions: function (queryOptions) {
    return queryOptions.sort(function (qo1, qo2) {
      if (qo1.name.toLowerCase() < qo2.name.toLowerCase()) {
        return -1;
      } if (qo1.name.toLowerCase() > qo2.name.toLowerCase()) {
        return 1;
      } else {
        if (qo1.type > qo2.type) {
          return -1;
        } else if (qo1.type < qo2.type) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  },
};

// String manipulation
Util.String = {

  /**
   * Return true iff prefix is a prefix of str.
   *
   * @param {string} str - string to check for prefix
   * @param {string} prefix - expected prefix of str
   * @return {boolean} - whether str starts with prefix
   */
  startsWith: function (str, prefix) {
    return str.lastIndexOf(prefix, 0) === 0;
  },

  /**
   * Strips a single trailing slash from a string.
   * Returns the unmodified string if there is no slash at the end.
   *
   * @param {string} url - String to be processed.
   * @return {string} - String without the trailing slash.
   */
  stripTrailingSlash: function (url) {
    if (url && url.charAt(url.length - 1) === '/') {
      return url.slice(0, url.length - 1);
    }
    return url;
  },

  /**
   * Capitalizes the first character of a string
   *
   * @param {string} text - string to be capitalized
   * @return {string} - capitalized string.
   */
  capitalize: function (text) {
    if (text.length > 0) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    } else {
      return text;
    }
  },
};

// Dictionary
Util.Dictionary = Util.Prototype.extend({
  construct: function construct(startValues) {
    this.values = startValues || {};
  },
  store: function store(name, value) {
    this.values[name] = value;
  },
  remove: function remove(name) {
    delete this.values[name];
  },
  lookup: function lookup(name) {
    return this.values[name];
  },
  contains: function contains(name) {
    return Object.prototype.propertyIsEnumerable.call(this.values, name);
  },
  each: function each(action) {
    Util.forEachIn(this.values, action);
  },
  clear: function clear(values) {
    this.values = values || {};
  },
});

// Set up destroy handlers that can be used to execute when an element
// is destroyed.
(function () {
  $.event.special.destroyed = {
    remove: function (o) {
      if (o.handler) {
        o.handler();
      }
    },
  };
})();

Util.ResizableNode = (function () {
  /**
   * This class encapsulates functionality of automatically adjusting
   * a particular node's height to fill the remainder of the viewport.
   *
   * @param {Node} node - the node whose height should be dynamic
   * @param {Object} config - additional configuration
   *                         (see below for possible values)
   * @constructor
   */
  function ResizableNode(node, config) {
    // Don't redraw more often than once per 100ms:
    var DEBOUNCE_INTERVAL = 100;
    this._node = node;

    this._config = $.extend({

      // Minimal height of the results div (in pixels):
      minHeightInPixels: 0,

      // Selectors of nodes that follow the resizable node and should be
      // considered when calculating the size of the node. Please note that
      // for this to work properly, the nodes should be present (i.e. not
      // still loading or filling with data) when the resizable node's
      // height is calculated.
      afterNodes: [],

      // Additional margin you want to be left at the bottom of the viewport:
      additionalBottomMargin: 0,

    }, config);

    this._resizeHandler = _.debounce(
      this.recalculateNodeHeight.bind(this), DEBOUNCE_INTERVAL);
    this._init();
  }

  $.extend(ResizableNode.prototype, {

    _init: function () {
      var self = this;

      // Recalculate the height on resize events:
      $(window).on('resize', this._resizeHandler);

      // Clean up when observed node is destroyed:
      $(this._node).bind('destroyed', function () {
        self.destroy();
      });
    },

    /**
     * Unregisters global resize handler and frees DOM node pointers.
     */
    destroy: function () {
      if (this._node) {
        $(window).off('resize', this._resizeHandler);

        // Drop references to node:
        this._node = null;
      }
    },

    _nodeStillValid: function () {
      return this._node && Util.isInDocument(this._node);
    },

    _calculateAfterNodesHeight: function () {
      var totalHeight = 0;
      _.each(this._config.afterNodes, function (selector) {
        $(selector).each(function () {
          var rect = this.getBoundingClientRect();
          totalHeight += rect.bottom - rect.top;
        });
      });
      return totalHeight;
    },

    /**
     * Checks if this._node is still valid:
     *  - if yes, recalculates its height;
     *  - if not, destroys itself.
     */
    recalculateNodeHeight: function () {
      if (this._nodeStillValid()) {
        this._recalculateValidNodeHeight();
      } else {
        this.destroy();
      }
    },

    /**
     * Sets the 'height' CSS property of this._node so that it fills
     * the whole viewport (minus afterNodes and additional margin).
     * Assumes this._node is valid (not null and in DOM).
     */
    _recalculateValidNodeHeight: function () {
      var viewportHeight = window.innerHeight;
      var nodeTop = this._node.getBoundingClientRect().top;
      var afterNodesHeight = this._calculateAfterNodesHeight();
      var desiredNodeHeight = viewportHeight - nodeTop - afterNodesHeight -
        this._config.additionalBottomMargin;

      desiredNodeHeight = Math.max(
        desiredNodeHeight, this._config.minHeightInPixels);
      $(this._node).css('height', desiredNodeHeight + 'px');
    },
  });

  return ResizableNode;
})();

// Used to detect loopback adresses:
//   - localhost
//   - IPv6 loopback address
//   - IPv4 loopback adress, in dotted notation or as an integer.
var LOOPBACK_PATTERN = /^localhost$|^127(?:\.[0-9]+){1,3}$|^[0:]*:0*1$/i;
var LOOPBACK_MIN = 0x7f000000;
var LOOPBACK_MAX = 0x7fffffff;

/**
 * Checks if a text represents a positive, decimal integer.
 *
 * Note that this chck will succeed even for very large integers
 * that cannot be represented as JS numbers.
 *
 * @param {string} text - Text to be checked.
 * @return {boolean} - True if the text is an integer, false otherwise.
 */
Util.isPositiveInteger = function (text) {
  return /^0$|^[1-9][0-9]*$/.test(text);
};

/**
 * Checks if a hostname looks like a loopback address.
 *
 * Note that this will return true for 'localhost', even if that is
 * mapped to a non-loopback adress in the hosts file.
 *
 * @param {string} host - Host to be checked.
 * @return {boolean} - True for loopback adresses, false otherwise.
 */
Util.isLoopback = function (host) {
  if (Util.isPositiveInteger(host)) {
    var value = parseInt(host, 10);
    return value >= LOOPBACK_MIN && value <= LOOPBACK_MAX;
  }
  return LOOPBACK_PATTERN.test(host);
};

/**
 * If an element with id of ID exists in the DOM, then scroll to it.
 *
 * @param {string} id - id of the element to look for.
 * @return {boolean} - true if found, else false.
**/
Util.scrollToIfPresent = function (id) {
  let found = $$(id);
  if (found) {
    found.scrollIntoView();
    return true;
  }
  return false;
};

// Sets the innerHTML of NODE to TEXT, and the CSS style
// of NODE to COLOR.
Util.setColoredText = function (node, text, color = 'inherit') {
  node.style.color = color;
  node.innerHTML = text;
};

// Shorter version of getElementById:
export function $$(name) {
  return document.getElementById(name);
}

export default Util;
