import { $$ } from '../util';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<p>The code entered below gets evaluated in every (shared) back-end. You\ncan use this to define, for example, a collection of Prolog functors.</p>\n\n<div class=\"inputfield\" id=\"initfile-input\">\n  <textarea></textarea>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.initfileInput($node);}) + "\"></var>");
__out.push("\n</div>\n\n<p>\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"warnerror\"><label> Compilation warnings are considered errors and will prevent the file being saved</label>\n  <br>\n  <button>Save</button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.saveInitfile($$('saving'), $$('warnerror'));
});}) + "\"></var>");
__out.push("\n  &nbsp; &nbsp; <span id=\"saving\"></span>\n</p>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;