import App from '../../app';
import Reports from '../../reports';
import $ from 'jquery';
import _ from 'underscore';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n\n<!-- title= is needed by jquery ui tooltips. -->\n<div class=\"report-plot \n            ");
if ($arg.embedded ) {
__out.push("report-plot-embedded");
}
__out.push("\"\n     title=\"\">\n</div>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){var data = _.map($arg.field.value, function (value) {
  return {
    data: value.value,
    color: value.color,
    label: {
      text: value.label,
      next: value.next,
    },
  };
});

var node = $($node);
var title = node.siblings('.report-pie-title');

var plot;

function display() {
  if (!node.width()) {
    // Prevent nasty console errors. The plot will be redrawn later.
    return;
  }
  plot = $.plot(node, data, {
    series: {
      pie: {
        show: true,
        label: {
          show: false,
        },
      },
    },
    legend: {
      show: !$arg.embedded,
      labelFormatter: function (label, series) {
        var value = Reports.formatValue(series.data[0][1],
                                        $arg.field.type.elements);
        var text = label.text + ': ' + value;
        if (label.next) {
          var url = Reports.reportUrl($arg.path, label.next);
          text = '<a href="' + url + '">' + text + '</a>';
        }
        return text;
      },
    },
    grid: {
      hoverable: true,
      clickable: true
    },
  });

  // If the total is 0 no pie chart is displayed, so let's
  // add a message to clarify what's happening.
  // Based on https://stackoverflow.com/questions/23254307/x
  if (isNaN(plot.getData()[0].percent)){
    var canvas = plot.getCanvas();
    var ctx = canvas.getContext("2d");
    var x = (node.outerWidth() - node.find('.legend > div').outerWidth()) / 2;
    var y = node.outerHeight() / 2;
    ctx.font = 'small-caps bold 30pt sans-serif';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'DarkGray';
    ctx.fillText('--- no data ---', x, y);
    title.hide();
  }

  // Adjust the title position - the pie is centered in the space
  // left after rendering the legend, title should be below the pie.
  if (!$arg.embedded) {
    var chartWidth = node.outerWidth()
                       - node.find('.legend > div').outerWidth();
    title.width(chartWidth);
    title.show();
  }
}

display();
// Embedded fields don't need to be resized
if (!$arg.embedded) {
  $(window).resize(_.debounce(display, 300));
}

node.bind('plotclick', function (evt, pos, item) {
  if (item) {
    var value = $arg.field.value[item.seriesIndex];
    if (value.next) {
      App.goTo(Reports.reportUrl($arg.path, value.next));
    }
  }
});

var tooltipShown = false;

node.bind("plothover", function (event, pos, item) {
  var text;
  var value;

  if (item) {
    value = $arg.field.value[item.seriesIndex];
    text = Reports.tooltipForValue(value.value, $arg.field.type.elements);
  }
  if (text) {
    if (!tooltipShown) {
      node.tooltip({ 
        content: function () { return '---'; },
        track: true,
      });
      node.trigger('mouseenter');
      tooltipShown = true;
    }
    node.tooltip('option', 'content', value.label + ' : ' + text);
  } else if (tooltipShown) {
    node.tooltip('destroy');
    // Two bugs in one: 
    // 1. jquery removes the title attribute completely
    // 2. it doesn't work without a title attribute,
    //    even when content is specified explicitly.
    node.attr('title', '');
    tooltipShown = false;
  }
});

}) + "\"></var>");
__out.push("\n\n");
if (!$arg.embedded ) {
__out.push("\n  <div class=\"report-pie-title\">");
__out.push(Mold.escapeHTML($arg.field.label ));
__out.push("</div>\n");
}
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;