
import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div id=\"requestsPage\" class=\"dialog\">\n\n  <h2>Requests</h2>\n\n  <table class=\"fixed-width-table display\"></table>\n\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;