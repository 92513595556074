import App from '../../app';
import Layout from '../../layout';
import { $$ } from '../../util';
import Store from '../../store';
import Base from '../../base';
import $ from 'jquery';

import importFileFormatTemplate from './import-file-format.mold';
import importAgloadOptionsTemplate from './import-agload-options.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var EDITOR_MODES = {
    'text/plain': 'text/n-triples',
    'text/x-nquads': 'text/n-triples',
    'application/x-extended-nquads': 'text/n-triples',
    'application/rdf+xml': 'application/xml',
    'application/trig': 'text/turtle',
    'text/turtle': 'text/turtle',
  };
  var editor = null;

__out.push("\n<form class=\"small-form\" method=\"post\" action=\"\" id=\"statements-import-form\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
    $arg.start(editor.getValue(), $node);
  
});}) + "\"></var>");
__out.push("\n  <table width=\"100%\">\n    <tr><td>\n      <p>Enter statements to be imported</p>\n    </td></tr>\n    <tr><td>\n      <label class=\"heading\" for=\"upload-body\">Statements:</label>\n      <div id=\"import-statements\" style=\"width: 40em; display: inline-table\">\n        <textarea id=\"upload-statements\" name=\"body\"></textarea>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){editor = App.codeMirrorFromTextArea($node, {mode: 'text/plain', resizeHandleId: 'importStatementsResizeHandle'});
          editor.on("change", function(editor, changeObj) {
            $$('upload-ok').disabled = editor.getValue() === '';
          });
        }) + "\"></var>");
__out.push("\n        <div class=\"editorResizeHandle\" id=\"importStatementsResizeHandle\"></div>\n      </div>\n    </td></tr>\n    <tr><td>\n        ");
__out.push(importFileFormatTemplate.template({prefix: 'server-'}));
__out.push("\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){// #server-importfile-ct is the selectbox for selecting input's format (content-type). It's created from
          // the template import-file-format.mold, instantiated 6 lines above.
          $('#server-importfile-ct').change(function(e) {
            // update editor's mode
            var newMode = EDITOR_MODES[e.target.value] || "text/plain";
            editor.setOption("mode", newMode);
          });
        }) + "\"></var>");
__out.push("\n    </td></tr>\n    ");
__out.push(importAgloadOptionsTemplate.template({prefix: 'upload-'}));
__out.push("\n    <tr><td class=\"buttons\">\n      <!-- If the last input happens to be an unchecked checkbox,\n           IE will send an invalid request when the form is submitted\n           through XMLHttpRequest. So we add a hidden field here. -->\n      <input type=\"hidden\" name=\"internet-explorer-is\" value=\"suboptimal\" />\n      <button type=\"submit\" id=\"upload-ok\" disabled>OK</button>\n      <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    </td></tr>\n  </table>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;