import Store from '../../store';
import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"small-form add-triple\">\n  ");
var context;
__out.push("\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
$event.stop();
                 $arg.ok($node.elements.subject.value, $node.elements.predicate.value, $node.elements.object.value,
                         $node.elements.graph.value || null, $node.elements.attributes.value || null); 
});}) + "\"></var>");
__out.push("\n  <h3>Add a statement ");
Mold.dispatchCustom("nodeinputhelp", undefined, __out);
__out.push("</h3>\n  <table width=\"100%\">\n    <tr>\n      <td>\n        <p>\n          <label>Subject:</label> <input type=\"text\" name=\"subject\" value=\"");
__out.push(Mold.escapeHTML($arg.subj));
__out.push("\"/>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();}) + "\"></var>");
__out.push("<br/>\n          <label>Predicate:</label> <input type=\"text\" name=\"predicate\" value=\"");
__out.push(Mold.escapeHTML($arg.pred));
__out.push("\"/><br/>\n          <label>Object:</label> <input type=\"text\" name=\"object\" value=\"");
__out.push(Mold.escapeHTML($arg.obj));
__out.push("\"/><br/>\n          <label>Graph:</label> <input type=\"text\" name=\"graph\" value=\"");
__out.push(Mold.escapeHTML($arg.context || ""));
__out.push("\"/>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){context = $node;}) + "\"></var>");
__out.push("<br/>\n          <label>&nbsp;</label> <span>Existing graphs:\n            <select style=\"min-width: 15em\"><option checked></option></select>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Store.fillContexts($node);}) + "\"></var>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
context.value = Util.selectValue($node);
});}) + "\"></var>");
__out.push("</span><br/>\n          <label>&nbsp;</label> <span>Leave the graph field empty to use the default graph.</span></br>\n          <label>Attributes:</label> <input type=\"text\" name=\"attributes\" value=\"");
__out.push(Mold.escapeHTML($arg.attributes || ""));
__out.push("\"/><br/>\n        </p>\n      </td>\n      <td class=\"buttons\">\n        <button type=\"submit\">OK</button>\n        <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n  </table>\n</form>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;