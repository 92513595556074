// utilities for repl replication

/*
 * @namespace ReplUtil
 */

var ReplUtil = {};

ReplUtil.configParams = function (scheme, host, port, catalog,
                                  name, group, user, password, instanceName) {
  var args = {};

  if (scheme !== '') {
    args.scheme = scheme;
  }
  if (host !== '') {
    args.host = host;
  }
  if (port !== '') {
    args.port = port;
  }
  if (catalog !== '') {
    args.catalog = catalog;
  }
  if (name !== '') {
    args.name = name;
  }
  if (group !== '') {
    args.group = group;
  }
  if (user !== '') {
    args.user = user;
  }
  if (password !== '') {
    args.password = password;
  }
  if (instanceName !== '') {
    args.instanceName = instanceName;
  }
  return args;
};


export default ReplUtil;
