import App from '../app';
import Triples from '../triples';
import User from '../user';
import Base from '../base';
import Store from '../store';
import setImmediate from '../set-immediate';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div>\n  <input class=\"centered-checkbox\" type=\"checkbox\" id=\"nv-showcontexts\" ");
if (App.showContexts) {
__out.push("checked=\"checked\"");
}
__out.push("/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
App.toggleContexts($node.checked); App.refresh(); 
});}) + "\"></var>");
__out.push("\n  <label for=\"nv-showcontexts\" title=\"Show graph names\">Show graph (i.e., context) names when available:</label>\n</div>\n\n");
if (window.GMap2 && $arg.node.geo()) {
__out.push("\n  <h2>Location</h2>\n  <div style=\"width: 500px; height: 300px;\">\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(Triples.showNodeOnMap.bind(Triples, $arg.node, $node), 0);}) + "\"></var>");
__out.push("\n  </div>\n");
}
__out.push("\n\n");
Mold.forDepth++;
Mold.forEach(["subj", "pred", "obj"], function(type, $i) {
__out.push("\n  ");
if ($arg[type].length || User.userAccess("W")) {
__out.push("\n    <div class=\"block nodeview\">\n      <h2>\n        Statements with a ");
__out.push(Mold.escapeHTML(Base.FULL_NAME[type]));
__out.push(" of ");
Mold.dispatchCustom("part", $arg.node, __out);
__out.push("\n      </h2>\n      ");
if ($arg[type].length) {
__out.push("\n        ");
Mold.dispatchCustom("triples", {triples: $arg[type], ignore: type,
                   more: $arg[type].more && Triples.loadMoreNeighbours($arg.node, type, $arg[type].length)}, __out);
__out.push("\n      ");
}
__out.push("\n      ");
if (User.userAccess("W")) {
__out.push("\n        <p><span class=\"linkish\">Add statement...</span>\n           ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.addStatementDialog(type, $arg.node);
});}) + "\"></var>");
__out.push("</p>\n      ");
}
__out.push("\n    </div>\n  ");
}
__out.push("\n");
});
Mold.forDepth--;
__out.push("\n\n");
if ((!($arg.subj.length || $arg.pred.length || $arg.obj.length || User.userAccess("W")))) {
__out.push("\n  <div class=\"block\">\n    <h2>No statements.</h2>\n    <p>Node ");
Mold.dispatchCustom("part", $arg.node, __out);
__out.push(" is not connected to anything.</p>\n  </div>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;