import { $$ } from '../../util';
import Store from '../../store';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>External Solr free-text indexer setting</h2>\n\n<p style=\"margin-top: 1em\">\n  <label for=\"solr-endpoint\">Solr endpoint url:</label>\n  <input id=\"solr-endpoint\" value=\"");
__out.push(Mold.escapeHTML($arg.solr_endpoint ));
__out.push("\" type=\"text\" width=\"30em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("solr-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~This should be a URL of the Solr server, to be accessed from the AllegroGraph server. (for example, <tt>http://localhost:8983/solr</tt>)", __out);
__out.push("\n  <br/>\n  <label for=\"solr-id-field\">Document id field name (e.g. <tt>id</tt>):</label>\n  <input id=\"solr-id-field\" value=\"");
__out.push(Mold.escapeHTML($arg.solr_id_field ));
__out.push("\" type=\"text\" width=\"6em\"/>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$("solr-parameter-set").disabled = false; 
});}) + "\"></var>");
__out.push("\n  ");
Mold.dispatchCustom("help", "~This is a Solr field name containing an integer.  AllegroGraph takes the value as the triple id.", __out);
__out.push("\n  <br/>\n  <button id=\"solr-parameter-set\" type=\"button\">Set</button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.setSolrParameters(
                  $$('solr-endpoint').value, $$('solr-id-field').value);
});}) + "\"></var>");
__out.push("\n</p>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;