import Dispatch from '../dispatch';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div id=\"processesDialog\" class=\"dialog\">\n\n  <h2>Server processes</h2>\n\n<ul>\n  ");
Mold.forDepth++;
Mold.forEach($arg.processes, function(proc, $i) {
__out.push("\n    <li id=\"proc--");
__out.push(Mold.escapeHTML(proc.name.replace(' ', '_') ));
__out.push("\">\n      ");
__out.push(Mold.escapeHTML(proc.displayName));
__out.push(" <span class=\"subtle\" title=\"PID ");
__out.push(Mold.escapeHTML(proc.pid));
__out.push("\">(");
__out.push(Mold.escapeHTML(proc.pid));
__out.push(")</span>\n      <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('processes/' + proc.name + '/stacktrace') ));
__out.push("\">[stacktrace]</a>\n      ");
if ((proc.name !== "Hub") ) {
__out.push("\n         <span class=\"linkish\">[kill]</span>\n         ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.killProcess(proc.name);
});}) + "\"></var>");
__out.push("\n         <span class=\"linkish\">[start telnet]</span>\n         ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.telnetInProcess(proc.name);
});}) + "\"></var>");
__out.push("\n      ");
}
__out.push("\n      <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.url('processes/' + proc.pid + '/chart') ));
__out.push("\">[process info graphs]</a>\n    </li>\n  ");
});
Mold.forDepth--;
__out.push("\n</ul>\n\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;