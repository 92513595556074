import { $$ } from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
function submit() {
      $arg.ok(
        $$("user-name").value,
        $$("old-password").value,
        $$("new-password").value,
        $$("new-password-2").value);
    }

__out.push("\n<table width=\"100%\">\n  <tr>\n    <td>\n      <table>\n        <tr>\n          <td><label for=\"user-name\">Username:</label></td>\n          <td>\n            ");
if ($arg.login === '') {
__out.push("\n              <input type=\"text\" id=\"user-name\" style=\"width: 18em;\"/>\n            ");
} else {
__out.push("\n              <input type=\"text\" id=\"user-name\" style=\"width: 18em;\"\n                     value=\"");
__out.push(Mold.escapeHTML($arg.login));
__out.push("\" disabled=\"disabled\"/>\n            ");
}
__out.push("\n          </td>\n        </tr>\n        <tr>\n          <td><label for=\"old-password\">Old password:</label></td>\n          <td><input type=\"password\" id=\"old-password\" style=\"width: 18em;\"/></td>\n        </tr>\n        <tr>\n          <td><label for=\"new-password\">New password:</label></td>\n          <td><input type=\"password\" id=\"new-password\" style=\"width: 18em;\"/></td>\n        </tr>\n        <tr>\n          <td><label for=\"new-password-2\">Repeat new password:</label></td>\n          <td><input type=\"password\" id=\"new-password-2\" style=\"width: 18em;\"/></td>\n        </tr>\n      </table>\n    </td>\n    <td class=\"buttons\">\n      <button>OK</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
submit();
});}) + "\"></var>");
__out.push("\n      <button>Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n    </td>\n  </tr>\n</table>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;