import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("<h3>Manage triple indices</h3>\n\n<span id=\"index-list-triple-count\" style=\"float: right\">\n  <span class=\"subtle\">fetching triple count...</span>\n</span>\n\n<table class=\"pure-table pure-table-horizontal index-table\"></table>\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$arg.renderIndexTable($node); }) + "\"></var>");
__out.push("\n\n<div class=\"index-create-group pure-button-group\" role=\"toolbar\" aria-label=\"Create new\">\n  <button class=\"pure-button pure-button-primary\" data-required-access=\"W\">\n    <i class=\"fas fa-plus\"></i> Add index...\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.showAddDialog(); 
});}) + "\"></var>");
__out.push("\n</div>\n\n<div class=\"index-button-group pure-button-group\" role=\"toolbar\" aria-label=\"Refresh\">\n  <button class=\"pure-button\">\n    <i class=\"regular-button-icon fas fa-sync\"></i> Refresh\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.refresh(); 
});}) + "\"></var>");
__out.push("\n</div>\n\n<div class=\"index-button-group pure-button-group\" role=\"toolbar\" aria-label=\"Optimize\">\n  <button class=\"pure-button index-op\" data-required-access=\"W\">\n    Optimize\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.optimizeSelected(); 
});}) + "\"></var>");
__out.push("\n  <button class=\"pure-button index-op\" data-required-access=\"W\">\n    ...\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.showOptimizeDialog(); 
});}) + "\"></var>");
__out.push("\n</div>\n\n<div class=\"index-button-group pure-button-group\" role=\"toolbar\" aria-label=\"Purge\">\n  <button class=\"pure-button index-op\" data-required-permission=\"super\">\n    Purge deleted triples\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.purgeSelected(); 
});}) + "\"></var>");
__out.push("\n</div>\n\n<div class=\"index-button-group pure-button-group\" role=\"toolbar\" aria-label=\"Style\">\n  <button class=\"pure-button index-op\" data-required-access=\"W\">\n    Change style...\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.showChangeDialog(); 
});}) + "\"></var>");
__out.push("\n</div>\n\n<div class=\"index-button-group pure-button-group\" role=\"toolbar\" aria-label=\"Delete\">\n  <button class=\"pure-button index-op\" data-required-access=\"W\">\n    <i class=\"danger-button-icon fas fa-trash-alt\"></i> Delete\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.removeSelected(); 
});}) + "\"></var>");
__out.push("\n</div>\n\n<div class=\"index-button-group pure-button-group\" role=\"toolbar\" aria-label=\"Settings\">\n  <button class=\"pure-button\" data-required-access=\"W\">\n    <i class=\"regular-button-icon fas fa-cog\"></i> Settings\n  </button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.showSettingsDialog(); 
});}) + "\"></var>");
__out.push("\n</div>");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;