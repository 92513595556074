import Util from './util';
import { Async } from './async';
import _ from 'underscore';

/**
 * This module contains utilities used for communication between
 * multiple tabs or browser windows.
 *
 * Storage events are used as the basic communication mechanism.
 * These are implemented in {@link CrossTab.Events}. A way of
 * keeping a value synchronized across all tabs is provided in
 * {@link CrossTab.Vars}.
 *
 * Detection of the presence of other tabs is based on timestamps
 * written periodically to local storage - see {@link CrossTab.Heartbeat}.
 *
 * @namespace CrossTab
 */
var CrossTab = {};

/**
 * These objects are used to abstract away local storage operations
 * for easier testing. In production code the {@link Util} module
 * provides an implementation of this interface.
 *
 * @typedef {Object} CrossTab.Storage
 * @property {function(string, string=)} getFromStorage - see {@link Util#getFromStorage}.
 * @property {function(string, *, string=)} setInStorage - see {@link Util#setInStorage}.
 * @property {function(string, string=)} removeFromStorage - see {@link Util#setInStorage}.
 */

/**
 * An observable object can be used to deliver notifications about an event
 * to multiple listeners.
 *
 * Callbacks can be added with {@link addObserver()} and removed with
 * {@link deleteObserver()} and {@link deleteObservers()}. They will
 * be executed when {@link notifyObservers()} is called.
 *
 * @constructor
 */
CrossTab.Observable = function () {
  var self = this;

  if (!(this instanceof CrossTab.Observable)) {
    throw new Error('Observable constructor invoked without "new".');
  }

  var counter = 0;
  var observers = [];

  /**
   * Registers a new callback function.
   *
   * The callback will be invoked whenever the {@link notifyObservers()}
   * method is invoked. It will receive all arguments that were passed to
   * {@link notifyObservers()}.
   *
   * @param {function(...*)} callback - The callback function.
   * @param {*} [context] - Value of <code>this</code> used when the callback
   *        is invoked.
   * @return {number} - A callback identifier that can be used to unregister
   *          the callback.
   */
  self.addObserver = function (callback, context) {
    observers.push({
      key: counter, callback: callback, context: context,
    });
    return counter++;
  };

  /**
   * Removes a registered callback function.
   *
   * @param {number} key - Callback identifier, can be obtained as the
   *        return value of {@link addObserver}.
   */
  self.deleteObserver = function (key) {
    var index;
    for (var i = 0; i < observers.length; i++) {
      if (observers[i].key === key) {
        index = i;
        break;
      }
    }
    if (index !== undefined) {
      observers.splice(index, 1);
    }
  };

  /**
   * Removes all registered callbacks.
   */
  self.deleteObservers = function () {
    observers = [];
  };

  /**
   * Executes all registered callbacks.
   *
   * @param {...*} [eventArguments] - Arguments that will be passed to the
   *        callback functions.
   */
  self.notifyObservers = function (eventArguments) {
    void eventArguments; // unused, jsdoc is ... not smart.
    for (var i = 0; i < observers.length; i++) {
      var observer = observers[i];
      observer.callback.apply(observer.context, arguments);
    }
  };
};

/**
 * An object that allows named events with optional payload to be sent
 * between all tabs and frames in which the same site is open.
 *
 * Note: event payloads must be JSON serializable, since events are
 * propagated using local storage.
 *
 * There is typically only one, global instance of this class (per tab),
 * but in testing it might be convenient to create multiple instances.
 *
 * @param {CrossTab.Storage} [storage=Util] - object implementing the local
 *        storage interface, compatible with the {@link Util} module.
 *
 * @constructor
 */
CrossTab.Events = function (storage) {
  var self = this;

  if (!(this instanceof CrossTab.Events)) {
    throw new Error('Events constructor invoked without "new".');
  }

  var STORAGE_KEY = 'storage-event';

  var observables = {};

  /**
   * Notify listeners in all tabs that an event has occurred.
   *
   * @param {string} key - Name of the event.
   * @param {...*} payload - arguments that will be passed to event handlers.
   *        Note that these values must be JSON serializable, since they are
   *        transferred through local storage.
   */
  self.triggerEvent = function (key, payload) {
    void payload; // jsdoc...
    var args = Array.prototype.slice.call(arguments, 1);
    triggerStorageEvent(key, args);
    notifyListeners(key, args);
  };

  /**
   * Notify listeners in all other tabs that an event has occurred.
   * Listeners registered in the tab in which this method is called
   * will not be triggered.
   *
   * @param {string} key - Name of the event.
   * @param {...*} payload - arguments that will be passed to event handlers.
   *        Note that these values must be JSON serializable, since they are
   *        transferred through local storage.
   */
  self.triggerEventInOtherTabs = function (key, payload) {
    void payload; // jsdoc...
    triggerStorageEvent(key, Array.prototype.slice.call(arguments, 1));
  };

  /**
   * Return an observable that makes it possible to register listeners
   * for an event with given name. When that event occurs, the listeners
   * will be notified and event payload will be passed in their arguments.
   *
   * Note that the same observable will be returned by all calls to this
   * method with the same event name.
   *
   * @param {string} key - Event name.
   * @return {CrossTab.Observable} - the observable corresponding to the
   *          named event.
   */
  self.observe = function (key) {
    if (!observables.hasOwnProperty(key)) {
      observables[key] = new CrossTab.Observable();
    }
    return observables[key];
  };

  /**
   * Releases all resources allocated during construction
   * and stops listening for new events.
   */
  self.destroy = function () {
    window.removeEventListener('storage', onStorageEvent);
  };

  function triggerStorageEvent(key, args) {
    // Add a value to the local storage
    storage.setInStorage(STORAGE_KEY, { key: key, args: args }, 'local');
    // ... and remove it immediately. The atomicity guarantees
    // of the local store ensure that the data cannot be read
    // between those operations, not even in a different tab.
    // Storage events will be generated in all other frames
    // and those events will contain all stored/deleted info as
    // their payload.
    storage.removeFromStorage(STORAGE_KEY, 'local');
  }

  function onStorageEvent(event) {
    if (event.key === STORAGE_KEY && event.oldValue !== null) {
      var data = JSON.parse(event.oldValue);
      var key = data.key;
      var args = data.args;
      if (key !== undefined) {
        notifyListeners(key, args);
      }
    }
  }

  function notifyListeners(key, args) {
    if (observables[key]) {
      observables[key].notifyObservers.apply(observables[key], args);
    }
  }

  if (!storage) {
    storage = Util;
  }
  window.addEventListener('storage', onStorageEvent);
};

/**
 * An object that makes it possible to share items from session storage
 * across multiple tabs.
 *
 * Use {@link setItem()} and {@getItem()} to interact with session-scoped
 * values across tabs. Note that {@getItem()} returns a future, since
 * tab detection might take some time.
 *
 * It is also possible to react to value changes - see {@link observe()}.
 *
 * @param {CrossTab.Heartbeat} heartbeat - provides tab detection.
 * @param {CrossTab.Events} events - provides cross-tab events.
 * @param {CrossTab.Storage} storage - Local storage implementation.
 * @constructor
 */
CrossTab.Vars = function (heartbeat, events, storage) {
  // Communication is implemented through cross-tab events.
  // When a value is retrieved, a request is sent to other tabs
  // and the response is returned, if it arrives within a short time.
  // When a value is modified, an event is sent so that all other
  // tabs can update their local values and trigger their change
  // listeners.
  var self = this;

  if (!(this instanceof CrossTab.Vars)) {
    throw new Error('CrossTab constructor invoked without "new".');
  }

  // Name of the event used to signal changes to other tabs.
  var VALUE_CHANGED_EVENT = 'crosstab-value-changed';
  // Name of the event used to ask other tabs for the current value.
  var VALUE_REQUESTED_EVENT = 'crosstab-value-requested';
  // Name of the event issued in reply to VALUE_REQUESTED_EVENT.
  var REPLY_EVENT = 'crosstab-value-reply';
  // Time to wait for replies to VALUE_REQUESTED_EVENT, in milliseconds.
  var REQUEST_TIMEOUT = 500;

  var changeListeners = {};
  var pendingRequests = {};

  /**
   * Stores a value in the session storage and makes other tabs aware of it.
   *
   * @param {string} key - Storage key.
   * @param {*} value - Value to be stored, must be JSON serializable.
   * @param {Object} [kwargs] - Additional options.
   * @param {boolean} [kwargs.includeCurrentTab] - if false then observers
   *     registered in the current tab will not be notified.
   *     The default is true.
   */
  self.setItem = function (key, value, kwargs) {
    var options = {
      includeCurrentTab: true,
    };
    if (kwargs) {
      _.extend(options, kwargs);
    }
    var old = storage.getFromStorage(key, 'session');
    storage.setInStorage(key, value, 'session');
    events.triggerEventInOtherTabs(VALUE_CHANGED_EVENT, key, value, old);
    if (options.includeCurrentTab) {
      triggerChangeListeners(key, value, old);
    }
  };

  /**
   * Retrieve a session-scoped value from either the session storage or the
   * other tabs.
   *
   * Note that the result is a promise, since tab detection might require
   * a timeout.
   *
   * @param {string} key - Storage key.
   * @return {Async} - A future that will resolve to the retrieved value,
   *          or <code>null</code> if no value was found.
   */
  self.getItem = function (key) {
    var value = storage.getFromStorage(key, 'session');
    if (value === null && heartbeat.otherTabsMayExist()) {
      var async = new Async().withTimeout(REQUEST_TIMEOUT);
      if (!pendingRequests.hasOwnProperty(key)) {
        pendingRequests[key] = [];
      }
      pendingRequests[key].push(async);
      events.triggerEventInOtherTabs(VALUE_REQUESTED_EVENT, key);
      return async;
    } else {
      return Async.dummy(value);
    }
  };

  /**
   * Returns an observable that can be used to detect changes of
   * a session scoped value across multiple tabs.
   *
   * When the value changes in any tab, listeners are notified
   * and both the new and the old value are passed as arguments
   * (in that order).
   *
   * @param {string} key - storage key.
   * @return {CrossTab.Observable} - an observable that can be used to
   *          listen for value changes. Note that the same object will
   *          always be returned each time {observe()} is called with the
   *          same key.
   */
  self.observe = function (key) {
    if (!changeListeners.hasOwnProperty(key)) {
      changeListeners[key] = new CrossTab.Observable();
    }
    return changeListeners[key];
  };

  function triggerChangeListeners(key, value, old) {
    storage.setInStorage(key, value, 'session');
    if (changeListeners.hasOwnProperty(key)) {
      changeListeners[key].notifyObservers(value, old);
    }
  }

  events.observe(VALUE_CHANGED_EVENT).addObserver(
    triggerChangeListeners, self);

  events.observe(REPLY_EVENT).addObserver(function (key, value) {
    var requests = pendingRequests[key] || [];
    storage.setInStorage(key, value, 'session');
    for (var i = 0; i < requests.length; i++) {
      requests[i].ok(value);
    }
    delete pendingRequests[key];
  }, self);

  events.observe(VALUE_REQUESTED_EVENT).addObserver(function (key) {
    var value = storage.getFromStorage(key, 'session');
    if (value !== null) {
      events.triggerEvent(REPLY_EVENT, key, value);
    }
  }, self);
};

/**
 * This class controls the process of detecting if another
 * AGWebView frame is open in the same browser.
 *
 * Simply call {@link otherTabsMayExist()} to check if the page is open in
 * more than one tab. Note that false positives are possible in rare
 * circumstances.
 *
 * @constructor
 */
CrossTab.Heartbeat = function () {
  // There is a record in the local store (thus common to all frames)
  // that contains a timestamp. Each active frame tries to refresh
  // the timestamp periodically. At any point, the record can be compared
  // to the current time. If it is too old, we can assume that no other
  // tab is alive. The opposite is, unfortunately, not true - a recent
  // timestamp does not guarantee that the frame that set it is still
  // alive.
  // Each frame generates a random, unique id that it stores with
  // the timestamp. This is done so that the frame is not fooled by
  // a timestamp it generated itself - it will use the previous timestamp,
  // which it always stores in a local variable when updating the frame
  // detection record.
  // Checking the timestamp is implemented in User._otherTabsMayExist().
  // The function User._startHeartbeatTimer() starts or restarts the timer
  // used to update the frame detection record. It is automatically invoked
  // on login.

  var self = this;

  if (!(this instanceof CrossTab.Heartbeat)) {
    throw new Error('Heartbeat constructor invoked without "new".');
  }

  // Name of the local store record.
  var HEARTBEAT_KEY = 'agwebview-keepalive';

  // This controls how often the local store record is refreshed (in ms).
  var HEARTBEAT_INTERVAL = 10000;

  // If the local store record is more than HEARTBEAT_INTERVAL + this value
  // milliseconds old, it is ignored.
  var HEARTBEAT_GRACE = 1000;

  // Last frame detection record that was written by another frame.
  var lastOtherHeartbeat = null;

  // Frame ID, computed lazily.
  var heartbeatTimestampId = null;

  /**
   * Check if the website is open in more than one tab.
   *
   * @return {boolean} - <code>false</code> if no other tabs may exist,
   *           <code>true</code> if we cannot rule out their existence.
   */
  self.otherTabsMayExist = function () {
    var record = Util.getFromStorage(HEARTBEAT_KEY, 'local');
    var maxAge = HEARTBEAT_INTERVAL + HEARTBEAT_GRACE;

    if (record && record.id === heartbeatTimestampId) {
      // The last timestamp was stored by this frame, so it is useless.
      // We must consult the previous timestamp.
      record = lastOtherHeartbeat;
    }

    if (record) {
      // We've found a record written by another frame,
      // check its age
      var timeSinceLastHeartbeat = Date.now() - record.time;
      return timeSinceLastHeartbeat <= maxAge;
    } else {
      // No frame detection record found...
      return false;
    }
  };

  /**
   * Puts current timestamp in the local store to indicate that
   * a frame is still alive.
   * Store the old record in a variable so that it can be accessed
   * if we want to check for *other* frames and the most recent info
   * in the local store is our own.
   */
  function refreshHeartbeatRecord() {
    // Init the frame id lazily.
    if (heartbeatTimestampId === null) {
      heartbeatTimestampId = Math.random();
    }
    // Keep the overwritten record - unless it's our own.
    var previous = Util.getFromStorage(HEARTBEAT_KEY, 'local');
    if (previous && previous.id !== heartbeatTimestampId) {
      lastOtherHeartbeat = previous;
    }
    var currentHeartbeatRecord = {
      time: Date.now(),
      id: heartbeatTimestampId,
    };
    Util.setInStorage(HEARTBEAT_KEY, currentHeartbeatRecord, 'local');
  }

  function startHeartbeatTimer() {
    refreshHeartbeatRecord();
    window.setInterval(
      refreshHeartbeatRecord, HEARTBEAT_INTERVAL);
  }

  // When this tab is closed, restore the previous heartbeat record
  // written by another frame.
  // This makes sure that new tabs will not wait for authentication
  // info from the recently closed one.
  window.addEventListener('unload', function () {
    var record = Util.getFromStorage(HEARTBEAT_KEY, 'local');
    if (record && record.id === heartbeatTimestampId) {
      // Our entry is the current heartbeat record, we'll
      // restore the last one we've seen from another frame.
      Util.setInStorage(HEARTBEAT_KEY, lastOtherHeartbeat, 'local');
    }
  });

  startHeartbeatTimer();
};

export default CrossTab;
