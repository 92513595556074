import Util from '../../util';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.getTotalLength() ) {
__out.push("\n");
__out.push(Mold.escapeHTML(Util.toFixed($arg.getVisibleLength(), 0) ));
__out.push("/");
__out.push(Mold.escapeHTML(Util.toFixed($arg.getTotalLength(), 0) ));
__out.push("\n");
} else {
__out.push("\n");
__out.push(Mold.escapeHTML(Util.toFixed($arg.getVisibleLength(), 0) ));
__out.push("\n");
}
__out.push("\n");
if (($arg.getVisibleLength() === 1) ) {
__out.push("Result");
} else {
__out.push("Results");
}
__out.push("\n");
if ($arg.getQueryTime() ) {
__out.push("\nin ");
__out.push(Mold.escapeHTML(Util.toFixed($arg.getQueryTime(), 3) ));
__out.push(" ");
__out.push(Mold.escapeHTML($arg.getQueryTimeUnits() ));
__out.push("\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;