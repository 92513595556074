import Base from './base';
import Util from './util';
import User from './user';
import App from './app';
import Dispatch from './dispatch';
import $ from 'jquery';
import { MAsync } from './async';
import { Async } from './async';
import Layout from './layout';
import _ from 'underscore';
import { $$ } from './util';
import Part from './part';
import Server from './server';
import Query from './query';
import Catalog from './catalog';
import CodeMirror from 'codemirror';
import ReplUtil from './replUtil';
import overviewTemplate from './templates/store/overview.mold';
import infoTemplate from './templates/store/info.mold';
import editinfoTemplate from './templates/store/editinfo.mold';
import textIndicesTemplate from './templates/store/text-indices.mold';
import importfileStatusTemplate from './templates/store/importfile-status.mold';
import bulkModeTemplate from './templates/store/bulkMode.mold';
import replicationTemplate from './templates/store/replication.mold';
import solrTemplate from './templates/store/solr.mold';
import mongoTemplate from './templates/store/mongo.mold';
import attributeDefinitionsDialogTemplate from './templates/store/attributeDefinitionsDialog.mold';
import attributeDefinitionsTemplate from './templates/store/attributeDefinitions.mold';
import namespaceTemplate from './templates/store/namespace.mold';
import queryOptionTemplate from './templates/store/queryOption.mold';
import textIndexFieldTemplate from './templates/store/text-index-field.mold';
import newtripleTemplate from './templates/store/newtriple.mold';
import deltriplesTemplate from './templates/store/deltriples.mold';
import importfileTemplate from './templates/store/importfile.mold';
import importserverfileTemplate from './templates/store/importserverfile.mold';
import importTextareaTemplate from './templates/store/importtextarea.mold';
import materializeTemplate from './templates/store/materialize.mold';
import duplicatesTemplate from './templates/store/duplicates.mold';
import suppressDuplicatesTemplate from './templates/store/suppressDuplicates.mold';
import warmupTemplate from './templates/store/warmup.mold';
import convertStoreTemplate from './templates/store/convertStoreDialog.mold';

/**
 * Functionality related to the repository overview and data.
 * @namespace Store
 */
var Store = {};

Store.getRepoData = function (tag) {
  return Base.req('GET', Base.url('data/wv.', tag, { allowEmpty: true }));
};

Store.getJsonRepoData = function (tag) {
  return Store.getRepoData(tag).transform(function (val) {
    return val ? Util.readJSON(val) : null;
  });
};

Store.setRepoData = function (tag, value) {
  return Base.req('PUT', Base.url('data/wv.', tag), { body: value });
};

Store.setJsonRepoData = function (tag, value) {
  return Store.setRepoData(tag, Util.writeJSON(value));
};

Store.delRepoData = function (tag) {
  return Base.req('DELETE', Base.url('data/wv.', tag));
};

// Overview info

var lastKnownSize = null;

Store.showOverview = function () {
  if (!User.userAccess('r')) {
    User.denied();
    return;
  }
  var isRealRepo = App.isInRealRepo();
  var storeParams = MAsync.collect(
    App.currentSession ? Async.dummy(null) : Store.getRepoData('info'),
    isRealRepo
      ? Base.jsonReq('GET', Base.url('data', { prefix: 'wv.query.' }))
      : Async.dummy(null),
    isRealRepo ? Base.jsonReq('GET', 'indices') : Async.dummy(null),
    isRealRepo ? Base.jsonReq('GET', 'suppressDuplicates') : Async.dummy(null),
    isRealRepo
      ? Base.jsonReq('GET', 'nd/geospatialDatatypeAutomation')
      : Async.dummy(null),
    Base.jsonReq('GET', '/largeOperationWarning'),
    isRealRepo
      ? Base.jsonReq('GET', Base.serverUrlNoSession('repl/running'))
      : Async.dummy(null),
    isRealRepo
      ? Base.catchNotFound(Base.jsonReq('GET',
                                        Base.serverUrlNoSession('warmstandby')))
      : Async.dummy(null),
    isRealRepo
      ? Base.jsonReq('GET', Base.serverUrlNoSession('repo-is-distributed'))
      : Async.dummy(null),
  );
  Base.load(storeParams, 'Loading', Dispatch.protect(function (
      info, queries, indices, suppressDuplicates,
      geospatialDatatypeAutomation, largeOperationWarning, mmrRunning,
      warmstandby, distributed) {
    // We don't show the Multi-Master Replication links if we're
    // inside a session and the repo is not an MMR repo because we
    // would then be offering something we can't do, namely to convert
    // the repo to a replication repo. We can't convert a normal repo
    // to a replication repo while any process has the repo open, and
    // a session is a process with the repo open.
    var inSessionWithoutMMR = App.currentSession && (mmrRunning === 'no');
    // We don't show any replication links if current repository is a
    // non-wrapping session or a distributed triple store.
    var showMMRLinks = User.userPerm('super') && isRealRepo && !distributed
        && !warmstandby && !inSessionWithoutMMR;
    var showWSLinks = (User.userPerm('super') || User.userPerm('replication'))
        && isRealRepo && !distributed && (mmrRunning === 'no');
    var ctrl = new Store.OverviewController({
      info: info,
      size: 'checking size',
      indices: indices,
      suppressDuplicates: suppressDuplicates,
      geospatialDatatypeAutomation: geospatialDatatypeAutomation,
      queries: queries && queries.map(function (q) {
        return q.id.slice('wv.query.'.length);
      }),
      largeOperationWarning: largeOperationWarning,
      mmrRunning: mmrRunning,
      warmstandby: warmstandby,
      distributed: distributed,
      showMMRLinks: showMMRLinks,
      showWSLinks: showWSLinks,
    });
    Store.bulkDialog = Store.bulkDialogInitial;
    overviewTemplate.cast(Layout.getPage(), ctrl);
    refreshStoreSize();
  }));
};

Store.OverviewController = function (args) {
  _.extend(this, args);

  // Links for the 'Explore the Repository' section.
  // Each link is an object with 'title' and 'query' attributes.
  this.exploreLinks = [
    {
      'title': 'View triples',
      'query': [
        '# View triples',
        'SELECT ?s ?p ?o { ?s ?p ?o . }'].join('\n'),
    },
    {
      'title': 'View quads',
      'query': [
        '# This PREFIX causes the default graph of the dataset to include',
        '# only triples that are not in a named graph.',
        '# Otherwise, the default graph will include every triple.',
        'PREFIX franzOption_defaultDatasetBehavior: <franz:rdf>',
        '',
        '# View quads',
        'SELECT ?s ?p ?o ?g {',
        '  # default graph',
        '  { ?s ?p ?o . }',
        '  UNION',
        '  # named graphs',
        '  { GRAPH ?g { ?s ?p ?o . }  }',
        '}'].join('\n'),
    },
    {
      'title': 'View repository\'s classes',
      'query': [
        '# View unique classes',
        'select distinct ?class {?resource a ?class}'].join('\n'),
    },
    {
      'title': 'View repository\'s predicates',
      'query': [
        '# View unique predicates',
        'select distinct ?pred {?x ?pred ?y}'].join('\n'),
    },
    {
      'title': 'View repository\'s named graphs',
      'query': [
        '# View unique named graphs',
        'select distinct ?g { graph ?g { ?s ?p ?o } }'].join('\n'),
    },
  ];

  this.deleteMaterializedDialog = function () {
    Layout.showDialog('deleteMaterialized-confirm',
      'Are you sure you want to delete materialized triples ' +
      'in the repository?',
      delMaterialized, null, false, true);

    function delMaterialized() {
      Base.load(
        Base.req('DELETE', 'materializeEntailed'),
        'Deleting materialized triples', function (deletedCount) {
          App.refresh();
          Layout.showMessage(
            deletedCount + ' materialized triples deleted.', false, false);
        });
    }
  };
};

Store.editRepositoryInfo = function (startText) {
  var currentValue = startText;

  function backTo(text) {
    return function () {
      infoTemplate.cast('repo-info', text);
    };
  }
  function saveBlurb(text) {
    currentValue = text;
    Store.setRepoData('info', text).protect(backTo(currentValue))
        .wait(function () {
          currentValue = text;
          backTo(text)();
        },
              'Saving');
  }

  editinfoTemplate.cast('repo-info', {
    text: currentValue || '',
    cancel: backTo(currentValue),
    ok: saveBlurb,
  });
};

// Namespaces

/**
 * A definition of a namespace object type.
 * @typedef {Object} NamespaceEntry
 * @property {string} prefix - prefix of the namespace.
 * @property {string} namespace - expansion of the given prefix.
 * @property {string|null} type - type of option definition.
 * @property {string|null} shadowed - whether the option is shadowed.
 */

/**
 * Shows a dialog that allows the user to add one or more namespaces.
 * @param {string} type - a type of namespace to pre-select from the
 *                        drop-down menu.
 * @return {Async} - when user clicks OK, will resolve to a list of
 *                   `NamespaceEntry` objects.
 */
Store.showNamespaceDialog = function (type) {
  var async = new Async();
  var close = Layout.customDialog('namespace-dialog',
    namespaceTemplate, {
      ok: ok,
      bulk: bulk,
      cancel: function () {
        close();
      },
      type: type,
    }, false, false);
  return async;

  function normal(uri) {
    var match = uri.match(/^<(.*)>$/);
    return match ? match[1] : uri;
  }
  function ok(prefix, uri, type) {
    close();
    async.ok([{ prefix: prefix, namespace: normal(uri), type: type }]);
  }
  function bulk(text, type) {
    function parse(line) {
      var match = line.match(/^([^:\s]+)[:\s]\s*(\S+)\s*$/);
      return match && { prefix: match[1], namespace: normal(match[2]), type: type };
    }
    async.ok(Util.filter(
      Util.ident.bind(Util), Util.map(parse, text.split(/\r?\n/))));
  }
};

// Query options

/**
 * A definition of a query option object type.
 * @typedef {Object} QueryOptionEntry
 * @property {string} name - name of the query option.
 * @property {string} value - value of the query option.
 * @property {string|null} type - type of option definition.
 * @property {string|null} shadowed - whether the option is shadowed.
 */

/**
 * Shows a dialog that allows the user to set a query option.
 * @param {string} type - a type of query option to pre-select from
 *                        the drop-down menu.
 * @return {Async} - when user clicks OK, will resolve to a
 *                   `QueryOptionEntry` object.
 */
Store.showQueryOptionDialog = function (type) {
  var async = new Async();
  var close = Layout.customDialog('query-option-dialog',
    queryOptionTemplate, {
      ok: ok,
      cancel: function () {
        close();
      },
      type: type,
    }, false, false);
  return async;

  function ok(name, value, type) {
    close();
    async.ok([{ name: name, value: value, type: type }]);
  }
};

// Text indexing

Store.showFTI = function () {
  function fetchFTI(name) {
    return Base.jsonReq('GET', Base.url('freetext/indices/', name)).transform(
      function (obj) {
        obj.name = name;
        return obj;
      });
  }

  Base.load(
    Base.jsonReq('GET', 'freetext/indices'), 'Fetching indices',
    function (indices) {
      Base.load(
        MAsync.collect.apply(null, Util.map(fetchFTI, indices)),
        'Loading indices', function () {
          textIndicesTemplate.cast(Layout.getPage(), arguments);
        });
    });
};

Store.deleteFTI = function (index, node) {
  Layout.showDialog('deleteIndex-confirm',
    'Are you sure you want to delete text index "' + index.name + '"?',
    ok, null, false, true);
  function ok() {
    Base.load(
      Base.req('DELETE', Base.url('freetext/indices/', index.name)),
      'Deleting index', function () {
        Util.removeNode(node);
      });
  }
};

Store.editFTI = function (index, create) {
  Base.load(
    Base.jsonReq('GET', '/config/fti-stop-words'), 'Loading default stop words',
    function (stopwords) {
      var close = Layout.customDialog('textIndexField-dialog',
        textIndexFieldTemplate, {
          cur: index,
          defaultStopWords: stopwords,
          create: create,
          ok: ok,
          cancel: function () {
            close();
          },
        }, false, false);
      function ok(form) {
        function multInputVals(name) {
          var result = [];
          var field;
          for (var i = 1; (field = form[name + i]); i++) {
            if (!/^\s*$/.test(field.value)) {
              result.push(field.value);
            }
          }
          return result;
        }

        function multSelectVals(name) {
          return Base.multSelectValues(form[name]);
        }

        function radioVal(name) {
          return Base.radioValue(form[name]);
        }

        var problems = [];

        function checkParts(parts) {
          for (var i = 0; i < parts.length; i++) {
            if (!Part.partFromUntrusted(parts[i])) {
              problems.push('"' + parts[i] + '" is not a valid part.');
            }
          }
          return parts;
        }

        var args = {};
        var lits = radioVal('literals');
        var fields = multSelectVals('fields');
        var wsize = Number(form.wordsize.value);

        args.predicate = radioVal('predicates') === 'some' ?
          checkParts(multInputVals('predicate')) : '';
        args.indexLiterals = lits !== 'false';
        if (lits === 'some') {
          args.indexLiteralType = checkParts(multInputVals('literalType'));
        }
        args.indexResources = radioVal('resources');
        args.indexField = fields.length ? fields : '';
        args.tokenizer = radioVal('tokenizer');
        if (isNaN(wsize) || wsize - Math.floor(wsize)) {
          problems.push('"' + wsize + '" is not an integer.');
        }
        args.minimumWordSize = wsize;
        args.stopWord = form.stopwords.value.split(/\s+/);
        if (!create && !form.reindex.checked) {
          args.reIndex = 'false';
        }
        args.wordFilter = form.wordfilters.value.split(/\s+/);

        if (problems.length) {
          Layout.showMessage(problems.join('\n'), false, true);
        } else {
          Base.load(
            Base.req('PUT', Base.url('freetext/indices/', index.name, args), { body: '' }),
            'Creating text index...', function () {
              close();
              App.refresh();
            });
        }
      }
    });
};

Store.createFTI = function (name) {
  if (!name) {
    return;
  }
  Store.editFTI({
    name: name,
    predicates: [],
    indexLiterals: true,
    indexResources: false,
    indexFields: ['object'],
    minimumWordSize: 3,
  }, true);
};

// Adding/deleting triples

Store._fetchingOK = true;
Store.fillContexts = function (select) {
  // Prevent long-lasting requests from clogging up the server.
  // TODO: drop the lastKnownSize check once /contexts is efficient again
  var STORE_SIZE_LIMIT = 1000000;
  var MAX_CONTEXTS = 300;
  if (!Store._fetchingOK || lastKnownSize > STORE_SIZE_LIMIT) {
    return;
  }
  Store._fetchingOK = false;
  Base.jsonReq('GET', 'contexts')
      .always(function () {
        Store._fetchingOK = true;
      }).wait(function (contexts) {
        if (contexts.length > MAX_CONTEXTS) {
          Store._fetchingOK = false;
        }
        Util.forEach(contexts, function (context) {
          var opt = select.appendChild(document.createElement('OPTION'));
          opt.appendChild(document.createTextNode(context.contextID));
          opt.value = context.contextID;
        });
        if (contexts.length) {
          select.parentNode.style.display = '';
        } else {
          select.disabled = true;
        }
      });
};

Store.addStatementDialog = function (slot, part) {
  var close;
  // Used to fill in a default value in the form.
  var data = {
    ok: addStatement,
    cancel: function () {
      close();
    },
  };
  Util.forEach(['subj', 'pred', 'obj'], function (type) {
    data[type] = slot === type ? part.string : '';
  });

  close = Layout.customDialog('newTriple-dialog', newtripleTemplate, data, false, false);

  function addStatement(subject, predicate, object, context, attributes) {
    // Expand abbreviated namespaces in a node. Note that the context
    // node may be null.
    function restore(str, context) {
      if (context && str === null) {
        return null;
      }
      var full = Part.NameSpaces.restore(str);
      if (!full) {
        Layout.showMessage('"' + str + '" is not a valid part.', false, true);
      }
      return full !== null && full;
    }

    if (!((subject = restore(subject)) &&
          (predicate = restore(predicate)) &&
          (object = restore(object)) &&
          (context = restore(context, true)) !== false)) {
      return;
    }

    Base.load(
      Base.req('POST', 'statements', {
        contentType: 'application/json',
        body: Util.writeJSON([
          [subject, predicate, object, context, attributes]]),
      }),
      'Adding statement', function () {
        close();
        App.refresh();
        Layout.showMessage('Statement added.', Layout.SHORT_DELAY, false);
      });
  }
};

Store.deleteStatementDialog = function () {
  var close;
  var dialogData = {
    ok: delStatement,
    cancel: function () {
      close();
    },
  };
  close = Layout.customDialog('delTriples-dialog', deltriplesTemplate, dialogData, false, false);

  function delStatement(subject, predicate, object, context) {
    var selective = false;
    function restore(str, context) {
      if (str === '*') {
        return null;
      }
      selective = true;
      if (context && str === null) {
        return 'null';
      }
      var full = Part.NameSpaces.restore(str);
      if (!full) {
        throw new Error('"' + str + '" is not a valid part.');
      }
      return full;
    }
    var args;
    try {
      args = {
        subj: restore(subject),
        pred: restore(predicate),
        obj: restore(object),
        context: restore(context, true),
      };
    } catch (e) {
      Layout.showMessage(e.message, false, true);
      return;
    }
    if (selective) {
      finish();
    } else {
      Layout.showDialog('deleteAllTriples-confirm',
        'Are you sure you want to delete all statements in the repository?',
        finish, null, false, true);
    }

    function finish() {
      close();
      Base.load(
        Base.req('DELETE', Base.url('statements', args)),
        'Deleting statements', function (n) {
          App.refresh();
          Layout.showMessage(n + ' statement' + (n !== '1' ? 's' : '') + ' deleted.',
              Layout.LONG_DELAY, false);
        });
    }
  }
};

Store.deleteStatement = function (triple) {
  var tripleId = triple.tripleId;
  if (tripleId === undefined) {
    throw new Error(
      'Cannot delete triple without known ID: ' + JSON.stringify(triple));
  }
  Base.load(
    Server.deleteStatements([tripleId]),
    'Deleting', App.refresh.bind(App));
};

Store.gatherUploadDialogParameters = function (form) {
  var bulkLoaders = form.elements.bulkLoaders.value;
  var filesElement = form.elements.files;
  if (bulkLoaders.length > 0) {
    bulkLoaders = parseInt(bulkLoaders, 10);
  }
  var externalReferenceTimeout = form.elements.externalReferenceTimeout.value;
  if (externalReferenceTimeout.length > 0) {
    externalReferenceTimeout = parseInt(externalReferenceTimeout, 10);
  }

  return {
    'files': filesElement && Base.multSelectValues(filesElement),
    'type': form.elements['content-type'].value,
    'context': form.elements.context.value || null,
    'continueOnError': Base.radioValue(form.elements.continueOnError) === 'true',
    'useAGLoad': Base.radioValue(form.elements.useAgload) === 'true',
    'bulkLoad': form.elements.bulkLoad.checked,
    'bulkLoaders': bulkLoaders || null,
    'attributes': form.elements.attributes.value,
    'externalReferences': form.elements.externalReferences.checked,
    'externalReferenceTimeout': externalReferenceTimeout,
    'baseURI': form.elements.baseURI.value,
    'jsonLdStoreSource': form.elements.storeSource.checked,
    'suppressDuplicateEmbeddedTriples': form.elements.suppressDuplicateEmbeddedTriples.checked,
  };
};

Store.uploadDialog = function () {
  var submitting = false;
  var mask = 0xFFFFFFFF;
  var radix = 16;
  var id = 'wv' + Math.floor(Math.random() * mask).toString(radix);
  var close = Layout.customDialog('importFile-dialog', importfileTemplate,
                                  { start: startUpload,
                                    cancel: function () {
                                      cancelUpload();
                                      close();
                                    },
                                    id: id,
                                  }, false, false);

  function withUploadStatus(files) {
    // Look out! `files` is an array-like object, but not an actual Array.
    var info = $$('triple-file-upload-info');
    var uploadStatus = {};
    _.forEach(files, function (file) {
      uploadStatus[file.name] = { loaded: 0, total: file.size };
    });
    function updateStatus() {
      importfileStatusTemplate.cast(info, {
        name: files.length > 1 ? 'multiple files' : files[0].name,
        loaded: _.reduce(uploadStatus, function (acc, st) {
          return acc + st.loaded;
        }, 0),
        total: _.reduce(uploadStatus, function (acc, st) {
          return acc + st.total;
        }, 0),
      });
    }
    return {
      uploadProgressFor: function (file) {
        return function (event) {
          var loaded = event.loaded;
          var total = event.total;
          uploadStatus[file.name] = {
            loaded: loaded,
            total: total,
          };
          updateStatus();
        };
      },
    };
  }

  // list of async's that can be used to abort pending requests.
  // an .ok() call will attempt to abort, a .fail() call will
  // cancel any chance to abort.
  var abortRequests = [];
  var cancelled = false;

  function cancelUpload() {
    // set cancelled first, because the wait function waiting for the requests
    // to complete will trigger immediately after they are aborted, and before
    // we can set cancelled to true.
    cancelled = true;
    abortRequests.forEach(function (async) {
      if (!async.fired) {
        async.ok(true);
      }
    });
  }

  function startUpload(data) {
    if (submitting) {
      return;
    }
    submitting = true;

    var files = $$('upload-body').files;
    var uploadStatus = withUploadStatus(files);

    function asReq(file) {
      var abortAsync = new Async();
      abortRequests.push(abortAsync);

      var async = Base.req('POST', Base.url('statements', {
        type: data.type,
        context: data.context,
        continueOnError: data.continueOnError,
        useAgload: data.useAGLoad,
        bulkLoad: data.bulkLoad,
        bulkLoaders: data.bulkLoaders,
        attributes: data.attributes,
        uploadedFile: file.name,
        externalReferences: data.externalReferences,
        externalReferenceTimeout: data.externalReferenceTimeout,
        baseURI: data.baseURI,
        jsonLdStoreSource: data.jsonLdStoreSource,
      }), {
        abort: abortAsync,
        form: file,
        handlers: { onUploadProgress: uploadStatus.uploadProgressFor(file) },
      });

      return async;
    }
    Base
        .load(MAsync.collect.apply(null, Util.map(asReq, files)), 'Importing')
        .wait(
          function () {
            close();
            App.refresh();
            if (!cancelled) {
              Layout.showMessage('Triples successfully imported.',
                  Layout.SHORT_DELAY, false);
            } else {
              Layout.showMessage('Triple import cancelled by user.',
                  Layout.LONG_DELAY, false);
            }
          },
          function (message) {
            submitting = false;
            refreshStoreSize();
            var match = message.match(/^[A-Z ]+: (.*)$/);
            if (match) {
              message = match[1];
            }
            Base.failMessage('Import', message);
            $$('upload-ok').disabled = false;
          }
        );

    // Disable the OK button once the uploads have started.
    $$('upload-ok').disabled = true;
  }
};

function refreshStoreSize() {
  Base.jsonReq('GET', 'size').wait(function (size) {
    lastKnownSize = size;
    var elt = $('#repo-statement-count');
    if (elt.length > 0) {
      elt.html(
        Base.bigNumber(size) + ' statement' +
          ((size === 0) || (size > 1) ? 's' : ''));
    }
  });
}

Store.guessFileType = function (idPrefix, filename) {
  var map = {
    'nt': 'nt', 'ntriples': 'nt', 'xml': 'xml', 'rdf': 'xml',
    'trix': 'trix', 'nq': 'nq', 'owl': 'xml', 'ttl': 'ttl', 'turtle': 'ttl',
    'trig': 'trig',
  };
  var match = filename.match(/\.([^.]+)$/);
  var id = match && map[match[1]];
  if (id) {
    $('#' + idPrefix + 'ct-' + id).selected = true;
  }
};

Store.initFilePicker = function (select) {
  var working = false;
  var $select = $(select);

  function loadDir(dir) {
    if (working) {
      return;
    }
    working = true;
    Base.load(
      Base.jsonReq('GET', Base.url('/directory', { dir: dir }))
          .always(function () {
            working = false;
          }),
      'Reading directory', _.partial(populate, dir));
  }

  function addOption(name, value, bold) {
    var op = select.appendChild(document.createElement('option'));
    op.appendChild(document.createTextNode(name));
    op.value = value;
    if (bold) {
      op.style.fontWeight = 'bold';
    }
  }

  function populate(dir, files) {
    $select.html('');
    $('#' + $select.attr('data-cwd')).text('from ' + dir);
    if (dir !== '/') {
      addOption('..', dir.substr(0, 1 + dir.lastIndexOf('/', dir.length - 2)));
    }
    // List directories first
    Util.forEach(files, function (file) {
      if (file.charAt(file.length - 1) === '/') {
        addOption(
          file.substr(file.lastIndexOf('/', file.length - 2) + 1), file);
      }
    });
    // Then regular files
    Util.forEach(files, function (file) {
      if (file.charAt(file.length - 1) !== '/') {
        addOption(
          file.substr(file.lastIndexOf('/', file.length - 2) + 1), file, true);
      }
    });
    // This scrolls to the top of the list
    select.selectedIndex = 0;
    select.selectedIndex = -1;
  }
  Util.connect(select, 'dblclick', function () {
    Util.forEach(select.childNodes, function (opt) {
      if (opt.selected && opt.value.charAt(opt.value.length - 1) === '/') {
        opt.selected = false;
        loadDir(opt.value);
        Util.setInStorage('wvLastFilePicker', opt.value);
      }
    });
  });
  Util.connect(select, 'keydown', function (event) {
    var KEY_ENTER = 13;
    if (event.keyCode === KEY_ENTER) {
      Util.forEach(select.childNodes, function (opt) {
        if (opt.selected && opt.value.charAt(opt.value.length - 1) === '/') {
          opt.selected = false;
          event.stop();
          loadDir(opt.value);
        }
      });
    }
  });
  if (Util.getFromStorage('wvLastFilePicker')) {
    loadDir(Util.getFromStorage('wvLastFilePicker'));
  } else {
    Base.jsonReq('GET', '/tutorialsDirectory').wait(function (data) {
      loadDir(data);
    });
  }
};

Store.fileLoadDialog = function () {
  var close = Layout.customDialog('importServer-dialog',
    importserverfileTemplate, {
      ok: ok,
      cancel: function () {
        cancelUpload();
        close();
      },
    }, false, false);

  // list of async's that can be used to abort pending requests.
  // an .ok() call will attempt to abort, a .fail() call will
  // cancel any chance to abort.
  var abortRequests = [];
  var cancelled = false;

  // runs when cancel button clicked on this dialog
  function cancelUpload() {
    // set cancelled first, because the wait function waiting for the requests
    // to complete will trigger immediately after they are aborted, and before
    // we can set cancelled to true.
    cancelled = true;
    abortRequests.forEach(function (async) {
      if (!async.fired) {
        async.ok(true);
      }
    });
  }

  // runs when ok button clicked on this dialog
  function ok(data) {
    var files = data.files;
    var type = data.type;
    var context = data.context;
    var continueOnError = data.continueOnError;
    var useAgload = data.useAGLoad;
    var bulkLoad = data.bulkLoad;
    var bulkLoaders = data.bulkLoaders;
    var attributes = data.attributes;
    var externalReferences = data.externalReferences;
    var externalReferenceTimeout = data.externalReferenceTimeout;
    var baseURI = data.baseURI;
    var jsonLdStoreSource = data.jsonLdStoreSource;
    function asReq(file) {
      var abortAsync = new Async();
      abortRequests.push(abortAsync);

      var async = Base.req('POST', Base.url('statements', {
        'file': file,
        'type': type,
        'context': context,
        'bulkLoad': bulkLoad,
        'continueOnError': continueOnError,
        'useAgload': useAgload,
        'bulkLoaders': bulkLoaders,
        'attributes': attributes,
        'externalReferences': externalReferences,
        'externalReferenceTimeout': externalReferenceTimeout,
        'baseURI': baseURI,
        'jsonLdStoreSource': jsonLdStoreSource,
      }), {
        abort: abortAsync,
        body: '' });

      return async;
    }
    var full = context && Part.NameSpaces.restore(context);
    if (context && !full) {
      Layout.showMessage(
        '"' + context + '" is not a valid part.', false, true);
    } else if (!files.length) {
      Layout.showMessage('You have to select at least one file.', false, true);
    } else {
      Base.load(
        MAsync.collect.apply(null, Util.map(asReq, files)), 'Importing')
          .wait(function () {
            close();
            App.refresh();
            if (!cancelled) {
              Layout.showMessage('Files successfully loaded.',
                  Layout.SHORT_DELAY, false);
            } else {
              Layout.showMessage('Server-side import cancelled by user.',
                  Layout.LONG_DELAY, false);
            }
          }, function (message) {
            refreshStoreSize();
            Base.failMessage('Importing', message);
            $$('upload-ok').disabled = false;
          });
    }

    // Disable the OK button once the uploads have started.
    $$('upload-ok').disabled = true;
  }
};

Store.bulkModeEnable = function (node) {
  Base.load(
    Base.jsonReq('PUT', 'bulkMode'),
      'Enabling bulk-load mode',
      function (_) {
        bulkModeTemplate.cast(node, { current: true });
        Store.bulkDialog = Store.bulkModeDisable;
      });
};

Store.bulkModeDisable = function (node) {
  Base.load(
    Base.jsonReq('DELETE', 'bulkMode'),
    'Disabling bulk-load mode',
    function (_) {
      bulkModeTemplate.cast(node, { current: false });
      Store.bulkDialog = Store.bulkModeEnable;
    });
};

Store.bulkDialogInitial = function (node) {
  Base.load(
    Base.jsonReq('GET', 'bulkMode'),
    'Opening durability control',
    function (bulkMode) {
      Store.bulkDialog = (bulkMode ? Store.bulkModeDisable : Store.bulkModeEnable);
      bulkModeTemplate.cast(node, { current: bulkMode });
    });
};

Store.bulkDialog = Store.bulkDialogInitial;

Store.deleteRepoQuery = function (name) {
  return Base.load(
    Store.delRepoData('query.' + name), 'Deleting', App.refresh.bind(App));
};

Store.addRepoQuery = function (query) {
  let title = query.title;
  if (!title) {
    Layout.showPrompt('add-query', 'Give a name for this query.', ok, null, null, false);
  } else {
    ok(title);
  }
  function ok(title) {
    if (!title) {
      return;
    }
    Base.load(
      Store.setJsonRepoData('query.' + title, query), 'Adding', function () {
        App.goTo(Dispatch.relativeUrl('query/r/' + title));
      });
  }
};

Store.textareaDialog = function () {
  var close = Layout.customDialog('importText-dialog',
                                  importTextareaTemplate,
                                  { start: upload,
                                    cancel: function () {
                                      cancelUpload();
                                      close();
                                    },
                                  });

  // Will be bound to an async that can be used to abort the pending
  // request.  an .ok() call will attempt to abort, a .fail() call
  // will cancel any chance to abort. The value is not set unless
  // upload has been started
  var abortAsync;
  var cancelled = false;

  function cancelUpload() {
    // set cancelled first, because the wait function waiting for the requests
    // to complete will trigger immediately after they are aborted, and before
    // we can set cancelled to true.
    cancelled = true;
    if (abortAsync && !abortAsync.fired) {
      abortAsync.ok(true);
    }
  }

  function upload(content, form) {
    var guessContentType = 'application/x-franz-guess-format';
    var type = $('select[name="content-type"]', form).val() || guessContentType;
    var context = $('input[name="context"]', form).val() || null;
    var attributes = $('input[name="attributes"]', form).val() || null;
    var relaxSyntax = $('input[name="relaxSyntax"]:checked', form).val();
    var externalReferences = $('input[name="externalReferences"]:checked', form).val();
    var externalReferenceTimeout = $('input[name="externalReferenceTimeout"]', form).val() || null;
    var baseURI = $('input[name="baseURI"]', form).val() || null;
    var storeSource = $('input[name="storeSource"]:checked', form).val();
    var suppressDuplicateEmbeddedTriples =
          $('input[name="suppressDuplicateEmbeddedTriples"]:checked', form).val();

    abortAsync = new Async();
    var async = Base.req('POST',
        Base.url('statements', {
          context: context,
          attributes: attributes,
          relaxSyntax: relaxSyntax,
          externalReferences: externalReferences,
          externalReferenceTimeout: externalReferenceTimeout,
          baseURI: baseURI,
          jsonLdStoreSource: storeSource,
          suppressDuplicateEmbeddedTriples: suppressDuplicateEmbeddedTriples,
        }), {
          abort: abortAsync,
          contentType: type,
          body: content,
        });

    Base.load(async, 'Importing')
        .wait(
          function () {
            close();
            App.refresh();
            if (!cancelled) {
              Layout.showMessage('Statements successfully loaded.',
                  Layout.SHORT_DELAY, false);
            } else {
              Layout.showMessage('Statements import cancelled by user.',
                  Layout.LONG_DELAY, false);
            }
          },
          function (message) {
            refreshStoreSize();
            var dialogMessage = message;
            if (type === guessContentType) {
              // Guess parser type from error message
              var parserErrors = [
                { regex: /TriX/, name: 'TriX' },
                { regex: /Sax/, name: 'RDF/XML' },
                { regex: /TriG/, name: 'TriG' },
                { regex: /NQX/, name: 'Extended N-Quads' },
              ];
              var parserError = _.find(parserErrors, function (error) {
                return message.match(error.regex);
              });
              if (parserError) {
                dialogMessage = 'Guessed format as ' + parserError.name
                  + ' but parser failed with message:\n'
                  + message;
              }
            }
            Base.failMessage('Importing', dialogMessage);
            $$('upload-ok').disabled = false;
          });

    // Disable the OK button once the uploads have started.
    $$('upload-ok').disabled = true;
  }
};

// Display function for #query/db/NAME
Store.showRepoQuery = function (name) {
  Base.load(
    Store.getJsonRepoData('query.' + name), 'Fetching query',
    function (query) {
      if (!query) {
        App.goTo('');
      } else {
        Query.doShowQuery(query);
      }
    });
};

Store.showReplication = function () {
  if (!User.userPerm('replication')) {
    User.denied();
    return;
  }
  Base.load(
    MAsync.collect(
      Base.catchNotFound(Base.jsonReq('GET',
                                        Base.serverUrlNoSession('warmstandby'))),
      Base.jsonReq('GET', Base.serverUrlNoSession('noCommit'))),
    'Fetching status', function (data, noComm) {
      if (data && (
        (data.type === 'server' && Util.some(function (cl) {
          return cl.switchingRoles;
        }, data.clients)) ||
        (data.type === 'client' && data.exiting))) {
        setTimeout(App.refreshIfSamePage(), 500);
      }
      replicationTemplate.cast(Layout.getPage(), { data: data, noComm: noComm });
    });
};

Store.startWarmStandby = function (jobname, server, port, user, password) {
  var args = {
    jobname: jobname,
    primary: server, primaryPort: port,
    user: user, password: password,
  };
  Base.load(
    Base.req('PUT', Base.serverUrlNoSession('warmstandby', args)), 'Starting job',
    App.refresh.bind(App));
};

Store.stopWarmStandby = function () {
  Base.load(
    Base.req('DELETE', Base.serverUrlNoSession('warmstandby')),
             'Stopping job', App.refresh.bind(App));
};

Store.switchStandbyRoles = function (
    jobname, user, password, becomeClient, allowCommit) {
  var args = {
    jobname: jobname,
    user: user,
    password: password,
    becomeClient: becomeClient,
    enableCommit: allowCommit,
  };
  Base.load(
    Base.req('POST', Base.serverUrlNoSession('warmstandby/switchRole', args)),
    'Switching roles', App.refresh.bind(App));
};

Store.switchNoCommit = function (on) {
  Base.load(
    Base.req(on ? 'PUT' : 'DELETE', Base.serverUrlNoSession('noCommit')), 'Setting no-commit',
    App.refresh.bind(App));
};

// Warmup

Store.showWarmupDialog = function () {
  var close = Layout.customDialog('warmup-dialog',
    warmupTemplate, {
      ok: doWarmup,
      cancel: function () {
        close();
      },
    }, false, false);

  function doWarmup(includeTriples, includeStrings) {
    Base.load(
      Base.req('PUT', Base.url('warmup'),
               { includeTriples: includeTriples,
                 includeStrings: includeStrings }),
      'Warmup triple store',
      function () {
        Layout.showMessage('Triple store warmed up', false, false);
        close();
      }
    );
  }
};

// Convert store into a replication instance

Store.showConvertStoreDialog = function () {
  function dummyHostPortInfo() {
    // in the event that the GET /hostPortInfo
    // failed create a dummy hostPortInfo
    // object that allows the dialog to be shown.
    var port = Base.rootLocation.port;
    var scheme = Base.rootLocation.protocol;
    var hostname = Base.rootLocation.hostname;
    var http = 0;
    var https = 0;

    if (Util.isLoopback(hostname)) {
      hostname = '';
    }

    if (scheme === 'http:') {
      http = port;
    } else {
      https = port;
    }

    return { hostname: hostname, http: http, https: https };
  }

  Base.jsonReq('GET', Base.url('/hostPortInfo'))
      .catch(dummyHostPortInfo)
      .then(function (hostPortInfo) {
        Store.showConvertStoreDialogCont(hostPortInfo);
        Store.maybeEnableConvertStoreButton();
        // force the dialog to be visible
        window.scrollTo(0, 0);
      });
};

Store.showConvertStoreDialogCont = function (hostPortInfo) {
  var close = Layout.customDialog('convertStore-dialog',
          convertStoreTemplate, {
            ok: doConvert,
            cancel: function () {
              close();
            },
            reponame: App.currentRepository,
            catalog: (App.currentCatalog === '/' ? '' : App.currentCatalog),
            hostPortInfo: hostPortInfo,
          }, false, false);

  function doConvert(scheme, host, port, catalog, name, group, user, password, instanceName) {
    $$('convertStoreButton').disabled = 'true';
    $('#workingMessage').html('Working ...');
    var args = ReplUtil.configParams(scheme, host, port, catalog, name,
                                     group, user, password, instanceName);
    args.ifExists = 'use'; // convert not create instance
    Base.req('PUT', Base.serverUrlNoSession('repl/createCluster', args)).then(
            function (_val) {
              close();
              Layout.showMessage(
                  'The store has been converted to a replication instance.',
                  false,
                  false);
              App.refresh();
            },
            function (message) {
              // fail - reenable the dialog for another try
              $$('convertStoreButton').disabled = null;
              $('#workingMessage').html('');
              Layout.showMessage(
                   'Conversion to an instance failed with message:\n' + message,
                   false,
                   true);
              App.refresh();
            });
  }
};

Store.maybeEnableConvertStoreButton = function () {
  // check that the necessary fields are filled in
  // before enabling the convert Store button

  var disabled = Util.idIsEmpty('host') || Util.idIsEmpty('port');

  $$('convertStoreButton').disabled = disabled;
};


Store.setPortToMatchScheme = function (hostPortInfo) {
  // in the convert dialog if the user switches between
  // http and https change the port number automatically.
  var scheme = Util.selectValue($$('scheme'));

  $$('port').value = hostPortInfo[scheme];
};


// Exporting

// The download service will make a request to a given relative path
// and send back the results, adding content-disposition headers
// so that the browser will offer to save the result as a file.
Store.downloadRepository = function (format) {
  Catalog.makeMaybeVerifyLargeOperationWarning(function () {
    var tp = Util.findIf(function (tp) {
      return tp[1] === format;
    }, Query.TRIPLE_CONTENT_TYPES);
    if (tp) {
      User.maybeShowLimitedResultsWarning('only a limited number of triples will be downloaded');
      var file = (App.currentRepository || 'export') + '.' + tp[2];
      Server.download({
        // This will be the suggested file name.
        file: file,
        // Accept header will be added to the request.
        accept: format,
        // The download service only accepts relative URLs. We get one by
        // replacing the server URL by an empty string.
        path: Base.serverUrl('statements', {}, { rootUrl: '' }),
      });
    }
  })();
};

// See comment in downloadRepository() above.
Store.downloadDuplicates = function (mode) {
  var tp = Util.findIf(function (tp) {
    return tp[0] === 'N-Quads';
  }, Query.TRIPLE_CONTENT_TYPES);
  if (tp) {
    User.maybeShowLimitedResultsWarning('only a limited number of duplicates will be downloaded');
    var file = (App.currentRepository || 'export') + '-duplicates.' + tp[2];
    Server.download({
      // This will be the suggested file name.
      file: file,
      // Accept header will be added to the request.
      accept: tp[1],
      // The download service only accepts relative URLs. We get one by
      // replacing the server URL by an empty string.
      path: Base.url(Base.serverUrl('statements/duplicates', {}, { rootUrl: '' }),
                                    { mode: mode }),
    });
  }
};

// Backups

Store.backupDialog = function () {
  Layout.showPrompt('backup',
    'Server-side file name to store the backup ' +
    '(this must not already exist)',
    Catalog.makeMaybeVerifyLargeOperationWarning(ok),
    null, false, false);
  function ok(file) {
    Base.load(
      Base.req('POST', Base.url('backup', { target: file })), 'Backing up',
      function (status) {
        Layout.showMessage(
          'Backup finished.' + (status ? ' Output:\n\n' + status : ''), false, false);
      });
  }
};

Store.restoreRepository = function (name, file, asReplica) {
  Base.load(
    Base.req(
      'PUT',
      Base.url('repositories/', name, { restore: file, replica: asReplica })),
    'Restoring', function (status) {
      Layout.showMessage(
        'Restore finished. ' + (status ? ' Output:\n\n' + status : ''), false, false);
      App.refresh();
    });
};

// Materialization handling
Store.showMaterializeDialog = function () {
  var $mat = $('#materializing');
  if ($mat.html()) {
    return;
  }

  var close = Layout.customDialog('materialize-dialog',
    materializeTemplate, {
      ok: ok,
      cancel: function () {
        close();
      },
    }, false, false);
  function ok(withRules, useTypeSubproperty, commit) {
    close();
    $mat.html(' [running]');
    Base.load(
      Base.req('PUT', Base.url('materializeEntailed', {
        'with': withRules,
        'useTypeSubproperty': useTypeSubproperty,
        'commit': commit,
      })),
      'Materializing entailed triples', function (n) {
        $mat.html('');
        App.refresh();
        Layout.showMessage(n + ' materialized triples added.', false, false);
      });
  }
};

// Duplicate handling

Store.showDuplicateDialog = function () {
  var close = Layout.customDialog('duplicates-dialog',
    duplicatesTemplate, {
      ok: doit,
      cancel: function () {
        close();
      },
    }, false, false);
  function doit(value) {
    Base.load(
      Base.req('DELETE', 'statements/duplicates?mode=' + value),
      'Deleting duplicates', function (status) {
        Layout.showMessage(
          'Deleted ' + status + ' duplicate statements', false, false);
        App.refresh();
        close();
      });
  }
};

Store.showSuppressDuplicateDialog = function () {
  Base.load(
    Base.jsonReq('GET', 'suppressDuplicates'), 'Fetching current setting',
    function (suppressDuplicates) {
      var close = Layout.customDialog('suppressDupes-dialog',
        suppressDuplicatesTemplate,
        {
          cancel: function () {
            close();
          },
          suppressDuplicates: suppressDuplicates,
          ok: function doit(value) {
            Base.load(
              Base.req('PUT', 'suppressDuplicates?type=' + value),
              'Setting duplicate suppression strategy',
              function (/* status*/) {
                Layout.showMessage(
                  'Set duplicate suppression strategy.', false, false);
                App.refresh();
                close();
              });
          },
        },
        false, false);
    });
};

// Solr integration

Store.showSolr = function () {
  Base.jsonReq('GET', 'solrParameters').wait(function (params) {
    solrTemplate.cast(Layout.getPage(), {
      'solr_endpoint': params.endpoint,
      'solr_id_field': params.idfield,
    });
    $$('solr-parameter-set').disabled = true;
  });
};

Store.setSolrParameters = function (endpoint, idfield) {
  Base.load(
    Base.req('POST', Base.url('solrParameters', {
      endpoint: endpoint,
      idfield: idfield,
    })),
    'Setting Solr parameters',
    function () {
      Layout.showMessage('Solr parameters updated');
      $$('solr-parameter-set').disabled = true;
      App.refresh();
    });
};

// MongoDB integration

Store.showMongo = function () {
  var DEFAULT_MONGO_PORT = 27017;
  Base.jsonReq('GET', 'mongoParameters').wait(function (params) {
    mongoTemplate.cast(Layout.getPage(), {
      'mongo_server': params.server ? params.server : 'localhost',
      'mongo_port': params.port ? params.port : DEFAULT_MONGO_PORT,
      'mongo_database': params.database,
      'mongo_collection': params.collection,
      'mongo_user': params.user,
    });
    $$('mongo-parameter-set').disabled = true;
  });
};

Store.setMongoParameters = function (params) {
  Base.load(
    Base.req('POST', Base.url('mongoParameters', params)),
    'Setting MongoDB parameters', function () {
      Layout.showMessage('MongoDB parameters updated');
      $$('mongo-parameter-set').disabled = true;
      App.refresh();
    });
};

Store.setGeospatialDatatypeAutomation = function (value) {
  Base.load(
    Base.req(value ? 'PUT' : 'DELETE', 'nd/geospatialDatatypeAutomation'),
    'Setting Geospatial Datatype Automation', function (/* data*/) {
      $('#repoGeospatialDatatypeAutomation').attr('checked', value);
    });
};

Store.showAttributeDefinitionsPage = function () {
  if (!User.userPerm('super')) {
    User.denied();
    return;
  }
  var controller = new Store.TripleAttributePageController();
  controller.loadAttributeDefinitions().wait(function () {
    controller.loadCurrentFilterValue().wait(function () {
      attributeDefinitionsTemplate.cast(Layout.getPage(), controller);
    });
  });
};

Store.autoOptStartMessage = 'Start background Automatic Optimizer';
Store.autoOptStopMessage = 'Stop background Automatic Optimizer';

Store.autoOptimizerToggle = function () {
  // start or stop the auto optimizer based on what
  // the web page says right now.
  var dostart = $('#startstopopt').html() === Store.autoOptStartMessage;
  Base.req('PUT', dostart ? 'auto/optimize/start' : 'auto/optimize/stop');
  Store.showOptRunning(dostart);
};

Store.setAutoOptState = function () {
  // based on whether the auto optimizer is running change the message
  // on the repo overview page to say either Start or Stop the
  // optimizer the next time the link is clicked.
  Base.req('GET', 'auto/optimize/status').wait(
          function (value) {
            Store.showOptRunning(value === 'running');
          });
};

Store.showOptRunning = function (isRunning) {
  // make it visible whether we're running the auto opt or not
  $('#startstopopt').html(isRunning ? Store.autoOptStopMessage
    : Store.autoOptStartMessage);
  $('#autooptrunning').html(isRunning ?
    '<b>&nbsp;running</b>' : ' ');
};

Store.autoOptimizerMessage = function () {
  Base.req('GET', 'auto/optimize/message').wait(
       function (value) {
         Layout.showMessage(value, true, true);
       });
};

Store.commitRepo = function () {
  Base.load(
    Base.req('POST', 'commit'),
    'commiting repository',
    function () {
      App.refresh();
      Layout.showMessage('Repository changes committed', Layout.SHORT_DELAY, false);
    });
};

Store.rollbackRepo = function () {
  Base.load(
    Base.req('POST', 'rollback'),
    'rolling back the repository',
    function () {
      App.refresh();
      Layout.showMessage('Repository changes undone', Layout.SHORT_DELAY, false);
    });
};

Store.TripleAttributePageController = function () {
  var savedAttributes = [];

  /**
   * Gets the store's attribute defintions from the server.
   *
   * @return {Async}
   */
  this.loadAttributeDefinitions = function () {
    return Base.load(
      Base.req('GET', 'attributes/definitions'), 'Getting definitions').then(
      function (value) {
        var currentAttributes = Util.readJSON(value) || {};
        _.each(currentAttributes,
          function (it) {
            // AG's JSON writer emits nil as [] so we need to coerce it to false.
            if (typeof(it.ordered) === 'object') {
              it.ordered = false;
            }
          });
        savedAttributes = _.each(currentAttributes, _.identity);
      });
  };

  this.getDefinitions = function () {
    return savedAttributes;
  };

  /**
   * Delete the attribute at specified index
   *
   * @param {string} attributeIndex - Index of the attribute.
   */
  this.deleteAttributeDefinition = function (attributeIndex) {
    var that = this;
    var attribute = savedAttributes[attributeIndex];
    that.sendDeleteRequest(attribute.name).then(function () {
      savedAttributes.splice(attributeIndex, 1);
      that.updatePage();
      Layout.showMessage('Attribute "' + attribute.name + '" has been deleted.',
                        Layout.SHORT_DELAY);
    }, function (message) {
      message = message || 'Cannot delete attribute "' + attribute.name +
                                                      '" Unknown error occurred.';
      Layout.showMessage(message, true, true);
    });
  };

  /**
   * Save new attribute to the server
   */
  function save() {
    var saveButton = $('#buttonSaveNewAttribute');
    var cancelButton = $('#buttonCancelNewAttribute');
    saveButton.prop('disabled', true);
    cancelButton.prop('disabled', true);

    var newAttribute = {
      'name': $('#attributeName').val() || '',
      'minimum-number': $('#attributeMin').val() || null,
      'maximum-number': $('#attributeMax').val() || null,
      'allowed-values': splitAttributeAllowedValues($('#attributeAllowed').val()),
      'ordered': $('#attributeOrdered').prop('checked'),
    };

    var that = this;

    this.sendNewAttributeDefinition(newAttribute).then(function () {
      that.hideNewAttributeForm();
      Layout.showMessage('Attribute "' + newAttribute.name + '" has been added.',
                          Layout.SHORT_DELAY);
    }, function (message) {
      message = message || 'Cannot save the attribute. Unknown error occured.';
      Layout.showMessage(message, Layout.LONG_DELAY, true);
      saveButton.prop('disabled', false);
      cancelButton.prop('disabled', false);
    });
  }

  this.hideNewAttributeForm = function () {
    Layout.removeMessages();
    Store.showAttributeDefinitionsPage();
  };

  this.showNewAttributeForm = function () {
    var buttonAdd = $('#buttonAddNewAttribute');
    buttonAdd.prop('disabled', true);

    var that = this;
    var close = Layout.customDialog('attrDefs-dialog',
                                    attributeDefinitionsDialogTemplate, {
                                      save: function () {
                                        save.call(that);
                                        buttonAdd.prop('disabled', false);
                                      },
                                      cancel: function () {
                                        buttonAdd.prop('disabled', false);
                                        close();
                                      },
                                    }, false, false);
  };

  function layoutPageWithAttributes() {
    attributeDefinitionsTemplate.cast(Layout.getPage(), this);
  }
  this.layoutPageWithAttributes = layoutPageWithAttributes;

  function splitAttributeAllowedValues(input) {
    var values = [];
    var SEARCHING = 0;
    var ESCAPING = 1;
    var QUOTING = 2;
    var isInQuotes = false;
    var token = '';
    var state = SEARCHING;
    var spaces = '';

    for (var i = 0; i < input.length; i++) {
      var char = input.charAt(i);
      switch (state) {
        case SEARCHING:
          if (char === '\\') {
            state = ESCAPING;
          } else if (char === '"') {
            isInQuotes = true;
            state = QUOTING;
          } else if (char === ',') {
            collectCurrentToken();
          } else if (char === ' ') {
            spaces += ' ';
          } else {
            if (token === '') {
              // ignore leading spaces
              spaces = '';
            }
            if (token !== '') {
              token += spaces;
              spaces = '';
            }
            token += char;
          }
          break;
        case QUOTING:
          if (char === '\\') {
            state = ESCAPING;
          } else if (char === '"') {
            isInQuotes = false;
            state = SEARCHING;
          } else {
            token += char;
          }
          break;
        case ESCAPING:
          token = token + char;
          state = isInQuotes ? QUOTING : SEARCHING;
          break;
      }
    }
    collectCurrentToken();

    if (state !== SEARCHING) {
      Base.failMessage('Attribute Definition',
        'Unable to parse Allowed Values in "' + input + '"');
    }

    function collectCurrentToken() {
      if (token !== '') {
        values.push(token);
      }
      token = '';
    }
    return values;
  }

  this.sendNewAttributeDefinition = function (attributeDefinition) {
    return Base.load(
      Base.jsonReq(
        'POST',
        'attributes/multiDefinitions',
        {
          'body': Util.writeJSON([attributeDefinition]),
          'contentType': 'application/json',
        }),
        'Sending definitions'
    );
  };

  this.sendDeleteRequest = function (name) {
    return Base.jsonReq('DELETE',
     'attributes/definitions?name=' + name + '&error=false');
  };

  this.updatePage = function () {
    this.layoutPageWithAttributes();
  };

  /**
   * Editor showing the current (saved on the server) filter value.
   *
   * @type {CodeMirror}
   */
  var currentEditor = null;

  /**
   * Value of the current filter fetched from the server, or null if not yet
   * fetched.
   *
   * @type {string|null}
   */
  var currentFilterValue = null;

  /**
   * Refreshes the current filter value from the server.
   *
   * @return {Async}
   */
  function loadCurrentFilterValue() {
    return Base.load(
      Base.req('GET', 'attributes/staticFilter'), 'Getting filter').then(
        function (value) {
          currentFilterValue = value;
        });
  }

  this.loadCurrentFilterValue = loadCurrentFilterValue;

  function setPlaceholder(editor) {
    editor.setOption('placeholder', 'Click to define a static filter');
  }

  function unsetPlaceholder(editor) {
    editor.setOption('placeholder', '');
  }

  /**
   * Updates the static filter input with the latest filter value from the server.
   * If there is a filter, enable the 'Delete Filter' button.
   */
  function updateCurrent() {
    loadCurrentFilterValue().wait(function () {
      var staticFilterIsEmpty = currentFilterValue === '';
      currentEditor.setValue(currentFilterValue);
      $$('sf_buttonDelete').disabled = staticFilterIsEmpty;
      if (staticFilterIsEmpty) {
        setPlaceholder(currentEditor);
      }
    });
  }

  function setStaticFilterModeline(text, color='inherit') {
    Util.setColoredText($$('staticFilterModeLine'), text, color);
    return;
  }

  /**
   * Sets the static filter dialog read-only, and disables all
   * buttons that act on it.
   */
  function disableEditor() {
    currentEditor.options.readOnly = true;
    $$('sf_buttonCancel').disabled = true;
    $$('sf_buttonSave').disabled = true;
    $$('sf_buttonDelete').disabled = true;

    if (savedAttributes.length === 0) {
      setStaticFilterModeline(
        '[ An attribute must be defined before defining a static filter ]',
        'red');
    } else {
      setStaticFilterModeline('');
    }

    updateCurrent();
    currentEditor.display.input.blur();
  }

  /**
   * If there are any triple attributes defined, make the static
   * filter editor editable. Does nothing if the dialog is already
   * editable, so we can preserve any existing edits if the dialog is
   * blurred then refocused.
   * If no triple attributes are defined, the editor remains read-only
   * and is blurred (will not accept focus).
   */
  this.maybeEnableEditor = function () {
    if (savedAttributes.length === 0) {
      disableEditor();
      return;
    }
    if (currentEditor.options.readOnly) {
      unsetPlaceholder(currentEditor);
      setStaticFilterModeline('[ editing ]');
      currentEditor.options.readOnly = false;
      $$('sf_buttonCancel').disabled = false;
    }
  };

  /**
   * Cancel an in-progress edit. Reverts the value of the dialog
   * to the current static filter, and sets the dialog ReadOnly.
   */
  this.cancelFilterEdit = function () {
    disableEditor();
  };

  function staticFilterChanged() {
    var editorValue = currentEditor.getValue();
    return ((editorValue.length !== currentFilterValue.length) ||
            (currentFilterValue.localeCompare(editorValue) !== 0));
  }

  function maybeEnableStaticFilterSaveButton() {
    var button = $$('sf_buttonSave');
    var oldState = button.disabled;

    button.disabled = !staticFilterChanged();
    if (oldState === true && button.disabled === false) {
      setStaticFilterModeline('[ changed ]');
    } else if (oldState === false && button.disabled === true) {
      setStaticFilterModeline('[ editing ]');
    }
  }

  this.applyDeleteFilter = function () {
    Base.load(Base.req('DELETE', 'attributes/staticFilter'),
              'Removing filter', disableEditor);
  };

  /**
   * Sends the value of the editable editor to the server as the new value
   * of static filter. If successful, refreshes the read-only editor value
   * to match it.
   */
  this.applyEditFilter = function () {
    var filter = currentEditor.getValue();
    if (filter.length === 0) {
      this.applyDeleteFilter();
    } else {
      Base.load(
        Base.req(
          'POST', Base.url('attributes/staticFilter', { filter: filter })),
        'Setting filter', disableEditor);
    }
  };

  /**
   * Initializes the static filter CodeMirror editor.
   *
   * @param {HTMLTextAreaElement} node - Placeholder for CodeMirror.
   */
  this.setupEditor = function (node) {
    currentEditor = setupEditorForNode(node, currentFilterValue);
    disableEditor();
  };

  /**
   * Replaces node with a CodeMirror instance filled with initialContents.
   *
   * @param {HTMLTextAreaElement} node - Placeholder for CodeMirror.
   * @param {string} initialContents - Initial value of the editor.
   * @return {CodeMirror}
   */
  function setupEditorForNode(node, initialContents) {
    var editor = CodeMirror.fromTextArea(node, { mode: 'text/x-lisp' });
    editor.setOption('lineNumbers', false);
    editor.setOption('lineWrapping', true);
    editor.setValue(initialContents || '');
    setPlaceholder(editor);
    // Hooking to the CM "change" event ensures that deleting a
    // character (e.g. via backspace), is detected as a change event.
    editor.on('change', function (_cm, _change) {
      maybeEnableStaticFilterSaveButton();
    });

    return editor;
  }
};

/**
 * A controller for the `user/namespace-section` and
 * `user/query-option-section` templates. For now, it's also used as
 * the controller for `user/namespace_table` and
 * `user/query_option_table` templates.
 *
 * @param {(NamespaceEntry|QueryOptionEntry)[]} options - options to be rendered.
 * @param {Object} config - configuration for the template
 * @constructor
 */
Store.OptionSectionController = function (options, config) {
  this.options = options;
  this.config = config;
};

Store.shutdownInstance = function () {
  Base.load(
    Base.req('POST', Base.url('shutdown', { ensureNotLingering: true }), {}),
    'Starting shutdown operation',
    function () {
      Layout.showMessage('Shutdown operation has been initiated.'
                         + ' The repository instance will terminate once it\'s no longer in use.');
      App.goTo(Dispatch.url(''));
    }
  );
};

export default Store;
