import Anystats from './anystats';

/** @namespace Replstats */

var Replstats = {};

Replstats.showReplstats = function (page) {
  Anystats.showAnystats(page,
          [
            {
              div: '#commitsChart',
              lines: [
                { key: 'cb',
                  label: 'Commits Behind',
                  color: 'rgba(240,10,10,0.7)' },
                { key: 'iq',
                  label: 'Ingest Queue Length',
                  color: 'rgba(90,10,240,0.7)' },
              ],
              gOptions: {
                series: {
                  stack: false,
                  lines:
                          {
                            show: true,
                            fill: false,
                          },
                },
                yaxis: { min: 0 },
              },
            },
            {
              div: '#transactionChart',
              lines: [
                { key: 'incpm',
                  label: 'Ingested Transactions per minute',
                  color: 'rgba(90,10,240,0.7)' },
                { key: 'lcpm',
                  label: 'Local Transactions per minute',
                  color: 'rgba(240,10,10,0.7)' },
              ],
              gOptions: {
                series: {
                  stack: false,
                  lines:
                          {
                            show: true,
                            fill: false,
                          },
                },
                yaxis: { min: 0 },
              },
            },
          ], 'repl/replStats', 'unused', null, null);
};

export default Replstats;
