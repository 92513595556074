
import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var editor = null;
__out.push("\n<h2>Functor definition</h2>\n\n<!-- TODO link to documentation -->\n\n<div class=\"inputfield\" id=\"functor-input\">\n  <textarea></textarea>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){editor = $arg.make($node);}) + "\"></var>");
__out.push("\n</div>\n\n<p>\n  <button>Save</button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.ok(editor.getValue());
});}) + "\"></var>");
__out.push("\n  <button>Cancel</button>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel(editor.getValue());
});}) + "\"></var>");
__out.push("\n</p>\n\n<p class=\"subtle\">Enter Prolog functor definitions in the input area\nabove. After pressing 'save', they will be defined in your current\nsession, and you will be able to use them in your queries.</p>\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;