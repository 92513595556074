import Layout from '../../layout';
import { $$ } from '../../util';
import Store from '../../store';
import Base from '../../base';
import setImmediate from '../../set-immediate';
import $ from 'jquery';

import importFileFormatTemplate from './import-file-format.mold';
import importAutodetectHelpTemplate from './import-autodetect-help.mold';
import importAgloadOptionsTemplate from './import-agload-options.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<form class=\"small-form\" method=\"post\" enctype=\"multipart/form-data\"\n      action=\"\"\n      id=\"file-upload-form\">\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "submit", function($event, $node) {
if ($node.elements.body.value) {
        $event.stop();
        $arg.start( Store.gatherUploadDialogParameters( $node ));
      } else {
        $event.stop();
        Layout.showMessage('Please select a file.', false, true);
      }
  
});}) + "\"></var>");
__out.push("\n  <table width=\"100%\">\n    <tr>\n      <td>\n        <p>Select one or more local files to import</p>\n      </td>\n    </tr>\n    <tr>\n      <td>\n        <label class=\"heading\" for=\"upload-body\">File:</label>\n          <input type=\"file\" id=\"upload-body\" name=\"body\" multiple=\"multiple\"/>\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
$$('upload-ok').disabled = $node.value === '';
});}) + "\"></var>");
__out.push("\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){$node.focus();
              if (window.opera) {
                $$("upload-ok").disabled = false;
              }
          }) + "\"></var>");
__out.push("\n          ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
Store.guessFileType('upload-', $node.value); 
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n    <tr>\n      <td>\n        ");
__out.push(importFileFormatTemplate.template({ prefix: 'upload-' }) );
__out.push("\n        <div class=\"subtle\" id=\"autodetect-help\">\n          ");
__out.push(importAutodetectHelpTemplate.template() );
__out.push("\n        </div>\n      </td>\n    </tr>\n    ");
__out.push(importAgloadOptionsTemplate.template({ prefix: 'upload-', extendedOptions: true}) );
__out.push("\n    <tr>\n      <td>\n      <div class=\"subtle\">\n        <p>Uploading files larger than 100 MB using a browser may cause performance issues.\nWe suggest using server-side file loading mode to efficiently import larger datasets.</p>\n        </div>\n      </td>\n    </tr>\n    <tr><td><div id=\"triple-file-upload-info\"></div></td></tr>\n    <tr>\n      <td class=\"buttons\">\n        <!-- If the last input happens to be an unchecked checkbox,\n             IE will send an invalid request when the form is submitted\n             through XMLHttpRequest. So we add a hidden field here. -->\n        <input type=\"hidden\" name=\"internet-explorer-is\" value=\"suboptimal\" />\n        <button type=\"submit\" id=\"upload-ok\" disabled>OK</button>\n        <button type=\"button\">Cancel</button>");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
$arg.cancel();
});}) + "\"></var>");
__out.push("\n      </td>\n    </tr>\n  </table>\n</form>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function () {
  $('#file-upload-form').tooltip();
});
}) + "\"></var>");
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;