import Base from './base';

var Gruff = {};

// Invoking gruff requires `eval' permission from the currently
// logged in user.
//
// We Asynchronously fetch the /gruff url so the auth of the current
// AG user auth token is automatically passed with the request
// instead of needing to be entered again by the user.
// If there's a way to open() or load a new document into a new
// window w/o passing the auth in the URL (as userinfo), I'd
// love to learn it.
//
// fetch the xhr.responseURL produced by this request (the service
// we call redirects to this url) and open it in a new window.
// The new window is where gruff will load.
//
// In the current window, return to the prior entry in the
// history list. Gruff links appear in the catalog and
// the repository overview pages, so backing up returns us to the
// location where it was clicked.
Gruff.startGruff = function () {
  let async = Base.req('GET', Base.serverUrl('gruff'));
  async.wait(function () {
    window.open(async.other.responseURL);
    window.history.back();
  });
};

export default Gruff;
