import Base from './base';

// UI imports
import User from './user';
import Layout from './layout';
import $ from 'jquery';

// templates
import createTemplate from './templates/indices/create.mold';
import optimizeTemplate from './templates/indices/optimize.mold';
import changeTemplate from './templates/indices/change.mold';
import listTemplate from './templates/indices/list.mold';
import settingsTemplate from './templates/indices/settings.mold';
import { MAsync } from './async';

/**
 * Functionality related to index management.
 *
 * @namespace Indices
 */
var Indices = {};

/**
 * Description of an existing index.
 *
 * @typedef {Object} Index
 * @property {string} name - Index flavor.
 * @property {int} style - Index style, either 1 or 2.
 * @property {string} status - Describes the currently running optimization.
 * @property {int} chunks - Number of disk chunks used by the index.
 * @property {int} size - Disk usage in bytes.
 * @property {number} oscore - Optimization score.
 * @property {string} scoreClass - Describes how well the index is optimized.
 *                                 Possible values: 'good', 'bad', 'ugly'.
 */

/**
 * Retrieves the list of currently defined indices and adapts it to
 * the format expected by the manager template.

 * @return {Async<Array<Index>>} - A promise that will resolve to a
 *                                 list of index descriptions.
 */
Indices.list = function () {
  return Base.jsonReq(
    'GET', Base.serverUrl('reports', { path: 'indices' }, { useShard: true })
  ).then(function (report) {
    var data = report.fields[3].value.map(function (v) {
      return {
        name: v[0], style: v[1], status: v[2], chunks: v[3],
        size: v[4], oscore: v[5].score, scoreClass: v[5].class,
      };
    });
    return data;
  });
};

/**
 * List indices that can be created.
 *
 * @return {Async<Array<string>>} - A promise that will resolve to a list
 *                                  of flavor names.
 */
Indices.listMissing = function () {
  return Base.jsonReq('GET', 'indices?listUndefined=true');
};

/**
 * Runs index optimization.
 *
 * @param {string|Array<String>} [index] - Index name or array of index names.
 *                                         If not given all indices will be
 *                                         optimized.
 * @param {int} [level] - Optimization level, optional (the default is to let
;;                        the server decide).
 * @return {Async} - A promise that will be resolved when the operation
 *                   is finished.
 */
Indices.optimize = function (index, level) {
  var args = {
    wait: true,
  };
  if (index !== undefined) {
    args.index = index;
  }
  if (level !== undefined) {
    args.level = level;
  }

  return Base.req('POST', Base.url('indices/optimize', args));
};


/**
 * Purges deleted triples from index / indices.
 *
 * @param {string|Array<String>} index - Index name or array of index names.
 * @return {Async} - A promise that will be resolved when the operation
 *                   is finished.
 */
Indices.purge = function (index) {
  return Base.req('POST', Base.url('jobs/purge-deleted-triples', {
    index: index,
  }));
};

/**
 * Creates a new index.
 *
 * @param {string} flavor - Index to create.
 * @param {int} [style] - Index style, Can be 0, 1 or 2.
 *                        If 0 or not given the style will be
 *                        determined by the server.
 * @return {Async} - A promise, resolved when the operation is complete.
 */
Indices.create = function (flavor, style) {
  var args = {};
  if (style !== undefined) {
    args.style = style;
  }
  return Base.req('PUT', Base.url('indices/', flavor, args));
};


/**
 * Changes the style of an existing index.
 *
 * @param {string} flavor - Index to modify.
 * @param {int} style - New index style, Can be 1 or 2.
 * @return {Async} - A promise, resolved when the operation is complete.
 */
Indices.update = function (flavor, style) {
  // Create will rebuild the index if required.
  return Indices.create(flavor, style);
};

/**
 * Deletes an index.
 *
 * @param {String} flavor - Index to delete.
 * @return {Async} - A promise, resolved when the operation is complete.
 */
Indices.remove = function (flavor) {
  return Base.req('DELETE', Base.url('indices/', flavor));
};

/**
 * Gets the purge rate limit.
 *
 * @return {Async<int>} - A promise that will resolve to the requested value.
 */
Indices.getPurgeRateLimit = function () {
  return Base.req('GET', Base.url('settings/purge-rate-limit'));
};

/**
 * Sets the purge rate limit.
 *
 * @param {int} rateLimit - New rate limit.
 * @return {Async} - A promise, resolved when the operation is complete.
 */
Indices.setPurgeRateLimit = function (rateLimit) {
  return Base.req('POST', Base.url('settings/purge-rate-limit', {
    rate: rateLimit,
  }));
};

//
// UI
//

Indices.showAddDialog = function (callback) {
  Indices.listMissing().then(function (flavors) {
    if (flavors.length === 0) {
      Layout.showMessage(
        'All available indices have already been added.', false, true);
      return;
    }
    var close = Layout.customDialog('addIndex-dialog',
      createTemplate, {
        options: flavors,
        cancel: function () {
          close();
        },
        ok: function (flavor, style) {
          Base.loadWithDefaultErrorHandler(
            Indices.create(flavor, style),
            'Creating index: ' + flavor).then(function () {
              close();
              if (callback) {
                callback();
              }
            });
        },
      },
      false, false);
  });
};

Indices.showOptimizeDialog = function (flavors, callback) {
  var close = Layout.customDialog('optimize-dialog',
    optimizeTemplate, {
      cancel: function () {
        close();
      },
      ok: function (level) {
        Base.loadWithDefaultErrorHandler(
          Indices.optimize(flavors, level), 'Optimizing').then(function () {
            close();
            if (callback) {
              callback();
            }
          });
      },
    },
    false, false);
};

Indices.showSettingsDialog = function (callback) {
  Indices.getPurgeRateLimit().then(function (rateLimit) {
    var close = Layout.customDialog('purgeSettings-dialog',
      settingsTemplate, {
        rateLimit: rateLimit,
        cancel: function () {
          close();
        },
        ok: function (newRateLimit) {
          if (newRateLimit !== rateLimit) {
            Base.loadWithDefaultErrorHandler(
              Indices.setPurgeRateLimit(newRateLimit),
              'Changing settings').then(function () {
                close();
                if (callback) {
                  callback();
                }
              });
          }
        },
      },
      false, false);
  });
};

Indices.showChangeDialog = function (indices, callback) {
  var close = Layout.customDialog('indexStyle-dialog',
    changeTemplate, {
      cancel: function () {
        close();
      },
      ok: function (style) {
        var asyncs = indices.filter(function (index) {
          return index.style !== style;
        }).map(function (index) {
          return Indices.update(index.name, style);
        });
        if (asyncs.length > 0) {
          Base.loadWithDefaultErrorHandler(
            MAsync.collect.apply(MAsync, asyncs),
            'Rebuilding').then(function () {
              close();
              if (callback) {
                callback();
              }
            });
        }
      },
    },
    false, false);
};

Indices.updateTripleCount = function () {
  Base.jsonReq('GET', 'size').then(
      function (size) {
        var node = $('#index-list-triple-count');
        // The node might not exist if the URL has changed
        if (node.length) {
          node.text('Triple count: ' + size.toLocaleString());
        }
      },
      function () {
        var node = $('#index-list-triple-count');
        // The node might not exist if the URL has changed
        if (node.length) {
          node.html('<span class="error">Unable to fetch triple count</span>');
        }
      });
};

Indices.showIndices = function () {
  // Implements DataTables AJAX interface
  var load = function (_data, callback) {
    Base.load(Indices.list(), 'Fetching indices', function (indices) {
      callback({ data: indices });
    });
  };
  var ctrl = {};
  $.extend(ctrl, {
    renderIndexTable: function (node) {
      // eslint-disable-next-line new-cap
      var table = $(node).DataTable({
        columns: [
          // Synthetic column for checkboxes
          {
            orderable: false,
            className: 'select-checkbox',
            width: '1em',
            data: function () {
              return '';
            },
            title: '<i class="regular-button-icon select-all fa fa-check"></i>',
          },
          {
            title: 'Name',
            data: 'name',
          },
          {
            title: 'Style',
            data: 'style',
          },
          {
            title: 'Status',
            data: 'status',
          },
          {
            title: 'Disk chunks',
            data: 'chunks',
          },
          {
            title: 'Disk usage',
            data: 'size',
            render: function (data, type) {
              if (type === 'display') {
                return Base.printBytes(data);
              } else {
                // Use raw value for sorting and filtering
                return data;
              }
            },
          },
          {
            title: 'Oscore',
            data: 'oscore',
            render: function (data, type, row) {
              // Number of decimal places to show
              var OSCORE_PRECISION = 3;
              if (type === 'display') {
                return data.toFixed(OSCORE_PRECISION) +
                  '<div class="oscore-indicator oscore-' + row.scoreClass +'"></div>';
              } else {
                // Use raw value for sorting and filtering
                return data;
              }
            },
          },
        ],
        // Data loading callback
        ajax: load,
        // Order by name
        order: [[1, 'asc']],
        // Allow selecting multiple rows
        select: {
          style: 'multi',
        },
        // No need for pagination, there can't be that many indices
        paginate: false,
        // No need for pagination control, filtering etc.
        dom: 't',
        // This is used to persist selections on refresh
        rowId: 'name',
        // Allow resizing to work
        autoWidth: false,
        // Colors
        stripeClasses: ['pure-table-odd', 'pure-table-even'],
      });

      // Save the table reference in the controller
      ctrl.table = table;

      // Check data attributes on a node to see if it should be enabled
      var shouldDisable = function (node) {
        var perm = node.getAttribute('data-required-permission');
        var access = node.getAttribute('data-required-access');
        return (perm && !User.userPerm(perm)) ||
          (access && !User.userAccess(access));
      };

      // Triggered on selection change + once after setup
      var updateButtons = function () {
        var selected = table.rows({ selected: true }).count();
        $('.index-op').prop('disabled', function () {
          return selected === 0 || shouldDisable(this);
        });
      };
      table.on('select', updateButtons);
      table.on('deselect', updateButtons);
      $('button').prop('disabled', function () {
        return shouldDisable(this);
      });
      updateButtons();

      // Make the header act as a select / deselect all button
      table.on('click', '.select-all', function () {
        var selected = table.rows({ selected: true } ).count();
        var total = table.rows().count();
        if (selected === total) {
          // All are selected - deselect
          table.rows().deselect();
        } else {
          // Some rows are not selected
          table.rows().select();
        }
      });
    },
    showAddDialog: function () {
      Indices.showAddDialog(function () {
        ctrl.refresh();
      });
    },
    showOptimizeDialog: function () {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        var flavors = ctrl.getSelectedFlavors();
        Indices.showOptimizeDialog(flavors, function () {
          ctrl.refresh();
        });
      }
    },
    showChangeDialog: function () {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        var indices = ctrl.table.rows({ selected: true }).data();
        Indices.showChangeDialog(indices, function () {
          ctrl.refresh();
        });
      }
    },
    showSettingsDialog: function () {
      Indices.showSettingsDialog(function () {
        ctrl.refresh();
      });
    },
    refresh: function () {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        ctrl.table.ajax.reload();
      }
      Indices.updateTripleCount();
    },
    getSelectedFlavors: function () {
      var selected = ctrl.table.rows({ selected: true }).data();
      return selected.map(function (row) {
        return row.name;
      });
    },
    optimizeSelected: function () {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        Base.loadWithDefaultErrorHandler(
          Indices.optimize(ctrl.getSelectedFlavors()),
          'Optimizing').then(ctrl.refresh.bind(ctrl));
      }
    },
    removeSelected: function () {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        var rows = ctrl.table.rows({ selected: true });
        var asyncs =
            ctrl.getSelectedFlavors().map(Indices.remove.bind(Indices));
        var async = MAsync.collect.apply(MAsync, asyncs)
            .protect(ctrl.refresh.bind(ctrl));
        Base.loadWithDefaultErrorHandler(async, 'Deleting indices').then(function () {
          // A refresh at this point seems to return stale data
          // So let's just delete the rows locally
          rows.remove();
          ctrl.table.draw();
        });
      }
    },
    purgeSelected: function () {
      // Do nothing if the table hasn't been loaded yet
      if (ctrl.table) {
        Base.loadWithDefaultErrorHandler(
          Indices.purge(ctrl.getSelectedFlavors()),
          'Purging').then(ctrl.refresh.bind(ctrl));
      }
    },
  });
  listTemplate.cast(Layout.getPage(), ctrl);
  Indices.updateTripleCount();
};

export default Indices;
