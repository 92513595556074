import Dispatch from '../../dispatch';
import Util from '../../util';
import Query from '../../query';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Saved Queries</h2>\n\n<ul>\n  ");
Mold.forDepth++;
Mold.forEach($arg, function(name, $i) {
__out.push("\n    <li><a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query/s/' + Util.encodeFragment(name)) ));
__out.push("\">");
__out.push(Mold.escapeHTML(name));
__out.push("</a>\n        <button class=\"small-delete-button\"><i class='fa fa-times-circle' title=\"delete saved query\"></i></button>\n        ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Query.deleteSavedQuery(name);
});}) + "\"></var>");
__out.push("\n    </li>\n  ");
});
Mold.forDepth--;
__out.push("\n</ul>\n");
if (!$arg.length) {
__out.push("\n  <p>You haven't saved any queries yet. <a href=\"");
__out.push(Mold.escapeHTML(Dispatch.relativeUrl('query') ));
__out.push("\">Make one.</a></p>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;