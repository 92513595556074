import Catalog from '../catalog';
import setImmediate from '../set-immediate';
import CodeMirror from 'codemirror';
import $ from 'jquery';

import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
var editor;

function ndShowExample1() {
  Catalog.Nd.ndShowExample("_lat_la_-9.+1_+9.+1_+1.-4_+1.-3_lon_lo_-1.8+2_+1.8+2_+1.-4_+1.-3_time_ti_+3.5976+9_+3.6291+9_+1.-1");
}

function ndShowExample2() {
  Catalog.Nd.ndShowExample("_X_ca_+0.+0_+1.+2_+1.-4_+1.-1_Y_ca_+0.+0_+1.+2_+1.-4_+1.-1_Z_ca_+0.+0_+1.+2_+1.-4");
}

__out.push("\n\n<div>\n<h2>N-Dimensional Geospatial Datatype Designer</h2>\n<div id=\"ndDatatypeDesigner\">\n<div id=\"ndLHS\">\n\n  <div id=\"ndDatatype\">\n    <h3>Datatype <span id='datatypeModeline' class='notes'></span></h3>\n    <div id=\"ndDatatypeInner\">\n    <div id=\"ndDT2\"><textarea id='ndDatatypeSpecifier' rows=\"6\" cols=\"40\" placeholder=\"Enter\">http://franz.com/ns/allegrograph/5.0/geo/nd#</textarea>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){editor = CodeMirror.fromTextArea($node, { mode: 'text/n-triples' });
      editor.setOption("lineNumbers", false);
      editor.setOption("lineWrapping", true);
      $node.editor = editor;
      }) + "\"></var>");
__out.push("\n    </div>\n    </div>\n    <div id=\"ndDatatypeInstructions\">\n      <!--\n      <button id=\"btnDataTypeToOrdinates\">Compute Ordinates</button>\n      <-?event click ndDatatypeToOrdinates();?->\n      -->\n    </div>\n  </div>\n\n  <div id=\"ndRHS\">\n    <h3>Example subtypes</h3>\n    <ul>\n      <li>Spherical Earth coordinates and time <button class=\"pure-button\">try it</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
ndShowExample1();
});}) + "\"></var>");
__out.push("</li>\n      <li>X, Y, Z coordinates <button class=\"pure-button\">try it</button>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
ndShowExample2();
});}) + "\"></var>");
__out.push("</li>\n    </ul>\n  </div>\n\n  <div id=\"ndOrdinates\">\n    <h3>Ordinates</h3>\n    <table id=\"tblOrdinates\">\n      <thead>\n        <tr>\n        <th></th>\n        <th></th>\n        <th></th>\n        <th></th>\n        <th></th>\n        <th  id=\"ndFirstHeader\" colspan=\"2\">Resolution</th>\n        <th></th>\n        <th></th>\n        <th></th>\n        </tr>\n        <tr>\n        <th></th>\n        <th>Name</th>\n        <th>Type</th>\n        <th>Minimum</th>\n        <th>Maximum</th>\n        <th>Requested</th>\n        <th>Actual</th>\n        <th>Strip Width</th>\n        <th></th>\n        <th></th>\n        </tr>\n      </thead>\n      <tbody>\n      </tbody>\n    </table>\n    <br>\n    <div id=\"ndTableButtonRow\" class=\"pure-button-group\">\n      <button id=\"ndBtnAdd\" class=\"pure-button\">\n        <i class=\"regular-button-icon fas fa-plus\"></i> Add New Ordinate Row\n      </button>\n    </div>\n    <br>\n    <div id=\"ndButtonRow\" class=\"pure-button-group\">\n      <button id=\"ndBtnClear\" class=\"pure-button\">\n        <i class=\"danger-button-icon fas fa-trash-alt\"></i> Start Over\n      </button>\n      <!--\n      <button id=\"btnOrdinatesToDatatype\">Compute Datatype</button>\n      <-?event click ndOrdinatesToDatatype();?->\n      -->\n    </div>\n  </div>\n  <div id=\"ndDescription\">\n  </div>\n</div>\n</div>\n</div>\n\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  $("#ndBtnClear").bind("click", Catalog.Nd.ndClearOrdinates.bind(Catalog.Nd));
  $("#ndBtnAdd").bind("click", Catalog.Nd.ndAddTableRow.bind(Catalog.Nd));
  editor.focus();
  editor.on('change', function () {
    Catalog.Nd.ndStartUpdateTimer('datatype');
  });
  Catalog.Nd.ndClearOrdinates();
});
}) + "\"></var>");
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;