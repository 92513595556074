import Script from '../../script';
import Util from '../../util';
import { $$ } from '../../util';
import User from '../../user';
import App from '../../app';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<h2>Server Scripting</h2>\n\n");
var editor = null;
__out.push("\n\n<p>\n  <label for=\"userscripts\" style=\"display: inline-block; min-width: 6em;\">Userscripts:</label>\n  <select id=\"userscripts\" style=\"min-width: 11em\"><option value=\"//\">Loading...</option></select>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Script.fetchUserScripts($node);}) + "\"></var>");
__out.push("\n  <span class=\"linkish subtle\">[load]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
var val = Util.selectValue($$('userscripts'));
      if (val !== '//') {
        Script.showScript(val, editor);
      }
  
});}) + "\"></var>");
__out.push("\n  <span class=\"linkish subtle\">[delete]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
var val = Util.selectValue($$('userscripts'));
      if (val !== '//') {
        Script.deleteScript(val, "user");
      }
  
});}) + "\"></var>");
__out.push("\n  <br/>\n  <label for=\"sitescripts\" style=\"display: inline-block; min-width: 6em;\">Sitescripts:</label>\n  <select id=\"sitescripts\" style=\"min-width: 11em\"><option value=\"//\">Loading...</option></select>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Script.fetchSiteScripts($node);}) + "\"></var>");
__out.push("\n  <span class=\"linkish subtle\">[load]</span>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
var val = Util.selectValue($$('sitescripts'));
      if (val !== '//') {
        Script.showScript(val, editor);
      }
  
});}) + "\"></var>");
__out.push("\n  ");
if (User.userPerm("super")) {
__out.push("\n    <span class=\"linkish subtle\">[delete]</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
var val = Util.selectValue($$("sitescripts"));
        if (val !== '//') {
          Script.deleteScript(val, "site");
        }
    
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n  ");
if (App.isInRepoOrSession()) {
__out.push("\n    <br/>\n    <label for=\"reposcripts\" style=\"display: inline-block; min-width: 6em;\">Reposcripts:</label>\n    <select id=\"reposcripts\" style=\"min-width: 11em\"><option value=\"//\">Loading...</option></select>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){Script.fetchRepoScripts($node);}) + "\"></var>");
__out.push("\n    <span class=\"linkish subtle\">[load]</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
var val = Util.selectValue($$('reposcripts'));
        if (val !== '//') {
          Script.showScript(val, editor);
        }
    
});}) + "\"></var>");
__out.push("\n    ");
if (User.userAccess("w")) {
__out.push("\n      <span class=\"linkish subtle\">[delete]</span>\n      ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
var val = Util.selectValue($$('reposcripts'));
          if (val !== '//') {
            Script.deleteScript(val, "repo");
          }
      
});}) + "\"></var>");
__out.push("\n    ");
}
__out.push("\n  ");
}
__out.push("\n\n\n</p>\n\n<div class=\"inputfield\" id=\"script-input\">\n  <textarea></textarea>\n  ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){editor = Script.initScriptEditor($node, $arg.code, $arg.language);}) + "\"></var>");
__out.push("\n</div>\n\n<p>\n  ");
if (User.userPerm("eval")) {
__out.push("\n    Language: <select id=\"scriptlang\">\n                 <option value=\"JS\" ");
if ($arg.language === 'JS') {
__out.push("selected");
}
__out.push(">JavaScript</option>\n                 <option value=\"Lisp\" ");
if ($arg.language === 'Lisp') {
__out.push("selected");
}
__out.push(">Lisp</option>\n              </select>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
editor.setOption('mode', Script.modeFromLanguage(Util.selectValue($node)));
});}) + "\"></var>");
__out.push("\n    &nbsp; &nbsp;\n  ");
}
__out.push("\n  ");
if (App.isInRepoOrSession()) {
__out.push("\n    <button>Run</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Script.runScript(
                    editor.getValue(), Util.selectValue($$('scriptlang')),
                    Util.selectValue($$('scriptaccept')));
});}) + "\"></var>");
__out.push("\n    (view as <select id=\"scriptaccept\">\n               <option value=\"application/json\" selected>JSON</option>\n               <option value=\"text/plain\">Text</option>\n             </select>)\n    &nbsp; &nbsp;\n  ");
}
__out.push("\n  ");
function save(flavor) {
         Script.saveScript(
           flavor, editor.getValue(), Util.selectValue($$('scriptlang')),
           $$("scriptname").value, $$("script-saving"));
       }
  
__out.push("\n  ");
if (User.userPerm("super")) {
__out.push("\n    <select>\n      <option selected>Save as</option>\n      <option value=\"user\">&nbsp;Userscript</option>\n      <option value=\"site\">&nbsp;Sitescript</option>\n      ");
if (App.isInRepoOrSession()) {
__out.push("<option value=\"repo\">&nbsp;Reposcript</option>");
}
__out.push("\n    </select>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "change", function($event, $node) {
var val = Util.selectValue($node);
	           save(val);
                   $node.selectedIndex = 0; 
});}) + "\"></var>");
__out.push("\n  ");
} else {
__out.push("\n    <button>Save as</button>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
save(true);
});}) + "\"></var>");
__out.push("\n  ");
}
__out.push("\n  <input type=\"text\" id=\"scriptname\" value=\"");
__out.push(Mold.escapeHTML($arg.name || ""));
__out.push("\"/>\n  ");
if (!User.userPerm("super")) {
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "enter", function($event, $node) {
save(true);
});}) + "\"></var>");
}
__out.push("\n  <span id=\"script-saving\"></span>\n</p>\n\n<div id=\"scriptoutput\"></div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;