import Reports from '../../reports';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg.path && $arg.path.length > 1 ) {
__out.push("\n  <ul class=\"report-nav\">\n    ");
Mold.forDepth++;
Mold.forEach($arg.path.slice(0, $arg.path.length - 1) , function(elt, $i) {
__out.push("\n      <li>\n        ");
if (elt.disabled ) {
__out.push("\n          ");
__out.push(Mold.escapeHTML(elt.label ));
__out.push("\n        ");
} else {
__out.push("\n          <a href=\"");
__out.push(Mold.escapeHTML(Reports.reportUrl($arg.path.slice(0, $i + 1)) ));
__out.push("\">\n            ");
__out.push(Mold.escapeHTML(elt.label ));
__out.push("\n          </a>\n        ");
}
__out.push("\n      </li>\n    ");
});
Mold.forDepth--;
__out.push("\n    <li>");
__out.push(Mold.escapeHTML($arg.label ));
__out.push("</li>\n  </ul>\n");
}
__out.push("\n\n<h2>");
__out.push(Mold.escapeHTML($arg.label ));
__out.push("</h2>\n");
if ($arg.description ) {
__out.push("\n  <div class=\"report-description\">");
__out.push(Mold.escapeHTML($arg.description ));
__out.push("</div>\n");
}
__out.push("\n<dl class='report'>\n  ");
Mold.forDepth++;
Mold.forEach($arg.fields, function(field, $i) {
__out.push("\n    ");
var type = Reports.getFieldType(field);
      var cls = type ? 'class="report-field-' + type + '"' : '';
    
__out.push("\n    <dt ");
__out.push(cls );
__out.push(">\n      ");
if (field.next ) {
__out.push("\n        <a href=\"");
__out.push(Mold.escapeHTML(Reports.reportUrl($arg.path, field.next) ));
__out.push("\">\n          ");
__out.push(Mold.escapeHTML(field.label ));
__out.push("\n        </a>\n      ");
} else {
__out.push("\n        ");
__out.push(Mold.escapeHTML(field.label ));
__out.push("\n      ");
}
__out.push("\n    </dt>\n    <dd ");
__out.push(cls );
__out.push(">");
__out.push(Reports.renderField($arg.path, field) );
__out.push("</dd>\n  ");
});
Mold.forDepth--;
__out.push("\n</dl>");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;