import Base from '../../base';
import { $$ } from '../../util';
import setImmediate from '../../set-immediate';
import $ from 'jquery';
import infoTemplate from './info.mold';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<div id=\"queryResults\">\n\n  <h2>SPARQL ");
__out.push(Mold.escapeHTML($arg.verb ));
__out.push(" query plan</h2>\n  <div id=\"queryWarnings\"><h2>Query had warnings</h2></div>\n    \n  <h3>Variables</h3>\n  ");
Mold.forDepth++;
Mold.forEach($arg.variables , function(name, $i) {
__out.push("\n    <span>");
__out.push(Mold.escapeHTML(name));
__out.push("</span>\n    ");
});
Mold.forDepth--;
__out.push("\n\n  ");
__out.push(infoTemplate.template($arg.information) );
__out.push("\n\n  <h3>Plan</h3>\n  <pre>\n  ");
__out.push(Mold.escapeHTML($arg.plan ));
__out.push("\n  </pre>\n</div>\n\n");
__out.push("<var class=\"__mold " + Mold.addSnippet(function($node){setImmediate(function(){
  if ($$('queryInformation')) {
    $("#queryResults").tabs({});
  }
  if ($$('.queryWarningFlag')) {
    $('.queryWarnings').css('display', 'block');
  } else {
    $('.queryWarnings').css('display', 'none');
  }
});
}) + "\"></var>");
__out.push("\n\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;