
import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n\n<div id=\"systemstatDialog\" class=\"dialog\">\n\n  <div class=\"systemstatFlotControls flotControls\">\n    <!-- These controls (buttons, etc.) deserve better presentation. -->\n    <h3>Server<br>Stats</h3>\n    <div>\n      <button id=\"refresh\" class=\"flotButtonClass\">Refresh</button><br/>\n      <button id=\"unzoom\" class=\"flotButtonClass\">Unzoom</button><br/>\n      <div class=\"flotControlHeader\">Time Zone:</div>\n      <button id=\"timeZone\" class=\"flotButtonClass\">local</button><br/>\n      <div class=\"flotControlHeader\">Auto refresh:</div>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"0\" id=\"autoNever\">never<br/></label>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"1\">1 sec</label><br/>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"2\" checked=\"checked\">2 sec</label><br/>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"5\">5 sec</label><br/>\n      <label><input name=\"autoUpdate\" type=\"radio\" class=\"radioClass\" value=\"30\">30 sec<br/></label>\n      <div class=\"flotControlHeader\">Charts size:</div>\n      ");
Mold.forDepth++;
Mold.forEachIn($arg.sizes, function(key, value, $i) {
__out.push("\n      <label>\n        <input name=\"yScale\" type=\"radio\" class=\"radioClass\"\n               ");
if (key === $arg.defaultSize ) {
__out.push("checked=\"true\"");
}
__out.push("\n               value=\"");
__out.push(Mold.escapeHTML(value));
__out.push("\">");
__out.push(Mold.escapeHTML(key));
__out.push("</label><br/>\n      ");
});
Mold.forDepth--;
__out.push("\n    </div>\n  </div>\n\n  ");
if ($arg.name) {
__out.push("\n    <h2>Charts for ");
__out.push(Mold.escapeHTML($arg.name));
__out.push("</h2>\n  ");
}
__out.push("\n\n  <div class=\"systemstatFlotcontent\">\n    <div class=\"flot-container\">\n      ");
Mold.forDepth++;
Mold.forEachIn($arg.graphs, function(i, graph, $i) {
__out.push("\n        <p class=\"chartTitle\" id=");
__out.push(Mold.escapeHTML(graph.div.substr(1) + "Header"));
__out.push(">");
__out.push(Mold.escapeHTML(graph.description));
__out.push("</p>\n        <div id=");
__out.push(Mold.escapeHTML(graph.div.substr(1)));
__out.push(" class=\"flot-placeholder flot-placeholder1\"></div>\n      ");
});
Mold.forDepth--;
__out.push("\n    </div>\n  </div>\n</div>\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;