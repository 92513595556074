
import Mold from "!../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n<p><b>Full URL:</b></p>\n<p class=\"full-request-uri\">");
__out.push(Mold.escapeHTML($arg.url));
__out.push("</p>\n\n");
if (Object.keys($arg.params).length > 0) {
__out.push("\n  <p><b>Query parameters:</b></p>\n  <ul>\n    ");
Mold.forDepth++;
Mold.forEachIn($arg.params, function(key, value, $i) {
__out.push("\n      <li><b>");
__out.push(Mold.escapeHTML(key));
__out.push("</b>: ");
__out.push(Mold.escapeHTML(value));
__out.push("</li>\n    ");
});
Mold.forDepth--;
__out.push("\n  </ul>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;