import Base from '../../base';
import User from '../../user';
import Store from '../../store';

import Mold from "!../../lib/mold";
var Template = {};
Template.template = function templateFunction($arg, __output){
var __out = __output || [];
__out.push("\n");
if ($arg) {
__out.push("\n  <p id=\"repo-info\">");
__out.push(Base.textToHTML($arg));
__out.push("</p>\n");
}
__out.push("\n");
if (User.userPerm("super")) {
__out.push("\n  <p class=\"subtle\">\n    <span class=\"linkish\">[edit description]</span>\n    ");
__out.push("<var class=\"__mold " + Mold.addSnippet(function(__node){Mold._attachEvent(__node, "click", function($event, $node) {
Store.editRepositoryInfo($arg);
});}) + "\"></var>");
__out.push("\n  </p>\n");
}
__out.push("\n");
return __output ? "" : __out.join("");
};
Template.cast = function (target, $arg) {
  return Mold.cast(target, Template.template, $arg);
}
export default Template;